import styled from 'styled-components';
import {error, success} from '../../assets/symbols/symbols/Symbols.css';
import {BGImageCenter, dFlexCenter, ellipsis, mobileMedia} from '../../globalVariables/GlobalVariables';
import {options} from '../../pages/attendance/participantsPage/participant/Participant.css';
import {animate} from '../animation/Animation.css';

export const container=styled.div` 
    width: 85px;
    height: 145px;
    margin: 3px;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    scale: 0;
    transition: all 400ms;
  &:hover{
    ${options}{
      opacity: 1;
    }
  }
    ${animate}{
      width:200% ;
      top: -30px;
    }
    ${mobileMedia}{
      margin-inline-start:14.5px
    }
 
 
  &.show{
    scale: 1;
  }
`;

export const image=styled.div` 
  height:70px;
  width:70px;
  ${BGImageCenter(props => props.symbol,'cover')};
  background-image:url("${p=>p.face}");
  transition: all 300ms;
  border-radius:50%;
  &.bigV{
    background-image:unset;
    }
  &.timer{
    display: none;
}
`;

export const wrapImage=styled.div` 
  height:80px;
  width: 80px;
  position:relative ;
  ${dFlexCenter}
  background-color: #FFF;
  border-radius:50%;
  box-shadow: 0 3px 6px #00000029;
  cursor: pointer;
  transition: all 300ms;
  &.bigV{
    background-color:#C6D600;
    display:block ;
    &::before{
      content: "";
      height:80px;
      width: 80px;
      display: block;
      ${BGImageCenter(success,'25px')};
    }
  }
&.timer{
  background-color:#C6D600;
  background-image: unset;
  ${dFlexCenter}
  flex-direction: column;
  font-size: 26px;
  font-weight: 700;
  cursor: unset;
  line-height: 22px;
}
`;

export const body=styled.div` 
    text-align: center;
    height: 65px;
    margin-top: 10px;
    line-height: 14px;
`;

export const name=styled.div` 
  font-weight: 600;
  width: 100px;
  ${ellipsis}
`;

export const smallPresent=styled.div` 
    height:22px;
    width: 22px;
    background-color: #C6D600;
    border-radius:50%;
    ${BGImageCenter(success, '10px 10px')}
    position: absolute;
    top: 0px;
    right: 3px;
`;

export const unverifiedUser= styled.div`
    height:18px;
    width: 18px;
    background-color:#DD2F21;
    border-radius:50%;
    ${BGImageCenter(error, '10px 9px')}
    position: absolute;
    bottom: 2px;
    left: 3px;
`;
