import styled from 'styled-components';

export const ticket = styled.div`
    width:29px;
    height: 29px;
    border-radius: 50%;
    margin-${props => props.theme.rtl ? 'right' : 'left'}:2px;
    background-color: ${props => (props.color)};
    background-image: url("${props => (props.animalSymbol)}");
    background-repeat: no-repeat;
    background-size: 15px;
    background-position: center;
`;
