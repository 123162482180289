import styled from 'styled-components';
import {payAttention} from '../../../../../assets/symbols/symbols/Symbols.css';
import {BGImageCenter, mobileMedia} from '../../../../../globalVariables/GlobalVariables';

export const lightBlue = styled.div`
    background-color: #76D6E8;
    height: fit-content;
    border-radius: 5px;
    padding: 2px 10px;
    display: flex;
`;

export const icon = styled.div`
    ${BGImageCenter(payAttention, '16px')}
    height: 25px;
    width: 25px;
    background-position: center 6px;
`;

export const text = styled.div`
    width: 100% ;
    margin-inline-start: 10px;
    font-size: 14px;
    line-height: 25px;
    font-weight:500;
    ${mobileMedia}{
        line-height:18px;
    }
`;
