import styled from 'styled-components';
import realtimeFonts from '../../fonts';
import {getColor} from '../../eventRealtime/EventRealtime.css';

export const wrapTimer = styled.div`
    grid-area: timer;
    position: relative;
    display: grid;
    justify-items: end;
    width: 100%;
`;
export const wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;
export const title = styled.div`
    ${realtimeFonts.text}
    color: ${p=>getColor(p.theme.isDarkMode)};
    width: 155px;
`;
export const timer = styled.div`
    font-weight: 800;
    font-size: 18px;
    color: ${p=>getColor(p.theme.isDarkMode)};
    direction: ltr;
`;
export const formats = styled.div`
    ${realtimeFonts.text}
    color: ${p=>getColor(p.theme.isDarkMode)};
    word-spacing: ${p=>p.theme.rtl ? '30px' : '15px'};
    position: relative;
    inset-inline-end: 5px;
`;

