import styled from 'styled-components';
import zIndex from '../../../../globalVariables/zIndex';

export const picker = styled.div`
    position: absolute;
    background: #fff;
    width: 300px;
    bottom:${props=>props.bottom};
    z-index: ${zIndex.dropDown};
    right:${props=>props.right};
    box-shadow: 0px 13px 24px #00000029;
    height:max-content;
`;