import styled from 'styled-components';
import {emailVerification} from '../../../../assets/symbols/images/verifyEmail';
import {dFlexCenter, mobileMedia, tabletMedia} from '../../../../globalVariables/GlobalVariables';
import {btn} from '../../../../component/button/btn/Btn.css';

export const container=styled.div`
  width: 100%;
  height: 100%;
  background-image: url("${emailVerification}");
  background-size: 190px;
  background-repeat: no-repeat;
  background-position: ${props=>props.theme.rtl?'30px':'calc(100% - 30px)'} center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding:60px ;

  ${tabletMedia} {
    padding: unset;
    padding: 30px 40px;
  }

  ${mobileMedia} {
    align-items: center;
    background-image:none;
  }
  `;

export const title=styled.div`
  font-weight: 800;
  font-size:16px;
`;

export const text=styled.div`
  width: calc(100% - 190px);
 ${mobileMedia} {
    width: max-content;
    max-width: 100%;
  }
`;

export const wrapBtn=styled(text)`
  ${dFlexCenter}
`;

export const wrapInput=styled.div`
 width:200px;
`;

export const emailMessage=styled(text)`
white-space: pre-line;
  ${mobileMedia} {
   height:200px ;
   ${dFlexCenter}
  }
`;

export const buttons = styled.div`
  display: flex;
  ${btn}{
    margin:0 3px;
    min-width: 90px;
    height: 30px;
    &.resent{
      background-color: white;
      border:1px solid ;
    }
    &.send{
      background-color: #dddddd;
    }

}
`;