import styled from 'styled-components';
import {BGImageCenter, dFlexCenter, mobileMedia} from '../../../globalVariables/GlobalVariables';
import {emptyScreen} from '../../../assets/symbols/images/emptyScreen';
import {emptyMobileScreen} from '../../../assets/symbols/images/emptyMobileScreen';
import zIndex from '../../../globalVariables/zIndex';

export const container = styled.div`
    width: 100%;
    ${dFlexCenter}
    flex-direction: column;
    `;

export const title = styled.div`
    letter-spacing: 0.7px;
    font-size: 25px;
    font-weight: 700;  
    padding-top: 50px;
    ${mobileMedia}{
        padding-top: 20px;
    }
`;

export const description = styled.div`
    padding: 10px 20px 20px 20px;
    font-size: 15px;
`;
export const images = styled.div`
    display: flex;
    width: 100%;
    ${mobileMedia}{
        align-items: center;
        flex-direction: column-reverse;
    }

`;
export const left = styled.div`
    height: 100%;
    flex-direction: column;
    display: flex;
    align-items:center ;
    width:55% ;
`;
export const right = styled(left)`
    position: relative;
    ${dFlexCenter}
    width: 40%;
     &::after{
        content: '';
        width: 0px;
        height: 224px;
        border: 1px solid #B1DEEF;
        opacity: 0.55;
        inset-inline-end: 0;
        position: absolute;
        }
    ${mobileMedia}{
        padding-top: 20px;
        &::after{
            height: 0;
            width: 200%;
            inset-inline-end: -50%;
            top: 15px;
        }
    }
    `;

export const size = styled.div`
font-size: 11px;
letter-spacing: 0.8px;
padding-top: 5px;

`;

export const border=styled.div`
width: 80%;
height: 93%;
margin-inline-start: 12%;
z-index: ${zIndex.baseHighest};
position: relative;


`;
export const mobileImage = styled.div`
        height: 210px;
        width: 180px;
        position: relative;
        margin-bottom: 20px;
        inset-inline-start: ${p=>p.theme.rtl?'unset':'20px'};
    &::before{
        pointer-events: none;
        inset-inline-start: -18px;
        position: absolute;
        content :'';
        z-index: ${zIndex.baseHigh};
        height: 240px;
        width: 200px;
        ${BGImageCenter(emptyMobileScreen,'200px')}
    }
    ${mobileMedia}{
        margin-top: 10px;
    }
    `;

export const wrapMobileImage = styled.div`
    position: absolute;
    overflow: hidden;
    background-image: url('${p=>p.image}');
    background-size: 100%;
    background-position: center center;
    background-repeat:no-repeat ;
    width: 93px;
    height: 120px;
    inset-inline-start: ${p=>p.theme.rtl?'46':'25'}px;
    top: 54px;
    z-index: ${zIndex.baseRaised};
`;
export const descImage = styled(mobileImage)`
    width: 300px;
    inset-inline-start: ${p=>p.theme.rtl?'0':'-30'}px;
    &::before{        
        width: 300px;
        inset-inline-start: 13px;

        ${BGImageCenter(emptyScreen,'420px')}
    }
    ${mobileMedia}{
        margin-inline-start: 15px;
    }
    `;
export const wrapDescImage = styled(wrapMobileImage)`
    background-size: ${p=>p.imageWidth}px;
    width: 212px;
    height: 128px;
    inset-inline-start: ${p=>p.theme.rtl?'45':'69'}px;
    top: 35px;
`;