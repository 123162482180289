import styled from 'styled-components';
import {btn} from '../../../../component/button/btn/Btn.css';
import {mobileMedia} from '../../../../globalVariables/GlobalVariables';

export const anotherTime=styled.div`
    ${btn}{
      color:#484848;
      background-color:#FFF;
      font-weight: 400;
      border: 1px solid;
      padding-top:30px;
    &:hover{
      color:#76d6e8;
    } 
}
`;

export const inForm=styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-inline-end:30px;

  & div:has(input) .empty {
    min-width: initial;
    max-width: 100%;
  }
  
  ${mobileMedia} {
    display: block;
    padding-top: 20px;
  }
  ${btn}{
    margin: 27px;

  }
 
`;
export const title=styled.div`
  font-size: 16px;
  padding-inline-start: 30px;
  padding-top: 34px;
  padding-bottom: 20px;
  font-weight: 800;
  ${mobileMedia} {
    display: none;
  }
`;
export const upload=styled.div`
  width: 300px;
`;
