import React from 'react';
import * as css from './PreviewScreen.css';
import {getTs, tsGetDayOfWeek, tsToDate} from '../../../library/dates';
import {useTranslation} from 'react-i18next';
import {useContext} from 'react';
import {BrandingContext} from '../BrandingPage';
import {useSelector} from 'react-redux';
import {useVariables} from '../../../assets/objects/Variables';
import {convertVariablesToHtml} from '../../../component/textEditor/Variables';
import sanitizeHtml from '../../../library/sanitizeHtml';

function PreviewScreen({brandingPreview}) {

    const {t}=useTranslation('global');

    const {textEditor: {currentEditorName}} = useContext(BrandingContext);
    const currentLang = useSelector(s => s.branding.currentLang);
    const value = useSelector(s => s.branding.data?.[currentLang]?.[currentEditorName]);
    const {defaultVariables} = useVariables();
    const {t:tVariables} = useTranslation('variables');
    const htmlWithValues = value ? convertVariablesToHtml(value, defaultVariables,tVariables): '';
    const text = htmlWithValues?.replace(/\n/g, '</p><p>');
    
    return (   
        <css.wrapAll>    
            <css.title> 
                <css.arrow />
                {brandingPreview||'Tickchak'}    
            </css.title>
            <css.wrapper>
                <css.profile />
                <css.text date={`${t('fullDay'+(tsGetDayOfWeek(getTs())))}  ${tsToDate(getTs())}`} time={()=>tsToDate(getTs(), null, null, 'HH:mm')}>
                    <css.contact dangerouslySetInnerHTML={sanitizeHtml(text)}/>
                </css.text>
            </css.wrapper>
        </css.wrapAll>     
    );
}

export default PreviewScreen;
