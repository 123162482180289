import styled from 'styled-components';
import realtimeFonts from '../../fonts';
import {getColor} from '../../eventRealtime/EventRealtime.css';
import {BLACK, mobileMedia} from '../../../../globalVariables/GlobalVariables';

export const cashHeader = styled.div`
    display: flex;
    align-items: center;
    ${realtimeFonts.windowTitle};
    justify-content: space-between;
`;
export const cashContainer = styled.div`
    padding: 20px;
    padding-bottom: 0;
    height: 120px;
    .isStats.modeBtn{
        position: absolute;
        top: 53px;
        inset-inline-end: 21px;
    }
    ${mobileMedia}{
        height: 90px;

    }
`;
export const Section = styled.div`
    display: inline-flex;
    margin-top: 2px;
    align-items: flex-end;
`;

export const cashAmount = styled.div`
    ${realtimeFonts.mainDataBold};
    margin-top: 9px;
    margin-inline-end: 10px;
    span{
        padding-inline-start:5px ;
      ${realtimeFonts.amount};

    }
`;

export const Subtext = styled.div`
    ${realtimeFonts.text};
    margin-inline-start: 10px;
  color: ${p=>getColor(p.theme.isDarkMode)};
    margin-bottom: 10px;
`;

export const Trend = styled.div<{ trend?: number; }>`
    position: relative;
    display: inline-block;
    background-color: #f0f0f0;
    border-radius: 25px;
    padding: 5px 10px;
    padding-inline-start: 15px;
    ${realtimeFonts.text};
    margin-bottom: 7px;

    color: ${BLACK};
    &::before{
        position: absolute;
        inset-inline-start: 5px;
        top: 3px;
        content: '${p => p.trend < 100 ? '\\2193' : '\\2191'}';
    }
`;