/* eslint-disable spellcheck/spell-checker */
import styled from 'styled-components';
import {BGImageCenter, BLACK, LIGHT_GRAY4, WHITE} from '../../../globalVariables/GlobalVariables';

export const status = styled.div`
    height: 100%;
    display: flex;
    width:max-content;
    padding: 2px 8px;
    padding-inline-start: 25px;
    color:${BLACK} !important;
    position:relative;
    text-align:left;
    cursor:pointer;
    border:1px solid ${LIGHT_GRAY4};
    border-radius:30px;
    transition:all 300ms ;
    background-color:${WHITE};
    font-weight: 500;
    align-items: center;
    letter-spacing: 0px !important;
    font-size: 13px;
    &::before{
        content: '';
        position: absolute;
        ${p=>BGImageCenter(p.icon, '13px')};
        width: 25px;
        height: 25px;
        inset-inline-start: 0;
        transition:all 300ms ;
    }
`;
