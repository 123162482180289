import styled from 'styled-components';

export const item = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
  &::after{
    content:'';
    width: 10px;
    height: 10px;
    background-color: ${p=>p.color};
  }
`;
