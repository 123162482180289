import styled from 'styled-components';
import {BGImageCenter, dFlexCenter, ellipsis, tabletMedia} from '../../globalVariables/GlobalVariables';

export const wrapper=styled.div`
    ${dFlexCenter};
    width:100%;
    height: 60px;
    background: #FFFFFF 0 0 no-repeat padding-box;
    box-shadow: 0 1px 4px #00000029;
    border-radius: 5px;
    position: relative;
    min-width: 250px;
    ${tabletMedia}{
        width: 250px;
        height: 50px;
        flex-shrink: 0;
    }
`;
export const text=styled.div`
    font-size: 12px;
    direction: rtl;
    width: 100%;
    ${ellipsis}

`;

export const boldText=styled(text)`
    font-weight: 600;
    margin-bottom: 2px;
    ${tabletMedia}{
        margin: 0;
    }
`;

export const title=styled.div`
    font-size:9px;
    font-weight: 600;
    &.FRONTPAGE:not(.main),
    &.HUB{
        position: absolute;
        ${ellipsis}
        text-align:${p => (p.theme.rtl ? 'right':'left')};
        width: max-content;
        max-width: 100%;
        inset-inline-start: 10px;
    }
    ${tabletMedia}{
        bottom: 1px;
    }
`;

export const icon=styled.div`
    height: 100%;
    margin-bottom: 3px;
    width: 25%;
    ${p => BGImageCenter(p.icon, '30px')}
    max-width: 80px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    &.homepage{
        background-size: 23px;
    }
    &.live{
        background-size: 45px;
    }
    ${tabletMedia}{
        background-size: 25px;
        &.homepage{
            background-size: 20px;
        }
        &.live{
            background-size: 30px;
        }
    }
`;

export const right=styled.div`
   display: flex;
   flex-direction: column;
   width: 30px;
   align-items: center;
`;

export const container=styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 25%;  
    text-align: center;
    ${tabletMedia}{
        width: 65px;  
    }
`;

export const wrapLeads=styled.div`
   width: 100%;
   ${dFlexCenter}
`;
