import * as css from './ContentArea.css';
import {useState, useEffect, useRef} from 'react';
import {saveSelection} from '../../../library/caretPosition';
import {useTranslation} from 'react-i18next';
import sanitizeHtml from '../../../library/sanitizeHtml';

function ContentArea(props) {
    const {setSelection, setRange, inputFocus, setInputFocus, contentRef, contentVal, setContentMid, openPopParams, handlePopOnPop, getRef}=props;
    const refText = useRef('');
    const {t} = useTranslation('mailing');

    const [contentValue, setContentValue] = useState('');
    const [contentValueTemp, setContentValueTemp] = useState('');

    const handleContent = (e) => {
        const html = e.target.innerHTML;
        setContentValueTemp(html);
        const caret = saveSelection();
        setSelection(caret.sel);
        setRange(caret.newrange);
    }; 

    const focusInput = (e) => {
        setInputFocus(1);
    };

    useEffect(() => {
        setContentValueTemp(contentValue);
    }, [contentValue]);

    useEffect(() => {
        setContentValueTemp(refText.current.innerHTML);
    }, [contentRef]);

    useEffect(() => {
        if(contentVal!=''){
            setContentValue(contentVal);
            refText.current.innerHTML = contentVal;
        }
    }, [contentVal]);    

    useEffect(() => {
        setContentMid(contentValueTemp);
        getRef(refText.current.textContent.length, refText.current);
    }, [contentValueTemp]);

    return (
        <css.contentArea className={'contentArea ' + (inputFocus && 'focus')} onClick={(e) => focusInput(0, e)}>
            <css.contentMiddle contentEditable={true} className="contentMiddle" id="contentMiddle" onInput={(e) => handleContent(e)} onFocus={(e) => focusInput(1)} onClick={(e) => openPopParams(e, 'content')} autoFocus ref={refText} suppressContentEditableWarning={true} 
                dangerouslySetInnerHTML={sanitizeHtml(contentValue)} />
            {contentValueTemp == '' &&
                <css.writeOrTemplate className="writeOrTemplate">
                    <css.write className="write">{t('writeYourMessage')}</css.write>
                    <css.template className="template" onClick={() => { handlePopOnPop('show', 1, 0); }}>{t('template')}</css.template>
                </css.writeOrTemplate>
            }
        </css.contentArea>
    );
}

export default ContentArea;