import styled from 'styled-components';
import {expanded} from '../expandedClass.css';

export const container=styled(expanded)`
    display: flex;
    align-items: center;
    padding-inline-start:0;
    padding-inline-end:70px;
    &.show{
        max-height: 100px;
    }
    `;

export const text=styled.div`
    padding: 25px 0;
    font-size: 14px;
`;

export const close=styled.div`
    width:70px;
    padding-inline-start:20px;
`;

