import styled from 'styled-components';
import {BGImageCenter, mobileMedia} from '../../globalVariables/GlobalVariables';

export const name=styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  padding-top: 10px;
  font-weight: bold;
  font-size: 20px;
`;

export const wrapAll=styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
`;

export const image=styled.div`

   width:${p => p.size?p.size+'px': '120px'};
  height:${p => p.size?p.size+'px': '120px'};
  background-color: #FFFFFF;
   ${p=>BGImageCenter(p.image,'contain')};
  background-size: ${p => p.size?p.size - 10+'px': '110px'};
  cursor: pointer;
  border-radius:50%;
  box-shadow: 0 3px 6px #00000029;
  margin:2px ;

   ${mobileMedia}{
      background-size: 70px;
      width: 80px;
      height:80px;
   }
  
`;
export const imageContainer = styled.div`
  top: -${p => p.size?p.size /2+'px': '40px'};
  left:0;
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  pointer-events: none;
   ${mobileMedia}{
      top: -40px;
   }
  
`;

