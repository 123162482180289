import styled from 'styled-components';

export const text = styled.div`
    text-align: center;
    font-size: 10px;
    font-weight: 200;
`;

export const detail = styled.div`

`;
