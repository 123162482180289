import styled from 'styled-components';
import * as ticketsRowCss from '../TicketsRow.css';
import {ellipsisLines} from '../../../../../../../globalVariables/GlobalVariables';
export const container = styled.div`

`;
export const firstPart = styled(ticketsRowCss.firstPart)`
`;

export const placeDrag = styled(ticketsRowCss.placeDrag)`

`;
export const ticketIcon = styled(ticketsRowCss.ticketIcon)`
`;
export const ticketRight = styled(ticketsRowCss.ticketRight)`
`;
export const header = styled.div`
    padding-block: 8px;
    height: 100%;
`;
export const price = styled.div`
    display: flex;
    justify-content: center;
`;
export const wrapper = styled(ticketsRowCss.wrapper)``;
export const text = styled.div`
    text-align: center;
    font-size: 10px;
    font-weight: 200;
`;

export const ticketTitle = styled(ticketsRowCss.ticketTitle)`
    font-weight: 500;
    text-align: center;
    margin-top: 8px;
    ${ellipsisLines(3)};
    font-size: 14px;

`;
export const arrow = styled(ticketsRowCss.arrow)`
`;
export const detail = styled.div`

`;
