import styled from 'styled-components';
import {BGImageCenter, GRAY, mobileMedia} from '../../../globalVariables/GlobalVariables';
import {Item, ItemContentIcon, ItemText} from '../../item/Item.css';
import {arrowBtn} from '../../../assets/symbols/symbols/Symbols.css';

export const button = styled.div`
 border: ${GRAY} 1px solid;
 display: flex;
  justify-content: space-between;
  align-items: center;
  padding-inline-end: 10px;
  cursor: pointer;
  width: 190px;
  height: 50px;
  border-radius: 5px;
  ${mobileMedia}{
   transform: scale(.8);
  }
  &::before{
    content: '';
    height: 20px;
    width: 20px;
    ${BGImageCenter(arrowBtn,'6px')}
    transform: rotate(180deg);
    transition: all 300ms;
    position: absolute;
    top: 16px;
    inset-inline-start: 22px;
  }
    &.show {
      &::before {
        transform: rotate(270deg);
      }
    }
`;
export const text = styled.div`
  font-size: 18px;
  text-align: end;
  width: calc(100% - 64px)
`;
export const icon = styled.div`
  background-image: url("${p => p.icon}");
  width: 40px;
  height: 28px;
  ${p=>BGImageCenter(p.icon,'30px')}
`;
export const wrapper = styled.div`
  position: relative;
  ${Item}{
    padding-inline-end: 10px;
    flex-direction: row-reverse;
    justify-content: space-between;
    min-width: unset;
  }
  ${ItemText}{
    width: calc(100% - 64px);
    text-align: end;
    padding-inline-end: unset;
  }
  ${ItemContentIcon}{
    background-size: 30px;
    width: 40px;
  }
`;
