import styled from 'styled-components';
import {btn} from '../../component/button/btn/Btn.css';
import {getAfterArrow} from '../../component/item/Item.css';
import {BGImageCenter, dFlexCenter, mobileMedia} from '../../globalVariables/GlobalVariables';
import {title, verificationMessageIn} from '../topMenu/accountVerificationMessage/AccountVerificationMessage.css';
import {text} from '../topMenu/accountVerificationMessage/verificationItem/VerificationItem.css';
import {linkIcon} from '../../component/copy/Copy.css';

export const accountWrap=styled.div`
    width:100%;
    border-top:1px solid #CCCCCC;
    div{
        font-size:16px ;
    }
    ${text}{
        font-size: 12px;
    }
    ${title}{
        font-size: 14px;
    }
    ${btn}{
        font-size: 13px;
    }
    ${linkIcon}{
        font-size: 0;
        width: 40px;
        &.copied {
        font-size: 12px; 
        width: max-content;

        }
    }
    ${mobileMedia}{
        ${verificationMessageIn}{
            margin-bottom: -31px;
        }
    }
`;

export const accountItem=styled.div`
    height:50px ;
    width:100%;
    ${dFlexCenter};
    justify-content:start ;
    border-bottom:1px solid #CCCCCC; 
    &.hasSubList{
        ${p=>getAfterArrow(p)}
}
`;

export const accountIcon=styled.div`
    height:50px ;
    width:40px;
    ${dFlexCenter}
    ${p=>BGImageCenter(p.icon,'12px')}
`;