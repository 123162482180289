/* eslint-disable spellcheck/spell-checker */
import styled, {keyframes} from 'styled-components';
import {BGImageCenter, dFlexCenter} from '../../globalVariables/GlobalVariables';
import * as symbols from '../../assets/symbols/symbols/Symbols.css';
import {star,loader,blackSuccess} from '../../assets/symbols/symbols/Symbols.css';

export const INPUT_PADDING_END='5px';
const ICON_WIDTH=25;
const PADDING_START=5;
export const INPUT_PADDING_START=ICON_WIDTH+PADDING_START;

const loading = keyframes`
    from { transform: rotate(0deg)  }
    to   { transform: rotate(360deg)  }
    
    from { -webkit-transform: rotate(0deg)}
    to   { -webkit-transform: rotate(360deg)}
    
    from { -moz-transform: rotate(0deg)}
    to   { -moz-transform: rotate(360deg)}
    
    from { -o-transform: rotate(0deg)}
    to   { -o-transform: rotate(360deg)}
`;

export const INPUT_WEIGHT = 600;

export const container = styled.div`
  width: 100%;
  /* height: 55px; */
  display: flex;
  padding: 10px 0 20px 0;
  position: relative;
  &::before {
    content: "";
    width: 13px;
    height: 13px;
    position: absolute;
    border-radius: 50%;
    bottom: 2px;
    inset-inline-end: 0;
    background-color:#77d154 ;
    transform:scale(0);
    transition:all 200ms cubic-bezier(0.42, 0, 0.95, 1.52);
  }

  &::after {
    content: "";
    opacity: 0;
    transition: all 300ms;
    position: absolute;
  }

  &.master {
    &::after {
      width: 7px;
      height: 7px;
      opacity: 1;
      background-color: #1348e6;
      border-radius: 50%;
      box-shadow: 0 0 0 4px #76d6e8;
    }
  }

  &.valid,&.SUCCESS {
    &::before {
      transform: scale(1);
      ${BGImageCenter(blackSuccess, '7px')}
      background-color: #77d154;
    }
  }  
 
  &.FAILED,&.invalid {
       &::before {
         transform: scale(1);
         ${BGImageCenter(star, '13px 8px')}
         background-color: #DD2F21
       };
     }; 

  &.LOADING {
      &::before {
        transition:all 0ms;
        transform: scale(1);
        background-color: transparent;        
        ${BGImageCenter(loader, '13px 13px')}
        -webkit-animation: ${loading} 1s linear infinite;
        animation: ${loading} 1s linear infinite;
        -moz-animation: ${loading} 1s linear infinite;
        -o-animation: ${loading} 1s linear infinite;
      }
    }
    &.verified {
      &::after {
        width: 18px;
        height: 18px;
        opacity: 1;
        border-radius: 50%;
        bottom: 25px;
        inset-inline-end: 0;
        ${BGImageCenter(blackSuccess, '10px')}
        background-color:#76D6E8 ;
        transition:all 200ms cubic-bezier(0.42, 0, 0.95, 1.52);
     }
   }
    &.problem {
      &::before {
        visibility: hidden;
      }
    }
    &.disabled {
      opacity: 0.5;
    }
    &.notChange {
      pointer-events: none;
    }
`;
export const wrap = styled.div`  
    width: calc(100% - 5px);
    margin-inline-start:${PADDING_START}px;
  &.hasIcon{
    width: calc(100% - 30px);

  }
`;

export const icon = styled.div`
  width: ${ICON_WIDTH}px;
  height: 25px;
  ${dFlexCenter}
  ${props => BGImageCenter(symbols[props.icon], '16px 16px')}
`;

export const input = styled.div`
  position: relative;
  border-bottom: 1px solid;
  display: flex;
  align-items: center;
  width: 100%;
  height: 25px;
  background: transparent;
`;

export const title = styled.div`
    white-space: nowrap;
    letter-spacing: 1px;
    font-weight: bold;
    font-size: 11px;
    position:absolute;
    span{
      font-size:10px ;
      letter-spacing:0.6px;
      font-weight:400 ;
    }
`;

