import styled from 'styled-components';

export const input=styled.input`
   padding-inline-start: 15px;
    height: 35px;
    background: rgb(255,255,255);
    border: 2px solid #67b1b7;
    border-radius: 54px;
    width:100%;
    font-size: 16px;
    font-weight: bold;
    caret-color: rgb(170,170,170);
    outline: none;
    &.error{
       border: 2px solid #e73c30;
    }
`;