import styled from 'styled-components';
import convertHexToRgbA from '../../functions/ConvertHexToRgbA';
import {boxShadow, DEFAULT_COLOR, dFlexCenter} from '../../globalVariables/GlobalVariables';
import zIndex from '../../globalVariables/zIndex';

export const container = styled.div`
z-index:${zIndex.highestModal};
left:calc(50% - 150px);
top:calc(50% - 300px);
background-color:#fff ;
position:absolute ;
 height:600px;
 max-height: 95%;
 width:300px;
 border-radius:10px ;
 ${boxShadow}
`;
export const top = styled.div`
  height:50%;

  background-color:${DEFAULT_COLOR};
  position:relative ;
    ${dFlexCenter}{
      border-radius: 10px;
      transition: all 300ms;
    }
  `;
export const topClose = styled.div`
    position:absolute ;
    top:10px;
    inset-inline-start:10px;
    height:30px ;
    width:30px ;
    border-radius: 50%;
    background-color:rgba(255,255,255,0.4) ;
    /* &::after{
        background-color:rgba(${convertHexToRgbA('#DD2162')},0.5);
    }
     */

     transition:all 300ms ;
    &:hover{
        background-color: #FFF ;
        & .white{
            background-color: #222;
        }
    }
`;

export const topText = styled.div`
font-size:20px ;
text-align: center;
    ${dFlexCenter};
      padding:0 40px ;
      font-weight: 700;
    
`;
export const center = styled.div`
    height:35% ;
    ${dFlexCenter};
      flex-direction:column ;
      justify-content: space-evenly;
      width:100% ;
      padding:0 40px ;
    
`;

export const buttons = styled.div`
    height:15% ;
    
    width:100% ;
    ${dFlexCenter}
    >div{
    white-space: nowrap;
}
position:absolute ;
bottom:0 ;
`;

