import styled from 'styled-components';
import {popUpBody} from '../../../../component/newPopup/mobilePopup/MobilePopup.css';
import {
    arrowDark,
    cloud,
    ex,
    greenCertificate,
    requiredVerification, vi
} from '../../../../assets/symbols/symbols/Symbols.css';
import {BGImageCenter} from '../../../../globalVariables/GlobalVariables';
import {Close} from '../../../../component/close/Close.css';
import zIndex from '../../../../globalVariables/zIndex';
export const warper = styled.div`
  ${popUpBody}{
    width:400px;
    max-width:100%;
  }
  ${Close}{
    background-image: url("${p => p.step === 2 ? arrowDark : ex}");
    transform: rotate(${p => (p.theme.rtl ? '0deg' : '180deg')});
  }
  
`;
export const contentIn = styled.div`
  overflow: auto;
  padding: 30px;
`;
export const content = styled.div`
  background-color: #26B5EB2B;
  border: 1px solid #76D6E8;
  border-radius: 6px;
  margin: 10px;
  
  margin-top: ${p => p.step === 2 ? '160px' :p => p.step === 1? '60px':'0'};
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  position: relative;
  
  max-height: calc(100% - 100px);
  
  &::before{
    position: absolute;
    top: ${p => p.step === 3 ? 'unset' : '-58px'};
    bottom: ${p => p.step === 3 ? '19px' : 'unset'};
    inset-inline-start: ${p => p.step === 3 ? 'calc(50% - 96px)' : 'calc(50% - 80px)'};
    content: '';
    background-image: url("${p => p.step === 2 ? requiredVerification : greenCertificate}");

    background-size: contain;
    background-repeat: no-repeat;
    height: ${p => p.step === 3 ? '220px' : '165px'};
    width: ${p => p.step === 3 ?  '220px' : '165px'};
    z-index: ${zIndex.baseRaised};
  }
 

 
  
`;
export const title = styled.div`
  letter-spacing: 4.2px;
  font-size: 17px;
  padding-top: 80px;
  text-align: center;
  
`;
export const subTitle = styled.div`
  font-size: 21px;
  font-weight: 600;
  text-align: center;

`;

export const details = styled.div`
  padding: 35px;
  padding-inline-start: 15px;
`;
export const confirm = styled.div`
  padding-inline-start: 30px;
  position: relative;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  &::before {
    top: 3px;
    inset-inline-start: 0;
    background-color: #ffffff;
    border: #01ACf0 1px solid;
    border-radius: 11px;
    height: 18px;
    width: 18px;
    content: '';
    position: absolute;
    ${p => BGImageCenter(p.state ? vi : '', '10px')};

  }
`;
export const stepOne = styled.div`

`;
export const stepTwo = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;
export const stepThree = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const actions = styled.div`
  padding-top: 44px;
  justify-content: space-evenly;
  position: relative;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  flex-direction: row;
 
 
`;
export const item = styled.div`
  padding-inline-start: 10px;
  position: relative;
  height: 26px;
  font-size: 18px;
  font-weight: 500;
  &::before {
    top: 8px;
    inset-inline-start: -10px;
    background-color: #00d0ff;
    border-radius: 50%;
    height: 9px;
    width: 9px;
    content: '';
    position: absolute;

  }
  span{
    font-weight: 300;
  }
`;
export const securityTitle = styled.div`
  position: absolute;
  font-size: 22px;
  font-weight: 600;
    top: -128px;
    right: 0;
   text-align: center;
    height: 20px;
    width: 100%;
    content: '';
  
`;
export const smsCode = styled.div`
padding: 90px;
  
`;
export const nowTitle = styled.div`
  font-size: 21px;
  font-weight: 600;
  padding: 69px;
  padding-top: 100px;
  text-align: center;
  
`;
export const image = styled.div`
  width: 100%;
  height: 170px;
  position: absolute;
  bottom: 0;
  right: 0;
  ${BGImageCenter(cloud,'contain')}
  
`;

