import styled from 'styled-components';
import {BGImageCenter} from '../../../../globalVariables/GlobalVariables';
import {op} from '../../../../assets/symbols/symbols/Symbols.css';
import {link} from '../../../../component/copy/Copy.css';
import {LINE_HEIGH} from '../../../../component/list/List.css';

export const options = styled.div`
    width:20px;
    height:20px;
    cursor: pointer;
    ${link}{
        width: 26px;
    }
    >div{
            white-space:nowrap ;
        }
        ${BGImageCenter(op, '10px')}

`;

export const guideItemLeft = styled.div`
    height: ${LINE_HEIGH}px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

