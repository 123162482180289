import styled from 'styled-components';
import {explanation} from '../../../assets/symbols/symbols/Symbols.css';
import {btn} from '../../../component/button/btn/Btn.css';
import {BGImageCenter} from '../../../globalVariables/GlobalVariables';

export const toggle=styled.div`
    display: flex;
    justify-content: flex-end;
    position: relative;
    ${btn}{
        color: #474747;
    }
`;

export const explanationIcon=styled.div`
cursor: pointer;
 ${BGImageCenter(explanation,'17px')}
 width: 50px;
 height: 40px;
`;

