import styled from 'styled-components';
import {BGImageCenter, mobileMedia} from '../../globalVariables/GlobalVariables';
import {image404} from '../../assets/symbols/images/image404';
import {btn} from '../../component/button/btn/Btn.css';

export const container=styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    width:90%;
    margin: 0 auto;
    ${btn}{
      margin: 0 auto;
      background-color: #FFEB00;
      border-color: #FFEB00;
      font-size: 20px;
      letter-spacing: -0.5px;
      padding: 7px 20px;
      height: unset;
    }
`;
export const image=styled.div`
  ${BGImageCenter(image404,'contain')}
  height:calc(100% - 200px);
  max-height: 300px;
  width: 100%;
  ${mobileMedia}{
    max-height: 250px;
  }
`;
export const text=styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    letter-spacing: 1.5px;   
    height: 70px;
    text-align: center;
  ${mobileMedia}{
    font-size: 14px;
  }
`;