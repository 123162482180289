import styled from 'styled-components';
import {simpleArrow} from '../../../assets/symbols/symbols/Symbols.css';
import {BGImageCenter, boxShadow, mobileMedia, scrollbar} from '../../../globalVariables/GlobalVariables';
import zIndex from '../../../globalVariables/zIndex';

export const BIG_SCREEN_SIZE=1550;
export const MIDDLE_SCREEN_SIZE=1400;
export const SMALL_SCREEN_SIZE=1260;
export const BIG_SCREEN=`${BIG_SCREEN_SIZE}px`;
export const MIDDLE_SCREEN=`${MIDDLE_SCREEN_SIZE}px`;
export const SMALL_SCREEN=`${SMALL_SCREEN_SIZE}px`;

export const pageFrame = styled.div`
    width: 100%;
    height: calc(100% - 65px);
    display: flex;
    @media screen and (max-width:1000px){
        display: unset;
    }
`;
// calc & border according left
export const right = styled.div`
    width:75%;
    min-width: 1080px;
    max-width:calc(100% - 70px);
    transition: all 300ms;
    position:relative;
    ${scrollbar};
    padding-inline: 60px;
    padding-top: 30px;
    height: 100%;
    overflow-x: hidden;
    @media screen and (max-width:${SMALL_SCREEN}){
      width:calc(100% - 70px);
      min-width:unset;
    }
    @media screen and (max-width:${BIG_SCREEN}) and (min-width:${MIDDLE_SCREEN}){
      width:calc(100% - 70px);
      min-width:unset;
    }
    ${mobileMedia}{
      width:100%;
      min-width:unset;
      max-width:100%;
      padding-inline: 10px;
    }
    &.openLeft{
        width:calc(100% - 430px);
    }

`;
export const left = styled.div`
    padding-inline:30px;
    overflow: hidden;
    width:25%;
    border-inline-start:1px solid #d7d7d7;
    max-width: 450px;
    transition: all 300ms;
    position: relative;
    @media screen and (max-width:${BIG_SCREEN}) and (min-width:${MIDDLE_SCREEN}){
      width:70px;
      padding: 0;
    }
    @media screen and (max-width:${SMALL_SCREEN}){
      width:70px; 
      padding: 0;
    }
    &.openLeft{
      width:430px;
      padding-inline:30px;
      position:relative;
      ${scrollbar};
    }
    ${mobileMedia}{
      display: none;
    }
`;

export const closeLeft=styled.div`  
    opacity: 0;
    transition: all 300ms;
      background-color:lightgray ;
      ${boxShadow};
      inset-inline-start: 20px;
      width: 30px;
      height: 30px;
      ${BGImageCenter(simpleArrow,'10px')}
      transform: rotate(${p=>p.theme.rtl?'90deg':'270deg'});
      border-radius:50%;
      cursor: pointer;
      &.openLeft{
        @media screen and (max-width:${BIG_SCREEN}) and (min-width:${MIDDLE_SCREEN}){
          opacity: 1;
      }
      @media screen and (max-width:${SMALL_SCREEN}){
        opacity: 1;
      } 
    }
      `;

export const leftTitle=styled.div`
    height: 100%;
    background-color: #FFFFFF;  
    opacity: 0;
    visibility: hidden;
    position: absolute;
    transition: all 300ms; 
    width: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
  &.openLeft{
    visibility: hidden;
    opacity: 0;
  }
  @media screen and (max-width:${BIG_SCREEN}) and (min-width:${MIDDLE_SCREEN}){
      opacity: 1;
      visibility: visible;
      z-index: ${zIndex.baseRaised};
    }
    @media screen and (max-width:${SMALL_SCREEN}){
      opacity: 1;
      visibility: visible;
      z-index: ${zIndex.baseRaised};
    }
`;
export const leftTitleText=styled.div`
cursor: pointer;
  border:1px solid;
width: max-content;
    padding-inline: 30px;
    height: 40px;
    border-radius: 18px;
    text-align: center;
    line-height: 40px;
    font-weight: 600;
    position: absolute;
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);

`;