import styled from 'styled-components';
import {popUpBody} from '../newPopup/mobilePopup/MobilePopup.css';
import {BGImageCenter, mobileMedia, scrollbar} from '../../globalVariables/GlobalVariables';
import {computerImage} from '../../assets/symbols/images/computerImage';
import {arrowBtn} from '../../assets/symbols/symbols/Symbols.css';
import {btn} from '../button/btn/Btn.css';

const COMPUTER_HEIGTH='120px';
const MOBILE_COMPUTER_HEIGTH='50px';
const BOTTOM_HEIGHT='70px';

export const computer = styled.div`
    height: ${COMPUTER_HEIGTH};
    position:relative;
    &::before{
        width: 424px;
        height: 255px;
        content:'';
        position:absolute;  
        ${BGImageCenter(computerImage,'cover')} 
        left: calc(50% - 212px);
        bottom: 0;
    }
    ${mobileMedia}{
        height: ${MOBILE_COMPUTER_HEIGTH};
        &::before{
            ${BGImageCenter(computerImage,'300px')} 
            height: 170px;
            width: 378px;
            left: calc(50% - 189px);
        }
    }
`;

export const wrapper = styled.div`
    ${popUpBody}{   
        background: transparent linear-gradient(0deg, #289FCB 0%, #B9E7FD 100%) 0 0 no-repeat padding-box;
        box-shadow: 0 3px 16px #00000050;
        height: 550px;
        width: 700px;
        ${mobileMedia}{
            &.show{ 
                max-height: 85%;
                height: 85%;
                width: 100%;
            }
        }
    }
`;

export const bottom = styled.div`
    height:${BOTTOM_HEIGHT} ;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 40px;
`;

export const arrow = styled.div`
    width: 25px;
    height: 6px;
    ${BGImageCenter(arrowBtn,'6px')}
     transform: rotate(180deg);
`;

export const container = styled.div`
    height: calc(100% - ${COMPUTER_HEIGTH} - ${BOTTOM_HEIGHT});
    &.noBottom{
        height: calc(100% - ${COMPUTER_HEIGTH});
    }
    &.noTopImage{
        height: calc(100% - ${BOTTOM_HEIGHT});
    }
    ${mobileMedia}{
        height: calc(100% - ${MOBILE_COMPUTER_HEIGTH} - ${BOTTOM_HEIGHT});
        &.noBottom{
            height: calc(100% - ${MOBILE_COMPUTER_HEIGTH});
        }
    }
    ${mobileMedia}{
        ${scrollbar}
    }
    ${btn}{
      margin-inline-start: 15px;
      margin-bottom: 15px;
    }
`;
