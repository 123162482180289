import styled from 'styled-components';
import * as symbol from '../../../../../assets/symbols/symbols/Symbols.css';
import {BGImageCenter} from '../../../../../globalVariables/GlobalVariables';
import {simpleArrow} from '../../../../../assets/symbols/symbols/Symbols.css';
export const groupTitle=styled.div`
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 50px;
    align-items: center;
    transition: all 300ms;
    background-color: #${props=>props.color};
    border-radius:5px;
    //margin-top: 20px;
    @media screen and (max-width:1000px){
        height: 35px;
    }
  &.open{
    border-radius:5px 5px 0 0;
  }
`;
export const groupTitleTitle=styled.div`
    width:calc(100% - 170px);
    @media screen and (max-width:1000px){
        width:calc(100% - 150px);
    }
`;
export const groupTitleOp=styled.div`
    /* width: 200px; */
    width: 160px;
    height: 100%;
    display: flex;
    align-items: center;
    >div{ 
       /* width:33%; */
       width:25%;
       cursor:pointer;
    }
`;
const bImage=styled.div`
    background-repeat: no-repeat;
    background-position: center center;
    position:relative;
    width: 100%;
    height: 30px;
`;
export const gTOAddEvent=styled(bImage)`
    background-image:url("${symbol['add']}");
    background-size: 15px 15px;
`;
export const gTOLink=styled(bImage)`
    background-image:url("${symbol['copy']}"); 
`;
export const gTOAddImage=styled(bImage)`
    mask-image:url("${symbol['image']}");
    -webkit-mask-image:url("${symbol['image']}");
    background-color: #484848;
    -webkit-mask-position: center;
    -webkit-mask-repeat: no-repeat;

`;
export const gTORemove=styled(bImage)`
    background-image:url("${symbol['remove']}"); 
    background-size: 17px 17px;
`;
export const gTOOp=styled(bImage)`
    background-image:url("${symbol['op']}");
    width: 100%;
`;

export const groupTitleArrow=styled.div`
    width:50px;
    height:100%;
    ${BGImageCenter(simpleArrow,'12px')}; 
    transform: rotate(90deg);
    transition:all 300ms;
    cursor: pointer;
    &.open{
      transform: rotate(0deg);
    }
    @media screen and (max-width:1000px){
        width:30px;
    }
`;