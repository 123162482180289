import styled from 'styled-components';
import {mobileMedia} from '../../../../../globalVariables/GlobalVariables';

export const location=styled.div`
    display: flex;
    width:100%;
    ${mobileMedia}{
     flex-direction:column ;
    }
  >div{
    width:50%;
    ${mobileMedia}{
      width:100%;
    }
  }
`;