import React, {useRef} from 'react';
import 'swiper/swiper-bundle.css';
import SystemMessage from './systemMessage';
import {useSelector} from 'react-redux';
import Swiper from '../../../../../component/swiper/swiper';
import * as css from './systemMessage.css';
import {TSystemMessage} from '../../../../../redux/realtime/realtime.type';

function SystemMessageSwiper({isPop, defaultMessages = null}:{isPop: boolean, defaultMessages?:TSystemMessage[]}){
    
    const swiperRef = useRef();
    const messages: TSystemMessage[] = defaultMessages || useSelector((s:any) => s.realtime.systemMessage[`${isPop?'pop':'window'}Messages`]);
    const autoplay={
        delay: 20000,
        disableOnInteraction: false,
    };
    return (
        <css.swiperWrapper className={isPop? 'message-pop': 'message-window'} >
            <Swiper autoplay={autoplay} navigation={true} spaceBetween={30} speed={600} pagination={{class:'system-message', clickable: true}} swiperRef={swiperRef} >
                {messages?.map((sm, i)=>
                    <css.messageWrapper key={i} image={isPop && sm?.image}>
                        <SystemMessage message={sm} isPop={isPop}/>
                    </css.messageWrapper>
                )}
            </Swiper>
        </css.swiperWrapper>
    );
}

export default SystemMessageSwiper;
