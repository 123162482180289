import styled from 'styled-components';
import {success} from '../../../../assets/symbols/symbols/Symbols.css';
import * as icons from '../../../../assets/symbols/symbols/newEventIcons';
import {BGImageCenter, dFlexCenter, GREEN, mobileMedia, scrollbar} from '../../../../globalVariables/GlobalVariables';

export const NAVIGATION_HEIGHT=200;
export const NAVIGATION_HEIGHT_MOBILE=150;

export const scrollWrapper=styled.div`
    width:max-content;
    max-width: 95%;
    ${scrollbar};
    min-height: ${NAVIGATION_HEIGHT}px;
    ${mobileMedia}{
      min-height: ${NAVIGATION_HEIGHT_MOBILE}px;
    }
`;

export const wrapper=styled.div`
    padding: 0 20px;
    display: flex;
    height: ${NAVIGATION_HEIGHT}px;
    align-items: center;
    min-width: max-content;
    ${mobileMedia}{
      height: 150px;
    }
`;
export const tab=styled.div`
    position: relative;
    cursor: pointer;
    width:42px ;
    height:42px ;
    border-radius: 50px;
    background-color: #A8ACAD;
    border: 3px solid #FFFFFF;
    text-align: center;
    ${p=>BGImageCenter(icons[p.icon],'13px')}
    ${dFlexCenter}
    &::before{
        content: "${p=>p.name}";
        position: absolute;
        color: white;
        top: 45px;
        font-weight: 600;
        transition: all 400ms ease 0s;
        line-height: 0.8; 
    }
    transition: all 300ms;
    &:hover , &.current{
        border-color: #7DC5E0;
        &::before{
            color: #7DC5E0;
        }
    }
    &:hover{
        background-color: #474747;
        background-image: unset ;
        &::after{
            background-color: #7DC5E0;
            content: '';
            -webkit-mask-image: url("${p=>icons[p.icon]}");
            mask-image: url("${p=>icons[p.icon]}");
            -webkit-mask-position: center;
            -webkit-mask-repeat: no-repeat;
            width: 42px;
            height: 42px;
            -webkit-mask-size: 13px ;
            display: block;
        }
    }

`;
export const line=styled.div`
width: 42px;
height: 42px;
${dFlexCenter}
::after{
    content: '';
    width:15px ;
    height:4px ;
    border-radius: 3px;
    background-color: #FFFFFF;
}
`;
export const vi=styled.div`
position: absolute;
width: 16px;
height: 16px;
border-radius: 50%;
background-color: ${GREEN};
top: -5px;
border: 2px solid white;
inset-inline-start:-5px ;
${BGImageCenter(success,'7px')}
`;