import styled from 'styled-components';
import {op, pen} from '../../../../assets/symbols/symbols/Symbols.css';
import {link} from '../../../../component/copy/Copy.css';
import {BGImageCenter, dFlexCenter, ellipsis} from '../../../../globalVariables/GlobalVariables';

export const container = styled.div`
    max-width:calc(100vw - 50px) ;
    width:450px ;
    height: 115px;
    display:flex ;
    border-radius: 5px;
    position: relative;
    &.selectable{
        max-width:calc(100vw - 80px) ;
    }
`;
export const mobileRow = styled.div`
    width:100%;
    height: 100%;
    display:flex ;  
    transition:all 300ms ;
    &.isDrag{
        width:calc(100% - 30px) ;
    }
`;

export const placeDrag = styled.div`
    &.isDrag{
        width:30px ;
        background-color:transparent ;
    }
`;

export const editPop = styled.div`
   padding:2px ;
   border-radius:50% ;
   cursor:pointer ;
   position:absolute ;
    ${BGImageCenter(pen,'13px'  )}
    background-color: #76D6E8;
    width:30px ;
    height:30px ;
    inset-inline-end:-15px;
`;

export const iconWrap = styled.div`
    overflow:visible;
    width:70px;
    background-color: ${p => p.color};
    position:relative ;
    display:flex ;
    align-items:center ;
    border-radius:${p => p.theme.rtl ? '0 5px 5px 0' : '5px 0 0 5px'};
    .circleWrap{
        width:50px ;
        height:50px ;
        inset-inline-end:-25px;
    }
    .ticketProgress_circle{
        width:35px ;
        height:35px ;
    }
`;

export const image = styled.div`
    border-radius:${p => p.theme.rtl ? '0 5px 5px 0' : '5px 0 0 5px'};
    width:70px;
    height:100% ;
    ${p => BGImageCenter(p.image, '25px')}
    position: absolute;
    top:0;
    background-size:cover;
    position:absolute ;
    inset-inline-start:0;
`;

export const left = styled.div`
    width:calc(100% - 70px);

`;
export const part = styled.div`
    height:50% ;
    display:flex ;
    width:100% ;
`;

export const topRight = styled.div`
    width:calc(100% - 85px) ;
    padding-top:15px;
    padding-inline-start:30px;
    overflow:hidden;
    >div{
        ${ellipsis}
    }
`;
export const topLeft = styled.div`
    width:85px ;
    ${dFlexCenter}
`;

export const details = styled.div`
    width:100%;
    display:flex;
    flex-direction:column ;
    align-items:flex-end ;
    justify-content:center ;
    padding-inline-end:15px;
    padding-bottom:10px ;
`;

export const price = styled.div`
    font-size:30px ;
    font-weight:500 ;
    >span{
        font-size:16px ;
        font-weight:400 ;
    }
`;

export const amount = styled.div`
    display:flex;
`;

export const boldText = styled.div`
   font-size:15px ;
   font-weight:600 ;
`;

export const text = styled.div`
    display:flex ;
    font-size:12px ;
    line-height:20px ;
`;

export const options = styled.div`
width:20px;
height:20px;
cursor: pointer;
${link}{
    width: 26px;
}
>div{
        white-space:nowrap ;
    }
    ${BGImageCenter(op, '10px')}

`;

