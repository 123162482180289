import styled from 'styled-components';
import {BGImageCenter} from '../../../../globalVariables/GlobalVariables';
import {arrowDown} from '../../../../assets/symbols/symbols/Symbols.css';
import zIndex from '../../../../globalVariables/zIndex';

export const wrapper = styled.div`
    direction: ltr;
    height: ${p => p.theme.isExpanded ? '445px' : '140px'};
    transition: all 200ms;
    margin-top: 10px;
`;

export const editor = styled.div`
    padding-bottom: 20px;
    height: ${p => p.theme.isExpanded ? '420px' : '115px'};
    transition: all 200ms;
    background-color: #F3F3F3;
    border-radius: 0 10px 10px 10px;
    width: 100%;
    z-index: ${zIndex.baseRaised};
`;

export const title = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

export const wrapName = styled.div`
    background-color: #F3F3F3;
    width: 70px;
    height: 22px;
    border-radius: 10% 10% 0 0;
    display: flex;
    align-items: center;
    padding: 5px;
    cursor: pointer;
    font-size: 10px;
    font-weight: bold;

    &::before {
        content: '';
        width: 13px;
        height: 13px;
        margin-inline: 3px;
        border-radius: 20%;
    }

    &.js::before {
        background-color: #FFDF00;
    }
    
    &.html::before {
        background-color: #F33052;
    }

    &.css::before {
        background-color: #57BBF9;
    }

    &::after{
        content: '';
        ${BGImageCenter(arrowDown, '8px')};
        transform: ${p => p.theme.isExpanded ? 'rotate(0deg)' : 'rotate(270deg)'};
        width: 20px;
        height: 100%;
    }
`;

export const codePixel = styled.div`
    font-size: 14px;
    inset-inline-start: 5px;
    direction: rtl;
    margin-bottom: 3px;
`;

export const copyCode = styled.div`
    font-size: 12px;
    direction: rtl;
    padding: 8px;
`;

export const error=styled.div`
    color: red;
    direction: rtl;
`;