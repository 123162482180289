import styled from 'styled-components';
import {dFlexCenter, scrollbar} from '../../globalVariables/GlobalVariables';

export const loaderContainer = styled.div`
height: ${p=>p.height};
${scrollbar}`;

export const loader=styled.div`
  position:relative ;
  width:100%;
  height:100px ;
  overflow:hidden ;
  margin-top:10px ;
  ${dFlexCenter}
  .animation{
      width:400px ;
  }
  `;
