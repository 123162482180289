import styled from 'styled-components';
import {wrapper} from '../StepLeft/StepLeft.css';
import {titleWrap} from '../../../../component/input/basicInput/BasicInput.css';
import {mobileMedia} from '../../../../globalVariables/GlobalVariables';

export const container = styled.div`
  height: 280px;
  width: 100%;
  padding-top: 10px;
  > div:first-child {
    position: absolute;
    width: 50%;
    padding-inline-end: 3px;
  }
  ${mobileMedia}{
    height: 415px;
  }
`;

export const wrapAll = styled.div`
  ${wrapper} {
    padding-top: 25px;
    justify-content: flex-start;
    ${mobileMedia}{
      padding: 5px 0 20px;
    }
  }
    ${titleWrap} {
      &.empty {
        font-size: 15px;
      }
    }
`;