import Stage from '../../stage/Stage';
import * as css from './TabsPopup.css';
import ButtonScroll from '../../button/buttonScroll/ButtonScroll';
import {useEffect, useRef, useState} from 'react';
import {createContext} from 'react';
import MobilePopupModal from '../mobilePopup/MobilePopupModal';
import CustomScrollbar from '../../customScrollbar/CustomScrollbar';
import {mobileMedia} from '../../../globalVariables/GlobalVariables';

export const TabsPopupContext = createContext();

function TabsPopup({showClass,children, header,top, image, color, tabsList, onClickStage,bottomComp,currentIndex=0,stageChildren}) {
    const [currentStageIndex,setCurrentStageIndex] = useState(currentIndex>-1?currentIndex:0);
    const currentItem = tabsList[currentStageIndex];
    const refContent=useRef();
    const [height,setHeight]=useState(0);
    const [show,setShow]=useState(false);
    const timer = useRef(null);

    useEffect(()=>{   
        setHeight(refContent.current?.firstElementChild.clientHeight);
        timer.current=setTimeout(()=>{
            setShow(refContent.current?.firstElementChild.clientHeight>refContent.current?.clientHeight);
        },1000);
    },[currentStageIndex]);

    useEffect(()=>()=>{
        clearTimeout(timer.current);
    },[]);

    if(!currentStageIndex&&currentStageIndex!==0)
        return (<></>);
    const cssStyle = `
        top: 20px;
        inset-inline-end: 20px;
        ${mobileMedia}{
            top: 60px;
            inset-inline-end: 3px;
        }
    `;

    return (
        <TabsPopupContext.Provider value={{currentStageIndex,setCurrentStageIndex,tabsList}}>
            <MobilePopupModal dataCyName={`${header.type}Tabs`} {...header} top={top} height={`${((190+height)<550?550:190+height)}px`}  showClass={showClass} >
                <>
                    {children}
                </>
                <>
                    <css.popUpImage color={color}>
                        <css.image image={image} />
                        <Stage tabsList={tabsList} onClickStage={onClickStage} color={color?`#${color}`:''}/>
                        {stageChildren&&stageChildren}
                    </css.popUpImage>   
                    {show && <CustomScrollbar contentRef={refContent} cssStyle={cssStyle} maxBottom={65}/>}            
                    <css.popUpContent hasBottom={!!bottomComp} ref={refContent} >
                        <currentItem.Comp {...currentItem} />
                        {show&&<ButtonScroll refToScroll={refContent}/>}
                    </css.popUpContent>
                    {bottomComp&&<bottomComp.Comp {...bottomComp} continueBtnText={currentStageIndex<tabsList.length-1?tabsList[currentStageIndex+1].stageText:null}
                        continueClick={() =>  tabsList[currentStageIndex + 1]&&setCurrentStageIndex(currentStageIndex + 1)}/>}               
                </>
            </MobilePopupModal>
        </TabsPopupContext.Provider>
    );
}

export default TabsPopup;
