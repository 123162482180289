import styled from 'styled-components';
import {BGImageCenter, mobileMedia} from '../../../../../globalVariables/GlobalVariables';
import * as input from '../../../../../component/classes/input';
import {calendar} from '../../../../../assets/symbols/symbols/Symbols.css';
import {container as dateContainer} from '../../../../../component/FormikDatePicker/FormikDatePicker.css';

export const container = styled.div`
  padding-bottom: 5px;
  display: flex;
    ${dateContainer}{
        width:28%;
    }
`;

export const wrapInputList = styled.div`
   width:50%;
   ${mobileMedia}{
            width:100% ;
    }
`;

export const icon = styled(input.icon)`
    ${BGImageCenter(calendar,16)}
    `;
export const wrap = input.wrap;

export const inputs = styled.div`
    display: flex;
    width:100%;
    >div:first-child{
       width:25%;
    }
    >div:nth-child(2){
       width:40%;
    }
    >div:nth-child(3){
       width:40%;
    }
    ${mobileMedia}{
        >div{
            width:100% !important;
        }
        flex-direction:column ;
    }

`;

export const wrapTexts = styled(wrap)`
    display: flex;
    flex-direction:column ;
    ${mobileMedia}{
        width:max-content;
        border-bottom: 0;
        height: 40px;
    }
`;

export const descText = styled.div`
    display: flex;
    font-size: 10px;

`;
export const dateText = styled.div`
       font-weight:${input.INPUT_WEIGHT};
       line-height: 20px;
       ${mobileMedia}{
        line-height: unset;
       }
       
`;

