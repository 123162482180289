import styled from 'styled-components';
import * as inputClasses from '../classes/input';

export const wrapSelect = styled.div`
  select{
    border: 0;
    outline:0 ;
    height: 25px;
    border-bottom: 1px solid;
    width:100% ; 
    background: transparent;
    option:not(:first-of-type) {
      cursor: pointer;
    }
  }
`;

export const input = styled(inputClasses.input)`
    position: relative;
    display: flex;
    align-items: center;

`;

export const icon = styled(inputClasses.icon)`
`;

export const container = inputClasses.container;

export const wrap = inputClasses.wrap;

export const title =  styled.div`
   letter-spacing: 0.3px;
    font-weight: bold;
    font-size: 11px;
    position:absolute;
`;