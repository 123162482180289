import styled from 'styled-components';

export const stage = styled.div`
    display: flex;
    justify-content: flex-start;
    margin-inline-start:58px;
    @media screen and (max-width: 750px){  
        justify-content: center;
        margin-inline-start: 0px;
    
    } 
`;

