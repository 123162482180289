import styled from 'styled-components';
import {mobileMedia} from '../../../../globalVariables/GlobalVariables';

export const formFields = styled.div`

`;
export const formImage = styled.div`

`;
export const aboutGroup = styled.div`
    padding: 40px;
    display: flex;
    ${mobileMedia}{
        display: block;
    }
`;
export const whiteBottom = styled.div`

`;
export const initRight = styled.div`
    width: 345px;
    max-width: 345px;
    ${mobileMedia}{
        width: 100%;
        max-width: 100%;
    }
`;
export const initLeft = styled.div`
    width: 157px;
    margin-${props => props.theme.rtl ? 'right' : 'left'}: 80px;
    ${mobileMedia}{
        margin-top: 17px;
    }
`;
export const tickets = styled.div`
    height: 54px;
    margin-top:15px;
    padding: 0 40px;
`;
export const ticketsTitle = styled.div`
    font-size: 12px;
`;
export const ticketsRow = styled.div`
    height: 60px;
    width: 100%;
    margin-top: 15px;
    display: flex;
    flex-wrap: wrap;
`;
export const ticket = styled.div`
  
`;