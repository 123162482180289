import styled from 'styled-components';
import {dFlexCenter} from '../../../../globalVariables/GlobalVariables';

export const details=styled.div`
    /* width: 180px; */
    font-size: 10px;
    ${dFlexCenter}

`;
export const text=styled.div`
    padding-inline:5px;
`;
