import styled from 'styled-components';
import {BGImageCenter, ellipsisLines, mobileMedia, YELLOW} from '../../../../globalVariables/GlobalVariables';
import {EditUserImgBtn} from '../../../../component/circleImageEdit/CircleImageEdit.css';
import {getColor} from '../../accountRealtime/AccountRealtime.css';
import realtimeFonts from '../../fonts';
import {editPencil} from '../../../../assets/symbols/symbols/Symbols.css';
import {windowBase} from '../../style.css';

export const profile = styled.div`
    ${windowBase};
    position: relative;
    grid-area: profile;
    width: 200px;
    height: 265px;
    padding: 20px;
    transition: all 0.5s;
    ${mobileMedia}{
        width: 100%;
    }
`;

export const edit = styled(EditUserImgBtn)`
    top: 10px;
    left: 10px;
    right: unset;
`;

export const profileImage = styled.div<{ image?: string }>`
    height: 84px;
    width: 84px;
    border-radius: 50%;
    position: relative;
    inset-inline-start: calc(50% - 42px);
    ${p => BGImageCenter(p.image, 'cover')};
`;
export const userName = styled.div`
    font-size: 18px;
    font-weight: 800;
    text-align: center;
    margin-top: 20px;
    color: ${p=>getColor(p.theme.isDarkMode)};
`;
export const userRole = styled.div<{ icon?: string }>`
    width: 84px;
    height: 25px;
    border-radius: 13px;
    background-color: ${YELLOW};
    position: relative;
    inset-inline-start: calc(50% - 42px);
    font-size: 12px;
    padding-block: 4px;
    padding-inline-start: 10px;  
    font-weight: 600;
    text-align: center;
    margin-block: 10px;
    &::before{
        content: '';
        height: 20px;
        width: 20px;
        position: absolute;
        inset-inline-start: 10px;
        ${p=>BGImageCenter(p.icon, '15px 15px')};
        background-position: center 0;
    }
 `;

export const profileText = styled.div<{ isMaster?: boolean }>`
    text-align: center;
    ${realtimeFonts.text}
    ${ellipsisLines(4)};
    color: ${p=>getColor(p.theme.isDarkMode)};
    ${p=>p.isMaster &&
    `cursor: pointer;
        &:hover{
            &::before{
                content: '';
                height: 20px;
                width: 20px;
                position: absolute;
                ${BGImageCenter(editPencil, '15px 15px')}
                inset-inline-start: 10px;
            }
        }
    `};
 
 `;
