import styled from 'styled-components';
import {tooltip} from '../../../../component/tooltip/tooltipAbsolute/TooltipAbsolute.css';
import {BGImageCenter, dFlexCenter, desktopMedia, ellipsis, ellipsisLines, mobileMedia} from '../../../../globalVariables/GlobalVariables';

export const item=styled.div`
    height:45px ;
    width:100% ;
    box-shadow:0px 1px 2px #00000070;
    background-color: white;
    border-radius: 22px;
    margin:7px 0;
    ${dFlexCenter}
    ${mobileMedia}{
        height:100px ;
        border-radius: 10px;
    }
`;

export const icon=styled.div`
    ${props=>BGImageCenter(props.icon,'12px 12px')}
    height: 16px;
    width: 12px;
    margin-inline-end: 3px;
    ${mobileMedia}{
        margin-inline-end: 8px;
     }
`;

export const rightPart=styled.div`
    width: calc(100% - 50px);
    display:flex ;
    ${mobileMedia}{
        height:100% ;
        padding: 10px 0;
    }
`;

export const leftPart=styled(icon)`
   ${dFlexCenter}
    width: 50px;
    border-${props => (props.theme.rtl ?'right':'left')}: 1px solid #d1cece;
    height:75% ;
`;

export const image=styled.div`
    height:38px;
    width: 38px;
    background-color: #fff;
    ${BGImageCenter(props => props.symbol,'cover')};
    background-image:url("${p=>p.image}");
    cursor: pointer;
    border-radius:50%;
    box-shadow: 0 3px 6px #00000029;
    margin:3px ;
    ${mobileMedia}{
        position: relative;
         top: 18px;
    }
  
`;

export const wrapText=styled.div`
   display: flex;
   flex-direction: column;
   justify-content: center;
   padding:0 5px;

`;

export const boldText=styled.div`
    font-weight: bold;
    font-size: 12px;
    ${ellipsis}
`;

export const title=styled(boldText)`
    ${mobileMedia}{
        ${ellipsisLines(2)};
        white-space: unset;
        padding:0 ;
    }
`;

export const text=styled.div`
    font-size: 11px;
    padding-inline-end: 8px;
    ${ellipsis}
    font-size: 11px;
    padding:0px ;
`;

export const widthText=styled(boldText)`
    max-width: calc(100% - 20px);
    width: max-content;
  ${mobileMedia}{
        width: 100%;
     }
 `;

export const wrapTexts=styled.div`
  width:100% ;
  display:flex ;
  align-items: center;
  ${mobileMedia}{
        display: flex;
        flex-direction: column;
        align-items:start ;
     }
`;

export const leftContext=styled.div`

    display:flex ;
    align-items: center;
    width: 50%;
    justify-content:space-evenly ;
    ${desktopMedia}{
        >div:first-child{
            padding-inline-start: 18px;
        }
    }
    ${mobileMedia}{
        flex-direction:column ;
        width: 40%;
    }
`;

export const middle=styled.div`
    justify-content: flex-end;
    align-items: center;
    display: flex;
    width:90px;
    ${mobileMedia}{
        justify-content: flex-start;
    }
`;

export const right=styled.div`
  display:flex;
  flex-direction:column ;
  justify-content:center;
  padding-inline-start: 7px;
  width: calc(100% - 90px);
  min-width: calc(100% - 90px);
  max-width: 200px;
  padding-inline-end: 10px;
  ${mobileMedia}{
      height:100% ;
      width: 100%;
      justify-content:space-evenly ;
    }
`;

export const wrapItem=styled.div`
    position:relative ;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width:33% ;
    ${mobileMedia}{
        width:calc(100% - 10px) ;
    }
    ${tooltip}{
        &::before{
            max-width: 160px;
        }
    }
`;
export const wrapPrice=styled.div`
    width:100% ;
    ${mobileMedia}{
        width:calc(100% - 10px) ;
    }
    ${tooltip}{
        &::before{
            max-width: 160px;
        }
    }
`;
export const wrap=styled.div`
    ${dFlexCenter}
    justify-content: flex-start;

`;
export const percent=styled(boldText)`
   padding-inline-end: 5px;
   width:max-content;
   ${mobileMedia}{
        line-height: 14px;
     }
`;

export const location=styled.div`
    display:flex ;
    max-width:50% ;
    width:50%;
    ${mobileMedia}{
        max-width:100% ;
        width:100% ;
     }
`;

export const rightContext=styled.div`
    display: flex;
    width: 50%;
    ${mobileMedia}{
    flex-basis: 60%;
    flex-grow: 0;
     flex-direction:column ;
     padding-inline-end: 10px;
     }
`;

export const date=styled(text)` 
    max-width:50% ;
    padding-inline-end: 6px;
    width:max-content ;
    ${mobileMedia}{
        max-width:100% ;
     }
`;
export const wrapLocation=styled(text)`
    width: calc(100% - 15px);
    line-height: 16px;
`;
