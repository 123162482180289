import styled from 'styled-components';
import zIndex from '../../globalVariables/zIndex';
import {BGImageCenter, DEFAULT_COLOR, LIGHT_GRAY, STRONG_BLUE} from '../../globalVariables/GlobalVariables';
import {add, addWhite} from '../../assets/symbols/symbols/Symbols.css';
import {cssEscape} from '../../library/charEscape';

const variable = `
  font-weight: bold;
  color: ${STRONG_BLUE};
`;

export const wrap = styled.div`
    border: 1px solid ${LIGHT_GRAY};
    border-radius: 10px;
    position: relative;
    width: 100%;
    .quill {
      direction: ltr;
      display: flex;
      flex-direction: column-reverse;
    }
    .ql-editor {
        direction: ${p => p.direction==='ltr' ? 'ltr' : 'rtl'};
        text-align:  ${p => p.direction==='ltr' ? 'left' : 'right'};
        margin-top: 30px;
    }
    .variable>span{
      ${variable}
    }
    .variable{
      ${variable}
    }
    .ql-container.ql-snow{
        font-size: 14px;
        border:none; 
    }
    .ql-toolbar.ql-snow {
        ${p=>!p.showToolbar &&
          'visibility: hidden; height: 0px;'
        }
        border: none; 
        border-bottom: 1px solid ${LIGHT_GRAY};
        display: flex;
        flex-wrap: wrap;
        
    } 
    .ql-snow .ql-picker.ql-size{
      width:max-content;
    }
    .ql-snow .ql-picker.ql-font{
      width:max-content;
    }
    .ql-snow .ql-picker-label{
      margin-right: 20px;
    }
    .ql-toolbar.ql-snow .ql-formats {
      margin-right: 0px;
    }
    .ql-variableDropdown {
      display: none !important;
    }
    .custom-picker{
      position: absolute;
      visibility: hidden;
      display: block;
    }
`;
export const variableDropdown = styled.div`
  content: '${p=>(cssEscape(p.text))}';
  visibility: visible;
  appearance: none;
  border: 1px solid ${LIGHT_GRAY};
  border-radius: 21px; 
  padding: 4px 30px 4px 16px;
  position: absolute;
  top: 10px;
  ${p => (p.theme.rtl && p.direction==='ltr') || (!p.theme.rtl && p.direction==='rtl') ? 'inset-inline-start': 'inset-inline-end'}: 10px;
  ${BGImageCenter(add,'8px')}
  background-position: right 10px center; 
  font-weight: 600;
  cursor: pointer;
  z-index:${zIndex.baseRaised};
`;
export const item = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
  &::after{
    content:'';
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: ${DEFAULT_COLOR};
    ${BGImageCenter(addWhite,'10px')}
  }
`;
export const warning = styled.div`
  position: absolute;
  bottom: -35px;
  width: max-content;
  max-width: calc(100% - 130px);
  height: max-content;
  border: 0.5px solid #F000002E;
  border-radius: 10px;
  padding-inline-end: 5px;
  inset-inline-start: 5px;
  display: flex;
  align-items: center;
  @media screen and (max-width:480px){
    bottom: -45px;
  }
  &::before{
    content: '!';
    position: relative;
    background-color: red;
    border-radius: 50%;
    color: white;
    padding-inline: 7px;
    font-weight: 700;
    margin-inline-end: 5px;
  }
`;

export const maxChar=styled.div`
    width: max-content;
    bottom: 0;
    padding-inline-start:  27px;
    position: absolute;
    font-size: 11px;
    span{
      color: red;
    }
`;