import React from 'react';
import GenericPieChart from '../genericPieChart/GenericPieChart';
import * as css from './TicketsChart.css';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import MasterPermission from '../../../components/rolePermission/MasterPermission';
import {formatRealtimeNumber} from '../../functions';

function TicketsChart ({tickets,activeIndex,setActiveIndex}){
    const totalPrice = useSelector((s) => s.realtime?.event?.eventStats?.all?.totalPrice);

    const {sumOfSold, sumOfAmount, totalValue, unsoldPrice, soldPrice} = tickets?.reduce(
        (acc, ticket) => {
            const ticketValue = ticket?.totalPrice ?? ((ticket?.amount ?? 0) * (ticket?.price ?? 0));
            //const unsoldTicketValue = ticketValue - (ticket?.soldPrice ?? 0) + (ticket?.discount ?? getDiscount(ticket));
            const unsoldTicketValue = ticket?.unsoldPrice ?? (((ticket?.amount ?? 0) - (ticket?.sold ?? 0 )) * (ticket?.price ?? 0));
            return {
                sumOfSold: acc.sumOfSold + (ticket?.sold ?? 0),
                sumOfAmount: acc.sumOfAmount + (ticket?.amount ?? 0),
                totalValue: acc.totalValue + ticketValue,
                unsoldPrice: acc.unsoldPrice + unsoldTicketValue,
                soldPrice: acc.soldPrice + ticket?.soldPrice ?? 0
            };
        },
        {sumOfSold: 0, sumOfAmount: 0, totalValue: 0, unsoldPrice: 0, soldPrice: 0}
    ) ?? {sumOfSold: 0, sumOfAmount: 0, totalValue: 0, unsoldPrice: 0, soldPrice: 0};

    const {t}= useTranslation('ticket');
    const {currencySymbol} = useSelector(s => s.event);
    const  textCenter=() => {
        const unlimited=tickets[activeIndex]?.amount===null;
        if(activeIndex!==null)
            return {
                name: tickets[activeIndex]?.title,
                sold: unlimited? `${tickets[activeIndex]?.sold} ${t('ticketPcs')}`:`${(((tickets[activeIndex]?.sold / tickets[activeIndex]?.amount) || 0) * 100).toFixed(0)}%`,
                amount: unlimited?'':`${tickets[activeIndex]?.sold}/${tickets[activeIndex]?.amount}`,
            };
        return  {
            name: t('allTicketText'),
            sold: `${((sumOfSold / sumOfAmount) * 100).toFixed(0)}%`,
            amount: `${sumOfSold}/${sumOfAmount}`
        };

    };

    const inlineRect = (
        <>
            <css.ticketName>{textCenter().name}</css.ticketName>
            <css.sold>{textCenter().sold}</css.sold>
            <css.amounts>{textCenter().amount}</css.amounts>
        </>
    );

    return (
        <css.container>
            <css.chartContainer>
                {<GenericPieChart
                    data={tickets.map((ticket, index) => ({
                        ...ticket,
                        index,
                        percentage: (ticket.sold / (ticket.amount || 1000)) || 0.01
                    }))}
                    dataKey='percentage'
                    setActiveIndex={setActiveIndex}
                    activeIndex={activeIndex}
                    hoverState={true}
                    inlineRect={inlineRect}
                />}

            </css.chartContainer>
            <MasterPermission>
                {totalPrice > 0 && <css.summaryContainer>
                    <css.summaryTitle>{`${t('totalSales')} `}<span>{`${currencySymbol}${formatRealtimeNumber(totalPrice)}`}</span>
                    </css.summaryTitle>
                    <css.summary className='free-color'>
                        <span> {`${currencySymbol}${formatRealtimeNumber(unsoldPrice)}`}</span>{` ${t('salesPotential')}`}
                    </css.summary>
                    <css.summary className='free-color'>
                        <span> {`${currencySymbol}${formatRealtimeNumber(unsoldPrice + totalPrice)}`}</span> {` ${t('maxEventRevenue')}`}
                    </css.summary>
                </css.summaryContainer>}
            </MasterPermission>
        </css.container>
    );
}

export default TicketsChart;
