import styled from 'styled-components';
import {BGImageCenter} from '../../globalVariables/GlobalVariables';
import {success} from '../../assets/symbols/symbols/Symbols.css';
import {btn} from '../button/btn/Btn.css';

export const wrapper = styled.div`
  ${btn}{
    padding-inline-start: 5px;
    background-color: white;
    border: unset;
    box-shadow: 0px 3px 10px #00000029;
    &:hover{
      background-color:#C7F6FF;
    }
  }
`;
export const checkbox = styled.div`
      width: 20px;
      height: 20px;
      border-radius: 50%;
      margin-inline-end: 5px;
      background-color: #D5D5D5;
      &.checked{
        background-color:#AED11C;
        ${BGImageCenter(success,'12px 12px')}
      }
`;