import styled from 'styled-components';
import {btn} from '../../../../../component/button/btn/Btn.css';

export const anotherTime=styled.div`
    ${btn}{
      color:#484848;
      background-color:#FFF;
      font-weight: 400;
      border: 1px solid;
    &:hover{
      color:#76d6e8;
    } 
}
`;
export const finishNow=styled.button`
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
    margin-${props => props.theme.rtl ? 'right' :'left'}: 10px;
  ${btn}{
    color:#484848;
    background-color:#76D6E8;
    font-weight: 800;
    border:1px solid #76D6E8;
    &:hover{
      color:#817474;
    }
  }
`;
export const inForm=styled.div`
`;
export const whiteBottom=styled.div`
    width: 100%;
    height: 10.5%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    ${props => props.theme.rtl ? 'left' :'right'}: 0;
    bottom: 8px;
`;