import styled from 'styled-components';

export const MenuBtn = styled.button`
    position: relative;
    width: 100%;
    height: 30px;
    text-align: ${props=>(props.theme.rtl?'right':'left')};
    border: none;
    border-radius: 20px;
    margin-bottom: 12px;
    ${props=>(props.theme.rtl?'padding-right':'padding-left')}: 20%;
    cursor: pointer;
    user-select:none;
    outline:none;
    color:#696969;
    background:none;
    font-size:16px;
    &:hover{
        color:#000;
    };
    &.clicked{
        font-weight: 700;
        color: #000;
        pointer-events:none;
    };
    &.clicked::before{
        content:'';
        position: absolute;
        ${props=>(props.theme.rtl?'right':'left')}: 0;
        top: 0;
        width: 6px;
        height:30px;
        background:#76D6E8;
    };
    &.isBuilding{
        opacity: 0.2;
        pointer-events: none;
    }
    
    
`;