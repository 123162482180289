import styled from 'styled-components';
import * as symbol from '../../../../../assets/symbols/symbols/Symbols.css';
import {BGImageCenter, dFlexCenter, mobileMedia}  from '../../../../../globalVariables/GlobalVariables';
import {btn} from '../../../../../component/button/btn/Btn.css';

export const wrapBtn=styled.div`
    display: flex;
    justify-content: flex-end;
    padding: 0 30px;
    ${mobileMedia}{
        width: 75%;
        padding: 0;
        padding-inline-end:5    px
    }
`;

export const bottom = styled.div`
    border-top: 1px solid #EEE;
    height: 70px;
    min-height: 70px;
    display:flex;
    align-items:center ;
    justify-content: space-between;
`;

export const wrapColor=styled.div`
position: relative;
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    border-${props => props.theme.rtl ?'left':'right'}: 1px solid #EEE;
    ${mobileMedia}{
        width: 25%;
    }
`;
export const arrow=styled.div`
        width: 22px; 
        height: 22px;
        cursor: pointer;
        ${props => props.theme.rtl ?'left':'right'}: 25px;
        ${BGImageCenter(symbol['simpleArrow'],'7px')}
`;

export const colorTicket=styled.div`
   display: flex;
   justify-content: center;
   align-items: center;
   height: 40px;
   width: 40px;
   border-radius: 50%;
   background-color:#${props=>props.color?props.color:'76d6e8'} ;
   cursor: pointer;
   &::before{
       content: '';
       background-image:url("${symbol['snail']}");
       background-repeat: no-repeat;
        background-position:center center;    
        height: 30px;
        width: 30px;
        position: absolute;
   }
`;
export const wrapColorPicker=styled.div`
    background:#FBFBFB ;
    padding: 40px 0 0 0;
    display: flex;
    justify-content: center;
    height: calc(100% - 180px);
    border-bottom: 1px solid #EEE;
`;
export const bottomPickerPop=styled.div`
   height: 70px;
   ${dFlexCenter}
`;

export const nextBtn=styled.div`
`;

export const saveBtn=styled.div`
    padding: 0 5px;
    &.disabled {
        ${btn}{
            border:none;

            background-color: #ccc;
            &:hover{
                border: 1px solid #FFF;
                background-color: #ccc;
            }
      }
    }

    
`;