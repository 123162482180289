import styled from 'styled-components';
import {dFlexCenter} from '../../../../globalVariables/GlobalVariables';
import {btn} from '../../../../component/button/btn/Btn.css';

export const container=styled.div`
    width: 100%;
    height:0 ;
    opacity:0;
    transition: all 400ms;
    ${dFlexCenter}
    &.valid{
        transition: all 400ms;
        opacity:1 ;
        height:125px ;
    }
    >${btn}{
        width: 320px;
        height:50px;
        background-color: transparent;
        box-shadow: 0px 3px 6px #00000029;
        border: 5px solid #FFFFFF;
        border-radius: 34px;
        margin:30px 0;
        ${dFlexCenter}
        cursor: pointer;  
        font-size:16px;
        font-weight: 600;
        transition: all 300ms;
        color:#FFFFFF;
        letter-spacing: 1px;
            &:hover{
                background: #FFFFFF; 
                color:#484848;
            }
        &.LOADING{
            &::after{
                top: unset;
                left: unset;
            };
        }
    }
`;