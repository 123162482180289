import styled from 'styled-components';
import {groupColor}  from '../../../../assets/symbols/images/Images.css';
import * as symbol from '../../../../assets/symbols/symbols/Symbols.css';

export const imageText=styled.div`
    height: 25px;
    font-weight: 500;
`;
export const wrapBtn=styled.div`
    .btnCss{
        width: 80px;
    }
`;
export const wrapImage=styled.div`
     height: fit-content;
`;
export const wrapPopImage=styled.div`
     width:fit-content;
`;

export const colorPicker=styled.div`
   display: flex;
   justify-content: center;
   align-items: center;
   height: 40px;
   width: 40px;
   border-radius: 50%;
   background-color:#${props=>props.groupColor};
   cursor: pointer;
   &::before{
       content: '';
       background-image:url("${symbol['snail']}");
       background-repeat: no-repeat;
        background-position:center center;    
        height: 30px;
        width: 30px;
        position: absolute;
   }
`;

export const popUpImage=styled.div`
      .popUpBottom{
       display: flex;
       align-items: center;
       height:70px;
       padding: 0 50px;
       justify-content: flex-end;
       justify-content: space-between;
    } 
    .popUpContent{
        height: calc(100% - 130px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .popTop{
        background-color:#76d6e8 ;
    }
    .popImage{
        display: none;
    }
    .popOnPopMiddle{
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

export const popOnPopImg=styled.div`
    background-image:url("${groupColor}");
    position: absolute;
    height: 100px;
    width: 135px;
    right: 70px;
    background-size: contain;
    background-repeat: no-repeat;
    bottom: 0px;
`;