import styled from 'styled-components';
import isLightColor from '../../../../../functions/isLightColor';
import {ellipsis} from '../../../../../globalVariables/GlobalVariables';

export const container = styled.div`
    overflow:visible ;
    position:relative ;
`;
export const group = styled.div`
    position: relative;
    width:70px ;
    height:20px ;
    border-radius: 9px;
    background-color:#${p => p.color} ;
    cursor:pointer;
    line-height:20px;
    padding: 0 8px;
    font-size: 11px;
    font-weight: 600;
    ${ellipsis};
    ${p=>isLightColor(p.color)?'':'color:#eee;'}
    &.draft{
        cursor: unset;
    }
`;
export const rect=styled.div`
        background-color:#${p => p.color}  ;
        margin-inline:10px;
        border-radius:6px ;
        width:20px ;
        height:20px;
`;
