/* eslint-disable spellcheck/spell-checker */
import styled from 'styled-components';
import * as icons from '../../../../assets/images/socialNetwork/socialNetwork';
import {face} from '../../../../assets/symbols/symbols/Symbols.css';
import {container} from '../../../../component/classes/input';
import {BGImageCenter, dFlexCenter, mobileMedia} from '../../../../globalVariables/GlobalVariables';

const TOP_HEIGHT='70%';

export const title=styled.div`
        letter-spacing: 1px;
        font-size: 12px;
        font-weight: 500;
        margin: 30px 0 10px 0;
        padding-inline-start: 20px;
        ${mobileMedia}{
            margin: 15px 0 0 0;
        }
        `;

export const networks=styled.div`
    display: flex;
    align-items: center;
`;
export const network=styled.div`
    cursor: pointer;
    height: 60px;
    width: 60px;
    margin: 3px;
    ${p=>BGImageCenter(icons[`${p.className}Color`],'37px')}
    &.facebook{
        background-size:20px;
    }
    &.tiktok{
        background-size:30px;
    }
`;

export const socialNetwork=styled.div`
height: 100%;
width:100%;

`;

export const top=styled.div`   
transition: all 300ms;
height: ${TOP_HEIGHT};
border-radius: 8px;
${dFlexCenter}
flex-direction: column;
padding-top: 100px;
&.tiktok{
    background-color: #262C38;
}
&.facebook{
    background-color: #337FFB;
}
&.instagram{
    background: transparent linear-gradient(215deg, #FFD500 0%, #F15A24 24%, #ED1E79 46%, #662D91 70%, #1B1464 100%) 0% 0% no-repeat padding-box;
}
`;

export const image=styled.div`
    ${BGImageCenter(face,'93px')}
    &.instagram{
        background-size: 85px;
    }
    width: 100px;
    height: 100px;
    box-shadow: 0px 5px 14px #00000057;
    border: 7px solid #FFFFFF;
    border-radius: 50%;
`;

export const followMe=styled.div`
flex-grow: 4;
padding-top: 10px;
font-weight: 600;
letter-spacing: 0.8px;
>span{
font-weight: 300;
}
`;
export const logo=styled.div`
    height: 50px;
    width: 100px;
    ${p=>BGImageCenter(icons[`${p.className}Icon`],'30px')}    
    &.instagram{
        background-size:60px;
    }
    &.facebook{
        background-size:15px;
    }
`;

export const logoText=styled.div`
    height: 50px;
    width: 100px;
    margin-top: -15px;
    ${p=>BGImageCenter(icons[`${p.className}Text`],'110px')}    
    &.tiktok{
       background-size:60px;
    }

    
`;

export const bottom=styled.div`
    ${dFlexCenter}
    flex-direction: column;
    height: calc(100% - ${TOP_HEIGHT});
    ${container}{
        width: 245px;
    }
    
`;