import styled from 'styled-components';
import * as input from '../classes/input';
import * as symbol from '../../assets/symbols/symbols/Symbols.css';

export const detail = styled(input.container)`
height:unset;
`;

export const detailInput = styled.div`

    display:flex;
    /* height:185px; */
    /* margin-bottom :30px; */
    position:relative;
    width: 100%;
    &::after{
         content:"${props => props.charsCount}/${props => props.sumChars}";
         position: absolute;
         height: 20px;
         ${props => props.theme.rtl ? 'right' : 'left'}:1px;
         font-size: x-small;
         bottom: -9px;
         font-size: 9px;
        }
        &.notValid{
             &::before{
            content:"${props => props.validationText}";
            position: absolute;
            ${props => props.theme.rtl ? 'right' : 'left'}:30px;
            letter-spacing: 1px; 
            bottom: -14px;
            font-size: 11px;
            font-weight: bold;
        }}
    textarea{
        border: 0;
        outline: 0;
        /* padding: 0 10px; */
        /* border-bottom: 1px solid; */
        margin: 0 5px;
        font-size:14px;
        border-right: 0;
        letter-spacing: 1px;
        width: 100%;
        resize: none;
        height: ${props=>props.textareaHeight};
         @media screen and (max-width: 750px){  
          width: 275px;
         } 
        &.notValid{
          color: red;
        }
        :focus{
            color:#65B8E2;
            font-weight:600;
        ::placeholder{
            color: #65B8E2;
            text-align: ${props => props.theme.rtl ? 'right' : 'left'};
          }
        } 
        &:hover{
          border-right: none;
          border-bottom: 1px solid ; 
        }
    }   
      .defaultInput{
          border-${props => props.theme.rtl ? 'right' : 'left'}:1px solid;
          border-bottom: 0;
      }
`;
export const diIcon = styled(input.icon)`
    &.notValid{
      display: none;
    }
`;

export const numChars = styled.div`
  font-size: x-small;
`;
export const isValid = styled.div`
  &.notValid{
     background-color: #fff ;
     font-size:0;  
     background-repeat:no-repeat;  
     background-image:url("${symbol['ExclamationMark']}"); 
     background-position: center center;
     background-size: 8px 10px;
     pointer-events:none;
     border: 1px solid #dd2f21;
     width: 18px;
     height: 18px;
     border-radius: 50%;
     margin: 1px 3.5px 0;
}
`;