import styled from 'styled-components';
import {BGImageCenter} from '../../globalVariables/GlobalVariables';
import {calendar} from '../../assets/symbols/symbols/Symbols.css';
import * as input from '../classes/input';
import {container as containerDatePicker} from '../FormikDatePicker/FormikDatePicker.css' ;

export const container = input.container;

export const icon = styled(input.icon)`
    ${BGImageCenter(calendar,16)}
    `;
export const wrap = input.wrap;

export const inputs = styled.div`
    display: flex;
`;

export const wrapDate = styled(input.input)`
    display: flex;
    input[type='date'] {
        width: 35%;
        background:transparent;
        position: relative;
        /* font-weight: 500; */
        font-size: 14px;
     border:none;
    }

    input[type='date']::-webkit-calendar-picker-indicator {
        font-size:14px;
        text-align: end;
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        padding: 0;
        background: transparent;
        cursor: pointer; 
    }
  ${containerDatePicker}{
    position: relative;
      width: 35%;
      margin-top: 3px;
  }
`;

export const sumDays = styled.div`
    padding-top: 1px;
    width: 30%;
`;
export const date = styled.div`
    padding-top: 1px;
    width: 35%;
`;
export const text = styled.div`
    display: flex;
    font-size: 10px;
`;
export const textStartEnd = styled.div`
    display: flex;
    width: 35%;
    font-size: 10px;
`;
export const sumDaysText = styled.div`
        width: 30%;

`;