import styled from 'styled-components';
import {BGImageCenter} from '../../../../globalVariables/GlobalVariables';

export const icon = styled.div`
    width: 20px ;
    height: 20px;
    ${p=>BGImageCenter(p.icon,'13px')}
    margin-inline-end: 7px;
    cursor: pointer;
    transition:all 200ms;
`;

