import styled from 'styled-components';
import * as scroll  from '../../../../component/scrollbar/Scrollbar.css';
import zIndex from '../../../../globalVariables/zIndex';

export const addEventFrame=styled.div`
    position: fixed;
    max-height:100%;
    ${props => (props.theme.rtl ? 'left: -360px' : 'right:-360px')};
    width: 360px;
    height:100%;
    background: #76D6E8;
    transition: all 400ms;
    top: 0;
    z-index:${zIndex.basicModal};    
    &.show{
        ${props => (props.theme.rtl ? 'left: 0' : 'right:0')};
    }
    @media screen and (max-width: 1000px) {
        border-radius: 0 8px 8px 0;
        max-width:100%;
    }
    `;
export const addEventTop=styled.div`
    height:60px;
    display: flex;
    padding: 20px;
    justify-content: space-between;
`;
export const addEventTitle=styled.div`
    height:220px;
    max-height:15%;
    font-size:18px;
    font-weight:900;
    display:flex;
    align-items: center;
    justify-content: center;
    line-height: 16px;
    text-align: center;
`;
export const addEventEvents=styled(scroll.Scrollbar)`
    max-height: calc(85% - 80px);
    display: grid;
    justify-content: center;
    grid-template-columns: 1fr 1fr;
    padding: 0 45px;
    grid-gap: 16px;
    > div:nth-child(1) {
        grid-column: 1 / 3;
    }
`;
export const inActive=styled.div`    
    div:not(.show,.close,.top){
      opacity: 0.7;
      pointer-events: none;
    }
`;
