import styled from 'styled-components';
import {BGImageCenter} from '../../../globalVariables/GlobalVariables';
import {arrow} from '../../../assets/symbols/symbols/Symbols.css';
import {btn} from '../../../component/button/btn/Btn.css';
import {pageInBuild} from '../../../assets/symbols/images/pageInBuild';

export const container = styled.div`
  background: rgba(0, 0, 0, 0.58) 0% 0% no-repeat padding-box;
  opacity: 1;
  backdrop-filter: blur(9px);
  -webkit-backdrop-filter: blur(9px);
  width: 100%;
  height: 100%;
  ${BGImageCenter(pageInBuild, '103px')};
  background-position: center 90px;
  text-align: center;
  padding: 20px;

`;
export const back = styled.div`
  ${BGImageCenter(arrow, '15px')};
  width: 20px;
  height: 20px;
`;
export const font = styled.div`
  color: #f5f3f3;
  padding: 0 20px;
`;
export const title = styled(font)`
    padding-top: 225px;
  font-size: 18px;
  font-weight: 600;
`;
export const text = styled(font)`
font-size: 16px;
  padding-top: 10px;
`;

export const actions = styled.div`
display: flex;
  justify-content: center;
  ${btn}{
    margin: 35px 7px;
  }
`;

