import * as css from './EventActive.css';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import State from '../../../component/state/State';
import EventActivation from '../../../component/eventActivation/EventActivation';
import {useState} from 'react';
import setGlobalState from '../../../redux/setGlobalState';
import useEffectOnUpdate from '../../../hooks/useEffectOnUpdate';

function EventActive({classes = ''}) {
    const {t} = useTranslation('layout');
    const event = useSelector(state => state.event);
    const activeXHR = useSelector(state => state.forms.eventSettings.activeXHR);
    const active=event.settings?.event?.active;
    const [className,setClassName] = useState(classes + (active ? ' active' : ' notActive'));

    useEffectOnUpdate(()=>{
        if(activeXHR==='SUCCESS'){
            setTimeout(()=>{
                setGlobalState('forms.eventSettings.activeXHR',null);
            },400);
        }
        else {
            setClassName(classes + (active ? 'active' : 'notActive'));
        }
    },[activeXHR]);

    return (
        <css.status>
            <EventActivation Comp={State} compProps={{active:active, activeText:t('activeEvent'), inactiveText: t('closedEvent'), className,activeXHR}}/>
        </css.status>
    );
}

export default EventActive;
