import {useSelector} from 'react-redux';
import * as css from './PixelsScreen.css';
import {useContext} from 'react';
import {pixelContext} from '../../PixelsPage/PixelsPage';
import * as pixelIcons from '../../../../assets/symbols/symbols/pixelIcons';
import {Else, If, Then} from 'react-if';
function PixelSelection({pixelKey,className}) {
    const pixel=useSelector(s=>s.publish.pixel);

    const {getOpenPixel}=useContext(pixelContext);
    const onOpenPixel=getOpenPixel({key:pixelKey});
    const isSelectedSpecialPixel=(pixel.specialPixels.some(p=>p.key===pixelKey));
    const {type}=useSelector(s=>s.event);

    return (
        <If condition={type === 'hub' && pixelKey === 'customPixel'}>
            <Then>
                <></>
            </Then>
            <Else>
                <css.pixelIcon icon={pixelIcons[pixelKey]} data-cy={`${pixelKey}Pixel`}
                    className={`${isSelectedSpecialPixel&&' selected'} ${className}`}
                    onClick={!isSelectedSpecialPixel&&onOpenPixel}/> 
            </Else>
        </If>  
                      
    );
}

export default PixelSelection;