import styled from 'styled-components';
import {BGImageCenter, ellipsis, mobileMedia} from '../../../globalVariables/GlobalVariables';
import * as input from '../../classes/input';
import {checkbox} from '../../checkbox/Checkbox.css';
import zIndex from '../../../globalVariables/zIndex';

export const container = input.container;

export const icon = styled(input.icon)`
    ${BGImageCenter(p=>p.icon,16)}`;
export const wrap = input.wrap;

export const inputs = styled.div`
    display: flex;
`;
export const wrapPicker = styled.div`
   width: 85px;
   ${mobileMedia}{
        width: 100px;
        position: relative;
        bottom: 13px;
    }
`;

export const wrapDate = styled(input.input)`
    display: flex;
    
    justify-content: space-between;
    font-weight:400 ;
    input{
        text-align: end;
        padding-inline-end: ${input.INPUT_PADDING_END};
    }
    ${checkbox}{
        z-index:${zIndex.baseRaised};
        margin-inline-end: 5px;
    }
`;

export const text = styled.div`
    position:absolute ;
    display: flex;
    font-size: 10px;
    letter-spacing:1px;
`;

export const descText = styled.div`

`;
export const dateText = styled.div`
       font-weight:500;
       width:calc(100% - 85px);
       ${mobileMedia}{
        width:calc(100% - 100px);
       }       
`;

export const timeText = styled.div`
       ${ellipsis}
       font-weight:500;
       width:calc(100% - 75px);
       &.hasCheckbox{
            width:calc(100% - 95px);
       }
`;

export const textDate = styled.div`
       font-weight:${input.INPUT_WEIGHT};
       width: calc(100% - 70px);
       margin-inline-end: 10px;
       ${ellipsis}
`;
export const wrapTimePicker = styled.div`
   width: 75px;
   ${mobileMedia}{
        position: relative;
        bottom: 13px;
    }
`;