import styled from 'styled-components';
import {BGImageCenter, LIGHT_GRAY, WHITE, dFlexCenter} from '../../../globalVariables/GlobalVariables';
import {ex, search} from '../../../assets/symbols/symbols/Symbols.css';

export const wrapper = styled.div`
    ${p => p.theme.isExpanded && ' display:flex; flex-direction:row-reverse'};
    position:absolute;
    transition:all 300ms ;
    height: 30px;
    inset-inline-end:10px;
    width: ${p => p.theme.isExpanded ? 'calc(100% - 70px)' : '120px'};
    border-radius: 20px;
    background-color: ${LIGHT_GRAY};
    ${dFlexCenter}
    &.mobileState{
        transition:all 300ms ;
        height: 30px;
        width: ${p => p.theme.isExpanded ? 'calc(100% - 50px)' : '30px'};
        background-color: ${p => !p.theme.isExpanded && WHITE};
    }
`;

export const icon=styled.div`
    width:30px ;
    height:30px ;
    cursor: pointer;
    ${p => BGImageCenter(p.theme.isExpanded ? ex : search,'13px')}
`;
export const input=styled.input`
    border:none;
    width: calc(100% - 50px);
    background-color: ${LIGHT_GRAY};
    padding-right: 8px;
    ::placeholder{
        color: #000000;
        font-weight: bold;
    }
`;