import {setCurrentRating} from '../rating/Rating';
import useEvent from '../../../hooks/useEvent';
import {getAccount, getAccountPermissions, getAccountUserPermissions} from '../../../redux/account/accountActions';
import setGlobalState, {resetGlobalState} from '../../../redux/setGlobalState';
import {TICK_DOMAIN_MY} from '../../../config/config';
import {getEvent, getEventPermissions, sendEventPromotion} from '../../../redux/event/eventActions';
import {dispatchCustomEvent} from '../../../hooks/dispatchEvent';
import {getRatings} from '../../../redux/ratings/ratingsActions';
import {useEffect, useRef} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import store from '../../../store';
import {updateRequestStatus} from '../../../library/request';
import useErrorMessages from '../../../hooks/useErrorMessages';
import yupMethods from '../../../functions/validationsYup';
import useWindowWidth from '../../../hooks/useWindowWidth';
import UniversalPopups from './Popups';
import PopupAfterAdvertise from '../../../component/popupAfterAdvertise/PopupAfterAdvertise';
import {getTs} from '../../../library/dates';
import {getLanguages} from '../../../redux/languages/languagesActions';
import {getEventStats, getStats} from '../../../redux/realtime/realtimeActions';
import {initialStateRealtime} from '../../../redux/realtime/realtimeReducer';
import tickchakQueue from '../../../functions/tickchakQueue';
import Queue from './Queue';
import useGlobalShow from '../../../hooks/useGlobalShow';

const getPackage = async (pid) => {
    if(pid && pid !== store.getState().account.pid) {
        setGlobalState('account.pid',pid);
        await getAccount(pid).then(res=>{
            if(!res?.package)
                return;
            if(res.package.userAccountRole)
                getAccountUserPermissions().then();
            getAccountPermissions().then();
        });
    }
};
function AppComponent({setForm}) {
    let {eid: eidParam, pid: pidParam} = useParams();

    const user = useSelector(s => s.user);
    const {eventReady,eid} = useSelector(s=>s.event);
    const getRatingsXHR = useSelector((s) => s.ratings.getRatingsXHR);
    const {i18n} = useTranslation('layout');
    const openPublishPop = useGlobalShow('isOpenPublishPop');

    useErrorMessages();
    yupMethods();
    const generalRef = useRef();
    const history = useHistory();

    useEvent('changePackage', async (e) => {
        getPackage(e.detail).then();
        resetGlobalState('crowds');
        setGlobalState('suggestions.events', []);
        setGlobalState('account.getAccountSuppliersXHR', null);
        getStats().then();
    });

    const pid = useSelector(state => state.account.pid);

    useEvent('changeEvent', async (e) => {
        resetGlobalState('cluster');
        resetGlobalState('team');
        resetGlobalState('event');
        resetGlobalState('tickets');
        resetGlobalState('attendance');
        resetGlobalState('publish');
        resetGlobalState('branding');
        resetGlobalState('languages');
        setGlobalState('realtime.event',initialStateRealtime['event']);
        setGlobalState('account.getAccountSuppliersXHR', null, 'set');
        if(e.detail) {
            let per = (localStorage.getItem('perEvent_' + e.detail));
            if(!per || per === 'undefined')
                getEventPermissions(e.detail).then();
            await getEvent(e.detail).then(event=>{
                if(event.pid!==pid)
                    dispatchCustomEvent('changePackage', {detail: event.pid});
            });
            getEventStats(e.detail).then();
        }
    });

    function receiveMessage(data) {
        //here
        if(data.origin !== TICK_DOMAIN_MY)
            return;
        if(data.data.location){
            if(data.data.location.split('/')[3]==='design'&&eventReady<5){
                setGlobalState('event.ready',5);
                history.push(data.data.location);
            }
            else
                window.location.href = data.data.location;
        }
        //    history.push(data.data.location); //Todo need update event.ready before use it
        if(data.data.click)
            generalRef.current?.click();
        if(data.data.form){
            if(setForm)
                setForm(data.data.form === 'open' ? 1 : 0);
            if(data.data.form === 'close'){
                setGlobalState('ui.newIframe.openBoxoffice', false);
            }
        }
        if(data.data.reload)
            window.location.reload();
        if(data.data.buildEvent) {
            setCurrentRating('createEvent');
        }
        if(data.data.iframeAjaxUpdate) {
            updateRequestStatus('iframeAjax', 'put', data.data.status, data.data.message);
        }
        if(data.data.openDesignPop){
            setGlobalState('ui.show.isOpenDesignPop',true);
            return;
        }
        if(data.data.changeDefaultLang){
            setGlobalState('event.lang', data.data.lang);
            setGlobalState('branding.currentLang', data.data.lang);
            return;
        }
        if(data.data.addNewLang || data.data.deleteLang){
            getLanguages().then();
            return;
        }
        if(data?.data?.openAccountPop){
            history.push({
                search: `?packagePage=${data?.data?.openAccountPop?.page}`,
                hash:data?.data?.openAccountPop?.hash
            });
            return;
        }

        if(data.data.publish){
            sendEventPromotion().then();
            const twentyFourHoursAgo = getTs() - (24 * 60 * 60);
            const previewLastUpdate = parseInt(localStorage.getItem('showPopUpDate' + eid));
            if(!previewLastUpdate || previewLastUpdate < twentyFourHoursAgo)
            {
                localStorage.setItem('showPopUpDate' + eid, getTs());
                setGlobalState('event.previewLastUpdate', 0);
                openPublishPop.open();
            }
        }
        if(data.data.tsqid){
            tickchakQueue('suspended', {eid: eidParam, tsqid: data.data.tsqid});
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            if(pid)
                await  getPackage();
            await getStats();
            if(!getRatingsXHR)
                await getRatings();
        };
        fetchData()
            .catch(console.error);
        window.addEventListener('message', receiveMessage, false);
        return () => {
            window.removeEventListener('message', receiveMessage, false);
        };
    }, []);

    useEffect(() => {
        if( eidParam && (eidParam !== store.getState().event.eid?.toString()))
            dispatchCustomEvent('changeEvent', {detail: eidParam});
    }, [eidParam]);

    useEffect(() => {
        if(pidParam && (pidParam !== pid?.toString())) {
            dispatchCustomEvent('changeEvent', {detail: null});
            dispatchCustomEvent('changePackage', {detail: pidParam});

        }
    }, [pidParam]);

    useEffect(() => {
        i18n.changeLanguage(user.lang).then();
    }, [user.lang]);

    useWindowWidth();

    return (
        <>
            <div ref={generalRef} />
            <UniversalPopups />
            <Queue />
            {!!openPublishPop.isOpen&&<PopupAfterAdvertise close={openPublishPop.close} showClass={openPublishPop.showClass}/>}
        </>
    );
}
export default AppComponent;
