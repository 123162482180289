import * as css from './BudgetItem.css';
import {useTranslation} from 'react-i18next';
import BudgetPopup from './BudgetPopup/BudgetPopup';
import useShow from '../../../../../hooks/useShow';
import RowTableBudget from './rowTableBudget/RowTableBudget';
import {useSelector} from 'react-redux';
import {useEffect, useState} from 'react';
import {getBudgetItems} from '../../../../../redux/account/accountActions';
import Btn from '../../../../../component/button/btn/Btn';
import EventRolePermission from '../../../../components/rolePermission/EventRolePermission';

function BudgetItem() {
    const {t} = useTranslation('package');

    const budgetItems = useSelector((s) => s.account?.budgetItems);
    const [currentItem, setCurrentItem] = useState(null);

    const {open, close, isOpen, showClass} = useShow();

    const onClickRow = (item, index) => {
        let newItem = Object.assign({}, item);
        newItem.index = index;
        setCurrentItem(newItem);
        open();
    };

    useEffect(async () => {
        if(!budgetItems?.length)
            await getBudgetItems();
    }, []);

    return (<>
        <css.wraper>
            <css.wrapText>
                <css.bottomTitle>{t('addBudgetItem')}</css.bottomTitle>
                <css.title>{t('budgetItemDesc')}</css.title>
            </css.wrapText>
            <EventRolePermission rolePermission="manage package">
                <Btn
                    style={`color:#484848;
                            border: 1px solid #87D2ED;
                            padding:0;
                            background-color:#FFFFFF;
                            &:hover{
                                background-color:#d7eff4;
                            } 
                            `}
                    onClick={() => { setCurrentItem(null); open(); }}
                >
                    <css.wrapBtn>
                        <css.plus />
                        <css.textBtn>{t('newBudget')}</css.textBtn>
                    </css.wrapBtn>
                </Btn>
            </EventRolePermission>
        </css.wraper>
        <EventRolePermission rolePermission="owner">
            <css.container>
                {budgetItems?.map((item, index) => {
                    return <RowTableBudget key={index} item={item} index={index} onClickRow={onClickRow} />;
                })}
            </css.container>
        </EventRolePermission>
        {isOpen && <BudgetPopup close={close} showClass={showClass} currentItem={currentItem} />}
    </>
    );
}

export default BudgetItem;
