import styled from 'styled-components';
import {clock} from '../../../../../assets/symbols/symbols/Symbols.css';
import {BGImageCenter, dFlexCenter} from '../../../../../globalVariables/GlobalVariables';

export const values = styled.div`
    font-weight: 600;
    font-size: 14px;
    padding-inline-end: 10px;
`;

export const wrapPlus=styled.div`
    margin: 4px;
`;
export const top=styled.div`
    display: flex;
    justify-content: space-between;
`;
export const text=styled.div`
    font-size: 11px;
    letter-spacing: 1px;
    padding: 5px 0;
 
`;
export const wrapPrice=styled.div`
  display:flex ;
  flex-flow: wrap;
  width:100% ;
  align-items:center ;
`;

export const wrapValue = styled.div`
    font-weight: 600;
    font-size: 14px;
    padding-inline-start: 10px;
`;

export const iconClock = styled.div`
    margin-inline-start: 10px;
    width: 25px;
    height: 25px;
    ${dFlexCenter}
    ${BGImageCenter(clock, '16px 16px')}
`;

export const wrapHour = styled.div`
    font-weight: 600;
    font-size: 14px;
`;
export const list = styled.div`
    width: 180px;
`;

export const wrapAll = styled.div`
  padding: 10px 0 20px;
`;