import styled from 'styled-components';
import {BGImageCenter, dFlexCenter} from '../../globalVariables/GlobalVariables';
import {googleWallet} from '../../assets/symbols/symbols/brandingIcons';

export const btn=styled.div`
    white-space:nowrap;
    padding: 0px 18px;
    border: 1px solid;
    border-radius: 25px;
    height: 40px;
    width: fit-content;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    ${dFlexCenter};
    background-color:#000000; 
`;

export const icon=styled.div`
   width: 35px;
   height: 28px;
   ${BGImageCenter(googleWallet,'28px')}
`;

export const text=styled.div`
    color:#FFFFFF;
    position: relative;
    margin-top: 10px;
    &::before{
        content: "Add to";
        width: max-content;
        height: 11px;
        position: absolute;
        font-size: 11px;
        top: -10px;
        left: 2px;
        font-weight: normal;
    }
`;

