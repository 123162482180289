import styled from 'styled-components';
import {DEFAULT_COLOR} from '../../../globalVariables/GlobalVariables';
import {buttonNo,  buttonNoText} from '../btnAdd/BtnAdd.css';
import {btnPlus, plusIcon} from '../btnPlus/BtnPlus.css';

export const buttonNoWhite = styled(buttonNo)`
  background-color: #fff ;
  border: 1px solid ${DEFAULT_COLOR};
  padding-block: 3px;
  padding-inline-end: 11px;
  ${btnPlus}{
    height: 25px;
    width: 25px;
  };
  ${plusIcon}{
    height: 25px;
    width: 25px;
  };
`;
export const buttonNoTextWhite = styled(buttonNoText)`

`;

