import styled from 'styled-components';
import {btn} from '../../../../component/button/btn/Btn.css';
import {arrowDark} from '../../../../assets/symbols/symbols/Symbols.css';
import {BGImageCenter, dFlexCenter} from '../../../../globalVariables/GlobalVariables';

export const button=styled.div` 
    width: 100%;
    display: flex;
    justify-content: center;
    ${btn}{
        background-color: #FFDA0D;
        ${dFlexCenter}
        &:hover{
            box-shadow: 0 9px 10px #00000029;
        }
        &.SUCCESS{
            background-color: #AED11C;
        }
        &.LOADING{
            width:35px;
        }
        height: 35px;
        width: 100%;
        position: relative;
        &.arrow ,&.leftArrow{
            &::before{
                content: '';
                ${BGImageCenter(arrowDark,'14px')}
                width: 20px;
                height: 20px;
                position: absolute;
                inset-inline-start: 20px;
            }

        }
        &.leftArrow{
            &::before{
                inset-inline-start: unset;
                inset-inline-end: 20px;
                transform: rotate(180deg);
            }
        }
      &.FAILED{
        font-size: 13px;
        color:#FFFFFF;
        background-position: ${p=>p.theme.rtl?'5px': 'calc(100% - 5px)'} center;
      }
    }
`;
 