import styled from 'styled-components';
import {mobileMedia} from '../../../../globalVariables/GlobalVariables';

export const detailsGrid=styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
  width: 100%;
  padding: 40px;
  padding-inline-start: 12px;
  ${mobileMedia} {
    padding: 20px 0 20px 0;

    display: block;
    grid-template-columns: unset;
    width: calc(100% - 30px);
  }
`;

