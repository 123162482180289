import styled from 'styled-components';
import {arrowDark} from '../../../../assets/symbols/symbols/Symbols.css';
import {mobileMedia} from '../../../../globalVariables/GlobalVariables';
import zIndex from '../../../../globalVariables/zIndex';

// height=420
export const Popup=styled.div`
    position: absolute;
    height: 420px;
    width: 100%;
    bottom:-420px;
    transition: all 300ms; 
    z-index:${zIndex.basicModal};
    border-radius:12px 12px 0px 0px;
    background-color: #FFFFFF;
    box-shadow: 0px -4px 8px #00000029;
    left: 0;
    &.show{        
        bottom:0px;
    }
    ${mobileMedia} {
        height:560px;
        bottom:-560px;
        max-height: calc(100% - 50px);
    }
`;

export const header=styled.div`
    height: 120px;
    background-color: #76D6E8;
    border-radius:12px;
    display: flex;
    align-items: center;
    ${mobileMedia} {
        border-radius:12px 12px 0 0;

    }
    &::after{
        content: "";
        width: 200px;
        height: 200px;
        background-image: url("${props=>props.icon}");
        background-size: 200px   ;
        background-repeat:no-repeat;  
        background-position:center center; 
        margin-inline-start:30px;
    }
`;

export const headerArrow=styled.div`
    background-repeat:no-repeat;  
    background-image: url("${arrowDark}");
    background-position:center center; 
    background-size: 18px 18px;
    ${props=>props.theme.rtl?'':'transform: rotate(180deg);'}
    width: 70px;
    height: 20px;
    cursor: pointer;
`;

export const headerTitle=styled.div`
    font-size: 20px;
    font-weight: 500;
    width:calc(100% - 400px);
    text-align:center;
    ${mobileMedia}{
        width: calc(100% - 70px);
        padding-left: 70px;
    }
`;

export const bottom=styled.div`
    position: relative;//????for office
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    ${mobileMedia} {
    border-top: 1px solid #EEE;
    padding-top:20px;
    }

`;
export const office=styled.div`
    position: absolute;
    ${props => (props.theme.rtl? 'left':'right')}:10px;
    ${mobileMedia} {
        bottom:80px;
    }
`;
