import styled from 'styled-components';
import * as symbol from '../../assets/symbols/symbols/Symbols.css';
import * as input from '../classes/input';

export const detail = styled(input.container)``;

export const detailInput = styled.div`
    display:flex;
    height:25px;
    /* margin-bottom :30px; */
    position:relative;
    width:100%;
    @media screen and (max-width: 750px){  
        margin-bottom: 50px;
    } 
    &.notValid{
        &::before{
            content:"${props => props.validationText}";
            position: absolute;
            height: 20px;
            ${props => props.theme.rtl ? 'left' : 'right'}:45px;
            letter-spacing: 1px; 
            font-size: 11px;
            font-weight: bold;
            bottom: -20.5px;
        }
    }
    &::after{
            /* content:"${props => props.dataText}"; */
            content:"${props => props.dataDesc ? props.dataDesc : props.dataText}";
            position: absolute;
            height: 20px;
            bottom:2px;
            ${props => props.theme.rtl ? 'right' : 'left'}:37px;
            font-size: 14px;
            letter-spacing: 1px; 
            pointer-events: none;
            transition: all 200ms;
        }
    input{
        border: 0;
        outline: 0;
        width:calc(100% - 26px);
        border-bottom: 1px solid;
        margin-${props => props.theme.rtl ? 'right' : 'left'}:5px;
        font-size:14px;
        border-right: 0;
            &.notValid{
            color: #F00020;
        }
        @media screen and (max-width: 750px){  
            /* width: 275px; */
        } 
        :focus{
            color:#65B8E2;
            font-weight:600;
        ::placeholder{
            color: #65B8E2;
          }
        ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            letter-spacing: 1px !important;
            }
        } 
        &:hover{
          border-right: none;
          border-bottom: 1px solid ; 
          &.notValid{
            border-bottom: 1px solid #F00020; 
          }
        }
    }   
    &:not(.defaultInput){
        &::after{
            height:20px;
            bottom: -19px;
            ${props => props.theme.rtl ? 'right' : 'left'}:31px;
            font-size: 11px;
            letter-spacing: 1px;
            font-weight: bold;
        }
      }
      .defaultInput{
          border-${props => props.theme.rtl ? 'right' : 'left'}:1px solid;
          border-bottom: 0;
          background-color:transparent ;
      }
      .deInput{
        background-color:transparent ;

      }

`;
export const diIcon = styled(input.icon)`
    &.notValid{
        display: none;
    }
    &.success{
        background-image:unset;
        background-color: #fff;
        border-radius: 50%;
        background-color: #9CB900;
        -webkit-mask-image: url("${props => symbol['success']}");
        mask-image: url("${props => symbol['success']}");
        -webkit-mask-position: center;
        -webkit-mask-repeat: no-repeat;
        -webkit-mask-size: 10px ;

    }
`;

export const isValid = styled.div`
  &.notValid{
     background-color: #fff ;
     font-size:0;  
     background-repeat:no-repeat;  
     background-image:url("${symbol['ExclamationMark']}"); 
     background-position: center center;
     background-size: 8px 10px;
     pointer-events:none;
     border: 1px solid #dd2f21;
     width: 18px;
     height: 18px;
     border-radius: 50%;
     margin: 3.5px 3.5px 0;
}
`;
export const valid = styled.div`
    display: none;
    &.valid{
        display: block;
        top: 30px;
        left: 0;
        height: 14px;
        width: 14px;
        background-color: #9CB900;
        transition: box-shadow 300ms;
        border-radius: 50%;
        position: absolute;
        background-image:  url("${symbol['success']}");
        background-size: 7px; 
        background-repeat: no-repeat;
        background-position:center center; 
}
`;
