import styled from 'styled-components';
import {BGImageCenter, DEFAULT_COLOR, ellipsis} from '../../../../../globalVariables/GlobalVariables';
import {cssEscape} from '../../../../../library/charEscape';
import {remove} from '../../../../../assets/symbols/symbols/Symbols.css';
export const shadowArtist='https://static.tickchak.co.il/images/artists/artistShadow.png';
export const container=styled.div`
  width: max-content;
  height: max-content;
  position: relative;
`;
export const artist=styled.div`
  width: 65px;
  height: 65px;
  border-radius: 50%;
  box-shadow: 0 3px 10px #00000029;
  border: 2px solid ${DEFAULT_COLOR};
  ${p=>BGImageCenter(p.image||shadowArtist,'cover')};
  position: relative;
    scale: 0;
    transition:all 300ms ;
    &.show{
      scale: 1;
    }
  &::before{
    content:'${p=>cssEscape(p.name)}';
    position: absolute;
    width: 100%;
    color:${DEFAULT_COLOR};
    font-weight: 600;
    bottom: -20px;
    left: 0;
    text-align: center;
    ${ellipsis};
  }
  &:hover{
    cursor:pointer;
    &::after{
      content: '';
      width: 100%;
      height: 100%; 
      position: absolute;
      background-color: #76D6E8C7;
      border-radius: 50%;
      ${BGImageCenter(remove,'18px 18px')};
      left: 0;
    }
    
  }
  
`;