import {useSelector} from 'react-redux';
import setGlobalState from '../../../redux/setGlobalState';
import {updateEventSettings} from '../../../redux/event/eventActions';
import {useTranslation} from 'react-i18next';
import {bounceMessage} from '../../../layout/userMessages/UserMessage';
import useEffectOnUpdate from '../../../hooks/useEffectOnUpdate';
import * as css from './PublishPageBtn.css';
import {bell, key} from '../../../assets/symbols/symbols/Symbols.css';
import useTimeout from '../../../hooks/useTimeout';

function PublishPageBtn() {
    const {t} = useTranslation('layout');
    const isIndexed = useSelector(s => s.event.settings?.event?.isIndexed);      
    const isIndexedXHR = useSelector(s => s.forms.eventSettings.isIndexedXHR);
    const timer = useTimeout();

    const onHubEventClick=()=>{
        if(isIndexed){
            bounceMessage(t('removeIndexMessage'), 'warning', 
                {text:t('removeIndex'),onClick: ()=>updateEventSettings('isIndexed',0,'event')},{text:t('cancelBtn')});
        }
        else
            setGlobalState('ui.show.isOpenPublishPop',true);
    };

    useEffectOnUpdate(()=>{
        if(isIndexedXHR==='SUCCESS'){
            timer.current=setTimeout(() => {
                setGlobalState('forms.eventSettings.isIndexedXHR',null);
            }, 400);
        }
    },[isIndexedXHR]);

    return (
        <css.status icon={isIndexed?key:bell} onClick={onHubEventClick} data-cy='statusMode'>
            {isIndexed?t('removeIndex'):t('publishPage')}
        </css.status>
    );
}

export default PublishPageBtn;

