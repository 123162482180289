import styled from 'styled-components';
import {noMeet} from '../../../assets/symbols/images/noMeets';
import {loaderContainer} from '../../../component/loader/Loader.css';
import {
    BGImageCenter,
    dFlexCenter,
    mobileMedia,
    scrollbar
} from '../../../globalVariables/GlobalVariables';
import {right as PWRight} from '../../components/pageWrapper/PageWrapper.css';

const TOP='90px';
const TITLE_HEIGHT='65px';

export const container=styled.div` 
    height: 100%;
  ${PWRight}{
    ${mobileMedia}{
      padding-inline: 10px;
    }
  }

    `;

export const   main =styled.div` 
    //padding-inline-start: 35px;
    height: calc(100% - ${TOP});
    ${loaderContainer}{
        height: calc(100% - ${TITLE_HEIGHT});
    }
  display: flex;
  max-width: 1150px;
  ${mobileMedia}{
    max-width: 400px;
    margin: 0 auto;
    flex-direction: column;
  }
    `;

export const leftContainer=styled.div`
  flex-grow: 2;
  padding-inline: 1vw;
  flex-basis: 67%;
  ${mobileMedia}{
    min-width: unset;
  }
`;
export const title=styled.div`
    font-weight: 600;
    font-size: 15px;
    width: 100%;
    height:${TITLE_HEIGHT};
    display: flex;
    align-items: center;
    min-height: 25px;
    justify-content: space-between;
`;

export const grid=styled.div`
${scrollbar};
  max-height: calc(100% - ${TITLE_HEIGHT});
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  gap: 1vw;
  justify-items: stretch;
  padding-inline-end: 30px;
  margin-inline-end: -30px;
  padding-bottom: 30px;
  padding-top: 10px;
  ${mobileMedia}{
    width: 100%; 
    grid-template-columns: 1fr; 
    row-gap: 12px;
    padding-inline-end: 0;
    margin-inline-end: 0;
    padding-bottom: 50px;
}

`;

export  const noMeetings=styled.div`  
width: 100%;
${dFlexCenter};
flex-direction: column;
    &::before{
    content: '';
    width: 100%;
    ${BGImageCenter(noMeet,'300px')}
    height: 300px;
}
${mobileMedia}{
    &::before{
        height: 250px;

    }
}

`;
