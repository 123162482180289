import styled from 'styled-components';
import {DEFAULT_COLOR} from '../../globalVariables/GlobalVariables';
import {dataItemwrapcss} from '../dataItem/DataItem.css';
export const container = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    input{
        caret-color: ${DEFAULT_COLOR};
        &::placeholder{color: ${DEFAULT_COLOR}};
        color: ${DEFAULT_COLOR};
        font-weight: bold;
    }
    &.disabled{
        opacity: 0.1;
        cursor: unset;
        pointer-events:none;
    }
`;

export const text = styled.div`
    font-size: 12px;
`;

export const listCheckbox = styled.div`
    width: 300px;
`;
export const wrap = styled.div`
   ${dataItemwrapcss};
`;

export const plus = styled.div`
    position: relative;
    margin-inline-end: 5px;
`;
export const warpLeft = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: black !important;
`;