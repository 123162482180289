import styled from 'styled-components';
import {whatsApp, packageImage, pencil} from '../../../assets/symbols/symbols/Symbols.css';
import {BGImageCenter, boxShadow, DEFAULT_COLOR} from '../../../globalVariables/GlobalVariables';
import {toggle,container as containerModeBtn} from '../../../component/modeBtn/ModeBtn.css' ;
import {role as roleBtn} from '../memberRoleBtn/MemberRoleBtn.css';
import zIndex from '../../../globalVariables/zIndex';

export const container=styled.div`
  ${boxShadow};
  width:100% ;
  height:100% ;
  position: relative;
  border-radius: 4px;
  //cursor: pointer;
  ${roleBtn}{    
    margin: 0 auto;
  }
`;
export const top=styled.div`
  width: 100%;
  border-radius: 4px 4px 0 0;
  height:120px;
`;
export const topDetails=styled.div`
  height:35px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${containerModeBtn} {
    &.small{
      .active{
        &::after{
          background-color: ${DEFAULT_COLOR};
        }
      }
    }
   }
  ${toggle}{
      background-color: #FFFFFF;
      ${p=>p.theme.isPackage&&'margin-inline-end: 10px' };
        &.LOADING.active,&.active:not(&.proposal) {
          background-color: #FFFFFF;
        }
        &.active{
          &::after{
            background-color: ${DEFAULT_COLOR};
          }
        }    
  }

`;
export const packageIcon=styled.div`
  width:calc(100% - 50px);
  ${p=>p.theme.isPackage && BGImageCenter(packageImage,'15px')};
  background-position: ${props=>props.theme.rtl?'calc(100% - 10px)':'10px '} center;
  height: 100%;
`;
export const options=styled.div`
  width:20px;
  cursor: pointer;
`;

export const blurImage=styled.div`
    height: 120px;
     width:100% ;
    position: absolute;
    overflow: hidden;
    z-index: ${zIndex.under};
    border-radius: 4px 4px 0 0;
  &::after{
    content: '';
    position: absolute;
    height: 120px;
    width:100% ;
    background-color: rgb(0 0 0);
    ${p=>BGImageCenter(p.image,'120%')};
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    filter: blur(18px);
    
  }
`;
export const image=styled.div`  
  cursor: pointer;
  top: 80px;
  inset-inline-start: calc(50% - 40px);
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin:-40px auto 0 auto;
  ${p=>BGImageCenter(p.image,'contain')};
  transition: transform 400ms ;
  transform: scale(1);
  ${boxShadow};
  background-color:${DEFAULT_COLOR};
    ${p=>(p.theme.isUnauthenticated)?'' :`:hover {
           ${BGImageCenter(pencil,'20px')};
           transform:scale(0.8) ;
         }`
    }
`;

export const name=styled.div`
  font-weight: 500;
  height: 39px;
  padding-top: 10px;
  text-align: center;
  max-width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0 auto;
`;

export const whatsUp=styled.div`
  height: 34px;
  background-position: ${props=>props.theme.rtl?'10px ':'calc(100% - 10px)'} center;
  width: 30px;
  margin-inline-start: calc(100% - 30px);
  &.valid{
    ${BGImageCenter(whatsApp,'18px 18px')};
    cursor: pointer;
  }
`;
export const footer=styled.div`
  height: 30px;
  width: 100%;
  border-top: #e8e8e8 1px solid;
  text-align: center;
  line-height: 2;
`;