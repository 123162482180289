import createReducer from '../createReducer';
import {TRealtime} from './realtime.type';

export const initialStateRealtime:TRealtime = {
    user:{
        countEvents:0,
        eventsTypes:[],
        realtimeDesign: null,
        getStatsXHR:null,
        oldSystem: false
    },
    event:{
        eventStats: {
            today: [],
            other: []
        },
        realtimeText: {},
        cash: {},
        tickets: {},
        seats: {},
        moreInfo: {},
        soldTickets: null,
        getEventStatsXHR: null,
        getRealtimeXHR: null,
        soldTicketsXHR: null,
        weatherXHR: null,
        getRealtimeTextXHR:null

    },
    systemMessage: {popMessages: [], windowMessages: []}
};

const reducer = createReducer('realtime',initialStateRealtime);

export default reducer;