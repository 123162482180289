import styled from 'styled-components';
import {greenCertificate, verificationSuccess} from '../../../../assets/symbols/symbols/Symbols.css';
import {BGImageCenter, mobileMedia} from '../../../../globalVariables/GlobalVariables';

export const container=styled.div`
  width: 100%;
  height: 100%;
  background-image: url("${verificationSuccess}");
  background-size: 190px;
  background-repeat: no-repeat;
  background-position: ${props=>props.theme.rtl?'30px':'calc(100% - 30px)'} center;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 58px;

  ${mobileMedia} {
    align-items: center;
    padding: unset;
    padding-top: 65px;
    padding-bottom: 58px;
    ${BGImageCenter(greenCertificate,'120px')}
    background-position: calc(50% - 10px) center;
  }
`;
export const image=styled.div`

`;
export const title=styled.div`
    font-weight: 800;
    font-size:16px;
  ${mobileMedia} {
    display: none;
  }
`;
export const text=styled.div`
  width:50% ;
  font-weight: 600;
  font-size:15px ;
  ${mobileMedia} {
    width:90%;
    text-align: center;
  }
`;
