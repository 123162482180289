import {WHITE} from '../../globalVariables/GlobalVariables';
import Btn from '../button/btn/Btn';
import Close from '../close/Close';
import {TShow} from '../prompotionPopup/PromotionPopup';
import * as css from './PromotionMessage.css';

export type TButtonsProps = {
    onClick: () => void,
    color: string,
    style?: string,
    className?: string
    text: string
}

function PromotionMessage({show, firstTitle, secondTitle, text, background, button, close}: {show: TShow, firstTitle:string, secondTitle:string, text:string, background:{image:string, color:string}, button:TButtonsProps, close: () => void}) {
    return (
        <css.message className={show.showClass} image={background?.image} color={background?.color}>
            <Close className={false} onClick={close} />
            <css.title>{firstTitle} <span>{secondTitle}</span></css.title>
            <css.flexWrap buttonColor={button.color}>
                <css.text>{text}</css.text>
                <Btn 
                    onClick={button.onClick}
                    style={button.style || `background-color:${button.color}; color:${WHITE}; border-color:${button.color};`}
                    className={button.className}
                    disabled={false}
                >
                    {button.text}
                </Btn>
            </css.flexWrap>
        </css.message>
    );
}

export default PromotionMessage;
