import styled from 'styled-components';
import * as symbol from '../../assets/symbols/symbols/Symbols.css';
import zIndex from '../../globalVariables/zIndex';

export const popUpScreen=styled.div`
    backdrop-filter: blur(8px) brightness(0.6);
    -webkit-backdrop-filter: blur(8px) brightness(0.6);
    position:fixed;
    width:100%;    
    top:0;
    left:0;
    z-index:${zIndex.basicModal};
    height:100%;
    opacity:0;
    &.show{
        opacity:1;
    }
   transition: all 150ms;
`;
export const popUp=styled.div`
    background:${props=>props.background?props.background:'#fff'};
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width:100%;
    border-radius: 10px 10px 0 0;
    position:absolute;
    bottom:0;    
    height:0;
    overflow:hidden;
    transition: all 300ms 150ms cubic-bezier(0.42, 0, 0.1, 1.36);
    &.show{
        height:450px;
        max-height: 95%;
        overflow: unset;
        &.keyboard{
        height:95%; 
    }   
 }

`;
export const popUpBack=styled.div`
       -webkit-backdrop-filter: brightness(0.8) blur(5px);
        backdrop-filter: brightness(0.8) blur(5px);
        -webkit-backdrop-filter: brightness(0.8) blur(5px);
        width: 100%;
        height: 100%;   
        top: 0;
        left: 0;
        position: absolute;
        background-image: linear-gradient(#ffffff47,#000000d1,#000);
        border-radius: 10px 10px 0 0;`;
export const popUpTop=styled.div`
    height:40px;
    width:100%;
    border-bottom:1px solid #777;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='22' height='14.5' viewBox='0 0 22 14.5'%3E%3Cdefs%3E%3Cstyle%3E.a,.c%7Bfill:none;%7D.b%7Bclip-path:url(%23a);%7D.c%7Bstroke:%23777;stroke-linecap:round;stroke-width:1.5px;%7D%3C/style%3E%3CclipPath id='a'%3E%3Crect class='a' width='22' height='14.5'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg class='b'%3E%3Cg transform='translate(-168.25 -53.75)'%3E%3Cline class='c' x2='18.5' transform='translate(170 55.5)'/%3E%3C/g%3E%3Cg transform='translate(-168.25 -48.75)'%3E%3Cline class='c' x2='18.5' transform='translate(170 55.5)'/%3E%3C/g%3E%3Cg transform='translate(-168.25 -43.75)'%3E%3Cline class='c' x2='18.5' transform='translate(170 55.5)'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center center;    
`;
export const popUpClose=styled.div`        
        background-image:url("${props=>props.color=='white'?symbol['arrowDark']:symbol['arrow']}");
        background-repeat: no-repeat;
        background-position: center center;
        position: absolute;
        width: 50px;
        height: 30px;
        top: 50px;
        ${props=>props.theme.rtl?'right':'left'}: 0;
        cursor: pointer;
`;