import styled from 'styled-components';
import zIndex from '../../../globalVariables/zIndex';

export const UserProfileDivStyled = styled.div`
        width: 60px;
        height:50px;
        // display: inline-block;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        // float: right;
        // line-height:70px;
        cursor:pointer;
        @media screen and (max-width: 1000px){  
               width:80px;
        }
    `;
export const UserImg =styled.div`
    // width:70px;  
    height: 100%;
    position:relative;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 1000px){  
        ${props => (props.theme.rtl ? 'border-left' : 'border-right')}:1px solid #cccccc;
        width:80px;
    }
      
`;
export const ProfileOpenClose = styled.div`
    display:none;
     @media screen and (max-width: 1000px){   
         position:absolute;
        width:20px;
        height:20px;
        top:22px;
        ${props=>props.theme.rtl?'left':'right'}:2px;
         background-repeat: no-repeat;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8.344' height='6.434' viewBox='0 0 8.344 6.434'%3E%3Cdefs%3E%3Cstyle%3E .cls-1 %7B fill: none; stroke: %233a4143; stroke-linecap: round; stroke-width: 2px; %7D %3C/style%3E%3C/defs%3E%3Cg id='Component_154_1' data-name='Component 154 – 1' transform='translate(1.406 1.406)'%3E%3Cpath id='Path_2879' data-name='Path 2879' class='cls-1' d='M233.982,21l2.766,3.434L239.514,21' transform='translate(-233.982 -21)'/%3E%3C/g%3E%3C/svg%3E%0A");
        background-position: center center;
        transform: rotate(180deg);    
        cursor: pointer;
        z-index:${zIndex.baseRaised};
        transition:all 400ms; 
         display:block;
        &.close{
            transform: rotate(0deg);  
        }
     }
`;
export const UserNameSpan = styled.div`
    // position: absolute;
    width: calc(100% - 70px);
    height: 30px;
    text-align: center;
    top: calc(50% - 15px);
    left: 0;
    line-height: 30px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    @media screen and (max-width: 1000px){  
        display:none;
    }
`;