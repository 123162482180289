/* eslint-disable spellcheck/spell-checker */
import styled from 'styled-components';
import {btn} from '../../component/button/btn/Btn.css';
import convertHexToRgbA from '../../functions/ConvertHexToRgbA';
import {dFlexCenter, mobileMedia} from '../../globalVariables/GlobalVariables';
import zIndex from '../../globalVariables/zIndex';

export const userMessagesBackground = styled.div`
    background-color: #000000C7;
    height:100%;
    width: 100%;
    bottom: 0;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 300ms;
    z-index:${zIndex.highestModal};
    
`;

export const warpUserMessages = styled.div`
    background-color: #D10362 ;
    box-shadow: 0 10px 15px #00000064;
    border-radius: 5px;
    width: 400px;
    max-width: 100%;
    height: max-content;
    min-height: 250px;
    transition: all 300ms;
    ${mobileMedia}{
      position:absolute ;
      width: 100%;
      bottom: 0; 
    border-radius: 5px 5px 0 0;

  }
  `;

export const Message = styled.div`
   height: calc(100% - 140px);
   color:white;
   text-align:center;
   padding: 15px;
   white-space: pre-line;
   letter-spacing: 1px;
   min-width: calc(100% - 20px);
   margin: 0px 8px 0px 8px;
  `;
export const Title = styled.div`
  height: 30px;
  color:white;
  font-size:18px;
  font-weight: 900;
  text-align:center;
  letter-spacing: 1px;
 `;
export const buttons = styled.div`
 ${dFlexCenter};
 height: 70px;
  ${btn}{
    color:white;
    margin:0px 5px;
    background-color: #D10362;
    border:2px solid ;
    width: 120px;
    text-align:end;
    height: 30px;
    &.main{
      color: #D10362;
      background-color:#fff;
      border:unset;
     }
    &:hover{
      color:white;
    border:2px solid ;
      background-color:rgba(${convertHexToRgbA('#ED87C2')},0.5);
    }
    }
}
`;

export const close = styled.div`
    display: -ms-flexbox;
    margin: 10px 10px 0 10px;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background-color: #dc3889;  
`;

