import styled from 'styled-components';
import {BGImageCenter, dFlexCenter} from '../../../../globalVariables/GlobalVariables';
import {success} from '../../../../assets/symbols/symbols/Symbols.css';

export const image = styled.div`
    height: 127px;
    width: 157px;
    border-color: #FFFFFF;
    border-style: solid;
    border-width:7px;
    border-radius: 11px;
    ${p=>BGImageCenter(p.image,'127px')}
    background-size: cover;
    cursor: pointer;
    &:hover{
        scale: 1.07;
    }
    transition: all 200ms;
    &.selected{
        &::after{
            content: '';
            position: relative;
            top:40px;
            margin-inline-start: 55px;
            width: 30px;
            height: 30px;
            border: 2px solid #FFFFFFB1;
            border-radius: 50%;
            ${BGImageCenter(success,'13px')}
            background-color: #AED11C;
            display: block;
        }
    }
    .animation{
        position:relative ;
        width:100%;
        height:100% ;
        overflow:hidden ;
        ${dFlexCenter};
    }
`;