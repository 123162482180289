import styled from 'styled-components';
import {blackSuccess} from '../../assets/symbols/symbols/Symbols.css';
import convertHexToRgbA from '../../functions/ConvertHexToRgbA';
import {BGImageCenter} from '../../globalVariables/GlobalVariables';

export const checkbox = styled.div`
    width:15px;
    height:15px;
    border:1px solid #000;
    position:relative;
    cursor:pointer;
    margin :0 auto;
    &.listCheckbox{
        border-radius: 50%;
        border:none;  
        box-shadow:unset;
        transition: box-shadow 300ms;
        background-color:#0000002C;
        /* background-color:${p=>p.color?`rgba(${convertHexToRgbA(p.color)},0.3)`:' #0000002C'}; */

        &.border{
            box-shadow:0px 0px 0px 5px rgba(${props => convertHexToRgbA(props.color?props.color: '#76D6E8')}, 0.5);
        } 
        &.selected {
        background-image: url("${blackSuccess}");
        background-repeat: no-repeat;
        background-position:center center;    
        background-size: 10px; 
        background-color:${props => (props.color?props.color: '#76D6E8')};

        }

    }
    &.classicCheckbox{
        &.selected{
            &::before{
            content:'';
                position:absolute;
                width:6px;
                height:6px;
                background:#43BAE7;
                right: calc(50% - 3.5px);
                top: calc(50% - 3.5px);
            }

        }
    }
    &.VCheckbox{
        &.selected{
            &::before{
                content:'';
                    position:absolute;
                    ${BGImageCenter(blackSuccess,'9px')}
                    height:100%;
                    width:100%;
                }

        }
    }
`;