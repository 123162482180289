import styled from 'styled-components';
import {BGImageCenter, boxShadow, desktopMedia, scrollbar} from '../../../globalVariables/GlobalVariables';
import {refresh} from '../../../assets/symbols/symbols/Symbols.css';

export const wrap=styled.div`
    container-type: inline-size;
    min-height: 100px;
    position: relative;
    ${scrollbar}
    a{
        pointer-events: none;
    }
    ${desktopMedia}{
    >div{
        >div{
            padding: 15px  0 !important;
        }
    }}

    `;
export const refreshBtn=styled.div`
    position: absolute;
    cursor: pointer;
    margin: 0 auto;
    inset-inline-start: 15px;
    top: 15px;
    border: solid white 3px ;
    width: 30px;
    height: 30px;
    ${BGImageCenter(refresh,'12px')}
    border-radius: 50%;
    background-color: lightgray;
    ${boxShadow}
`;