import Btn from '../button/btn/Btn';
import * as css from './PromotionPopup.css';
import MobilePopup from '../newPopup/mobilePopup/MobilePopup';
import {BLACK, mobileMedia, WHITE} from '../../globalVariables/GlobalVariables';
import {btn} from '../button/btn/Btn.css';

export type TButtonProps = {
    onClick: () => void;
    text: string;
};
export type TShow ={
    open :() => void,
    close: () => void,
    isOpen: boolean,
    showClass: string
}

function PromotionPopup({show, color, image, button, children}:{show:TShow, color: string, image:string, button: TButtonProps, children: React.ReactNode}){
    const cssStyle = `
        background: ${color};
        height: max-content;
        max-height: calc(100% - 200px);
        width: 440px;
        ${mobileMedia}{
            &.show{ 
                max-height: 85%;
                height: 85%;
                width: 100%;
                background: ${color};
            }
        }
        ${btn}{
            position: absolute;
            inset-inline-end: calc(50% - 30px);
            inset-block-end: 15px; 
            background-color:${BLACK};
            color:${WHITE};
            border-color:${BLACK};
            &:hover{
                background-color: ${BLACK};
                border-color: ${BLACK};
            }
        }
    `;

    return (
        <MobilePopup showClass={show.showClass} close={show.close} dataCyName={'PromotionPopup'} cssStyle={cssStyle} title={undefined} list={undefined} forwardRef={undefined}>
            <>
                <css.container image={image}>
                    {children}
                </css.container>
                <Btn onClick={button.onClick} disabled={false} className={false}>
                    {button.text}
                </Btn>
            </>
        </MobilePopup>
    );
}

export default PromotionPopup;
