import styled from 'styled-components';
import {mobileMedia, scrollbar} from '../../../globalVariables/GlobalVariables';

export const wrapper = styled.div`
    padding-inline:50px;
    padding-top:20px;
    ${scrollbar};
    border-bottom: 1px solid #F3F3F3;
    ${mobileMedia}{
      padding-inline:15px;
    }
`;

export const saveButton=styled.div`
    padding: 20px;
    display: flex;
    justify-content: flex-end;
`;

export const wrapInput=styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 40px;
  padding-top: 15px;
  padding-bottom: 40px;
  ${mobileMedia} {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const wrapInfo=styled.div`
    height: 130px;
`;

export const informationTitle=styled.div`
    font-size: 15px;
    padding-bottom: 15px
`;