import styled from 'styled-components';
import {Item, ITEM_RIGHT_WIDTH} from '../../../../component/item/Item.css';
import {dFlexCenter} from '../../../../globalVariables/GlobalVariables';

export const wrapItem=styled(Item)`
    display: flex;
    width: 100%;


`;

export const itemRight=styled.div`
    width: ${ITEM_RIGHT_WIDTH};
    height: 100%;
    ${dFlexCenter}
`;

export const text=styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 180px;
`;