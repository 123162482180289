import styled from 'styled-components';
import {btnPlus, plusIcon} from '../../../../component/button/btnPlus/BtnPlus.css';
import {BGImageCenter} from '../../../../globalVariables/GlobalVariables';
import {addWhite} from '../../../../assets/symbols/symbols/Symbols.css';

export const addGroup = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width:100%;
    height:100%;
    border-radius: 8px;
    border: 2px dashed #F3F3F3;
    position: relative;
    justify-content: center;
    ${btnPlus}{
        background-color: #F3F3F3;
        border-color: #F3F3F3;
        color: #FFFFFF;
    }
    ${plusIcon}{
        ${BGImageCenter(addWhite,'45% 45%'  )}
    }
`;

export const title = styled.div`
     margin: 10px auto;
     width: 90px;
     height: 15px;
     border-radius: 25px;
     background-color: #F3F3F3;
`;