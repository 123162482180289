import styled from 'styled-components';
import {pencil} from '../../../../assets/symbols/symbols/Symbols.css';
import {BGImageCenter} from '../../../../globalVariables/GlobalVariables';

export const top = styled.div`
    height: 65px;
    background-color:#${props => props.color};
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    position: relative;
`;
export const circle = styled.div`
    width:28px ;
    height:28px ;
    border-radius:50% ;
    font-size:12px ;
    font-weight:600 ;
    line-height:8px ;
    padding-top:2px ;
    background-color:#${props => props.color}; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
`;

export const groupProgress = styled.div`
    background-color:#fff ;
    position:absolute ;
    width:40px ;
    height:40px ;
    background-color:#fff;
    padding:2px ;
    border-radius:50% ;
    bottom: -12px; 
    left: calc(50% - 20px); 
    &.edit{
        background-color: #76D6E8;
        ${BGImageCenter(pencil, 9)}
        cursor: pointer;
    }
`;
export const topTop = styled.div`
    height: 22px;
    padding-top: 8px;
`;
export const topTopRight = styled.div`
    width: 18px;
    height: 18px;
    margin-${props => props.theme.rtl ? 'right' : 'left'}: 10px;
    float: ${props => props.theme.rtl ? 'right' : 'left'};
    background-image: url("${props => props.bg}");
    background-repeat: no-repeat;
`;
export const topTopLeft = styled.div`
    height: 100%;
    float: ${props => props.theme.rtl ? 'left' : 'right'};
    display: flex;
    width: 74px;
`;
export const isActive = styled.div`
    width: 86px;
    height: 20px;
    font-size:12px;
    margin-top:-1.5px;
    .state{
        width:60px
    }
`;
export const percent = styled.div`
    font-size:9px ;
    color: #fff;
`;
