/* eslint-disable spellcheck/spell-checker */
import styled from 'styled-components';
import * as input from '../../classes/input';
import {ellipsis, DEFAULT_COLOR} from '../../../globalVariables/GlobalVariables';

export const container = styled(input.container)`
`;

export const textWrap = styled.div`
  width: calc(100% - 20px);
  div {
    ${ellipsis}
  }
  position: absolute;
  height: 20px;
  letter-spacing: 1px;
  transition: all 200ms;
  bottom: -17px;
  inset-inline-start: 5px;
  pointer-events: none;
  line-height: 20px;
  display: flex;
  &.hasIcon{
    width: calc(100% - 45px);
    inset-inline-start: 30px;
  }
  
`;
export const titleWrap = styled.div`
  min-width: max-content;
  font-weight: bold;
  font-size: 11px;
  transition: all 200ms;
  bottom: 0;
  position: relative;
  width: max-content;
  display: flex;

  &.empty {
    position: absolute;
    inset-inline-start: 3px;
    bottom: 20px;
    font-size: 14px;
    font-weight: 400;

    .required {
      width: 7px;
      height: 7px;
      margin-top: 2px;
    }
  }`;

export const title = styled.div`
  width: max-content;
  font-weight:${input.INPUT_WEIGHT};
`;
export const required = styled.div`
  width: 5px;
  height: 5px;
  margin-top: 5px;
  margin-inline-start: 2px;
  background-color: ${DEFAULT_COLOR};
  border-radius: 50%;
`;

export const description = styled.div`
  white-space: nowrap;
  inset-inline-start: 0;
  transition: all 200ms 200ms;
  font-size: 11px;
  letter-spacing: 0.6px;
`;

export const wrapInput = styled.div`
  display: flex;
  height: max-content;
  position: relative;
  width: 100%;
  &.invalid {
    &::before {
      content: "${p => p.validationText}";
      position: absolute;
      height: 20px;
      ${p => p.theme.rtl ? 'left' : 'right'}: 45px;
      letter-spacing: 1px;
      font-size: 11px;
      font-weight: bold;
      bottom: -20.5px;
    }

    ${p => p.types.fieldType} {
      color: #DD2F21;
      font-weight: 600;
      :focus {
        color: #DD2F21;
      }
      ::placeholder {
        color: #DD2F21;

      }

    }
  }
  //--------

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-transition: color 9999s ease-out, background-color 9999s ease-out;
      -webkit-transition-delay: 9999s;
    }
    ${p => p.types.fieldType} {
      height: 25px;
      border: 0;
      outline: 0;
      width: 100%;
      font-weight:${input.INPUT_WEIGHT} ;
      border-bottom: 1px solid;
      margin-inline-start: 5px;
      font-size: 16px;
      border-right: 0;
      transition: all 100ms;
      resize: none;
      background: transparent;
      &.notValid {
        color: #F00020;
      }
      &:hover {
        color: #65B8E2;
        font-weight: 600;
        &.notValid {
          border-bottom: 1px solid #F00020;
        }
        ::placeholder {
          color: #65B8E2;
        }
        ::-webkit-input-placeholder { 
          letter-spacing: 1px !important;
        }
      }
      /* &:hover {
        font-weight: 600;
        &.notValid {
          border-bottom: 1px solid #F00020;
        }
      } */
    }
    &.hasIcon{
      ${p => p.types.fieldType} {
        width: calc(100% - 26px);
      }
    }
    ${p=>p.types.inputType==='number'&&`
    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button { 
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none; 
      }
    input[type=number]{ 
      -moz-appearance: textfield;
    }`
    }

`;

export const icon = styled(input.icon)`
`;

export const checkbox = styled.div`
  height: 23px;
  width: 25px;
  background-color: #76d6e8;
  border-radius: 14px;
`;