import styled from 'styled-components';
import {DARK_GRAY} from '../../../globalVariables/GlobalVariables';
import * as imagePopupCss from '../ImagePopup.css';
import {popUpBody} from '../../newPopup/smallPopUp/SmallPopUp.css';

export const image = styled(imagePopupCss.image)``;

export const wrapPopup = styled(imagePopupCss.wrapPopup)`
    position: relative;
    height: 100%;
    div, input{
        color: ${DARK_GRAY} !important;
    }
    ${popUpBody}{
        right:calc(50% - 180px);
    }
`;

export const wrapAll = styled(imagePopupCss.wrapAll)``;
export const top = styled(imagePopupCss.top)``;
export const buttons = styled(imagePopupCss.buttons)``;
export const details = styled(imagePopupCss.details)``;