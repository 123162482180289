import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import '../../App.css';
import Events from '../events/Events';
import * as css from './TopMenue.css';
import LayoutUserProfile from './layoutUserProfile/LayoutUserProfile';
import UserPackages from '../userPackages/UserPackages';
import {useTranslation} from 'react-i18next';
import Menu from '../menu/Menu';
import Mailing from '../../component/mailing/Mailing';
import SaveAjax from '../../pages/components/saveAjax/SaveAjax';
import PackageSettings from './packageSettings/PackageSettings';
import {Else, If, Then} from 'react-if';
import AddEventManage from '../events/addEvent/addEventManage/AddEventManage';
import {useParams} from 'react-router-dom';
import useTablet from '../../hooks/useTablet';
import Tooltip from '../../component/tooltip/Tooltip';
import useMobile from '../../hooks/useMobile';
import MyTickchakSystem from './myTickchakSystem/MyTickchakSystem';
import useGlobalShow from '../../hooks/useGlobalShow';
import BtnAddEvent from '../../component/button/btnAddEvent/BtnAddEvent';
import ClearingFeeMessagePop from './clearingFeeMessagePop/ClearingFeeMessagePop';
import useShow from '../../hooks/useShow';
import {showDetailsAccountPixel} from '../../pages/realtime/accountRealtime/AccountRealtime';

function TopMenu({updateLayout}) {
    const account = useSelector(s => s.account);
    const user = useSelector(s => s.user);
    const countEvents = useSelector(s => s.realtime.user.countEvents);
    const event = useSelector(s => s.event);
    const isTablet = useTablet();
    const {t} = useTranslation('layout');
    const {isOpen,showClass,open,close}=useGlobalShow('isOpenEvents');
    const [menu, setMenu] = useState(0);
    const [EventsSearch, setEventsSearch] = useState(0);
    const params=useParams();
    const isMobile = useMobile();
    const openClearingFeeMessagePop = useShow();

    const handleOnClickEvents = (closeStatus) => {
        if(closeStatus)
            setEventsSearch(0);
        isOpen?close():open();
    };

    const showManageEvent = useGlobalShow('isOpenCreateEvent');

    const [showMailing, setShowMailing] = useState(0);

    const handleOnClickMailing = (show) => {
        setRecipients([{text: t('importantCrowd'), key: '123'}, {text: t('crowdB'), key: '456'}, {text: t('crowdC'), key: '789'}, {text: t('crowdD'), key: '246'}, {text: t('crowdE'), key: '000'}, {text: t('crowdF'), key: '111'}]);
        setShowMailing(show);
    };

    const [showPackage, setShowPackage] = useState(0);
    const handleOnClickPackage = (close) => {
        setShowPackage(close ? 0 : 1);
    };
    const openMenu = (open) => {
        setMenu(open);
    };
    
    useEffect(() => {
        updateLayout(menu ? 100 : 0);
    }, [menu]);

    useEffect(() => {
        updateLayout(isOpen ? -100 : 0);
    }, [isOpen]);
    useEffect(() => {
        if(params.pid && params.page === 'home')
            open();
        else if(event.ready===5)
            close();
    }, [event.getEventXHR,event.ready,params.pid]);

    const [recipients, setRecipients] = useState([]);
    const removeRecipient = (e) => {
        setRecipients(recipients.filter(item => item.key !== e));
    };
    const [mailOrSms, setMailOrSms] = useState('email');
    // const mailingType = (type) => {
    //     setMailOrSms(type);
    // };
    const mailingType = (type) => {
        setMailOrSms(type);
    };

    const onClickPlus = () => {
        if(!showManageEvent.isOpen)
            openClearingFeeMessagePop.open();
        else
            showManageEvent.close();
    };

    const onClickPop = () => {
        showManageEvent.open();
        openClearingFeeMessagePop.close();
    };

    const mailingProps = {
        closePop: () => { handleOnClickMailing(0); },
        title: t('newMessage'),
        imgPathClass: 'mailing',
        listTitle: t('menu'),
        // moreTabs:[{text:"אופציה1",key:"123"},{text:"אופציה2",key:"456"},{text:"אופציה3",key:"789"}],
        recipients: recipients,
        recipientsLength: 268,
        recipientsMails: [{cmid: '37330', recipient: 'leahknafo1@gmail.com'}, {cmid: '725160', recipient: 'leah@tickchak.co.il'}],
        recipientsSms: [{cmid: '37330', recipient: '0584071035'}, {cmid: '725160', recipient: '0527611637'}],
        mailOrSms: mailOrSms,
        area: event ? 'crm' : 'crowd',
        crid: event ? null : 122,
        removeRecipient: (e) => {
            removeRecipient(e);
        },
    };

    return (
        <>
            <css.TopMenu className="topMenu">
                <SaveAjax/>
                <css.right>
                    <If condition={!isTablet}>
                        <Then>
                            <>
                                <css.TopMenuLogo className="topMenuLogo "/>
                                <LayoutUserProfile showEvents={() => handleOnClickEvents(0)}
                                    userName={t('yourProfile')}
                                    imgPath={user.picture} />
                                {(!!account.pid && showDetailsAccountPixel()) && <PackageSettings />}
                                <css.SelectPackage className="topMenuPackage" onClick={() => handleOnClickPackage(0)}>
                                    <css.SelectTextPackageTitle/>
                                    <Tooltip text={t('selectAccount')}>
                                        <css.SelectTextPackage>{account.pid ? account.title : t('selectAccount')}</css.SelectTextPackage>
                                    </Tooltip>
                                    {!!showPackage && <UserPackages openEvents={open} closePackages={() => {
                                        handleOnClickPackage(1);
                                    }}/>}
                                </css.SelectPackage>
                            </>
                        </Then>
                        <Else>
                            <If condition={!account.userAccountRole && !event.eid}>
                                <Then>
                                    <LayoutUserProfile closeMenu={() => openMenu(0)} showEvents={() => {
                                        handleOnClickEvents(0);
                                        openMenu(0);
                                    }} userName={user.nameFirst + ' ' + user.nameLast} imgPath={user.picture}/>
                                </Then>
                                <Else>
                                    <css.SelectMenu className={!event.eid && 'packageIcon'} onClick={() => openMenu(1)}>
                                        {(!(account.ownerEmailVerified === 1 && account.termsVerified && account.bankVerified) && account.userAccountRole) &&
                                            <css.unverified/>}
                                        {menu === 1 &&
                                        <Menu close={() => openMenu(0)} updateLayout={updateLayout}>
                                            <LayoutUserProfile closeMenu={() => openMenu(0)} showEvents={() => { handleOnClickEvents(0); openMenu(0); }} userName={user.nameFirst + ' ' + user.nameLast} imgPath={user.picture} />
                                            <css.SelectPackage className="topMenuPackage" onClick={() => handleOnClickPackage(0)}>
                                                <css.SelectTextPackage>{account.pid ? account.title : t('selectAccount')}</css.SelectTextPackage>
                                                {!!showPackage && <UserPackages openEvents={open} closePackages={() => {
                                                    handleOnClickPackage(1);
                                                }} />}
                                            </css.SelectPackage>
                                            <If condition={account.userAccountRole}>
                                                <Then>
                                                    {(!!account.pid && showDetailsAccountPixel()) && <PackageSettings />}
                                                </Then>
                                            </If>
                                        </Menu>
                                        }
                                    </css.SelectMenu>
                                </Else>
                            </If>
                        </Else>
                    </If>
                </css.right>
                <css.AddEvent>
                    {
                        (!event.eid|| event.ready === 5) &&
                        <>
                            {!isTablet && <BtnAddEvent state={showManageEvent.isOpen} onClick={onClickPlus}/>}
                            {openClearingFeeMessagePop.isOpen&&<ClearingFeeMessagePop show={openClearingFeeMessagePop} onClick={onClickPop}/>}
                            {showManageEvent.isOpen && <AddEventManage onClickClose={showManageEvent.close} closeEvents={close}/>}
                        </>
                    }
                </css.AddEvent>
                <css.left>
                    {/*<css.TopMenuLogo className="topMenuLogo "/>*/}
                    <css.MailingComponent className="MailingComponent" onClick={() => {
                        handleOnClickMailing(1);
                    }}>
                        <css.MailingBtn className="MailingBtn btn" onClick={() => {
                            mailingType('email');
                        }}/>
                        <css.SmsBtn className="SmsBtn btn" onClick={() => {
                            mailingType('sms');
                        }}/>
                    </css.MailingComponent>
                    {!isMobile && <MyTickchakSystem/>}
                    <css.SelectEvents data-cy='myEventsBtn' className="selectEvents" onClick={() => {
                        handleOnClickEvents(0);
                    }}>
                        <css.SelectEventsSearch onClick={() => setEventsSearch(1)}/>
                        <css.SelectEventsIcon/>
                        <css.SelectTextEvents>{t('yourEvents')}</css.SelectTextEvents>
                        <css.SelectEventsCount data-cy="eventsCounts">{countEvents}</css.SelectEventsCount>
                    </css.SelectEvents>
                    {isOpen && <Events show={{close, showClass}} search={EventsSearch} closeEvents={() => {
                        handleOnClickEvents(1);
                    }} updateLayout={updateLayout}/>}
                    {showMailing === 1 && <Mailing mailingProps={mailingProps}/>}
                </css.left>
            </css.TopMenu>

        </>
    );
}

export default TopMenu;
