import styled from 'styled-components';

export const wrapper=styled.div`
    display: grid;
    grid-template-areas:"startText          endText"
                        "startDate        endDate";
    column-gap: 30px;
    grid-template-columns: 1fr 1fr;
    text-align: start;
    `;

export const startText=styled.div`
      grid-area: startText;
    `; 
export const startDate=styled.div`
      grid-area: startDate;
      font-weight: 800;
      >span{
        font-weight: 500;
        font-size: 12px;
        margin-inline-start:5px ;
      }
    `;
export const endText=styled.div`
      grid-area: endText;

    `; 
export const endDate=styled(startDate)`
      grid-area: endDate;
    `;