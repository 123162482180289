import styled from 'styled-components';
import {dFlexCenter} from '../../globalVariables/GlobalVariables';
import {animate} from '../animation/Animation.css';
import zIndex from '../../globalVariables/zIndex';

export const wrapper = styled.div`
  z-index:${zIndex.highestModal};
  transition: all 300ms;
  opacity: 0;
  &.show {
    opacity: 1;
  }
  transition: all 300ms ease 0s;
  top: 0px;
  width: 100%;
  height: 100%;
  ${dFlexCenter}
  background-color: rgb(0 0 0 / 05%);
  backdrop-filter: blur(20px);
  ${animate} {
    width: 200px;
  }
  ${(p) => p.cssStyle}
`;
export const progressWarp = styled.div`
  display: flex;
  justify-content: center;

`;