import styled from 'styled-components';
import {WHITE} from '../../../../globalVariables/GlobalVariables';

export const wrapper=styled.div`
    width:100%; 
`;
export const participant=styled.div`
    display: flex;
    width: 100%;
    max-width: 100%;
    margin-top:15px;
    background-color: ${WHITE};
    border-radius: 10px;
    padding:10px 30px 8px 30px;
    justify-content: space-between  ;
 
`;
export const right=styled.div`
    span{
        font-weight: 700;

    }
 
`;
export const left=styled.div`

    
 
`;
