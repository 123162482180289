import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import * as css from './ManagePromotion.css';
import useShow from '../../hooks/useShow';
import {getPromotionMessage, stopShowMessage, acceptPromotion} from '../../redux/event/eventActions';
import PromotionMessage from '../promotionMessage/PromotionMessage';
import PromotionPopup from '../prompotionPopup/PromotionPopup';
import {getStaticImage} from '../../globalVariables/GlobalVariables';

// export const promotionColors = {
//     eventReady: ['#5FBF9C', '#4695A9', '#16DC99'],
//     salesDecline: ['#5D1D83', '#332673', '#D963F9'],
//     oneWeekBeforeEvent: ['#6656C9', '#8594E7', '#FBD24A'],
//     lastMinute: ['#878ADB', '#E176A9', '#9F63FF']
// };
export const colors = ['#FCE79B', '#FFDA56', '#101010'];

function ManagePromotion() {
    const showPromotion = useShow();
    const showPromotionPopup = useShow();

    const {pmid, type} = useSelector((state: any) => state.event.promotionMessage) || {};
    // const colors = promotionColors[type] || [];

    const backgroundColor = `linear-gradient(180deg, ${colors[0] || ''} 0%, ${colors[1] || ''} 100%);`;
    const backgroundImage = getStaticImage(`app/promotion/${type}Promotion.png`);

    const {t} = useTranslation('promotionMessage');

    const closeMessage = () => {
        showPromotion.close();
        stopShowMessage(pmid);
    };

    const accept = () => {
        acceptPromotion(pmid);
        showPromotionPopup.open();
        showPromotion.close();
    };

    useEffect(() => {
        getPromotionMessage();
    }, []);

    useEffect(() => {
        if(pmid) {
            showPromotion.open();
        }
    }, [pmid]);
    
    return (
        <>
            <PromotionMessage
                show={showPromotion}
                firstTitle={t(`${type}TitleFirst`)}
                secondTitle={t(`${type}TitleSecond`)}
                text={t(`${type}Text`)}
                background={{image: backgroundImage, color: backgroundColor}}
                button={{onClick: accept, text: t(`${type}Accept`), color: colors[2]}}
                close={closeMessage}
            />
            {!!showPromotionPopup.isOpen && (
                <PromotionPopup
                    show={showPromotionPopup}
                    color={backgroundColor}
                    image={backgroundImage}
                    button={{onClick: showPromotionPopup.close, text: t('thanks')}}
                >
                    <css.title>{t('popupMessageTitle')}</css.title>
                    <css.text>{t('popupMessageText')}</css.text>
                    <css.contact>{t('contactDetails')}</css.contact>
                </PromotionPopup>
            )}
        </>
    );
}

export default ManagePromotion;
