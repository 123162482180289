import styled from 'styled-components';
import zIndex from '../../../../../../globalVariables/zIndex';

export const UserImgMenuDivStyled = styled.div`
        position: absolute;
        width: 150px;
        height: auto;
        top: 35px;
        left: calc(50% - 75px);
        background: #fff;
        border: none;
        box-shadow: 0px 0px 3px 1px #00000029;
        z-index:${zIndex.baseRaised};
        text-align: center;
        user-select: none;
        
    `;

export const UserImgMenuLine = styled.div`
    position: relative;
    width: 100%;
    height: 35px;
    cursor: pointer;
    line-height: 30px;
    border-top: 1px solid #ebebeb;
    transition: transform 0.3s;
    font-size:16px;
    &.title{
        color: #01acf1;
        cursor: default;
        font-weight: 700;
        border-top: none;
    };
    &:not(.title):hover{
        background: #ebebeb;
    };
   
`;

export const UserPreviewImgCtr = styled.div`
        position: absolute;
        width: 250px;
        height: 200px;
        top: 8%;
        left: calc(50% - 125px);
        background: #fff;
        border: none;
        box-shadow: 0px 0px 3px 1px #00000029;
        z-index:${zIndex.baseRaised};
        text-align: center;
        user-select: none;
        
`;

export const UserPreviewImgPreviewCtr = styled.div`
        position: relative;
        width: 70%;
        height: 100%;
        background: red;
        display: inline-block;        
`;

export const UserPreviewImgPreview = styled.div`
        position: absolute;
        width: 80%;
        height: 80%;
        top: 10%;
        left: 10%;
        background: gray;
`;

export const UserPreviewImgImg = styled.img`
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
`;

export const UserPreviewImgSelect = styled.div`
        position: relative;
        width: 30%;
        height: 100%;
        background: yellow;
        display: inline-block;        
`;

export const UserPreviewImgSelectYes = styled.div`
        position: absolute;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: green;
        left: 0;
        top: 0;
`;

export const UserPreviewImgSelectNo = styled.div`
        position: absolute;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: red;
        left: 35px;
        top: 0;    
`;
