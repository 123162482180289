import styled from 'styled-components';
import {checkbox} from '../../../../../component/checkbox/Checkbox.css';
import {text, wrapCheckbox} from '../../../../../component/checkboxFormik/CheckboxFormik.css';

export const wrapCB = styled.div`
       width: 175px;
       margin-inline-start: 129px;
       ${checkbox}{
         width: 14px;
         height: 14px;
       }
       ${wrapCheckbox}{
         width: 250px;
       }
       ${text}{
         padding-inline-start: 10px;
       }
`;