import styled,{css} from 'styled-components';
import {BGImageCenter, dFlexCenter} from '../../globalVariables/GlobalVariables';
import * as symbols from '../../assets/symbols/symbols/Symbols.css';

const DEFAULT_COLOR='#76D6E8';
export const dataItemwrapcss = css`
    width: max-content;
    height: 24px;
    border-radius: 13px;
    border: 1px solid #747474;
    background-color: transparent;
    padding: 5px;
    padding-inline-end:10px;
    display: flex;
    align-items: center;
    &.sum{
        cursor: pointer;
    }
    &:hover{
        div{
            color: ${p=>p.color||DEFAULT_COLOR};
        }
        border-color: ${p=>p.color||DEFAULT_COLOR};
        .dataItem{
            background-image: unset;
            background-color: ${p=>p.color||DEFAULT_COLOR} ;
        &::before{
            content: "X";
            ${dFlexCenter}
            font-size: 12px;
            font-weight: 700;
            letter-spacing: 0px;
            font-size: 11px;
            line-height: 18px;
            color: #000;
            transform: scaleX(1.3);      
          }
        }
    }
`;
export const wrap = styled.div`
${dataItemwrapcss}
`;
export const icon = styled.div`
cursor: pointer;
    width: 17px ;
    height: 17px;
    border-radius: 50%;
    ${p=>BGImageCenter(symbols[p.icon],'10px')}
    background-color: ${p=>p.color||DEFAULT_COLOR};
    margin-inline-end:10px;

    
`;
export const clear = styled.div`

`;

export const text = styled.div`

    font-size: 12px;
`;