import styled from 'styled-components';
import {dFlexCenter} from '../../globalVariables/GlobalVariables';

export const text=styled.div`
    font-weight: 600;
`;
export const status=styled.div`
    width: max-content;
    height: 18px;
    background: #0DABFF 0% 0% no-repeat padding-box;
    border-radius: 23px;
    background-color:${p => p.color|| '#0DABFF'};
    color:white;
    font-size: 13px;
    font-weight: 500;
    text-align: center;
    padding-inline: 10px;
`;

export const wrapper=styled.div`
    ${dFlexCenter}
    flex-direction: column;
    flex-shrink: 0;
`;