import i18next from 'i18next';
import Backend from 'i18next-http-backend';
import {initReactI18next} from 'react-i18next';
import store from '../store';

const detectLanguage = () => {
    let lang = detectUserLanguage();
    if(lang === 'he-IL'){
        lang = 'he';
    }
    if(lang === 'en-Us'){
        lang = 'en';
    }
    if(lang === 'en_us'){
        lang = 'en';
    }
    return lang;
};

const detectUserLanguage = () => {
    let state = store.getState();
    if(window.location.search.match(/lang=(.*)&/))
        return window.location.search.match(/lang=(.*)&/)[1];
    if(window.location.search.match(/lang=(.*)/))
        return window.location.search.match(/lang=(.*)/)[1];
    if(state.auth?.isLoggedIn)
        return state.user.lang;
    // if(REACT_APP_ENV !== 'production'){
    //     //for DEVELOPMENT:
    //     return 'en';
    // }
    //for PRODUCTION:
    const langloc = JSON.parse(localStorage.getItem('user.langloc'));
    if(langloc)
        return langloc;
    return navigator.language;
};

const configI18next = () => {
    i18next.use(Backend).use(initReactI18next).init({
        react: {
            useSuspense: true,
            wait: false
        },
        fallbackLng: 'he',
        ns: ['attendance', 'cluster', 'event', 'global', 'layout', 'login', 'mailing', 'package', 'permission', 'security', 'ticket', 'team', 'newEvent','errors','publish','branding', 'variables', 'realtime', 'promotionMessage'],
        debug: false,
        interpolation: {escapeValue: false},  // React already does escaping
        lng: detectLanguage(),                              // language to use
        backend: {
            loadPath: '/locale/{{lng}}/{{ns}}.json',
            // addPath: '/locale/{{lng}}/{{ns}}.json',
            allowMultiLoading: true
        },
        // saveMissing: true
    }).then();
};

export default configI18next;