import styled, {createGlobalStyle} from 'styled-components';
import {popUpBody} from '../../../component/newPopup/mobilePopup/MobilePopup.css';
import {BGImageCenter, YELLOW, mobileMedia} from '../../../globalVariables/GlobalVariables';
import {mail} from '../../../assets/symbols/symbols/Symbols.css';
import {btn} from '../../../component/button/btn/Btn.css';
import {userVerification} from '../../../assets/symbols/images/userVerification';

export const container=styled.div`
    font-size: 16px;
    white-space: break-spaces;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 250px;
    ${btn}{
        background-color: ${YELLOW};
        border: 1px solid ${YELLOW};
        width: 200px;
    }
`;

export const mailIcon=styled.div`
    width: 20px ;
    height: 20px;
    ${BGImageCenter(mail,'12px')}
    margin: 5px;
`;
export const image = styled.div`
  ${BGImageCenter(userVerification, '110px')};
  height: 110px;
  width: 110px;
  max-width: 80%;
  margin: 0 auto;
  max-height: calc(100% - 100px);
`;

export const globalStyle = createGlobalStyle`
  ${popUpBody}{
    width: 350px;
    ${mobileMedia}{
      width: 100%;
    }
  }
`;