import styled from 'styled-components';
import {BLACK} from '../../globalVariables/GlobalVariables';

export const title = styled.div`
    color: ${BLACK};
    font-size: 22px;
    font-weight: 800;
    white-space: pre-line;
`;
export const text = styled.div`
    color: ${BLACK};
    white-space: pre-line;
    margin-block: 20px;
`;
export const contact = styled.div`
    color: ${BLACK};
    font-weight: 700;
    white-space: pre-line;
`;
