import styled from 'styled-components';
import {dFlexCenter} from '../../globalVariables/GlobalVariables';

export const container = styled.div``;
export const text = styled.div`
${dFlexCenter};
font-size:12px;
white-space: pre-line;
`;
export const code = styled.div`
padding:10px 0;
`;
export const options = styled.div`
display:flex;
justify-content:space-between;
`;
export const option = styled.div`
cursor: pointer;
text-decoration: underline;
`;
