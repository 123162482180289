import styled from 'styled-components';
import {add} from '../../../assets/symbols/symbols/Symbols.css';
import {BGImageCenter,DEFAULT_COLOR,dFlexCenter} from '../../../globalVariables/GlobalVariables';
export const btnPlus=styled.div`
  width: ${p => (p.hasText ? 'fit-content' :(p.bsize ? p.bsize+'px':'40px'))};
  height: ${p => (p.bsize ? p.bsize+'px':'40px')};
  border-radius: 20px;
  background: ${p => (p.white ? '#ffffff' : DEFAULT_COLOR)};
  border:${DEFAULT_COLOR} 1px solid;
  color: #fff;
  display: flex;
  cursor: pointer;
  &:hover{
  background: ${p => (p.white ? DEFAULT_COLOR:'#ffffff')};

}
  &.open {
    background: ${p => (p.white ? DEFAULT_COLOR:'#ffffff')};
  }
  &.open .btnIcon {
    transform: rotate(45deg);
  }
  &.close {
    &:hover {
      .btnIcon {
        transform: rotate(180deg);
      }
    }
  }
`;

export const plusIcon = styled.div`
  align-items: center;
  justify-content: center;
  height:  ${p => (p.bsize ? (p.bsize-1)+'px':'39px')};
  width:  ${p => (p.bsize ? (p.bsize-1)+'px':'39px')};
  cursor: pointer;
  transition:all 200ms;
  ${dFlexCenter};
   ${BGImageCenter(add,'45% 45%'  )}
  
`;
export const btnText = styled.div`
  font-size: 16px;
  padding-inline-start: 20px;
  font-weight: 500;
  height: 100%;
  line-height: 37px;



`;