import styled from 'styled-components';
import {BGImageCenter, ellipsis, mobileScreen} from '../../../globalVariables/GlobalVariables';
import {op} from '../../../assets/symbols/symbols/Symbols.css';
import zIndex from '../../../globalVariables/zIndex';

export const POPUP_TOP='60px';

export const popUpTop=styled.div`
    position: relative;
    height: ${POPUP_TOP};
    display: flex;
    z-index:${zIndex.baseRaised};
    .close{
        height:100% ;
    }
`;

export const popTitle=styled.div`
    line-height: 60px;
    width:185px;
    font-weight: 800;
    ${ellipsis};
    @media screen and (max-width:${mobileScreen}px){
        font-size: 14px;
        width:111px ;
    }
`;
export const options = styled.div`
    position:relative ;
    cursor: pointer;
    width:40px;
    // ${BGImageCenter(op, '9px')}
    &:hover{
        transform: scaleX(2.5);
    }
`;
// export const options = styled.div`
//     cursor: pointer;
//     width: 20px;
//     height: 10px;
//     ${BGImageCenter(symbol['op'], "8px")}
//     &:hover{
//         transform: scaleX(3.5);
//     }
// `

export const children = styled.div`
    width:calc(100% - 200px) ;
`;

export const close = styled.div`
    padding-inline-end: 10px;
 `;
export const listCon=styled.div`
  display: flex;
  align-items: center;

 `;
