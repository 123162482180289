import styled from 'styled-components';
import zIndex from '../../globalVariables/zIndex';

export const Menu=styled.div`
    width: 325px;
    background: #fff;
    position: absolute;
    top: 0;
   ${props => (props.theme.rtl ?'right':'left')}: -335px;
    z-index: ${zIndex.baseHighest};
    height: ${props=>props.theme.vh*100}px;
    max-width: calc(100% - 50px);
    transition: all 400ms;
    border-radius: ${props => (props.theme.rtl ?'8px 0px 0px 8px;':'0px 8px 8px 0px;')} ;
    box-shadow: 0 3px 15px #00000080;
    &.show{
        ${props => (props.theme.rtl ?'right':'left')}: -100px;
    }
`;
export const MenuTop=styled.div`
    width:100%;
    height:50px;
    background:#fff;
    display:flex;
    border-radius: ${props => (props.theme.rtl ?'8px 0px 0px 0px;':'0px 8px 0px 0px;')} ;
`;