import styled from 'styled-components';
import {mobileMedia, scrollbar,desktopMedia} from '../../../../globalVariables/GlobalVariables';
    
//Note: this file has comment that Should be used in the future

// height=420
export const body=styled.div`
    display: flex;
    flex-direction: row-reverse;
    height: calc(100% - 170px);
    padding-top:60px;
    padding-right:100px;
    padding-left:100px;
    ${mobileMedia} { 
                margin-top:100px;
                margin-bottom: 50px;
                flex-direction: column;
                padding:15px;
                height: calc(100% - 340px);
                padding-top: 0;
                ${scrollbar}
    }

`;

export const right=styled.div`
    height: 80%;
    width:70%;
    border-${props=>props.theme.rtl?'left':'right'}: 1px solid #CCC; 
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-inline-end: 30px;
    ${mobileMedia} {
        margin:0 10px;
        height: unset;
        width: unset;
        border-top: 1px solid #EEE; 
        border-${props=>props.theme.rtl?'left':'right'}: unset; 
        }
`;

export const rightHeader=styled.div`
    ${mobileMedia} {
        position: absolute;
        top:160px;
    }
`;

export const rightTitle=styled.div`
    font-size: 15px;
    font-weight: 800;
    
`;

export const rightId=styled.div`
    font-size: 10px;

`;

export const rightText=styled.div`
    margin-top: 12px;
    font-size: 12px;
    ${desktopMedia} {
        min-height: 60px;
        }
`;

export const rightCheckbox=styled.div`
    display: flex   ;
    margin-top: 20px;
    align-items: center;
`;

export const rightCBText=styled.div`
    margin-${props=>props.theme.rtl?'right':'left'}:10px;
    font-size: 12px;
    font-weight: 700;
`;

export const left=styled.div`
        width: 30%;
        /* padding-right:50px;
        padding-left:50px; */
        padding-inline-start:20px;
       
        ${mobileMedia} {
            width: unset;
            display: flex;
            justify-content: space-around;
            align-items: flex-end;
            padding: 40px;
            padding-top: 0px;
        }

        
`;
export const leftPriceWrap=styled.div`
        
`;
export const leftPrice=styled.div`
    margin-top: 30px;
    /* font-size:50px ; */
    font-size:15px ;
    font-weight: 800;
    
        ${mobileMedia} {
    margin-top: unset;
        }

`;
export const leftPerMonth=styled.div`
    font-size:15px ;
    /* font-weight: 800; */
    /* line-height: 5px; */
`;
export const leftOffice=styled.div`

`;
export const commentOffice=styled.div`
    margin-top: 20px;
    font-size:10px ;
`;
export const comment=styled.div`
    font-size:15px ;
    font-weight:700;
`;