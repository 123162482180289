import styled from 'styled-components';
import { symbolEventDark } from '../../assets/symbols/symbols/Symbols.css';
import {headerArrow, ListTitle} from '../../component/list/List.css';
import { BGImageCenter, tabletMedia } from '../../globalVariables/GlobalVariables';
import { NavigationTitle } from '../navigation/Navigation.css';

export const pathItem = styled.div`
    /* width:115px; */
    width:max-content ;
    text-align:center;
    padding-inline-end: 40px;
    &.cur{
        span{
            display: block;
            width: max-content;
            margin: 0 auto;
            position: relative;
            &::after {
                content: '';
                position: absolute;
                width: 100%;
                height: 5px;
                background: #76D6E8;
                border-radius: 30px;
                left: 0;
                bottom: 0px;
                 @media screen and (max-width: 1000px){
                    height: 4px;
                }
            }
        }
    }
     @media screen and (max-width: 1000px){
        padding-inline-end: 30px;
     }
`;

export const listStyle = styled.div`
   ${ListTitle}{
        font-size: 18px;
        font-weight: 700;
        border-bottom: 2px solid #d4d3d3;
        color: #474747;
   }
   ${headerArrow}{
        ${p=>BGImageCenter(symbolEventDark[p.icon],'15px')}
        transform: rotate(0);
   }
`;

export const childItem = styled.div`
    display:flex ;
    height: 60px;
    border-bottom: 1px solid #EEE;
    padding-inline-start: 40px;
    align-items:center ;
`;
