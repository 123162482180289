import styled from 'styled-components';
import {BGImageCenter, DEFAULT_COLOR, dFlexCenter, mobileMedia, scrollbar} from '../../../../../globalVariables/GlobalVariables';
import {attach, circle, line, rectangle, simpleArrow, pen} from '../../../../../assets/symbols/symbols/Symbols.css';
import {plus} from '../coursePopupContent/CoursePopupContent.css';

const getIconByType = (type) => {
    switch (type) {
        case 'text':
        case 'firstName':
        case 'lastName':
            return line;
        case 'radioBtn':
            return circle;
        case 'checkbox':
            return rectangle;
        case 'file':
            return attach;
        case 'list':
            return simpleArrow;
        default:
            break;

    }
};

export const wrapAll = styled.div``;
//height:100%`;

export const container = styled.div`
  height:calc(100% - 70px);
  ${scrollbar};
  padding:45px;
  padding-top:20px;

`;

export const title = styled.div`
  margin:0 0 10px 0;
  ${mobileMedia}{
    padding-bottom:60px;
    height:80px;

  }
  
`;

export const items = styled.div`
  grid-gap: 15px; 
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  ${mobileMedia}{
    display:block ;

  }
   `;

export const item = styled.div`
  width:270px;
  height:30px;
  border-radius:19px ;
  border:1px solid;
  display:flex ;
  align-items:center ;
  position:relative ;
  &:hover{
    .courseFields_edit{
      width:25px ;
      opacity:1;
    }
  }
  .checkboxFormik{
    margin-inline-start:-2px;
  }
  ${mobileMedia}{
    width:100% ;
    max-width:400px ;
    margin-top:15px ;
  }
  &.disabled{
    pointer-events:none;
    opacity:0.7 ;
  }
`;

export const checkbox = styled.div`
  width:30px;
`;
export const text = styled.div`
  padding-inline-start:10px;
  width:calc(100% - 90px);
  font-weight:600 ;
  letter-spacing:0.7px ;
    line-height: 14px;
  &.required{ 
    &::after{
      content:'.';
      color:${DEFAULT_COLOR};
      position:absolute;
      top:0;
      font-size:35px;
      line-height:1px ;
      opacity:0.7;
    }
  }
`;
export const typeWrap = styled.div`
  width:60px;
  position:absolute;
  inset-inline-end:0;
  display: flex;
  justify-content:end ;
  padding-inline-end:5px;
  align-items:center ;
`;
export const type = styled.div`
  border-radius: 50%;
  background-color:#EBEBEB;
  width:20px;
  height:20px ;
  ${dFlexCenter}
  &::after{
    content:'';
    ${p => {
    const icon = getIconByType(p.type);
    return BGImageCenter(icon, '9px');
}}
    width: 20px;
    height: 20px;
    ${p => p.type === 'text' ? 'margin-top:7px;' : ''}
    
  }
`;
export const edit = styled.div`
transition:all 200ms;
  ${BGImageCenter(pen, '15px')}
width: 0px;
opacity: 0;
height: 20px;
cursor: pointer;
`;
export const wrapPlus = styled.div`
  margin-top: 20px;
  display:flex ;
  align-items:center ;
  letter-spacing:1px ;
  &.disabled{
    opacity:0.7 ;
    pointer-events:none;
  }
  ${mobileMedia}{
    position:absolute ;
    top:170px;
  }
`;
export const plusBtn = plus;
