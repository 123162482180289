import styled from 'styled-components';

export const wrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    font-size: 15px;
`;
export const title = styled.div`
    font-weight: bold;
    margin-bottom: 2px;
`;
export const value = styled.div`
`;