import styled from 'styled-components';
import {filter} from '../../../assets/symbols/symbols/Symbols.css';
import {filter as filterCSS} from '../../../component/filterList/FilterList.css';
import {BGImageCenter, mobileMedia} from '../../../globalVariables/GlobalVariables';

export const wrapAllItems=styled.div`
     margin: 0 auto;
     width:92%;
     display:flex ;
     flex-direction:column ;
     ${mobileMedia}{
        width:93%;
     }  
`;

export const wrapBtn=styled.div`
    ${BGImageCenter(filter, '20px 18px')}
     width:50px ;
     height:20px;
     cursor: pointer;
`;

export const wrapTopChildren=styled.div`
     display: flex;
     align-items: center;
     justify-content: flex-end;
     height: 100%;
     ${mobileMedia}{
        flex-direction: column;
        flex-direction: column-reverse;
        align-items: flex-end;
        justify-content: space-around;
     }
     ${filterCSS}{
        margin-inline-end: 7px ; 
        font-weight: 600;
        ${mobileMedia}{
            margin:0;
     }
    }
`;

export const eventsTitle=styled.div`
    letter-spacing: 1px;
    height:40px;
    transition: all 200ms;
    width: 85%;
    line-height:40px;
`;

export const boldTitle=styled(eventsTitle)`
    font-size: 15px;
    font-weight: 600;
    margin: 0 auto;
`;

export const title=styled(eventsTitle)`
    font-size: 13px;
    font-weight: 500;
`;