import {useTranslation} from 'react-i18next';
import * as css from './AutomatedLead.css';
import * as leadsIcons from '../../assets/symbols/symbols/leadsIcons'; 
import {formatNumber} from '../../library/numberFunction';
import store from '../../store';

export function AutomatedLeadDetail({type,lead}) {
    const {t} = useTranslation('publish');
    return (
        <css.container data-cy="leadDetail">
            <css.boldText>{t(type)}</css.boldText>
            <css.text>{formatNumber(lead[type])} {type==='totalPrice' && store.getState().event.currencySymbol}</css.text>
        </css.container>
    );
}

export function AutomatedLead({lead}) {
    const {t} = useTranslation('publish');
    const leadDetails = ['totalPrice','transactions','tickets'];
    const {title,name}= lead;

    return (
        <css.wrapper data-cy="automatedLead">
            <css.icon icon={(leadsIcons[name.toLowerCase()])} className={title}>
                <css.title className={`${name}  ${title==='frontpage'&&'main'}`}>{t(title)}</css.title>
            </css.icon> 
            {leadDetails.map(detail => (
                <AutomatedLeadDetail key={detail} type={detail} lead={lead} />
            ))} 
        </css.wrapper>
    );
}

