import {css, keyframes} from 'styled-components';

const colorChange=(width)=> keyframes`
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: ${width} ${width};
  }
`;
export const Template =(width)=> css`
    background-image:linear-gradient(90deg, rgba( 0,0,0,0.125 ), transparent 50%, rgba( 0,0,0,0.125 ) 60%);
    -webkit-animation: ${colorChange(width)} 1s linear infinite;
    animation: ${colorChange(width)} 1s linear infinite;
`;