import styled from 'styled-components';
import {wrapper,title} from '../../../../component/anotherOneCircle/AnotherOneCircle.css';
import {BGImageCenter, DEFAULT_COLOR, mobileMedia} from '../../../../globalVariables/GlobalVariables';
import {SearchClose,Search} from '../../../../component/inputSearch/InputSearch.css';
import {search} from '../../../../assets/symbols/symbols/Symbols.css';

const placeholder=`
    color: #000000;
    font-weight: 800;
    margin-inline-start: -10px ;
`;
export const artistWrapper=styled.div`
  height: max-content;
  width: 100%;
  ${wrapper}{
    width: 65px;
    height: 65px;
  }
  ${title}{
    color:${DEFAULT_COLOR};
    margin: 0;
  }

  ${SearchClose}{
    &.close{
      ${BGImageCenter(search)};
      pointer-events: none;
    }
  }
  ${Search}{
    padding-block: 30px;
    height: 100px;
  }
  input{
    padding-inline-start:0;
    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      ${placeholder} ;
      opacity: 1; /* Firefox */
    }
    :-ms-input-placeholder { /* Internet Explorer 10-11 */
      ${placeholder} ;
    }
    ::-ms-input-placeholder { /* Microsoft Edge */
      ${placeholder} ;
    }
  }
 
`;
export const artists=styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  row-gap: 30px;
  ${mobileMedia}{
    grid-template-columns: repeat(auto-fill,83px);
    padding-bottom: 20px;
    justify-items: center;
  }
`;
export const artistSearch=styled.div`
  margin-top: -30px;
  height: 40px;
  position: relative;
  
`;
