import styled from 'styled-components';
import {ex, search} from '../../assets/symbols/symbols/Symbols.css';
import {BGImageCenter, DEFAULT_COLOR, dFlexCenter} from '../../globalVariables/GlobalVariables';

export const container=styled.div`
    width:30px ;
    height:30px ;
    border-radius:28px; ;
    position:relative ;
    transition:all 300ms ;
    border:1px solid transparent;
    &:hover{
        border:1px solid #222;
    }
    ${dFlexCenter}
    &.expended{
        width:250px ;
        border:1px solid #222;

    }
    overflow:hidden ;


`;

export const icon=styled.div`
    position:absolute ;
    width:30px ;
    height:30px ;
    inset-inline-end:0;
    ${BGImageCenter(search,'13px')}
    &.expended{
        background-image: url("${ex}");
    }


`;
export const input=styled.input`
    position:absolute ;
    inset-inline-end:30px;
    border:none;
    ::placeholder{
        color:${DEFAULT_COLOR}
    }
    width:205px ;
`;