import styled from 'styled-components';
import {LEFT_WIDTH} from '../StepLeft/StepLeft.css';
import {mobileMedia} from '../../../../globalVariables/GlobalVariables';

export const wrapper=styled.div`
    width: calc(100% - ${LEFT_WIDTH});
    ${mobileMedia}{
      width: 100%;
    }
`;
export const header=styled.div`
    font-size: 24px;
    font-weight: 700    ;
`;
export const description=styled.div`
    font-size: 14px;
`;