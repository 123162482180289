import styled from 'styled-components';
import {bubbles} from '../../assets/symbols/symbols/Symbols.css';
import {animate} from '../animation/Animation.css';
import {btn} from '../button/btn/Btn.css';
import {BGImageCenter, dFlexCenter, mobileMedia, mobileScreen, popupScreen} from '../../globalVariables/GlobalVariables';
import zIndex from '../../globalVariables/zIndex';

const MARGIN='7%';
export const MARGIN_TOP='10px';
export const ROBOT_HEIGHT='120px';

export const mobileBlur=styled.div` 
${popupScreen}
  transition: all 300ms;
    z-index:${zIndex.upperModal}; 

`;

const borderRadius='border-radius: 11px;';

export const wrap=styled.div` 
    position: fixed;
    bottom:50px;
    inset-inline-end: 60px;
    z-index: ${zIndex.upperModal};
    width: 300px;
    ${mobileMedia}{
      top:${MARGIN_TOP};
      width: calc(100% - ${MARGIN} - ${MARGIN});
      right: ${MARGIN};
      left:${MARGIN};
      bottom: unset;
    
    }
`;

export const explanation=styled.div`
    z-index: ${zIndex.baseRaised};
    overflow: hidden;
    white-space: pre-line;
    border-radius: 10px;
    background-color: white;
    margin-bottom: 15px;
    transition: all 300ms;
    height: 0px;
    text-align: center;
    font-weight: 500;
    box-shadow: 0px 3px 15px #00000029;
    ${borderRadius}
    padding: 0 40px;
    &.show{
      height: 270px;
      padding: 40px;
    }
    ${mobileMedia}{
      position: fixed;
      inset-inline: ${MARGIN};
      bottom: ${MARGIN};
      margin-bottom: 0px;
      /* bottom: 0;
      border-radius: 11px 11px 0 0 ; */
    }
`;

export const robot=styled.div` 
    position: relative;
    background-color: white;
    border-radius: 10px;
    display: flex;
    ${borderRadius}
    box-shadow: 0px 3px 15px #00000029;
    height: ${ROBOT_HEIGHT};
`;

const animateWidth='35%';

export const animateWrap=styled.div`  
    background-color: #F7AE23;
    height: 100%;
    width:${animateWidth};
    ${borderRadius}

    ${animate}{
      top: -23px;
        inset-inline-start: -30px;
        width: 160px;
    }
    @media screen and (max-width:${mobileScreen}px) and (min-width:580px){
      ${dFlexCenter}
      ${animate}{
        inset-inline-start: unset;
        width: 160px;
    }
    }
`;

export const robotText=styled.div`
    width: calc(100% - ${animateWidth});
    /* border: 2px solid; */
    ${borderRadius}
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    padding-inline-start: 18px;
    padding-block: 10px;
    ${btn}{
      background-color: white;
      border-color: black;
      color: black;
      height: 26px;
      &:hover{
        background-color: #F7AE23 ;
      }
    }
`;

export const bubble=styled.div`
    position: absolute;
    ${BGImageCenter(bubbles,'70px')}
    width: 40px;
    height: 40px;
    inset-inline-end:20px ;
    top:-16px;
    transition: all 300ms;
    z-index: ${zIndex.baseHighest};
    &.show{
      transform: rotate(180deg);
      ${BGImageCenter(bubbles,'95px')}
      width: 60px;
      height: 60px;
      inset-inline-end:80px ;
      top:-46px;
    }
`;

export const bottomBubbles=styled.div`
    position: absolute;
    ${BGImageCenter(bubbles,'40px')}
    width: 20px;
    height: 20px;
    inset-inline-start:-10px ;
    bottom:-7px;
    z-index: ${zIndex.baseHighest};

`;
export const notResting=styled.div`
    font-weight: 700;
    flex-grow: 1;

`;
export const text=styled.div`
    flex-grow:2 ;
`;
