import styled from 'styled-components';
import zIndex from '../../../globalVariables/zIndex';

export const popUpBody = styled.div`
    position:fixed ;
    background: #FFFFFF;
    /* ${p=>p.left?`left:${p.left}px;`:''} */
    ${p=>p.bottom?`bottom:${p.bottom}px;`:`top:${p.top}px;`}
    width: ${p=>p.size?.width?p.size.width:'fit-content'};
    height: ${p=>p.size?.height?p.size.height:'fit-content'};
    box-shadow: 0 5px 15px #00000052;
    border-radius: 8px;
    flex-direction: column;
    transition: all 300ms ;
    display:none ;
    max-width: calc(100vw - 20px);
    &.disabled{
        pointer-events: none;
    }
    &.show{ 
          display:block ;
          z-index:${zIndex.baseHighest}; 
        }
`;

export const popOnPop = styled.div`
   
`;

export const wrapClose = styled.div`
   height:40px ;
   align-items: center;
   display: flex;
`;

