import styled from 'styled-components';
import {calendar} from '../../../assets/symbols/symbols/Symbols.css';
import {BGImageCenter, dFlexCenter, ellipsis, mobileMedia} from '../../../globalVariables/GlobalVariables';

export const item=styled.div`
    height:45px ;
    width:85% ;
    box-shadow:0px 1px 2px #00000070;
    background-color: white;
    border-radius: 22px;
    margin:7px 0;
    display:flex;
    align-items:center;
    justify-content: space-between;
`;

export const wrapItems=styled.div`
    ${dFlexCenter}
    flex-direction:column ;
    width:100%;
`;

export const rightPart=styled.div`
    display:flex ;
    align-items:center ;
    margin-inline-end: 10px;
    width:calc(50%) ;
`;

export const leftPart=styled.div`
    display:flex ;
    width:calc(50%) ;
    justify-content: flex-end;
`;

export const icon=styled.div`
    ${BGImageCenter(calendar,'18px')}
    position:relative ;
    width:40px;
    height:30px;
`;
export const date=styled.div`
   font-weight:600;
   font-size:13px ;
   ${ellipsis}
`;

export const text=styled.div`
   font-size:13px ;
   height: 30px;
   line-height: 30px;
   width:calc(100% - 75px);
   text-align: end;
    ${ellipsis}
   ${mobileMedia}{
       /* width:calc(100% - 75px) ; */
   }
  
`;
