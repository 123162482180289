import React, {useState, useRef, useEffect} from 'react';
import * as css from './CustomScrollbar.css';

function CustomScrollbar({contentRef, cssStyle, maxBottom = 40}) {
    const thumbRef = useRef(null);
    const [thumbPosition, setThumbPosition] = useState(0);
    const [canScrollUp, setCanScrollUp] = useState(false);
    const [canScrollDown, setCanScrollDown] = useState(false);

    const checkScroll = () => {
        const content = contentRef.current;
        if(content) {
            setCanScrollUp(content.scrollTop > 0);
            setCanScrollDown(content.scrollTop < (content.scrollHeight - content.clientHeight));
        }
    };

    const scrollContent = (direction) => {
        const scrollAmount = direction === 'up' ? -100 : 100;
        contentRef?.current?.scrollBy({top: scrollAmount, behavior: 'smooth'});
        checkScroll();
    };

    const handleMouseMove = (e) => {
        const content = contentRef.current;
        if(!content || !thumbRef.current) return;
        const deltaY = e.clientY - thumbRef?.current?.initialMouseY;
        let newThumbTop = thumbRef?.current?.initialTop + deltaY;
        const maxThumbTop = content?.clientHeight - thumbRef?.current?.offsetHeight;
        newThumbTop = Math.min(maxThumbTop, Math.max(0, newThumbTop));
        const scrollAbleHeight = content?.scrollHeight - content?.clientHeight;
        content.scrollTop = (newThumbTop / maxThumbTop) * scrollAbleHeight;
        newThumbTop = Math.max(0, newThumbTop - maxBottom); 
        setThumbPosition(newThumbTop);
    };

    const handleMouseDown = (e) => {
        e.preventDefault();
        thumbRef.current.initialMouseY = e.clientY;
        thumbRef.current.initialTop = thumbPosition;
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    };

    const handleMouseUp = () => {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
    };

    useEffect(() => {
        const handleScroll = () => {
            const content = contentRef?.current;
            if(!content) return;
            const scrollRatio = content.scrollTop / (content?.scrollHeight - content?.clientHeight);
            let newTop = scrollRatio * (content?.clientHeight - thumbRef?.current?.clientHeight);
            newTop = Math.max(0, newTop - maxBottom); 
            setThumbPosition(newTop);
            checkScroll();
        };
        checkScroll();
        contentRef?.current?.addEventListener('scroll', handleScroll);

        return () => {
            contentRef?.current?.removeEventListener('scroll', handleScroll);
        };
    }, [contentRef]);

    return (
        contentRef.current?.scrollHeight > contentRef.current?.clientHeight ? (
            <css.container data-cy="custom-scrollbar">
                <css.customScrollbar cssStyle={cssStyle}>
                    <css.scrollbarThumb ref={thumbRef} style={{top: `${thumbPosition}px`}} onMouseDown={handleMouseDown}>
                        <css.arrow className={`up ${!canScrollUp ? 'disabled' : ''}`} onClick={() => canScrollUp && scrollContent('up')} />
                        <css.arrow className={`down ${!canScrollDown ? 'disabled' : ''}`} onClick={() => canScrollDown && scrollContent('down')} />
                    </css.scrollbarThumb>
                </css.customScrollbar>
            </css.container>
        ) : null
    );
}

export default CustomScrollbar;
