import styled from 'styled-components';
import {mobileMedia} from '../../../globalVariables/GlobalVariables';

export const container=styled.div`
 &:hover{
    & .settingItem{
        font-weight: 600;
        letter-spacing: 1px;
    }
}
&.disabled{
    opacity: 0.7;
}
`;

export const setting=styled.div`
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 70px;
    ${mobileMedia}{
        padding: 0;
        padding-inline-start:40px;
    }
`;

export const text=styled.div`
    font-weight: 300;

`;

export const leftWarp=styled.div`
    font-weight: 300;
    ${props=>props.hasExplanation?'':'padding-inline-end:50px;'}
    display: flex;
    align-items: center;
    
`;
