import styled from 'styled-components';
import {BGImageCenter, dFlexCenter} from '../../../../globalVariables/GlobalVariables';
import {pixelAnimate} from '../PixelsScreen/PixelsScreen.css';

export const container=styled.div`
	${dFlexCenter}
	height: 100%;
	width: 100%;
	flex-direction: column;
`;
export const title=styled.div`
	font-size:20px;
	font-weight:800;
	padding-bottom: 10px;
`;
export const text=styled.div`
	flex-direction:column;
	max-width:500px;
	letter-spacing:1px;
	font-size: 14px;
	text-align: center;

`;

export const line=styled.div`
		content: '';
		width: 100px;
		height: 1.5px;
		border-radius: 8px;
		margin-top: 25px;
		background-color: #BFBFBF;
`;
export const pixels=styled.div`
	display:flex;
	justify-content: center;
	padding-top: 20px;
	flex-wrap: wrap;
	gap:20px;

`;
export const pixel=styled.div`
	cursor: pointer;
	width:110px;
	height:80px;
	text-align: center;
	font-size: 14px;
	${pixelAnimate}
`;
export const pixelIcon=styled.div`
	width:100%;
	height:50px;
	${p=>BGImageCenter(p.icon,'20px')}
`;
