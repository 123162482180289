import * as css from './Subject.css';
import {useState, useEffect, useRef} from 'react';
import {saveSelection} from '../../../library/caretPosition';
import {useTranslation} from 'react-i18next';
import sanitizeHtml from '../../../library/sanitizeHtml';

function Subject(props) {
    const {mailOrSms, setSelection, setRange, setInputFocus, subjectRef, subjectVal, setSubjectMid, openPopParams, getRef}=props;
    const refSubject = useRef('');
    const {t} = useTranslation('mailing');

    const [subject, setSubjectValue] = useState('');
    const [subjectTemp, setSubjectTemp] = useState('');

    const handleSubject = (e) => {
        const html = e.target.innerHTML;
        setSubjectTemp(html);
        const caret = saveSelection();
        setSelection(caret.sel);
        setRange(caret.newrange);
    };

    const focusInput = () => {
        setInputFocus(1);
    };

    useEffect(() => {
        setSubjectTemp(subject);
    }, [subject]);

    useEffect(() => {
        setSubjectTemp(refSubject.current.innerHTML);    
    }, [subjectRef]);

    useEffect(() => {
        if(mailOrSms=='email'){
            setSubjectValue(subjectVal);
            refSubject.current.innerHTML = subjectVal;
        }
    }, [subjectVal]);

    useEffect(() => {
        setSubjectMid(subjectTemp);
        getRef(refSubject.current?refSubject.current.textContent.length:0,refSubject.current);
    }, [subjectTemp]);

    return (
        <css.subject className="subject">
            {mailOrSms == 'email' &&
                <>
                    <css.subjectIcon />
                    <css.subjectContent data-placeholder={t('subject')} contentEditable={true} className="subjectContent" id="subjectContent" onInput={(e) => handleSubject(e)} onFocus={(e) => focusInput(1)} onClick={(e) => openPopParams(e, 'subject')} autoFocus ref={refSubject} suppressContentEditableWarning={true} 
                        dangerouslySetInnerHTML={sanitizeHtml(subject)} />
                </>
            }
        </css.subject>
    );
}

export default Subject;