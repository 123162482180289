import styled from 'styled-components';

export const content=styled.div`
        width:105px;
        height: 105px;
        line-height: 15px;
    `;

export const percentage=styled.div` 
        margin-top:10px;
        font-size: 25px;
        font-weight: 1000;
`;

export const percentageChar=styled.span`
    font-size: 15px;
`;

export const text=styled.div`
    font-size:15px;
    font-weight: 600;
`;

export const title=styled.div`
    font-size:13px;
    font-weight: 200;
`;

