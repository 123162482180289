import styled from 'styled-components';
import {dFlexCenter} from '../../../../../globalVariables/GlobalVariables';

export const part = styled.div`
    ${dFlexCenter}
    border-inline-end:1.5px solid #ddd;
    padding: ${p=>p.padding||'0 3px'} ;
    ${p=>p.cssStyle||''}
    &.click{
        cursor: pointer;
    }
`;
