import styled from 'styled-components';

export const ImageWrapperStyledDiv = styled.div`
        // position:absolute;
        border-radius:50%;
        border: 2px solid #161616;
        transition: all 400ms;
        &.layout-img-user{
            width: 30px;
            height: 30px;
            // top: calc(50% - 22.5px);
            // left:unset;
            // ${props=>props.theme.rtl?'right':'left'}:8px;
        };
        &.profile-menu-user-img{
            position:unset;
            width:80px;
            height: 80px;
            margin:10px auto;
            // bottom:10px;
            // left:unset;
            // right:calc(50% - 40px);
             @media screen and (max-height: 500px){
                width:60px;
                height: 60px; 
             }
        };
        &.edit-profile-user-img{
            position:relative;
            width:${p=>p.width?p.width:120}px;
            height: ${p=>p.height?p.height:120}px;
            margin:10px auto;
            // bottom:10px;
            // left: unset;
            // right: calc(50% - 60px);
            border: none;
            box-shadow: 0px 0px 7px 4px #00000029;
            @media screen and (max-height: 600px){
                width:${p=>p.width?p.width:80}px;
                height: ${p=>p.height?p.height:80}px;
            }
        }
        
    `;