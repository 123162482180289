import styled from 'styled-components';
import {pinkHeart} from '../../assets/symbols/symbols/Symbols.css';
import convertHexToRgbA from '../../functions/ConvertHexToRgbA';
import {DEFAULT_COLOR, dFlexCenter} from '../../globalVariables/GlobalVariables';

export const container = styled.div`
&.small{
    & .modeBtnText{
        margin-inline-end:2px;
        font-size:9px ;
        width:15px;
        color:#000;
    }
    & .toggle{
        width:35px ;
        height:16px;
        border:none; 
        &:after{
            inset-inline-start:4px;
        }  
        &.active{
            background-color: ${DEFAULT_COLOR};
            &:after{
                background-color:#FFFFFF ;
                inset-inline-start:calc(100% - 13px);
                }  
        }
    }
}
    display: flex;
    align-items: center;
    &.disabled{
        opacity: 0.6;
        >div{
            cursor:unset;
            box-shadow: unset;
            &:hover{
                box-shadow: unset !important;//remember
              &::after{
                box-shadow: unset !important;
                }              
            }
        }
    }
`;
export const text = styled.div`
    font-size: 10px;
    margin-inline-end:3px;
    color: #747474;
    &.active{
        color: #76D6E8;
    }
    &.white{
        color: #FFFFFF;
    }
`;

export const toggle = styled.div`
        cursor: pointer;
        transition:all 400ms;
        &.proposal:not(.LOADING){
            width:20px;
            height: 20px;
            border:1px solid #76D6E8;
            border-radius: 50%;
            box-shadow:unset;
            background-color:rgba(${convertHexToRgbA('#76D6E8')}, 0.4);
            ${dFlexCenter}
            &:hover{
                box-shadow:0 0 0 5px rgba(${convertHexToRgbA('#76D6E8')}, 0.5);
            }
            &:after{
                content:"+";
                color:#76D6E8;
                font-size: 20px;
                font-weight: 600;
                height: 100%;
                line-height:17px ;
            }
}

&.LOADING, &:not(.proposal){
        position: relative;
        border-radius: 12px;
        width: 50px;
        height: 20px;
        border: 1px solid rgb(174 168 168);
        background-color: rgba(100, 100, 100, 0.1);
        &.white{
            background-color:#FFF !important;
            width: 36px;
            height: 16px;
        }
        &::after{
            content:'';
            width:9px;
            height:9px;
            border-radius:12px;
            background: rgb(170 164 164 / 50%);
            inset-inline-start:6px;
            position:absolute;
            top: calc(50% - 4.5px);
            transition: inset-inline-start 250ms ,width 250ms, box-shadow 350ms;
        }
        &.LOADING{
            &::after{
                width: calc(100% - 12px);
                border-radius: 12px;
            }
        }
    
    
    &:hover{
        &::after{
            box-shadow:0 0 3px 12px rgba(170, 164, 164 ,0.2);             
        }
        &.active{
            &::after{
                    box-shadow:0 0 3px 12px rgba(${convertHexToRgbA('#76D6E8')}, 0.3);
            }
        }
    }
    
    
    &.active{
        border:1px solid #76D6E8;
        background-color:rgba(${convertHexToRgbA('#76D6E8')}, 0.4);
        &::after{
            background:#76D6E8;
            inset-inline-start:calc(100% - 15px);
        }
        &.LOADING{
            &::after{
                inset-inline-start:6px;
            }
        }
    }

    &.message{
        border:1px solid #D10362;
        background-color:rgba(${convertHexToRgbA('#d10362')}, 0.4);
        &:hover{
            &::after{
                box-shadow:0 0 0 12px rgba(${convertHexToRgbA('#d10362')}, 0.15);
                background-color: rgba(${convertHexToRgbA('#d10362')}, 0.15);
            }

        }
        &::after{
                content:"";    
                background: unset;
                background-image:url("${pinkHeart}");
                position: absolute;
                inset-inline-start:calc(100% - 20px);
                top:1px;
                background-size: 13px ;
                background-repeat:no-repeat;  
                background-position:center center;
                width:18px;
                height:18px;
                border-radius:50%;
                transition:inset-inline-start 250ms ;

            }         
        }

}
`;

