import React, {useEffect} from 'react';
import DOMPurify from 'dompurify';
import {getEmailPreview} from '../../../redux/branding/brandingActions';
import * as css from './EmailScreenPreview.css';
import {useSelector} from 'react-redux';
import setGlobalState from '../../../redux/setGlobalState';
import {useState} from 'react';
import {useVariables} from '../../../assets/objects/Variables';
import {convertVariablesToHtml} from '../../../component/textEditor/Variables';
import {useTranslation} from 'react-i18next';

function createMarkup(html) {
    const cleanHTML = DOMPurify.sanitize(html,{
        FORCE_BODY: true,
    });
    return {__html: cleanHTML}; 
}
function EmailScreenPreview() {
    const branding = useSelector(s => s.branding);
    const [previewHtml,setPreviewHtml]=useState(createMarkup('<div></div>'));
    const {t} = useTranslation('variables');

    const refresh=async ()=>{
        setGlobalState('branding.previewKey',branding.previewKey+1);
    };
    const {defaultVariables, disabledVariables} = useVariables();
    const  emailContent=convertVariablesToHtml(branding.data?.[branding.currentLang]?.emailContent,{...defaultVariables, ...disabledVariables} , t);

    useEffect(()=>{
        if(branding.getBrandingData[`${branding.currentLang}XHR`]==='SUCCESS')
            getEmailPreview(emailContent).then(html=>
                setPreviewHtml(createMarkup(html.replace('@media screen and','@container')))
            );
    }, [branding.previewKey,branding.getBrandingData[`${branding.currentLang}XHR`]]);

    return (  
        <>
            <css.wrap>
                <css.refreshBtn onClick={refresh} />
                <div dangerouslySetInnerHTML={previewHtml} key={branding.previewKey}  />
            </css.wrap> 
        </>
    );
}

export default EmailScreenPreview;
