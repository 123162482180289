/* eslint-disable spellcheck/spell-checker */
import styled from 'styled-components';
import {getGridArea} from '../../../../../functions/cssFenctions';
import {BGImageCenter, BLACK, mobileMedia} from '../../../../../globalVariables/GlobalVariables';
import {simpleArrow} from '../../../../../assets/symbols/symbols/Symbols.css';
import {BOTTOM_HEIGTH} from '../../../../../component/newPopup/tabsPopup/TabsPopup.css';
import {btn} from '../../../../../component/button/btn/Btn.css';
import * as input from '../../../../../component/classes/input';
import {charNum} from '../../../../../component/input/textareaInput/TextareaInput.css';
import {wrapInput} from '../../../../../component/input/basicInput/BasicInput.css';
import {wrapCheckbox} from '../../../../../component/checkboxFormik/CheckboxFormik.css';
import {icon} from '../../../../../component/input/inputList/InputList.css';
import {container as modeBtnCountainer} from '../../../../../component/modeBtn/ModeBtn.css';

export const POPUP_PADDING_INLINE='40px';
export const POPUP_MOBILE_PADDING_INLINE='20px';

export const container=styled.div<{ formType?: string }>` 
    margin: 20px ${POPUP_PADDING_INLINE};
    display: grid;
    grid-template-areas: ${p=> p.formType === 'text'? `"titleTitle titleTitle"
                        "title title"
                        "message message"
                        "link linkText"
                        "design picture"
                        "settingsTitle settingsTitle"
                        "publishDateTime expirationDateTime"
                        "viewCount distributions"
                        "officeTitle officeTitle"
                        "howToDisplay howToDisplay"`:
                        `"design picture"
                        "link linkText"
                        "settingsTitle settingsTitle"
                        "publishDateTime expirationDateTime"
                        "viewCount distributions"
                        "officeTitle officeTitle"
                        "howToDisplay howToDisplay"`};
    column-gap: 4%;
    row-gap: 15px;
    grid-template-columns: 48% 48%;
    ${getGridArea(['titleTitle', 'title','message','link','linkText','picture', 'settingsTitle', 'publishDateTime', 'expirationDateTime', 'viewCount', 'distributions','officeTitle','watchRequired','howToDisplay','modeBtn'])}
    ${mobileMedia}{
            display: flex;
            flex-direction: column;
            padding-inline:${POPUP_MOBILE_PADDING_INLINE};
            margin-inline: 0;
            >div{
                margin-top: 10px;
            }
    }
    .message {     
    display: flex;
    flex-direction: column;
    height: 150px;
        ${wrapInput}, textarea, div[class*=container] {
            height: 100%;
        }
    }
        ${charNum} {
            position: unset;
            height: fit-content;
        }
    textarea {
        border: 0.5px solid ${BLACK};
        border-radius: 5px;
    }
    .picture {
        height: 290px
    }
    ${wrapCheckbox} {   
        gap: 10px;
        width: fit-content;
    }
`;

// export const colors = styled.div`
//     grid-area: colors;
//     display: flex;
//     justify-content: flex-start;
//     gap: 5px;
//      ${mobileMedia}{
//         display: none;
//     }
//  `;
// export const color = styled.div`
//  width: 24px;
//  height: 24px;
//  border-radius: 50%;
//  background-color: ${p => p.color};
// `;

export const subTitle = styled.div`
    font-size: 14px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0.2em;
    display: inline-flex;
    gap: 2%;
    align-items: center;
    &.settingsTitle { 
        margin-top: 15px;
    }
`;
export const imageUpload = styled(input.container)`
    inset-inline-start: -20px;
`;
export const dateTime = styled.div<{ hide?: boolean }>`
    display: grid; 
    grid-template-columns: 70% 30%;
    transition:all 300ms; 
    height: 85px;
    ${p=>p.hide? 'overflow: hidden; height: 0;':''}
.time {
    inset-inline-start: -5px;
}
`;
export const viewCountWrapper = styled.div`
position: relative;
    input[value="-1"] {
        color: transparent;
    }
${modeBtnCountainer} {
    position: absolute;
    top: 20%;
    inset-inline-end: 0;
    width: 120px;
    transition: width 300ms ;
    display: flex;
    justify-content: space-between;

    &:has(.active){
        width: 98%;
    }
    &>div{
        font-weight: 600;
        font-size: 14px;
    }
}
`;
export const distributions = styled.div``;
export const howToDisplay = styled.div`
    grid-area: howToDisplay;
    display: inline-flex;
`;
export const roleSelectInput = styled(input.container)`
    padding-bottom: 0;
    margin-top: -8px;
    ${icon} {
        width: 0;
    }
`;
export const rolesConteiner = styled.div`
        position: absolute;
        left: 20px;
        top: 15px;
        display: inline-flex;
        max-width: 100%;
        flex-wrap: wrap;
`;
export const distributionsButton = styled.button`
    justify-content: space-between;
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid;
    height: 35px;
    width: 100%;
    display: flex;
    text-align: end;
    padding-bottom: 5px;
    margin-inline-start: 3px;
    font-weight: 600;
    align-items: flex-end;
    padding-inline-end: 20px;
    position: relative;
    &::after{
        content: "";
        position: absolute;
        width: 20px; 
        height: 20px;
        cursor: pointer;
        ${props=>props.theme.rtl?'left':'right'}:0px;
        top:calc(50% - 10px);
        ${BGImageCenter(simpleArrow,'9px')}
        background-position: ${props=>props.theme.rtl?'left ':'right '} center;
    }
`;
export const design = styled.div`
    display: flex;
    flex-direction: column;
    gap:15px;
    input {  
    width:  50px;
    }
    `;
// export const margin = styled.div`
//     display: flex;
//     flex-wrap: wrap;
//     width: 60%;
//     .marginTop, .marginRight, .marginBottom, .marginLeft {
//         width: 50%;
//         *, input{
//         width: 50px;
//         }
//         .hasIcon {
//     inset-inline-start: 1px;
//     }
// }
// `;

export const bottom=styled.div`
display:flex;
align-items: center;
height: ${BOTTOM_HEIGTH};
border-top: 1px ;
border-top: 1px solid #EEE;
padding-inline: 30px;
`;
export const buttonsRight = styled.div`
    display: flex;
    width: 50%;
`;
export const buttonsLeft = styled(buttonsRight)`
    justify-content: flex-end;
`;
export const wrapBtn=styled.div` 
    width: 55%;
    display: flex;
    justify-content: flex-end;
    padding: 0 30px;
    ${mobileMedia}{
        width: 75%;
        padding: 0;
        padding-inline-end:30px
    }`;
export const nextBtn=styled.div`
    ${btn}{
        margin-inline-end:10px;
        min-width: 80px;
    }
`;

export const saveBtn=styled.div`
    /* margin-inline-end: 30px; */
    transition:all 300ms; 
    width:70px ;
    overflow:hidden ;
    &.hidden{
       width:0;
       /* transform:scale(0.5) ; */
    }
  &.disabled{
    ${btn}{
    /* min-width: 70px; */
      opacity: 0.7;
      cursor: pointer;
      &:hover{
        border: 1px solid #FFF;
        background-color: #76D6E8;
      }
    }
  }
    
`;
export const top=styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;    
`;