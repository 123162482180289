import styled from 'styled-components';
import zIndex from '../../globalVariables/zIndex';
import {BGImageCenter, DARK_GRAY, GRAY} from '../../globalVariables/GlobalVariables';
import {lightArrow} from '../../assets/symbols/symbols/Symbols.css';

export const swiperComponent = styled.div`
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: ${zIndex.base};
    display: block;
    height: 100%;
    .swiper-pagination-bullet {
        background: ${GRAY};
        opacity: 1;
        border: 6px solid ${GRAY};
        width: 10px;
        height: 10px;
        opacity: 1;
        &.swiper-pagination-bullet-active {
            background: ${DARK_GRAY};
            border: ${DARK_GRAY};
        }
  } 
`;

export const swiperWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    transition-property: transform;
    transition-timing-function: var(--swiper-wrapper-transition-timing-function,initial);
    box-sizing: content-box;
`;

export const actionsWrapper = styled.div`
    display: inline-flex;
    bottom: 0;
    width: 100%;
    position: absolute;
    padding: 8px;
`;
export const swiperSlide = styled.div``;

export const swiperPagination = styled.div`
    position: static;`;

export const navigationButtonPrev = styled.div`
    height: 10px;
    ${BGImageCenter(lightArrow,'contain')}
    transform: rotate(${props => (props.theme.rtl ? '0deg' : '180deg')});
    position: static;
    margin-top: 0;

    &::after {
        font-size: 0;
    }
`;

export const navigationButtonNext = styled(navigationButtonPrev)`
    transform: rotate${props => props.theme.rtl ? '(180deg)' : '(0deg)'};
`;