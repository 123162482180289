import styled from 'styled-components';
import {DEFAULT_COLOR, LIGHT_GRAY2, mobileMedia, scrollbar} from '../../../globalVariables/GlobalVariables';
import {btn} from '../../button/btn/Btn.css';

export const header = styled.div`
    background-color: ${DEFAULT_COLOR};
    height: 60px;
    width: 100%;
    position: relative;
    margin-bottom: 30px;
`;
export const content = styled.div`
    position: relative;
    height: calc(100% - 70px);
    ${scrollbar}
`;
export const buttons = styled.div`
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    border-top: 1px solid ${LIGHT_GRAY2};
    ${btn}{
        width: 80px;
        inset-inline-end: 40px;
    }
    ${mobileMedia}{
        justify-content: center;
        ${btn}{
            inset-inline-end: unset;
        }
    }    
`;
