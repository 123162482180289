import styled from 'styled-components';
import {INPUT_PADDING_END} from '../../classes/input';

export const wrapInput = styled.div`
    position:relative ;
    width: 100%;
`;
export const amount = styled.div`
    pointer-events: none;
    position: absolute;
    top: 10px;
    inset-inline-end: ${INPUT_PADDING_END};
    line-height: 25px;
    height: 25px;
    font-weight: bold;
`;