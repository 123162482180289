import styled from 'styled-components';
import * as symbol from '../../../../../assets/symbols/symbols/Symbols.css';

export const CheckboxWrap = styled.div`
    padding-${props => props.theme.rtl ? 'right' : 'left'}:40px;
    display: flex;
    width: calc(100% - 79px);
    justify-content: space-around;
    padding: 0 10px;
    @media screen and (max-width: 750px){
        padding: 0px;
        justify-content: flex-start;
        width: calc(100% - 90px);
    }   
    .classicCheckbox{
        width: 12px;
        height: 12px;
        border: 1px solid #484848;
    }
    .ItemText{
            font-size: 14px;
            letter-spacing: 1px;
    }
`;
export const wrapBtn = styled.div`
    margin: 0px 50px;
    .btnCss{
        width: 79px;
    }
    .success{
        background-color: #76D6E8;
    }
    @media screen and (max-width: 750px){
        margin: 0px;
        width: 90px;
    }   
`;
export const continueBtn = styled.div`
    display: flex;
    width: 100%;
`;
export const arrowMoreCB = styled.div`
    background-repeat: no-repeat;
    background-image:url("${props => symbol[props.icon]}");
    width: 22px;
    height: 29px;
    background-position: center center;
    cursor: pointer;
    position: relative;
`;