import styled from 'styled-components';
import {blackSuccess} from '../../assets/symbols/symbols/Symbols.css';
import convertHexToRgbA from '../../functions/ConvertHexToRgbA';

export const container = styled.label`
    display: flex;
    position: relative;
    cursor: pointer;
  `;

export const input = styled.input`
    width:0px;
    opacity: 0;
    :checked ~ .checkmark {
        background-color:${props => (props.color?props.color: '#76D6E8')};
      }
    :checked ~ .checkmark:after {
        display: block;
  }
  
  `;

export const checkmark  = styled.span`
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #0000002C;
    transition: box-shadow 300ms;
    border-radius: 50%;
    &::after {
    content:"";
    background-image: url("${blackSuccess}");
    display: none;
        background-repeat: no-repeat;
        background-position:center center;    
        background-size: 10px; 
        height: 20px;
        width: 20px;
  }
  &.border{
            box-shadow:0px 0px 0px 5px rgba(${props => convertHexToRgbA(props.color?props.color: '#76D6E8')}, 0.5);
        } 
  `;