import * as css from './Table.css';
import Row from './newRow/Row';
import WarpTable from './WrapTable';

function Table({
    list, 
    checked, 
    buttonsChildren, 
    title = '', 
    scrollProps = {}, 
    searchProps ,
    header=false,
    customScrollbar= {}
}) {
    const props={list,checked,title,scrollProps,searchProps,header,buttonsChildren,customScrollbar};
    return (
        <>  
            <WarpTable {...props}>
                {(getCheckedProps)=>
                    <css.table >
                        {list.map((item, index) => (
                            <Row key={index} index={index}  checked={getCheckedProps(item.key)}>
                                <item.comp {...item.props} index={index} />
                            </Row>))
                        }
                    </css.table>      
                }  
            </WarpTable>
        </>

    );

}
export default Table;

