import styled from 'styled-components';
import {btn} from '../button/btn/Btn.css';
import {BGImageCenter, mobileMedia} from '../../globalVariables/GlobalVariables';
import {shadowArtist} from '../../pages/newEvent/components/artistsStep/artist/Artist.css';
import {arrowBtn} from '../../assets/symbols/symbols/Symbols.css';
import zIndex from '../../globalVariables/zIndex';
export const messageWrapper = styled.div`
    width: 100%;
    position:absolute;
    bottom: 0;
    height: 0;
    background: #FFFFFF 0 0 no-repeat padding-box;
    box-shadow: 0 5px 15px #00000029;
    display: flex;
    justify-content: center;
    border-radius: 15px 15px 0 0;
    transition: all 400ms;
    overflow: hidden;
    z-index: ${zIndex.baseHigh};
    &.show{
        height: 80px;
        overflow: unset;
     
    }
  ${mobileMedia}{
    bottom: 50px;
    flex-direction: column;
    
    &.show{
      height: 230px;
    }
  }
`;
export const messageContent = styled.div`
   display: flex;
    align-items: center;
    width: 550px;
  ${mobileMedia}{
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
`;
export const image = styled.div`
    width: 90px;
    height: 90px;
    border-radius: 50%;
    box-shadow: 0 6px 15px #00000029;
    border: 3px solid #FFFFFF;
    margin-block-end: 35px;
    margin-inline-end: 15px;
    ${p=>BGImageCenter(p.image||shadowArtist,'cover')};
  ${mobileMedia}{
    margin-block:-100px 10px;
    margin-inline-end: 0;
  }

`;
export const messageTitleWrapper = styled.div`
    width: calc(100% - 200px);
    ${mobileMedia}{
      width: 100%;
      text-align: center;
      padding: 10px 30px;
      height: 80px;
    }
`;
export const messageTitle = styled.div`
    font-weight: bold;
    font-size: 18px;
`;
export const messageText = styled.div`
    font-size: 14px;
`;

export const messageButtonWrapper = styled.div`
    ${btn}{
        width: 90px;
        margin-block-end: 5px;
    }
    > div:nth-child(2) {
    &::after{
        content: '';
        width: 20px;
        height: 6px;
        ${BGImageCenter(arrowBtn,'6px')}
        transform: rotate(${p => p.theme.rtl?0:180}deg);
        position: absolute;  
        inset-inline-end :7px;
    }
    }
  ${mobileMedia}{
    display: flex;
    column-gap: 10px;
  }
`;
