import styled from 'styled-components';
import * as title from '../../component/title/Title.css';
import zIndex from '../../globalVariables/zIndex';
export const packageUpgrade = styled.div`
    position :relative;
    cursor:pointer;
    &.center{
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;
export const packageUpgradeBlock = styled.div`
        pointer-events:none;
        opacity:0.7;
`;
export const packageUpgradeIcon = styled(title.titleBlue)`
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='29.521' height='29.939' viewBox='0 0 29.521 29.939'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:%23fff;opacity:0.508;%7D.b%7Bfill:%2376d6e8;%7D.b,.d%7Bstroke:%23000;stroke-linejoin:round;%7D.c%7Bfill:%2343bae7;%7D.d%7Bfill:none;stroke-linecap:round;stroke-width:1.6px;%7D%3C/style%3E%3C/defs%3E%3Cg transform='translate(0.5)'%3E%3Ccircle class='a' cx='12' cy='12' r='12' transform='translate(1.158)'/%3E%3Cg transform='translate(2.138 7.198)'%3E%3Cpath class='b' d='M419,259.267v11.569c0,1.065.5,1.261,1.106,1.261h17.722c.611,0,1.106-.2,1.106-1.261V259' transform='translate(-416.845 -249.856)'/%3E%3Crect class='c' width='9' height='11' rx='1' transform='translate(3.094 9.802)'/%3E%3Cpath class='b' d='M405.26,216.153l3.987,6.287h10.274l-3.753-6.063a.8.8,0,0,0-.678-.378H395.431a.8.8,0,0,0-.678.378L391,222.44h10.274l3.987-6.287v13.983' transform='translate(-393.138 -207.866)'/%3E%3Cline class='d' x2='2.564' y2='3.811' transform='matrix(0.839, 0.545, -0.545, 0.839, 11.936, -5.398)'/%3E%3Cline class='d' y1='0.532' x2='3.433' transform='matrix(0.839, 0.545, -0.545, 0.839, 5.209, -0.653)'/%3E%3Cline class='d' x1='1.228' y2='3.193' transform='matrix(0.839, 0.545, -0.545, 0.839, 18.237, -1.305)'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    position: absolute;
    width: 25px;
    height: 25px;
    background-repeat: no-repeat;
    background-size: contain;
    left: -10px;    
    z-index: ${zIndex.baseRaised};
    &.event{
     left: 10px;
     top:5px;
    }
    &.sideMenu{
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='38.863' height='28.939' viewBox='0 0 38.863 28.939'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:%23fff;opacity:0.508;%7D.b%7Bfill:%2376d6e8;%7D.b,.d%7Bstroke:%23000;stroke-linejoin:round;%7D.c%7Bfill:%2343bae7;%7D.d%7Bfill:none;stroke-linecap:round;stroke-width:1.6px;%7D%3C/style%3E%3C/defs%3E%3Ccircle class='a' cx='12' cy='12' r='12'/%3E%3Cg transform='translate(11.98 6.198)'%3E%3Cpath class='b' d='M419,259.267v11.569c0,1.065.5,1.261,1.106,1.261h17.722c.611,0,1.106-.2,1.106-1.261V259' transform='translate(-416.845 -249.856)'/%3E%3Crect class='c' width='9' height='11' rx='1' transform='translate(3.094 9.802)'/%3E%3Cpath class='b' d='M405.26,216.153l3.987,6.287h10.274l-3.753-6.063a.8.8,0,0,0-.678-.378H395.431a.8.8,0,0,0-.678.378L391,222.44h10.274l3.987-6.287v13.983' transform='translate(-393.138 -207.866)'/%3E%3Cline class='d' x2='2.564' y2='3.811' transform='translate(-4.42 0.491)'/%3E%3Cline class='d' y1='0.532' x2='3.433' transform='translate(-7.478 8.134)'/%3E%3Cline class='d' x1='1.228' y2='3.193' transform='translate(3.094 0.491)'/%3E%3C/g%3E%3C/svg%3E");
        left: 10px;
        top: 15px;
        width: 30px;
        height: 30px;
        &::before{      
            bottom: 0px;
            left: 80px;           
        }
        &::after{
            bottom:9px;
            right: -5px;
        }
    }
    
`;