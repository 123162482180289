import styled from 'styled-components';
import {BGImageCenter} from '../../globalVariables/GlobalVariables';
export const item=styled.div<{ icon?: string; }>`
        font-size: 14px;
        padding-top: 25px;
        position: relative;
        width: max-content;
        cursor: pointer;
    
    &::before {
            content: '';
            position: absolute;
            ${p=>BGImageCenter(p.icon, '20px')};
            width: 25px;
            height: 25px;
            top: 0;
            right: calc(50% - 12.5px);
    }
`;

