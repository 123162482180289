import styled from 'styled-components';
import {mobileMedia} from '../../../../globalVariables/GlobalVariables';

export const more = styled.div`
    max-width: 95%;
    ${mobileMedia}{
        display: flex;
        justify-content: center;
    }
`;
export const ticketsArea = styled.div`
    display:flex;
    flex-wrap: wrap;
    gap: 20px;
    padding-bottom: 30px;  
    ${mobileMedia}{
        justify-content: center;
        align-items: center;
    }
`;