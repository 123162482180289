/* eslint-disable spellcheck/spell-checker */
import styled from 'styled-components';
import {success} from '../../../assets/symbols/symbols/Symbols.css';

export const color = styled.div`
    width: 30px;
    height: 30px;
    background-color:#${props => props.color};
    border-radius: 50%;  
    &:hover:not(.disabled){
        border: 2px solid #76D6E8;
        box-shadow: 0 0 1px 2px #fff inset;
        cursor: pointer;
    } 
    &.selected{
        background-image: url("${success}");
        background-repeat: no-repeat;
        background-position:center center;    
        background-size: 10px; 
    }
    &.disabled{
        opacity: 0.2;
        pointer-events: none
    }
`;
