import styled from 'styled-components';
import {BGImageCenter, DEFAULT_COLOR, tabletPlusMedia} from '../../../globalVariables/GlobalVariables';
import {container} from '../../../component/popUpBubble/PopUpBubble.css';

export const warp = styled.div`
  width: 315px;
  display: flex;
  align-items: center;
  justify-content: end;
  padding-inline-end: 20px;
  position: relative;
  transition: all 400ms;
  ${container}{
    width: 313px;
    height:291px;
    background-color: white;
  }
  ${tabletPlusMedia} {
    &.margin {
      padding-inline-end: 100px;
    }
  }
  >div:nth-child(2) {
    position: absolute;
    top: 53px;
    inset-inline-end:${(p) => (p.theme.rtl ? ' -75' : '-55')}px;
  }
`;

export const text = styled.div`
    height: 95px;
    font-weight: 600;
    text-align: center;
    padding: 0 45px;
    align-items: center;
    display: flex;
`;

export const top = styled.div`
    background-color:${DEFAULT_COLOR} ;
    height:150px ;
    border-radius: 13px;
    transition: all 300ms;
    position: relative;
  &::after {
        content: "";
        width: 220px;
        height: 112px;
        right: 46px;
        bottom: -6px;
        position: absolute;
        ${p => BGImageCenter(p.image, 'cover')}
  }
`;

export const body = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
`;

