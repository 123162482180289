import styled from 'styled-components';
import {BGImageCenter, WHITE, mobileMedia} from '../../../globalVariables/GlobalVariables';
import {shadowArtist} from '../../newEvent/components/artistsStep/artist/Artist.css';
import {btn} from '../../../component/button/btn/Btn.css';

export const wrapper=styled.div`
   width: 100%;
   height: 100px;
   background: ${WHITE} 0% 0% no-repeat padding-box;
   border-radius: 10px;
   margin-top: 10px;
   display: flex;
   align-items: center;
  padding: 0px 20px;
  ${mobileMedia}{
    padding: 0;
    padding-inline-end: 8px;
    ${btn}{
      height: 35px;
      font-size: 14px;
      padding: 10px 15px;
    }
  }
`;

export const image=styled.div`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  box-shadow: 0 3px 10px #00000029;
  border: 2px solid #FFFFFF;
  ${p=>BGImageCenter(p.image||shadowArtist,'cover')};
  margin-inline-end: 15px;
  ${mobileMedia}{
    margin: 0 8px;
    width: 70px;
    height: 70px;
  }
`;

export const smallText=styled.div`
 ${mobileMedia}{
    font-size: 12px;
  }
`;

export const text=styled.div`
  font-size: 18px;
  font-weight: 600;
  ${mobileMedia}{
    font-size: 16px;
  }
`;

export const left=styled.div`
    width: calc(100% - 86px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-inline-end: 10px;
`;