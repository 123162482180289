import styled from 'styled-components';
import * as symbol from '../../../assets/symbols/symbols/Symbols.css';
export const btnIcon=styled.div`
    width: 55px;
    height: 25px;
    transition:all 200ms;
    cursor:pointer;
    .opText.showAlways{
        opacity:1;
    }
    &:hover{
      .opText{
        opacity:1;
      }
      .opIcon{ 
        background-color: #76D6E8;
      }
  }
  @media screen and (max-width:1000px){
    width: 50px;
  } 
`;
export const opItemIcon = styled.div`
  -webkit-mask-image:url("${props=>symbol[props.icon]}");
    width: 100%;
    height: 20px;
  background-color: #484848;
  -webkit-mask-position: center;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-size: 18px 18px;
`;
export const opItemText = styled.div`
    height: 20px;
    text-align:center;
    font-size:10px;
    line-height: 11px;
    font-weight: 600;
     opacity:0;        
`;