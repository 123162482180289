import styled from 'styled-components';
import {dFlexCenter} from '../../globalVariables/GlobalVariables';
import {office} from '../../assets/symbols/symbols/Symbols.css';
import * as title from '../title/Title.css';
import zIndex from '../../globalVariables/zIndex';

export const circle = styled(title.titleBlue)`
    z-index:${zIndex.baseRaised};
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #89D4E5;
    overflow: visible;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left:10px;
    margin-right:10px;
    &::before{
        bottom:48px;
        background-color: #76D6E8;
        ${props => (props.theme.rtl ? 'left':'right')}: 37px;
    }
    &::after{
        bottom:44px;
        background-color: #76D6E8;

    }
    `;

export const officeIcon = styled.div`
    margin: -10px;
    width: 50px;
    height: 50px;
    background-image:url("${office}") ;
    background-position: center center;
    background-repeat: no-repeat;
    background-size:40px ;
    transition: all 300ms;
    &:hover{
        transform: rotate(${props => (props.theme.rtl ? '-30deg' : '30deg')});
        background-size:50px ;
   }
   
`;
export const activeCircle = styled.div`
    opacity:0;
    overflow: hidden;
    transition: all 200ms;
    width: 18px;
    height: 18px;   
    top:-7px;
    &.show{

        opacity: 1;
    }
    background-color:#AED11C ;
    border-radius:50%;
    ${dFlexCenter}
    position: absolute;
    inset-inline-end:-3px;
    &::after{
     content: "";
        width: 3px;
        height: 5px;
        border-radius:50%;
        background: #222;
        position: absolute;
   }
   &::before{
        content: "";
        width: 9px;
        height: 9px;
        border-radius:50%;
        border: 1px solid #222;
        position: absolute;
   }   
`;