import styled from 'styled-components';
import {mobileMedia} from '../../globalVariables/GlobalVariables';

export const wrapper = styled.div`
    width:314px;
    max-width: 95%;
    height: 115px;
    //margin:10px;
    border-radius: 8px;
    border: 2px dashed #76D6E8;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    ${mobileMedia}{
        width: 324px;
    }
`;
export const addGroup = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;
// export const addBtn = styled.div`
//     width:20px;
//     height:20px;
//     border-radius:50%;
//     background:#76D6E8;
//     color:#FFF;
//     text-align: center;
//     line-height: 20px;
//     font-weight: 500;
//     transition:all 400ms;
//     font-size:0;
//     position:relative;
//     margin: 15px;
//     &:hover{
//             transform: rotate(180deg);
//     }
//     &::after{
//         content:'+';
//         font-size:20px;
//         transition:all 400ms;
//         left: 5px;
//         position:absolute;
//     }
//
//
// `;
export const title = styled.div`
    font-weight: 600;
    padding-inline-start: 10px;
`;