import styled from 'styled-components';
import {attendanceImg} from '../../../../assets/symbols/symbols/Symbols.css';
import {BGImageCenter} from '../../../../globalVariables/GlobalVariables';

export const iconParticipant=styled.div`
    height:20px;
    ${BGImageCenter(attendanceImg,'20px')};
    cursor: pointer;

`;

export const wrapOptions=styled.div`
    display:flex ;
    div{
     width:40px ;
    }
`;
