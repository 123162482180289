import styled from 'styled-components';
import {noMeet} from '../../../../assets/symbols/images/noMeets';
import {
    BGImageCenter,
    dFlexCenter,
    GRAY,
    mobileMedia,
} from '../../../../globalVariables/GlobalVariables';
import {title} from '../AttendancePage.css';
import {arrowBtn} from '../../../../assets/symbols/symbols/Symbols.css';
import {todayIcon} from '../../courseMeetingsList/CourseMeetingsList.css';

export const frame =styled.div` 
  border-radius: 5px;
  padding-bottom: 10px;
  flex-grow: 1;
  flex-basis: 33%;
  min-width: 250px;
  margin-bottom: 10px;
  border: solid 1px #0000001f;
  ${mobileMedia}{
    margin-inline-end:0;
    margin-bottom: 0;
  }
`;
export const date =styled.div`
    cursor: pointer;
    &::after{
      content: '';
      ${BGImageCenter(arrowBtn,'6px')};
      width: 20px;
      height: 20px;
      position: absolute;
      transform: rotate(-90deg);
    }
    ${p=>todayIcon(p.text)}
    `;

export const loader=styled.div`
  position:relative ;
  width:100%;
  height:100px ;
  overflow:hidden ;
  margin-top:10px ;
  ${dFlexCenter}
  .animation{
  width:100%;
  max-width:400px ;
}
`;

export const frameTitle=styled(title)`
    border-bottom: 1px solid ${GRAY};
  position: relative;
  ${mobileMedia}{
    border-bottom:unset;
  }
`;
export const titleArrow=styled.div`
    width:60px;
    height:100%;
    position:relative;
    cursor:pointer;
    &::before{
      content:'';
      ${BGImageCenter(arrowBtn,'6px')};
      position:absolute;
      width:100%;
      height: 100%;
    }


`;

export const titleArrowRight=styled(titleArrow)`
 border-inline-end: 1px solid ${GRAY};
  ${p=>p.theme.rtl&&
  `::before{
          transform:rotate(180deg);
  }`}
  ${mobileMedia}{
    border-inline-end:unset;
  }
`;
export const titleArrowLeft=styled(titleArrow)`
  border-inline-start: 1px solid ${GRAY};
  ${p=>!p.theme.rtl&&
          `::before{
          transform:rotate(180deg);
  }`}
  ${mobileMedia}{
    border-inline-start:unset;
  }
`;

export const right=styled.div`
    display: flex;
    flex-direction: column;
  align-items: stretch;
  padding-inline-start: 12.5px;
  padding-inline-end: 10px;
  margin-inline: 5px;  
  padding-top: 10px;
  row-gap: 1vw;
    &.noMeetToday{
        ${BGImageCenter(noMeet,'230px')}
        height: 300px;
        align-items: center;    
    }
    ${mobileMedia}{
       width: 100%;
        padding: 0;
        margin:0;
        row-gap: 12px;
        padding-inline-start: 10px;
        &.noMeetToday{
            background-size: 200px;
            height: 270px;
            padding-bottom: 20px;
          padding-inline-start: 0;
        }
    }
    `;
export const noMeetText=styled.div`  
    margin-top: auto;
    font-size: 16px;
    font-weight: 600;
    white-space: pre-line;
    text-align: center;
    padding-bottom: 20px;
`;

export  const noMeetings=styled.div`  
width: 100%;
${dFlexCenter};
flex-direction: column;
    &::before{
    content: '';
    width: 100%;
    ${BGImageCenter(noMeet,'300px')}
    height: 300px;
}
${mobileMedia}{
    &::before{
        height: 250px;

    }
}

`;
