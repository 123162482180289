import styled from 'styled-components';
import {simpleArrow} from '../../../../assets/symbols/symbols/Symbols.css';
import {mobileMedia} from '../../../../globalVariables/GlobalVariables';

export const Accordion = styled.div`
`;

export const Header = styled.div`
    height: 50px; 
    display: flex;
    align-items: center;
`;

export const title = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    position: relative;
    height:100%;
    letter-spacing: 1.5px;
    font-weight: 600;
    font-size: 15px;
    padding-${props => (props.theme.rtl ? 'right' : 'left')}:40px;
    &.show{
        &::before{
            transform: rotate(${props => (props.theme.rtl ? '0deg' : '0deg')});
        }  
    }
    &::before{
        transition: all 300ms;
        content: "";
        transform: rotate(${props => (props.theme.rtl ? '90deg' : '-90deg')});  
        position: absolute;
        width: 40px;
        height:50px;
        background-image: url("${simpleArrow}");
        background-repeat: no-repeat;
        background-position:center center;    
        background-size: 10px;     
        top:0;
        ${props => (props.theme.rtl ? 'right' : 'left')}:0px;

    }


`;
export const Inner = styled.div`
    padding:0 40px;
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.55s ease-out;//
    overflow: hidden;
    &.show{
        max-height: 880px;
        transition: max-height 0.25s ease-in;   
    }
    &.overflow{
        overflow: visible;

    }
    ${mobileMedia}{
        padding:0;
        &.show{
            max-height: 1500px;
        }
    }
    transition:all 400ms;
    overflow: hidden;

`;
