import styled from 'styled-components';
import {BLACK, DARK_GRAY, GRAY92, LIGHT_GRAY4, SHADOW_COLOR, VERY_DARK_GRAY, VERY_PALE_BLUE, dFlexCenter} from '../../../../../globalVariables/GlobalVariables';
import realtimeFonts from '../../../fonts';
import {circle, circleWrap, percent} from '../../../../tickets/components/ticketRow/ticketProgress/TicketProgress.css';

export const container = styled.div`
  height: 100%;
  padding: 20px;
  padding-bottom: 0;
`;
export const content = styled.div`
  display: flex;
  justify-content: space-between;
  width: calc(100% - 30px);
  min-width: max-content;
`;
export const pas = styled.div`
  width: 0;
  height: inherit;
  margin: 14px;
  margin-top: 10px;
  border: 1px solid ${VERY_DARK_GRAY};
  opacity: 0.44;
`;
export const section = styled.div`
  flex: 1;
  max-width: fit-content;
  min-width: calc(50% - 15px);
`;

export const sectionTitle = styled.div` 
  ${realtimeFonts.windowTitle}
    min-width: max-content;
`;

export const amountBold = styled.div`
  ${realtimeFonts.mainDataBold}
  line-height: 40px;
  margin-top: 15px;
  height: 55px;
  min-width: max-content;
`;
export const amount = styled(amountBold)`
`;

export const units = styled.span`
  ${realtimeFonts.amount}
  font-weight: normal;
`;

export const subtext = styled.div`
  ${realtimeFonts.textBold}
  width: max-content;
  padding: 2px 14px;
  background-color: ${LIGHT_GRAY4};
  border-radius: 11px;
  font-weight: bold;
`;
export const insightsNumber = styled(subtext)`
  background-color: ${GRAY92};
  width: 50px;
  min-width: max-content;
  height: 21px;
  color: ${p=>p.theme.isDarkMode?DARK_GRAY:BLACK};
  ${dFlexCenter}
`;

export const insights = styled.div`
  padding-bottom: 5px;
  height: 50%;
  justify-content: space-between;
  border-radius: 8px;
  display: flex;
  align-items: flex-end;
`;
export const insightsItem = styled.div`
  width: 60%;
  &, & span {
    min-width: fit-content;
  }
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  ${realtimeFonts.text}
`;
export const insightsNumbers = styled.div<{ eventType?: string; }>`
  height: 98%;
  display: flex;
  flex-direction: column;
  justify-content: ${p=> p.eventType === 'area'? 'space-between' : 'flex-end'};
  ${insightsItem} {
    margin-bottom: ${p=> p.eventType === 'area'?0:5}px;   
  }
`;

export const progressWrapper = styled.div`
  height: 100px;
  ${circleWrap}{
    width: 90px ;
    height: 90px;
    box-shadow: 0 3px 6px ${SHADOW_COLOR};
    position: unset;
  }
  ${circle} {
    width: 75%;
    height: 75%;
    font-size: 26px;
    background-color: ${VERY_PALE_BLUE};
 }
  ${percent} {
    display: contents;
    font-size: 13px;
    height: 26px;
  }
`;

export const goalText = styled.span`
  font-size: 10px;
  line-height: 13px;
  font-weight: normal;
`;