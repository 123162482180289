import styled from 'styled-components';
import {container} from '../../components/ticketBox/TicketBox.css';
import {mobileMedia} from '../../../../globalVariables/GlobalVariables';

export const ticketsArea = styled.div`
    display:flex;
    flex-wrap: wrap;
    gap: 20px;
    padding-bottom: 30px;
    ${mobileMedia}{
        justify-content: center;
        align-items: center;
    }
    ${container}{
        cursor: pointer;
    }
`;