import styled from 'styled-components';

export const icon = styled.div`
    background-repeat: no-repeat;
    height: 17px;
    width: 18px;
    background-size: contain;
    background-position: center;
    margin-${props => props.theme.rtl ? 'left' : 'right'}: 10px;
`;

export const senderIcon = styled(icon)`
background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16.338' height='13.487' viewBox='0 0 16.338 13.487'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bstroke:rgba(0,0,0,0);stroke-miterlimit:10;%7D%3C/style%3E%3C/defs%3E%3Cg transform='translate(0.5 0.5)'%3E%3Cpath class='a' d='M3998.747-1142.082a.578.578,0,0,1-.576-.578v-2.714c-.241-.007-.484-.01-.721-.01a12.53,12.53,0,0,0-3.585.427,8.09,8.09,0,0,0-3.226,1.875.562.562,0,0,1-.373.148h-.027a.565.565,0,0,1-.562-.557,8.793,8.793,0,0,1,.459-3.375,5.714,5.714,0,0,1,1.695-2.428c1.409-1.2,3.542-1.834,6.341-1.89v-2.807a.6.6,0,0,1,.168-.409.574.574,0,0,1,.41-.169.556.556,0,0,1,.4.169l5.633,5.669a.593.593,0,0,1,0,.818l-5.626,5.654h-.007A.521.521,0,0,1,3998.747-1142.082Zm-1.246-4.464c.4,0,.824.01,1.274.028a.565.565,0,0,1,.547.572v1.882l4.237-4.259-4.237-4.259v1.981a.567.567,0,0,1-.575.572h-.173c-2.759,0-4.724.528-6.007,1.615a5.146,5.146,0,0,0-1.711,3.659,9.045,9.045,0,0,1,2.68-1.319A13.92,13.92,0,0,1,3997.5-1146.546Z' transform='translate(-3989.669 1154.569)'/%3E%3C/g%3E%3C/svg%3E");
@media screen and (max-width: 750px){  
    padding-left: 5px;
}
`;

export const senderText = styled.div`
`;
export const senderTitle = styled.span`
    margin-${props => props.theme.rtl ? 'left' : 'right'}: 10px;
    @media screen and (max-width: 750px){  
        margin-right:0;
    } 
`;

export const senderValue = styled.span`
    font-weight: 700;
    @media screen and (max-width: 750px){  
        margin-${props => props.theme.rtl ? 'right' : 'left'}: 10px;
        margin-${props => props.theme.rtl ? 'left' : 'right'}: auto;
    } 
`;
export const bottomItem = styled.div`
    display: flex;
    .text{
        height: 22px;
        line-height: 22px;
        border-${props => props.theme.rtl ? 'right' : 'left'}: 1px solid;
        padding-${props => props.theme.rtl ? 'right' : 'left'}: 15px;
        @media screen and (max-width: 750px){  
            padding-right: 0px;
        } 
    }
`;

export const bottomSender = styled(bottomItem)`
    padding: 20px 20px 10px;
    @media screen and (max-width: 750px){  
        padding:0 15px;
    } 
`;