import styled from 'styled-components';
import {container} from '../../../../component/input/inputList/InputList.css';
import {ItemText} from '../../../../component/item/Item.css';
import {bottom} from '../bottomPopup/BottomPopup.css';

export const wrapper = styled.div`
display: flex;
flex-wrap: wrap;
padding: 30px 107px;
column-gap: 35px;
   ${container}{
        width: 270px;
   }
`;

export const clearingPopup = styled.div`
   ${bottom}{
        -webkit-box-pack: end;
        -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        padding: 0 60px;
        align-items: center;
        height: 40px;
        }
      ${ItemText}{
         max-width: 200px;
      }
`;
