import styled from 'styled-components';
import {BGImageCenter, mobileMedia} from '../../../../globalVariables/GlobalVariables';

export const popUpImage = styled.div`
    background-color: ${p=>p.color};
    height: 60px;
    min-height: 60px;
    position: relative;
    transition: all 200ms;
    &.focus{
        ${mobileMedia} {
            height:0;
            opacity:0;
        }
    }
    display: flex;
    align-items: center;
    ${mobileMedia}{
        background-color: #FFFFFF;
    }
`;

export const title=styled.div`
    color:#FFFFFF;
    opacity: 1;
    font-size: 18px;
    width: calc(100% - 220px);
    padding-inline-start: 75px;
    ${mobileMedia}{
        color: #484848;
        width: 300px;
        font-size: 20px;
        text-align: center;
        padding-inline-start: 0px;
        font-weight: bold;
        width: 100%;
    }
`;

export const image = styled.div`
    width: 150px;
    height: 150px;
    ${p=>BGImageCenter(p.image,'cover')};
    margin-top: -70px;
    ${mobileMedia}{
        display: none;
    }`;