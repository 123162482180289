import styled from 'styled-components';
import {BGImageCenter, dFlexCenter} from '../../globalVariables/GlobalVariables';

export const icon = styled.div`
    width: 20px;
    height: 20px;
    border-radius: 50%;
    ${BGImageCenter(p=>p?.symbol, '7px')}
    background-image:url("${p=>p?.icon}");
    background-color:#${p=>p.color};
`;

export const editBtn = styled.div`
  cursor:pointer;
    position: relative;
    height: 20px;
    border-radius: 30px;
    background-color: #${p=>p.color};
    display: flex;
    align-items: center;
    &::before{
        content: "";
        width: 20px;
        height: 20px;
        position: absolute;
        ${BGImageCenter(p=>p?.symbol, '8px')}
        background-image:url("${p=>p?.icon}");
    }
`;

export const wrapList = styled.div`
  pointer-events: all;
`;
export const wrapEdit = styled.div`
  ${dFlexCenter}
  margin-inline-end: 5px;
  pointer-events: all;
`;

export const text = styled.div`
    font-size:11px;
    margin-inline-start: 20px;
    margin-inline-end: 10px;
    &.edit{
        margin: 0px 7px;
    }
`;
