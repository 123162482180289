import styled from 'styled-components';
import {DEFAULT_COLOR} from '../../globalVariables/GlobalVariables';
import {Close} from '../close/Close.css';
import zIndex from '../../globalVariables/zIndex';

export const container = styled.div`
    box-shadow: 0 3px 15px #00000029;
    position: relative;
    opacity:0 ;
    border-radius: 13px;
    z-index:${zIndex.basicModal};
    transition: all 400ms;
    background-color: ${p=>p.color||DEFAULT_COLOR};
    ${p=>p.isText?'pointer-events: none;':''};
    &.show{
        opacity: 1;
     }
    &::before{
        content: '';
        position: absolute;
        width: 15px;
        height: 15px;
        background-color: ${p=>p.color||DEFAULT_COLOR};
        transform: rotate(45deg);
        top:-8px;
        margin-inline-start:150px;
    }

${Close}{
    position: absolute;
    inset-inline-start: 10px;
    top: 10px;
}
`;