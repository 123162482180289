import styled from 'styled-components';
import {btn} from '../button/btn/Btn.css';

export const message=styled.div`
    width:100% ;
    background-color:#E31010 ;
    height:45px;
    padding: 0 10px;
    border-radius: 0 0 8px 8px;
`;

export const text = styled.div`
    padding-inline-end: 10px;
    color:white;
`;

export const wrapBtns = styled.div`
   display:flex ;
   ${btn}{
      padding:0 12px ;
      height:25px;
   }
`;
export const wrapAll = styled.div`
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
`;

export const wrapBtn = styled.div`
    padding-inline-end:5px;
`;
