import styled from 'styled-components';
import {mobileMedia, scrollbar} from '../../../globalVariables/GlobalVariables';
import {icon} from '../../../component/classes/input';

export const wrapper = styled.div`
    padding-inline:50px;
    padding-top:20px;
    ${scrollbar};
    border-bottom: 1px solid #F3F3F3;
    ${icon}{
      display: none;
    }
`;

export const saveButton=styled.div`
    padding: 20px;
    direction: ltr;
`;

export const wrapList=styled.div`
    display: flex;
    padding-top: 10px;
    padding-bottom: 15px;
    ${mobileMedia}{
        display: block;
    }
`;