import styled from 'styled-components';
import {BGImageCenter, dFlexCenter, tabletMedia} from '../../../globalVariables/GlobalVariables';

export const wrapper = styled.div`
    width: 100%;
    height: calc(100% - 280px);
    ${dFlexCenter}
    ${tabletMedia}{
        height: calc(100% - 160px);
    }
`;

export const icons = styled.div`
    display: flex;
    padding-bottom: 8px;
`;

export const icon = styled.div`
  width: 25px;
  height: 25px;
  ${p => BGImageCenter(p.icon, '16px 16px')}
  margin: 0 8px;
`;

export const linkIcon = styled(icon)`
  margin: 0;
  margin-inline-end:5px;
`;

export const text = styled.div`
  letter-spacing: 2px;
  white-space: pre-line;
  text-align: center;
  font-size: 13px;
`;

export const title = styled(text)`
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 5px;
`;

export const container = styled.div`
  ${dFlexCenter}
  flex-direction: column;
`;