import styled from 'styled-components';
import {dFlexCenter, scrollbar} from '../../../../../globalVariables/GlobalVariables';

export const bottomPop=styled.div`
   height: 70px;
   ${dFlexCenter}
`;

export const wrapContentPop=styled.div`
    background: #fbfbfb;
    padding: 40px 0 40px 30px;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    height:375px;
    border-bottom: 1px solid #EEE;
    ${scrollbar} 
`;
export const wrapCheckbox=styled.div`
    padding: 10px 30px 20px 0;
`;