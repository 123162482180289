import React, {useEffect, useRef} from 'react';
import Swiper from 'swiper';
import * as css from './swiper.css';
import 'swiper/swiper-bundle.css';
import {Autoplay, FreeMode, Navigation, Pagination} from 'swiper/modules';

Swiper.use([Autoplay, FreeMode, Pagination]);

interface SwiperProps {
    children: React.ReactNode;
    swiperRef: React.RefObject<any>;
    onSlideChange?: (swiper: Swiper) => void;
    onReachEnd?: (swiper: any) => void;
    isReachEnd?: boolean;
    onSwiper?: (swiper: any) => void;
    spaceBetween?: number;
    loop?: boolean;
    freeMode?: boolean;
    autoplay?: boolean | { delay: number, disableOnInteraction: boolean};
    centeredSlidesBounds?: boolean;
    centeredSlides?: boolean;
    pagination?: { class: string} | any;
    navigation?: boolean;
    speed?: number;
    slidesPerView?: number;
}

// NOTICE: copy from LIVE, not checked as generic component
function SwiperComponent({
    children,
    swiperRef,
    onSlideChange,
    onReachEnd,
    isReachEnd = false,
    ...swiperSettings
}:SwiperProps){

    const swiper = useRef({} as Swiper);
    
    useEffect(() => {
        if(!swiperRef.current) return;
        swiper.current = new Swiper(swiperRef.current, {
            ...(swiperSettings as any),
            modules: [Navigation, Pagination],
            autoplay: swiperSettings.autoplay,
            ...(swiperSettings?.navigation? {navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            }}:{}),
            on: {
                touchMove: () => {
                    if(swiper.current.isEnd && !isReachEnd) {
                        onReachEnd?.(swiperRef.current);
                    }
                },
                slideChange: (swiper)=>onSlideChange?.(swiper),
            },
            ...(swiperSettings?.pagination?.class
                ?  {
                    pagination: {
                        clickable: true,
                        el: `.swiper-pagination-${swiperSettings.pagination.class}`,
                        type: 'bullets'
                    }
                }
                : {})
        });     
        return () => {
            swiper.current.destroy(true, true);
        };
    }, [swiperRef, isReachEnd, children]);

    const disableAutoplay = () => {
        swiper.current.autoplay.stop();
    };

    return (
        <css.swiperComponent ref={swiperRef} className="swiper-container">
            <css.swiperWrapper className="swiper-wrapper">
                {React.Children.map(children, (child, index) => (
                    child &&
                    <css.swiperSlide className="swiper-slide" key={index}>
                        {child}
                    </css.swiperSlide>
                ))}
            </css.swiperWrapper>
            <css.actionsWrapper>
                {swiperSettings?.navigation && <css.navigationButtonPrev onClick={disableAutoplay} className="swiper-button-prev" />}
                {swiperSettings?.pagination?.class &&
				<css.swiperPagination data-cy="swiper-pagination" className={`swiper-pagination swiper-pagination-${swiperSettings?.pagination?.class}`} />
                }
                {swiperSettings?.navigation &&  <css.navigationButtonNext onClick={disableAutoplay} className="swiper-button-next" />}
            </css.actionsWrapper>
        </css.swiperComponent>
    );
}

export default SwiperComponent;
