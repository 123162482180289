import styled from 'styled-components';
import {ex} from '../../assets/symbols/symbols/Symbols.css';
export const Close=styled.div`
    background-image: url("${ex}");
    background-repeat:no-repeat;
    background-position:center center;
    background-size: 12px;
    transition:all 400ms;
    cursor:pointer;
    width:30px;
    height: 30px;
    /* ${props=>(props.size&&'height:'+props.size)};
    ${props=>(props.size&&'width:'+props.size)}; */
    &:hover{
        transform:rotate(180deg);
    }
    &.popupRating{
        height: 20px;
        width: 13px;
        background-size: 13px 13px;
    }
    &.white{
        background-image: unset ;
        background-color: #FFF;
        -webkit-mask-image: url("${ex}");
        mask-image: url("${ex}");
        -webkit-mask-position: center;
        -webkit-mask-repeat: no-repeat;
        -webkit-mask-size: 12px 12px ;
        
    }
    &.pop{
       width: 57px;
        
    }
`;