import styled from 'styled-components';
import {mobileScreen} from '../../../globalVariables/GlobalVariables';
import zIndex from '../../../globalVariables/zIndex';

export const popUpTop = styled.div`
    height: 60px;
    display: flex;
    position: relative;
    z-index:${zIndex.baseRaised};
`;

export const popUpBody = styled.div`
    position: absolute;
    background: #FFFFFF;
    width: 680px;
    max-height: calc(100% - 100px);
    min-height: 550px;
    height:${p=>p.height?p=>p.height:550}px;
    box-shadow: 0 5px 15px #00000052;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    transition: all 400ms 200ms;
    &.disabled{
        pointer-events: none;
    }
    @media screen and (max-width:${mobileScreen}px){
        position:absolute ;
        height: 0;
        transition: all 300ms 150ms cubic-bezier(0.42, 0, 0.1, 1.36);
        overflow:unset;
        width: 100%;
        bottom: 0;
        min-height: unset;
        max-height: 94%;

      &.show{ 
             height: 94%;
        }
    } 
`;

export const popUpWrapper = styled.div`
    display:flex;
    align-items: start;
    padding-block: 50px;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.5);
    z-index: ${zIndex.basicModal};
    backdrop-filter: blur(2px);
    transition: all 400ms;
`;

export const popOnPop = styled.div`
   
`;