import styled from 'styled-components';
import {animate} from '../../../../component/animation/Animation.css';

export const button = styled.div`
    cursor: pointer;
    height:28px ;
    width:max-content;
    background-color:#E3E3E3 ;
    border-radius: 15px;
    align-items: center;
    display: flex;
    font-weight: 500;
    font-size: 16px;
    padding-inline:15px;
    position: relative;
    transition: 300ms all;
    justify-content: flex-end;
    &:hover{
        padding-inline-start:31px;
        ${animate}{
            opacity:1 ;
            transition-delay: 200ms 300ms;
    }
    }
    ${animate}{
        opacity:0;
        top: -6px;
        width: 35px;
        inset-inline: -2px;
        transition-delay: 200ms 300ms;
    }
`;

