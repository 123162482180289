import styled from 'styled-components';
import {mobileMedia} from '../../../globalVariables/GlobalVariables';

export const groupsArea = styled.div`
    display: flex;
    flex-wrap:wrap;
    gap:20px;
    padding-bottom: 30px;
    ${mobileMedia}{
        width: 332px; 
        margin: 0 auto;
        /* margin-top: 25px; */
    }
`;
export const bodyChildren = styled.div`

`;
export const topChildren = styled.div`

`;
export const groupForm = styled.div`
`;
export const bottomChildren = styled.div`
`;

export const topTopChildren = styled.div`
`;
export const formFields = styled.div`
`;
export const formImage = styled.div`
`;
export const another = styled.div`
    float: left;
    margin-left: -20px;
`;