import styled from 'styled-components';
import {mobileMedia} from '../../../../globalVariables/GlobalVariables';

export const wrapRB=styled.div`
    padding: 20px 0 0 0;
    display: flex;
`;

export const wrapInputs=styled.div`
    padding:20px 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 40px;
    grid-row-gap: 10px;
    ${mobileMedia}{
            width: unset;
            padding: 20px 0;
            display: flex;
            flex-direction: column;
    }
`;
export const phoneWrapper = styled.div`
    display: inline-flex;
    .valid::before {
        margin-left: -45px;
    }
}
`
export const wrapBranding=styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 40px;
    grid-row-gap: 10px;
    ${mobileMedia}{
            width: unset;
            display: flex;
            flex-direction: column;
    }
`;

export const bottomTitle=styled.div`
    font-size: 14px;
    letter-spacing: 2px;
    padding-top: 20px;
`;

export const title=styled.div`
    padding: 5px 0 10px 0;
    font-size: 13px;
`;
export const upload=styled.div`
  width: 300px;
`;

export const modeBtn=styled.div`
  padding-${props => props.theme.rtl ? 'right' : 'left'}:20px;
`;

