import styled from 'styled-components';
import {DEFAULT_COLOR, mobileMedia} from '../../../globalVariables/GlobalVariables';
import {calendar} from '../../../assets/symbols/symbols/Symbols.css';
import {Item, ItemContentIcon} from '../../../component/item/Item.css';

export const title=styled.div`

  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 17px;
`;
export const todayIcon=(text)=>`
  ${Item} {
    position: relative;
    &.today {
      &::before {
        content:"${text}";
        padding:0 6px;
        height: 15px;
        border-radius: 14px;
        background-color: ${DEFAULT_COLOR};
        position: absolute;
        inset-inline-end: 48px;
        font-size: 10px;
        ${mobileMedia}{
          inset-inline-end: 65px;
        }
      }
    }
  }
  ${ItemContentIcon}{
    background-size: 18px;
  }`;
export const container=styled.div`
  ${p=>todayIcon(p.text)}
`;
export const titleText=styled.div`
  color: ${DEFAULT_COLOR};
  
`;
export const titleBtn=styled.div` 
  height: 30px;
  padding: 10px;
  padding-inline-start: 32px;
  background-image:url("${calendar}") ;
  background-repeat: no-repeat;
  border-radius: 30px;
  border: #000000 1px solid;
  background-position: ${props=>props.theme.rtl?'calc(100% - 11px)':'11px'}  6px;
  background-size: 20%;
  display: flex;
  align-items: center;
`;
