import styled from 'styled-components';
import {unVerified} from '../../../assets/symbols/symbols/Symbols.css';
import {btn} from '../../../component/button/btn/Btn.css';
import {BGImageCenter, mobileMedia} from '../../../globalVariables/GlobalVariables';

export const verificationMessage=styled.div`
    width:100%;
`;

export const verificationMessageIn=styled.div`
    height: 115px;
    width: 225px;
    background-color: #DD2162;
    box-shadow: 0px 5px 15px #00000029;
    border-radius: 5px;
    margin: 0px auto;
    margin-top: 12px;
    position: relative;
    ${btn}{
        height: 24px;
        width: 60px;
        position: absolute;
        inset-inline-end:${p=>p.theme.rtl?'20px':'6px'};
        bottom: 11px;
    }
    ${mobileMedia}{
        width: 290px;
        margin-top: 17px;
    }
`;

export const title=styled.div`
   font-weight: 700;
   color: #FFF;
   font-size: 14px;
   margin-bottom: 3px;
`;

export const bigIcon=styled.div`
    ${BGImageCenter(unVerified,'65px')};
    height: 47px;
    width: 65px;
    position: absolute;
    inset-inline-end: 0;
    top: 10px;
`;

export const verificationMessageBox=styled.div`
    width: 200px;
    height: 50px;
    margin-inline-start: ${p=>p.theme.rtl?'20px':'10px'};
    padding-top: 17px;
`;

