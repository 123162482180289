import styled from 'styled-components';
import * as symbol from '../../../assets/symbols/symbols/Symbols.css';

export const pageHeader = styled.div`
    width: 100%;
    display: flex;
    height:60px;
    align-items:center;
    background:#fff;
    border-bottom: 1px solid #d7d7d7;
`;
export const phTitle = styled.div`
    width: calc(100% - ${props => props.width}px);
    font-weight: 600;
    padding: 0 30px;
    transition: all 400ms;
`;
export const phOperations = styled.div`
    width:${props => props.width}px;
    display: flex;
    /* border-${props => props.theme.rtl ? 'right' : 'left'}:1px solid;     */
    align-items: center;
    transition: all 400ms;
`;
export const save = styled.div`
  background-color: #76d6e8;
  border: 1px solid;
  border-radius: 30px;
  padding: 2px 10px;
  height: 30px;
  display: flex;
  align-items: center;
  cursor:pointer;
  &.cancel{
    background-color: #fff;
    margin-left: 5px;
  }
`;
export const search = styled.div`
    width: 300px;
    @media screen and (max-width: 750px){ 
        width: 200px;
    }    
`;
