import styled from 'styled-components';
import {locationImage} from '../../../../../assets/symbols/images/locationImage';
import {BGImageCenter} from '../../../../../globalVariables/GlobalVariables';

export const top=styled.div`
    height: 140px;
    border-radius: 0 0 12px 12px;
    background-color: #76D6E8;
    ${BGImageCenter(locationImage,'160px')}
    background-position-y: bottom;
`;

export const content=styled.div`
    height: calc(100% - 140px);
    padding-top: 20px;
`;

export const inputWidth=styled.div`
    padding: 0 15px ;
`;

export const wrapMap=styled.div`
    height: 65%;
    padding-top: 10px;
`;