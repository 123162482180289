import createReducer from '../createReducer';
import account from './uiAccount';
import layout from './uiLayout';
import fileBase64 from './uiFileBase64';
import colorsPicker from './uiColorsPicker';
import editImage from './uiEditImage';
import tabsPopup from './uiTabsPopup';
import popOnPop from './uiPopOnPop';
import courses from './uiCourses';
import codeInput from './uiCodeInput';

import suggestions from './uiSuggestions';
import newIframe from './uiNewIframe';
import userMessage from './uiUserMessage';
import userMessageBlock from './uiUserMessageBlock';

import login from './uiLogin';
import event from './uiEvent';

const initialState = {
    artists:[],
    layout: {...layout},
    account: {...account},
    fileBase64: {...fileBase64},
    colorsPicker: {...colorsPicker},
    editImage: {...editImage},
    tabsPopup: {...tabsPopup},
    popOnPop: {...popOnPop},
    courses: {...courses},
    codeInput:{...codeInput},
    suggestions: {...suggestions},
    newIframe: {...newIframe},
    userMessage:{...userMessage},
    userMessageBlock:{...userMessageBlock},
    exportExcel:{isNotLoadAll:null},
    login:{...login},
    team:{isGuide:0},
    show:{
        isOpenEasyCourseStart:null,
        isOpenEvents:null,
        isOpenTickchakLoader:null,
        isOpenDesignPop:null,
        isOpenCreateEvent:null,
        isOpenPublishPop:null
    },
    event:{...event},
    isMasterState: false,
    pixel:{
        newPixel:{}
    },
    putXHR: null,
    isKeyboardOpen:false,
    windowWidth:window.innerWidth,
    isInlineMode: false

};

const reducer = createReducer('ui', initialState);

export default reducer;

