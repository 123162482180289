import styled from 'styled-components';
import {btn} from '../button/btn/Btn.css';
import {BGImageCenter} from '../../globalVariables/GlobalVariables';
export const wrapper = styled.div`
    height: 100%;
    width: 100%;
`;
export const buttons = styled.div`
    display: flex;
    justify-content: center;
    margin-inline-start:55px;
      ${btn}{
        ${p=>!p.theme.rtl&& 'font-size: 11px'};
        bottom:40px;
        padding:0 8px ;
        &:hover{
            transform: scale(0.9);
        }
        &.SUCCESS{
            background-image: none;
            font-size: 13px;
        }
    }
`;
export const btnIcon=styled.div`
    height: 15px;
    width: 15px;
    margin-inline-end: 5px;
     ${p=>BGImageCenter(p.icon)};
    &.LOADING, &.FAILED{
        background-image: none;
    }
    
`;
export const remove=styled.div`
    ${btn}{
        padding: 0;
        padding-top: 6px;
        border-top-color:white ;
        border-top-width:0.5px ;
        border-top-style:solid;
        width: calc(100% - 40px);
        position: relative;
        bottom: 15px;
        margin-inline-start: 20px;
        transition:all 0.3s ;
        background-color: transparent;
        border-radius: 0;
        box-shadow: none;
        justify-content: flex-start;
        font-weight: normal;
        &:hover{
            transform: scale(0.9);
        }
        &.SUCCESS{
            background-image: none;
            font-size: 13px;     
        }
    }
`;

export const main = styled.div`
    display: flex;
    height: 80px;
    margin-bottom: 12px;
`;

export const icon = styled.div`
    ${p=>BGImageCenter(p.icon,'contain')}
      margin-inline-start: 10px;
      height: 95px;
      width: 80px;
`;
export const title = styled.div`
    font-size: 18px;
    font-weight: 800;
`;