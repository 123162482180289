import styled from 'styled-components';
import zIndex from '../../globalVariables/zIndex';

export const listWrap=styled.div`

  position:${props=>props.isFixed?'fixed':'absolute'};
  width:250px;
  height: max-content;
  inset-inline-end: ${props=>props.left};
  top:${props=>props.top+'px'} ;
  z-index: ${zIndex.dropDown};
  
`;