import styled from 'styled-components';

export const values = styled.div`
    font-weight: 600;
    font-size: 14px;
    padding-inline-end: 10px;
`;

export const wrapPlus=styled.div`
    margin: 4px;
`;
export const text=styled.div`
    font-size: 11px;
    letter-spacing: 1px;
    padding: 5px 0;
 
`;
export const wrapPrice=styled.div`
  display:flex ;
  flex-flow: wrap;
  width:100% ;
  align-items:center ;
`;

export const wrapText=styled.div`
  font-weight:600 ;
`;