import styled from 'styled-components';
import * as symbols from '../assets/symbols/symbols/Symbols.css';
import * as newEventIcons from '../assets/symbols/symbols/newEventIcons';
import * as categoriesIcons from '../assets/symbols/symbols/categoriesIcons';
import * as languagesIcons from '../assets/symbols/symbols/languagesIcons';
import {BGImageCenter} from '../globalVariables/GlobalVariables';

const css={
    container:styled.div` 
        position: fixed;
        top: 0;
    `,
    group:styled.div` 
        margin-bottom: 30px;
        display: flex;
        flex-wrap: wrap;
    `,
    symbolDiv:styled.div`
        height: 40px;
        width: 40px;
        background-color: #f2e8cf;
        ${p=>BGImageCenter(p.image,'20px')}
        margin: 2px;
        `,
};

function ViewSymbols() {
    return (
        <>
            <css.container>
                <css.group>
                    {Object.keys(symbols).map(key=>
                        <css.symbolDiv key={key} image={symbols[key]}  onClick={()=>alert(key)}/>
                    )}
                </css.group>
                <div>
                        newEventIcons:
                </div>
                <css.group>
                    {Object.keys(newEventIcons).map(key=>
                        <css.symbolDiv key={key} image={newEventIcons[key]}  onClick={()=>alert(key)}/>
                    )}
                </css.group>
                <div>
                     categoriesIcons:
                </div>
                <css.group>
                    {Object.keys(categoriesIcons).map(key=>
                        <css.symbolDiv key={key} image={categoriesIcons[key]}  onClick={()=>alert(key)}/>
                    )}
                </css.group>
                <div>
                languagesIcons:
                </div>
                <css.group>
                    {Object.keys(languagesIcons).map(key=>
                        <css.symbolDiv key={key} image={languagesIcons[key]}  onClick={()=>alert(key)}/>
                    )}
                </css.group>
            </css.container>
        </>
    );
}

export default ViewSymbols;