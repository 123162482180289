import styled from 'styled-components';
import {BGImageCenter} from '../../../../globalVariables/GlobalVariables';
import {fullArrow} from '../../../../assets/symbols/symbols/Symbols.css';
import {ItemContentIcon} from '../../../../component/item/Item.css';

export const wrap = styled.div`
     font-weight: 600;
     font-size: 13px;
     border-radius: 25px;
     padding:0px 3px;
     background-color:rgba(255,255,255,0.45) ;
     width:max-content ;
     display:flex ;
     align-items:center ;
     height:28px ;
     cursor: pointer;
     transition: all 300ms;
     position: absolute;
     left: 10px;
     top: 10px;
     ${ItemContentIcon}{
          background-size: 16px;
          width: 25px;
          height: 25px;
     }
     &:hover{
          background: #FFFFFF;
     }
`;

export const icon = styled.div`
    width: 24px ;
    height: 24px;
    border-radius: 50%;
    ${p=>BGImageCenter(p.icon,'contain')}
    margin-inline-start:7px;
`;

export const arrow = styled.div`
     ${p=>BGImageCenter(fullArrow,'7px')}
     width: 21px ;
     height: 17px;

`;