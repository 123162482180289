/* eslint-disable spellcheck/spell-checker */
import styled from 'styled-components';
import {animate} from '../animation/Animation.css';
import zIndex from '../../globalVariables/zIndex';

const CIRCLE_PADDING='6px';
export const stateAnimation = styled.div`
  ${animate}{
    top: -70px;
    left:-30px;
    z-index: ${zIndex.baseRaised};
  }
`;
export const state = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    width:max-content;
    padding: 2px 8px;
    padding-inline-start: 25px;
    color:black;
    position:relative;
    text-align:left;
    cursor:pointer;
    border:1px solid #76D6E8;
    border-radius:30px;
    transition:all 300ms ;
    background-color:#FFF;
    &::after{
        content:'';
        font-size: 0;
        width:12px;
        height:12px;
        border-radius: 30px;
        background:#D5D5D5;
        inset-inline-start:${CIRCLE_PADDING};
        position:absolute;
        top: calc(50% - 6px);
        transition: width 300ms,font-size 0ms 300ms;
    }
    &.active{
        text-align:right;
        padding:2px 8px;
        padding-inline-end: 25px;
        &::after{
            background: #AED11C ;
            inset-inline-start:unset;
            inset-inline-end:${CIRCLE_PADDING};
        }
       
    }
    &.notActive{  
        text-align:left;
        padding:2px 8px;
        padding-inline-start: 25px;    
        &::after{
            ${({successAndActive,active}) => (!active && !successAndActive) && 'content: \'-\';'}
            background: #FE0022 ;
            color:white;
            font-size: 19px;
            font-weight: 600;
            text-align: center;
            line-height: 9px;
            inset-inline-start:${CIRCLE_PADDING};
        }
       
    }
    &.LOADING ,&.SUCCESS{
        &::after{
            content: '';
            width: calc(100% - (${CIRCLE_PADDING} * 3 - 1px));
            border-radius: 30px;
            background:transparent linear-gradient(270deg, #E2011F 0%, #C6D600 100%) ;
        }
        } 
        &.SUCCESS{
            &::after{
                width: calc(100% - (${CIRCLE_PADDING} * 2 - 1px)); 
             }
        }
      
:hover{
    &::after{
            box-shadow: 0 0 7px 2px #B4B4B4;
    }
}
`;
