import styled from 'styled-components';
import {imageLoader} from '../imageEditor/ImageEditor.css';

export const EditUserImgBtn = styled.button`
    position: absolute;
    width: 35px;
    height: 35px;
    bottom: 0px;
    right: -4px;
    background: #B5B5B5;
    border-radius: 50%;
    border: none;
    box-shadow: 0px 0px 3px 1px #00000029;
    cursor: pointer;
    outline: none;
    transition: transform 0.3s;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='19.06' height='19.054' viewBox='0 0 19.06 19.054'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:%23484848;%7D%3C/style%3E%3C/defs%3E%3Cpath class='a' d='M276.243,390.056a2.939,2.939,0,0,0-.855-2.073l-3.327-3.333a2.963,2.963,0,0,0-2.085-.861v.006h0a2.887,2.887,0,0,0-2.068.855q-5.259,5.233-10.495,10.489l-.006.017H257.4l-.012.012h0l-.012.011v.012h-.011l-.006.011v.011l-.011.012v.011h-.012l-.011.006-.012.017v.012l-.011.006v.006l-.012.011v.017l-.017.012v.011l-.006.011-.006.011h-.006l-.006.012h0l-.006.017-.006.006v.011l-.006.006v.012l-.011.011v.029l-.012.012v.006l-.011.023v.046l-.012.011v.023h0v6.475a.789.789,0,0,0,.791.791h6.428l.017-.006h.064l.011-.006.023-.006.023-.006h.035l.035-.023h.017l.023-.011.012-.012.017-.011.017-.012.017-.006.017-.011.023-.012.012-.011.023-.006.011-.011.012-.006.023-.011.011-.011.017-.012.011-.023.012-.017c3.5-3.506,6.994-6.989,10.494-10.489a2.945,2.945,0,0,0,.855-2.073Zm-9.669-1.825h0l.947.947-7.468,7.474-.947-.941,7.468-7.479Zm-7.8,13.03h0v-3.644l3.644,3.644Zm1.964-3.939h0l7.468-7.468,1.981,1.987-7.479,7.468-1.969-1.987Zm3.61,3.615h0l-.959-.953,7.479-7.479.947.959-7.468,7.474Zm10.321-10.881h-.011a1.328,1.328,0,0,1-.4.959l-1.317,1.323L267.7,387.11l.121-.133.479-.479.722-.722a1.374,1.374,0,0,1,.953-.4v.006a1.323,1.323,0,0,1,.947.393l3.333,3.333a1.305,1.305,0,0,1,.41.947Z' transform='translate(-383.789 276.243) rotate(-90)'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 15px 15px;
    &.unableEdit{
        pointer-events:none;
    }
    &:hover{
        transform: scale(1.1);
    }
    @media screen and (max-height: 600px){
        width: 30px;
        height: 30px;
        background-size: 12px 12px;
    }    

`;

export const EditUserTop = styled.div`
    height: max-content;
    width: 50%;
    margin: 0 auto;
    position:relative;
    ${imageLoader}{
        width:100% ;
    }
`;

export const EditUserSave = styled.div`
    height:30px;
    width:100%;
    font-size:16px;
    .save{
        float:  ${props=>(props.theme.rtl?'left':'right')};
        height: 25px;
        padding: 0 14px;
        line-height: 24px;
        margin-top: 5px;  
    }
    .success{
        font-size:16px !important;
        background-position:right center !important;
        background-size: 16px 16px !important;
        padding: 0 18px;
    }
`;

