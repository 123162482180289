import styled from 'styled-components';
import {BGImageCenter} from '../../../globalVariables/GlobalVariables';
import {op} from '../../../assets/symbols/symbols/Symbols.css';
import {link} from '../../../component/copy/Copy.css';

export const options = styled.div`
  width:20px;
  height:20px;
  cursor: pointer;
  ${link}{
    width: 26px;
  }
  >div{
    white-space:nowrap ;
  }

`;
export const optionsIcon = styled.div`

  width: 20px;
  height: 20px;
  ${BGImageCenter(op, '10px')}

`;

