import styled from 'styled-components';
import {btn as btn}    from '../button/btn/Btn.css';
import {btn as iconBtn}  from '../button/btnCircleIcon/BtnCircleIcon.css';
import {tabletMedia} from '../../globalVariables/GlobalVariables';

export const operations = styled.div`

  height:100% ;
  width:max-content ;
  display: flex;
  align-items: center;
  ${btn}{
    margin-inline-end:5px;
    ${tabletMedia}{
      width:100% ;
      margin-top: 8px;
    }
    &.gray{
      background-color:#E8E8E8 ;
    }
  }
  ${tabletMedia}{
    display: block;
    height: max-content;
  }
  ${iconBtn}{
    background-size: 80%;
    
  }
  ${p=>p.cssStyle}
  
`;
export const plus = styled.div`
    font-size: 17px;
    width: 16px;
`;
export const icons = styled.div`
  padding-inline-end: 10px;
  &.border{
    border-left: #BBBCBD 1px solid;
  }
  ${tabletMedia}{
    display:none ;
  }

`;
export const buttons = styled.div`
    padding-inline-start: 25px;
    display:flex;
    ${tabletMedia}{
      padding-inline-start: 0px;
      flex-direction:column ;
    }
`;