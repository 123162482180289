import styled from 'styled-components';
import {DARK_OLIVE_GREEN, dFlexCenter, LIGHT_BLUE3, LIGHT_BLUE_GRAY, mobileMedia, OLIVE_GREEN} from '../../../../globalVariables/GlobalVariables';
import {container, input} from '../../../../component/classes/input';
import {side as packageSide} from '../packageSettings/PackageSettings.css';

export const side=styled(packageSide)`
    height: max-content;
    ${input}{
        &.percent{
            &::before{
                content: '%'
            }
        } 
        &.ILS{
            &::before{
                content: '₪'
            }
        } 
    }
`;

export const top =styled.div`
    width: calc(100% - 40px);
    margin-inline-start: 20px;
    height: 38px;
    background-color: ${DARK_OLIVE_GREEN};
    border: 2px solid ${OLIVE_GREEN};
    border-radius: 6px;
    ${dFlexCenter}
    ${container}{
        width: fit-content;
        padding: 0px;
        font-size: 18px;
        font-weight: 800;
        ${input}{
            &::before{
                content: '%'
            }
        }
    }   
`;
export const defaultCommission = styled.div`
    font-size: 18px;
    font-weight: 800;
    ${mobileMedia}{
        font-size: 14px;
    }
`;

export const bottomInputs=styled.div`
    padding-top: 10px;
    display: flex;
    ${mobileMedia}{
        display: block;
    }
`;

export const text = styled.div`
    width: calc(100% - 40px);
    margin-inline-start: 20px;
    font-size: 14px;
    position: relative;
    padding-inline-start: 30px;
    &::before{
        content: '!';
        display: block;
        position: absolute;
        inset-inline-start: 0px;
        color: ${LIGHT_BLUE3};
        background-color: ${LIGHT_BLUE_GRAY};
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 1px solid ${LIGHT_BLUE3};
        text-align: center;
    }
`;