import {useRef} from 'react';
import Btn from '../../button/btn/Btn';
import TextEditor from '../TextEditor';
import * as css from './EditorWrapper.css';
import {useTranslation} from 'react-i18next';
import {useState} from 'react';
import useEffectOnUpdate from '../../../hooks/useEffectOnUpdate';
import {DEFAULT_LANG} from '../../../globalVariables/locals';

function EditorWrapper({events, defaultText, showToolbar=true ,limit, text = '',dependencies, lang=DEFAULT_LANG}){
    const quillRef = useRef(null);
    const {t} = useTranslation('layout');
    const [content, setContent] = useState(defaultText);
    const {onSave,onFocus}= events;
    const [hasChanged, setHasChanged] = useState(false);

    useEffectOnUpdate(()=>{
        setContent(defaultText);
    },[defaultText]);

    const firstValue =useRef('');
    const handleChange = newText=>{
        if(!firstValue.current)
            firstValue.current=newText;        
        setHasChanged(firstValue.current!==newText);
    };
 
    return (
        <css.wrap text={text || t('freeText')} data-cy="editorWrapper">
            <TextEditor onChange={handleChange}  onSave={setContent} {...{dependencies,defaultText,showToolbar, quillRef, onFocus, lang,limit}} />
            <css.saveButton><Btn disabled={!hasChanged} onClick={()=>{onSave(content); setHasChanged(false);}}>{t('save')}</Btn></css.saveButton>
        </css.wrap>
    );
}

export default EditorWrapper;