import styled from 'styled-components';
import {BGImageCenter, dFlexCenter, ellipsis} from '../../globalVariables/GlobalVariables';
import {LINE_HEIGH} from '../list/List.css';
import {simpleArrow} from '../../assets/symbols/symbols/Symbols.css';

export const ITEM_RIGHT_WIDTH='40px';
export const ITEM_HEIGHT='40px';

export const getAfterArrow=(props)=>`
    position:relative ;
    &::after{
        content:'';
        ${BGImageCenter(simpleArrow,'9px')}
        transform: rotate(${props.theme.rtl ? '90deg' : '270deg'});
        width: 20px;
        height: 20px;
        position:absolute ;
        inset-inline-end:20px;
 }`;

export const ItemContent=styled.div`
    width:${ITEM_RIGHT_WIDTH};
    height: 100%;
    ${dFlexCenter}
`;

export const ItemText=styled.div`
    font-size:16px;
    padding-inline-end: 20px;
    ${ellipsis}
    width: calc(100% - ${ITEM_RIGHT_WIDTH});
`;

export const Item=styled.div`
${ellipsis}
&.disabled{
    opacity:0.6 ;
    cursor:unset ;
    pointer-events:none;
}
    display:flex;   
    /* justify-content: flex-start; */
    align-items: center;
    min-width: 200px;
    border-bottom: 1px solid #EEE;
    cursor:pointer;
    height:${LINE_HEIGH}px;
    &:hover{
        background:#eaeaea;
    }
    
    &.bold{
        font-weight: 600;
    }
    &.arrow{
        ${p=>getAfterArrow(p)}
    }


`;

export const ItemContentIcon = styled.div`

        background-image: url("${props => (props.icon)}");
        background-repeat: no-repeat;
        /* transform: rotate(${props => (props.theme.rtl ? '90deg' : '270deg')});*/
        background-position:center center;    
        background-size: 12px;
        width: 20px;
        height: 20px;
        &.groups{
            background-size: 16px;
        }
`;

