import styled from 'styled-components';

export const wrapper = styled.div`
    width:146px;
    height: 200px;
    //margin:10px;
    border-radius: 8px;
    border: 2px dashed #76D6E8;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;
export const addGroup = styled.div`
    margin-top: 50px;
      display: flex;
      flex-direction: column;
      align-items: center;
`;
// export const addBtn = styled.div`
//     width:30px;
//     height:30px;
//     border-radius:50%;
//     background:#76D6E8;
//     color:#FFF;
//     text-align: center;
//     line-height: 30px;
//     font-weight: 500;
//     transition:all 400ms;
//     font-size:0;
//     position:relative;
//     margin: 15px;
//     &:hover{
//             transform: rotate(180deg);
//     }
//     &::after{
//         content:'+';
//         font-size:36px;
//         transition:all 400ms;
//         left: 6px;
//         position:absolute;
//     }
//
//
// `;
export const title = styled.div`
    font-weight: 600;
     margin: 10px auto;
`;