import styled from 'styled-components';
import {dFlexCenter, scrollbar}  from '../../../../../globalVariables/GlobalVariables';

export const wrapAll=styled.div`
    height:200px ;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
`;

export const wrapContentPop=styled.div`
    background: #fbfbfb;
    padding: 70px 15px 15px 15px;
    height: calc(100% - 179px);
    border-bottom: 1px solid #EEE;
    ${scrollbar} 
`;
export const bottomPop=styled.div`
   height: 70px;
   ${dFlexCenter}
`;

export const wrapDays=styled.div`
   padding: 10px 0 20px 0;
`;