import styled from 'styled-components';
import zIndex from '../../../globalVariables/zIndex';

export const menuPopUp=styled.div`
    position: absolute;
    z-index: ${zIndex.dropDown};
    box-shadow: 0px 13px 26px #00000029;
    cursor:pointer;
    border-radius: 7px;
    height: ${props => (props.cssProps.height?props.cssProps.height:'fit-content')};
    background-color:  ${props => (props.cssProps.backgroundColor?props.cssProps.backgroundColor:'#fff')};
    width: ${props => (props.cssProps.width?props.cssProps.width:'250px')};
    color: ${props => (props.cssProps.textColor?props.cssProps.textColor:'#484848')};
    ${props => props.theme.rtl ? 'left' : 'right'}:-230px;
    ${props => props.theme.rtl ? 'right:' : 'left'}:auto;
    top: ${props => (props.cssProps.top?props.cssProps.top:'0')};
    transition: all 400ms;
    opacity: 0;
    &.current{
        ${props => (props.theme.rtl ? 'left' : 'right')}:auto;
        ${props => props.theme.rtl ? 'right' : 'left' }:-208px;   
        opacity: 1;
    }
    &.show{
        ${props => (props.theme.rtl ? 'left' : 'right')}:0px;
        ${props => props.theme.rtl ? 'right' : 'left' }:auto;   
        opacity: 1;
    }
    &.show.current{
        ${props => (props.theme.rtl ? 'left' : 'right')}:auto;
        ${props => props.theme.rtl ? 'right' : 'left' }:12px;   
    }
    .menuTitle{
       color: ${props => (props.cssProps.titleColor?props.cssProps.titleColor:'#76D6E8')};
    }
    .menuItems{
        max-height: ${props => (props.cssProps.maxHeight?props.cssProps.maxHeight:'240px')};
    }
`;

export const menuTitle=styled.div`
    border-bottom: 1px solid;
    text-align: center;
    height: 45px;
    line-height: 45px;
    font-weight: 500;
`;

export const menuItems=styled.div`
    overflow: auto;
    ::-webkit-scrollbar {
        width: 0px;
    }
`; 

export const menuItem=styled.div`
    display: flex;
    height: 40px;
    line-height: 40px;
    border-bottom: 1px solid #d3d3d3;
    position: relative;
    &:hover{
        background: #E6E6E6 0% 0% no-repeat padding-box;
    }
`;
export const menuItemIcon=styled.div`
    height: 40px;
    background-repeat: no-repeat;
    width: 25px;
    background-position: center;
    margin-${props => props.theme.rtl ? 'right' : 'left'}: 14px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9.648' height='19.531' viewBox='0 0 9.648 19.531'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:none;stroke:%23484848;stroke-linejoin:round;stroke-miterlimit:10;%7D%3C/style%3E%3C/defs%3E%3Cg transform='translate(0.5 0.5)'%3E%3Cpath class='a' d='M520.589,45,513,55.5h3.53l-2.647,8.03,7.766-10.325h-3.353Z' transform='translate(-513 -45)'/%3E%3C/g%3E%3C/svg%3E");
`;
export const menuItemText=styled.div`
    margin-${props => props.theme.rtl ? 'right' : 'left'}: 12px;
    font-size: 14px;
    max-width: 102px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;
export const menuItemBtn=styled.div`
    position: absolute;
    ${props => props.theme.rtl ? 'left' : 'right'}: 10px;
    top: 8px;
    height: 25px;
    width: 25px;
    &.selected{
        background-color:#C9E52C;
        border-radius: 19px;
        :before{
            content:"";
            background-repeat: no-repeat;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48.09 31.47'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:none;stroke:%23fff;stroke-miterlimit:10;stroke-width:8px;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3Ev%3C/title%3E%3Cline class='a' x1='1.49' y1='14.68' x2='15.23' y2='29.95'/%3E%3Cline class='a' x1='46.8' y1='1.53' x2='13.23' y2='29.95'/%3E%3C/svg%3E");
            height: 15px;
            width: 16px;
            margin: 4px;
            display: block;
            background-position: center bottom;
        }
    }
    &.mailing{
        background-color:#76D6E8;
        border-radius: 19px;
        :before{
            content:"+";
            color:#fff;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 24px;
            font-size: 24px;
        }
    }
    &.garbage{
        background-color:#C9E52C;
        border-radius: 19px;
        :before{
            content:"";
            background-repeat: no-repeat;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13.625' height='16.047' viewBox='0 0 13.625 16.047'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:%23fff;%7D%3C/style%3E%3C/defs%3E%3Cg transform='translate(0 0)'%3E%3Cpath class='a' d='M88.639,759.785h10.4v-1.664H87.775v1.664h.864Zm2.033-5.011h5.46a.606.606,0,0,1,.591.615v1.5h2.9a.609.609,0,0,1,.595.614v2.907a.606.606,0,0,1-.595.615h-.869V770.2a.6.6,0,0,1-.595.619H88.639a.609.609,0,0,1-.591-.619v-9.179H87.18a.6.6,0,0,1-.586-.615V757.5a.6.6,0,0,1,.586-.614h2.91v-1.5a.6.6,0,0,1,.582-.615ZM95.542,756H91.267v.776h4.275V756Zm-4.232,5.02h0v7.138a.364.364,0,0,1-.359.372.36.36,0,0,1-.351-.372v-7.138H89.226v8.564h8.349v-8.564H96.205v7.138a.356.356,0,1,1-.71,0v-7.138H93.753v7.138a.358.358,0,0,1-.355.372.365.365,0,0,1-.351-.372v-7.138Z' transform='translate(-86.594 -754.774)'/%3E%3C/g%3E%3C/svg%3E");
            height: 20px;
            width: 25px;
            display: block;
            background-position: bottom;
        }
    }
`;

export const menuItemSelected=styled.div`
    height:40px;
    width:10px;
    background-position: center;
    background-repeat: no-repeat;
    margin-right: 10px;
    visibility:hidden;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48.09 31.47'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:none;stroke:%23c9e52c;stroke-miterlimit:10;stroke-width:8px;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3Ev%3C/title%3E%3Cline class='a' x1='1.49' y1='14.68' x2='15.23' y2='29.95'/%3E%3Cline class='a' x1='46.8' y1='1.53' x2='13.23' y2='29.95'/%3E%3C/svg%3E");
    &.show{
        visibility: visible;
    }
`;

export const menuMore=styled.div`
    height: 17px;
    &:after{
        content:'';
        float:left;
        width:100%;
        height:10px;        
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8.344' height='6.434' viewBox='0 0 8.344 6.434'%3E%3Cdefs%3E%3Cstyle%3E .cls-1 %7B fill: none; stroke: %23484848; stroke-linecap: round; stroke-width: 2px; %7D %3C/style%3E%3C/defs%3E%3Cpath id='Path_2879' data-name='Path 2879' class='cls-1' d='M233.982,21l2.766,3.434L239.514,21' transform='translate(-232.576 -19.594)'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        margin: 6px;
    }
`;