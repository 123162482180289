import styled from 'styled-components';
import {EditUserTop} from '../../../../component/circleImageEdit/CircleImageEdit.css';
import {mobileMedia, scrollbar} from '../../../../globalVariables/GlobalVariables';
import {POPUP_MOBILE_PADDING_INLINE} from '../../about/About.css';
import { List } from '../../../../component/list/List.css';

export const container = styled.div`
    height:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    ${EditUserTop}{
        display: flex;
        flex-basis: 150px;
        align-items: center;
        padding-top: 10px;
        ${mobileMedia}{
            flex-shrink: 1;
        }
    }
    ${List}{
        position: fixed;
        inset-inline-end: ${p => p.theme.rtl ? '' : '380px'} ;
    }
`;

export const wrapTeamMemberRole = styled.div`
    display:flex ;
    ${mobileMedia}{
        margin: 0 auto;
        width: max-content;
        padding-inline-start: 10px;
    }
`;    

export const inputs = styled.div`
    width: 85%;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-gap: 1px;
    ${mobileMedia}{
        width: 100%;
        display:block ;
        padding-inline:${POPUP_MOBILE_PADDING_INLINE};
        >div{
                margin-top: 10px;
            }
    }
`;   

export const text = styled.div`
    letter-spacing:1px ;
    position: absolute;
    top: 140px;
    inset-inline-start: 30px;
    font-size: 12px;
`;  

export const wrapper = styled.div`
    display:flex ;
    flex-basis: 100px;
    padding-top: 10px;
    align-items: center;
    ${mobileMedia}{
        width:90% ;
        flex-grow:1;
        flex-shrink:0;
        ${scrollbar}
    }
`;    

