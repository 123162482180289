import styled from 'styled-components';
import {success,greenCertificate, clouds} from '../../../../assets/symbols/symbols/Symbols.css';
import {mobileMedia} from '../../../../globalVariables/GlobalVariables';
import zIndex from '../../../../globalVariables/zIndex';

export const outerWrapper=styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #929292;
    &.endScreen{
        background-color: #76D6E8;
    }
`;
export const verifyWrapper=styled.div`
    width: 360px;
    height: 700px;
    ${mobileMedia}{
        width: 100%;
        height: 100%;
    }
`;
export const gray=styled.div`
    height: 19%;
    position: relative;

`;
export const LightBlue=styled.div`
    height: 17%;
    background-color: #76D6E8; 
    display: flex;
    align-items: center;
    justify-content: center;
`;
export const whiteSection=styled.div`
    height: 66%;
    position: relative;
    background-color: #fff;
`;
export const tickLogo=styled.div`
    position: absolute;
    width: 115px;
    height: 115px;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("https://static.tickchak.co.il/all/dln_00TTqZGZLlFUVpGbKBVeENXTtRXNSxmYzB3VZJUd3RGNMV3b5Z2dzRnaKZ2S_090TQK9yUjJzMKJVaupWSJFlTzJUMpN3V.png_e2eccecb-c17b-43ab-ad2b-844621a8534c.png?v=1645126231442");
`;
export const verifyLogo=styled.div`
    background-image: url("https://static.tickchak.co.il/all/dln_00DMzt0QN9SNZ5kW2FHM6l0Rt5kbadWWXJleUtSRzZ1Lp5kbURkS5MEWhpXa_090TQK9yUjJzMKJVaupWSJFlTzJUMpN3V.png_9f57238b-488e-43e9-ac72-78d27a9ffc82.png?v=1645016861476");
    background-repeat: no-repeat;
    height: 130px;
    width: 200px;
    margin: 0;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: -65px;
`;
export const LightBlueTitle=styled.div`
    margin-top: 73px;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.8px;
`;
export const whiteMain=styled.div`
    height: 86%;
    border-bottom: 1px solid #d6d2d2;
`;
export const whiteMainIn=styled.div`
    width: 86%;
    height: 100%;
    margin: 0 auto;
    padding-top: 5%;
`;
export const inTitle=styled.div`
    text-align: center;
    font-size: 13px;
    margin-bottom: 6px;
`;
export const checkbox=styled.div`
    height: 25px;
    width: 25px;
    background-color: #76d6e8;
    border-radius: 25px;
    cursor: pointer;
    &.checked{
        background-image: url("${success}");
        background-repeat: no-repeat;
        background-position:center center;    
        background-size: 10px; 
    }
`;

export const lightBlueOverAll=styled.div`
    width: 100%;
    height: 100%;
    background-color:#76D6E8;
    position: relative;
`;
export const nowCalm=styled.div`
    font-size: 33px;
    font-weight: 800;
    line-height: 31px;
    text-align: center;
    padding: 55% 3% 0;
`;
export const greenCertificateSymbol=styled.div`
    background-image: url("${greenCertificate}");
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: contain;
    height: 255px;
    width: 250px;
    margin-right: 17%;
    z-index: ${zIndex.baseRaised};
    position: absolute;
`;
export const cloudSymbol=styled.div`
    background-image: url("${clouds}");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    height: 225px;
    width: 100%;
    bottom: 0;
    left: 0;
    position: absolute;
`;