import styled from 'styled-components';
import {dragHandle, op} from '../../../../assets/symbols/symbols/Symbols.css';
import {BGImageCenter} from '../../../../globalVariables/GlobalVariables';
import zIndex from '../../../../globalVariables/zIndex';

export const wrapper = styled.div`
    width:146px;
    height: 200px;
    //margin: 10px;
    border-radius: 8px;
    box-shadow: 0 3px 6px #00000029;
    position: relative;
    cursor: pointer;
`;

export const body = styled.div`

`;
export const title = styled.div`
    text-align: center;
    margin-top: 12px;
    width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-${props => props.theme.rtl ? 'right' : 'left'}: 28px;
`;
export const tickets = styled.div`
    height: 34px;
    display: flex;
    min-height: min-content;
`;
export const ticketsWrapper = styled.div`
    flex: 1;
    display: flex;
    overflow: auto;
    cursor: pointer;
    width: 90%;
    margin: 0 3.5% 0 6.5%;
    ::-webkit-scrollbar {
        /* width: 0; */
        height: 0;
    }
`;
export const bottom = styled.div`
    position: absolute;
    bottom: 0;
    width: 100%;
`;
export const amount = styled.div`
    margin-${props => props.theme.rtl ? 'right' : 'left'}: 5px;
    float: ${props => props.theme.rtl ? 'right' : 'left'};
    line-height: 30px;
`;
export const link = styled.div`
    float: ${props => props.theme.rtl ? 'left' : 'right'};
`;
export const amountLink = styled.div`
    height: 30px;
`;
export const outerGroupProgress = styled.div`
    height: 45px;
    width: 45px;
    background-color: #FFF;
    border-radius: 23px;
    position: absolute;
    bottom: -12px;
    left: calc(50% - 20px);
`;

export const threeDots = styled.div`
    width: 30px;
    height:30px;   
    z-index:${zIndex.baseRaised};
    background-image: url("${op}");
    background-repeat:no-repeat;
    cursor:pointer;
    background-size: 20px 20px;
`;

export const topDrag = styled.div`
    height: 20px;
    width: 20px;
    ${BGImageCenter(dragHandle, '10px')}
    position: absolute;
    top: 23px;
    ${props => props.theme.rtl ? 'right' : 'left'}: 16px;
`;

