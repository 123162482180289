import styled from 'styled-components';
import {text as textCheckBox, wrapCheckbox} from '../../../../../component/checkboxFormik/CheckboxFormik.css';
import {container} from '../../../../../component/modeBtn/ModeBtn.css';
import {dFlexCenter, mobileMedia, scrollbar} from '../../../../../globalVariables/GlobalVariables';

export const wrapAll = styled.div`
  //height:100%;
  ${scrollbar};
  padding: 40px;
  ${mobileMedia}{
    padding: 40px 20px;
    }
`;

export const text = styled.div`
  font-size:11px ;
  letter-spacing:1.2px ;
  padding-bottom: 7px;
 
`;

export const wrapSettings = styled.div`
    padding-top: 45px;
`;

export const wrapGrid = styled.div`
   display:grid;
   grid-template-columns: repeat(2 , 1fr);
   grid-gap: 20px;
   padding-bottom: 20px;
   &.notShow{
    opacity : 0.5;
    pointer-events:none;
   }
   ${mobileMedia}{
     display:block ;
   }
`;

export const wrapMode = styled.div`
   display: flex;
   justify-content: space-between; 
   width:95% ;
`;

export const wrapSelect = styled.div`
  select{
    border: 0;
    height: 25px;
    border-bottom: 1px solid;
    width:100% ; 
    option:not(:first-of-type) {
      cursor: pointer;
    }
  }
`;

export const wrapCB = styled.div`
  padding-inline-start: 20px;
  height: 40px;
  ${wrapCheckbox}{
    width: fit-content;
  }
  ${textCheckBox}{
    margin-inline-start: 10px;
  }
`;

export const wrapStartEnd = styled.div`
     padding: 20px 20px;
`;

export const paymentsText = styled.div`
    font-weight:500;
    height: fit-content;
    width: fit-content;
    background: #76d6e8;
    border-radius: 5px;
    margin-bottom: 30px;
    padding: 3px 20px;
    ${dFlexCenter}
    
`;
export const paymentsTextWrap = styled.div`
   display:flex;
   justify-content:center ;
`;

export const wrapCrowd = styled.div`
  ${mobileMedia}{
    padding-bottom:15px ;
    }
`;

export const modeBtn = styled.div`
  height: 18px;
  ${container}{
    float: left;
  }
`;

export const monthInput = styled.div`
    &.notShow{
        pointer-events: none;
        opacity: 0.5;
    }
`;
