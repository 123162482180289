import styled from 'styled-components';
import {BGImageCenter} from '../../../globalVariables/GlobalVariables';
import {simpleArrow} from '../../../assets/symbols/symbols/Symbols.css';
import * as inputClasses from '../../classes/input';
import {ITEM_HEIGHT, Item} from '../../item/Item.css';

export const container = inputClasses.container;

export const icon = styled(inputClasses.icon)`
`;

export const wrap = inputClasses.wrap;

export const wrapMobileInput = styled.div`
    font-weight: 600;
    position: relative;
    width: 100%;
    height: ${ITEM_HEIGHT};
    display: flex;
    align-items: center;
    justify-content: flex-start;
`;

export const input = styled(inputClasses.input)`
    position: relative;
    display: flex;
    align-items: center;
    font-weight:${inputClasses.INPUT_WEIGHT} ;
    ${Item}{
        font-weight:400;
    }
    &::after{
        content: "";
        position: absolute;
        width: 20px; 
        height: 20px;
        cursor: pointer;
        ${props=>props.theme.rtl?'left':'right'}:0px;
        top:calc(50% - 10px);
        ${BGImageCenter(simpleArrow,'9px')}
        background-position: ${props=>props.theme.rtl?'left ':'right '} center;
    }
    input{
        position: absolute;
        top: 0;
        left: 0;
        background-color: transparent;
        border: none;
        width: 100%;
        height: 100%;
        color: transparent;   
        caret-color: black;
        &::placeholder {
            color: transparent; 
        }
    }
`;
export const title = styled(inputClasses.title)`
`;
