import styled from 'styled-components';
import {BGImageCenter, dFlexCenter} from '../../../../globalVariables/GlobalVariables';
import {filter, fullArrow} from '../../../../assets/symbols/symbols/Symbols.css';

export const sortIcon=styled.div`
   width: 50px;    
   ${BGImageCenter(filter,'15px')};
   height: 20px;

`; 

export const line=styled.div`
   display: flex;
   align-items: center;
   justify-content: space-between;
`;
export const lineSort=styled.div`
   width: max-content;
   height: 40px;
   padding-inline-end: 3px;
   position: relative;
   ${dFlexCenter}
   &::before, &::after {
    content: '';
    width: 8px;
    height: 8px;
    position: absolute;
    inset-inline-end: -10px;
    ${BGImageCenter(fullArrow,'contain')}
    top: 20px;
  }
  &::before{
   transform: rotate(180deg);
   top: 12px;
  }
`;