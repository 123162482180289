import styled from 'styled-components';
import {BGImageCenter, desktopMedia, dFlexCenter, mobileMedia} from '../../../globalVariables/GlobalVariables';
import * as Symbol from '../../../assets/symbols/symbols/Symbols.css';
import zIndex from '../../../globalVariables/zIndex';

export const LINE_MARGIN = 8;

export const basicRow=styled.div` 
    box-shadow:0px 1px 4px #00000029;
    border-radius: 5px;
    margin: ${LINE_MARGIN}px 2px 0 2px;
`;

export const placeForDrag = styled.div`
    width:0;
    transition:all 300ms ;
`;

export const wrap = styled.div`
    position: relative;
`;

export const row = styled(basicRow)`
    display:flex;
    height:${p => p.rowHeight}px;
    align-items:center;
    box-shadow:${p => (p.isDragging ? '0px 8px 14px #0000004A' : '0px 1px 4px #00000029')} ;
    border-radius: 5px;
    background-color: white;
    left: auto !important;
    top: auto !important;
    ${desktopMedia}{
        ${p => (p.isPlaceToHover && `
        &:hover{
             .dragSign{
                width:30px;
                margin-inline-end:-30px ;
                z-index:${zIndex.baseHigh};
            }
            .place{
                width:30px ;
            }
            .row_icon{
                border-radius:unset;
            }
        }
        `)}
    }
    
    &.drag{
        .dragSign{
            width:30px;
            margin-inline-end:-30px ;
            z-index:${zIndex.baseHigh};
        }
        .place{
            width:30px ;
        }
      position:absolute !important;
      transform: translate(0,${props => props.style?.transform?.slice(props.style.transform.search(',') + 1, props.style.transform.length - 1)}) !important ;
    } 
    &.checked{
        box-shadow: 0 0 0 2px #76d6e8;

        }
`;

export const select = styled.div`
    width:30px;
    height:100%;
    ${dFlexCenter}
`;

export const drag = styled.div`
    width:0;
    height:100%;
    transition:all 300ms ;
    ${BGImageCenter(Symbol.drag, '15px')}
    cursor: grab ;
    ${mobileMedia}{
        width:30px ;
        position:absolute ;
        &.select{
            width: 0;
        }
    }
`;
