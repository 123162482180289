import styled from 'styled-components';
import {animate} from '../../../../component/animation/Animation.css';
import {dFlexCenter, mobileMedia} from '../../../../globalVariables/GlobalVariables';
import {loginEllipse} from '../../components/loginInput/LoginInput.css';

export const container = styled.div`
    padding: 35px;  
    padding-top  :80px ;
    input{
        width: 40px;
        height: 50px;
        margin:0 3px;
        font-size: 16px;
    }
`;

export const animationWrap=styled.div`
position: relative;
width: 255px;
${animate}{
    top:-130px;
    inset-inline-end:-115px;

}
`;

export const title=styled.div`  
    font-size: 20px;
    font-weight: 800;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-bottom: 20px;
`;

export const text=styled.div`  
    font-weight: 500;
    font-size: 16px;
`;

export const bottom=styled.div`  
    padding-top: 20%;
    >div{
        margin-top: 20px;
    }
    ${mobileMedia}{
        padding-top: 10px;
    }
`;

export const button=styled(loginEllipse)` 
    background-color:#43BAE7 ;
    ${dFlexCenter}
    width:100%;

`;
