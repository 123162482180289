import styled from 'styled-components';
import {dFlexCenter} from '../../../../globalVariables/GlobalVariables';
import zIndex from '../../../../globalVariables/zIndex';

export const summeryText = styled.div`
    position: absolute;
    top:0;
    left:0;
    ${dFlexCenter}; 
    width:100%;
    height: 100%;
    flex-direction: column;
    transition: all 0.5s ease-in-out;
    z-index: ${zIndex.under};    
`;