import {useTranslation} from 'react-i18next';
import * as css from './SaveEventBtn.css';
import {newEventContext} from '../newEventForm/NewEventForm';
import {useContext} from 'react';
import useStep from '../StepLeft/useStep';
import useTimeout from '../../../../hooks/useTimeout';
import useEffectOnUpdate from '../../../../hooks/useEffectOnUpdate';
import {useFormikContext} from 'formik';
import Btn from '../../../../component/button/btn/Btn';
import {useSelector} from 'react-redux';
import useRestartXHR from '../../../../hooks/useRestartXHR';

function SaveEventBtn({validForm,containerRef,onSubmit, children}){
    const {t} = useTranslation('newEvent');
    const {currentStep,steps}=useContext(newEventContext);
    const {onContinue} = useStep(steps[currentStep]);
    const timer = useTimeout();
    const form=useFormikContext();
    const event = useSelector(s=>s.event);
    useRestartXHR(event.eventDuplicationXHR,'event.eventDuplicationXHR');

    useEffectOnUpdate(() => {
        if(containerRef.current && validForm) {
            timer.current=setTimeout(() => {
                containerRef.current.scrollTo({top: containerRef.current.scrollHeight, behavior: 'smooth'});
            }, 200);
        }
    }, [validForm]);

    return (
        <css.container className={validForm}>
            <Btn data-cy={`finish-${children? 'duplicateEvent' : 'createSalesPage'}`} 
                className={children ? event.eventDuplicationXHR : event.createDefaultTemplateXHR}
                onClick={
                    (e)=>{
                        onSubmit ? onSubmit(form) : onContinue(e,true);
                    }}>
                {children ? children:t('createSalesPage')}
            </Btn>
        </css.container>
    );
}

export default SaveEventBtn;
