import styled from 'styled-components';
import {dFlexCenter} from '../../../../globalVariables/GlobalVariables';
import {loginEllipse} from '../loginInput/LoginInput.css';

export const toSMS=styled(loginEllipse)` 
    cursor: pointer;
    font-size:14px;
    ${dFlexCenter}
    font-weight: 500;
    width: 100%;
    transition: all 300ms;
    max-width: 160px;
    margin: 0 auto;
    &:hover{
        background-color: rgba(255,255,255,0.25);
    }
`;

