import styled from 'styled-components';
import * as images from '../../../../../assets/symbols/images/Images.css';
import {BGImageCenter, dFlexCenter, mobileMedia, scrollbar} from '../../../../../globalVariables/GlobalVariables';
import {tableContainer} from '../../../../../component/newTable/Table.css';
import zIndex from '../../../../../globalVariables/zIndex';

export const content = styled.div`
    padding: 35px 40px;
    height: 100%;
    overflow: auto;
    ${scrollbar} 
    .row{
        margin-top: 12px;
    }
    ${mobileMedia}{
        height: calc(100% - 70px);
    }
`;
export const wrapTable = styled.div`
    ${tableContainer}{
        z-index: ${zIndex.baseRaised};
    }
    display: flex;
    justify-content: center;
    .tableContainer{
        overflow:unset ;
    }
`;
export const wrapPlus = styled.div`
    display:flex ;
    align-items: center;
    height: 40px;
`;
export const text = styled.div`
    font-weight:500 ;
    letter-spacing:1px;
    padding:0 10px 35px 10px;
    font-size:13px ;
`;
export const textSmall = styled(text)`
  padding: unset;
  padding-inline-start: 10px;
`;
export const wrapForm=styled.div`
 //height:100% ;
 //  form{
 //    height:100% ;
 //  }
`;

export const extensionsImage=styled.div`
    width:140px;
    height:180px;
  margin-top: 22px;
    ${BGImageCenter('', '140px')}
    background-image: url("${p=>images[p.image]}");
    &.extensionsTextTicket{
        width: 230px;
        height: 179px;
        background-size: 220px;
    }
`;

export const wrapImage=styled.div`
    ${dFlexCenter};
    flex-direction:column ;
    height: calc(100% - 70px);
    overflow: auto;
    ${scrollbar} 
  
`;

export const container = styled.div`
    height:100%;
`;

export const ballImage=styled.div`
    width:140px;
    height:180px;
    ${BGImageCenter(images.ballGame, '140px')}
`;
export const extension=styled.div`
    position:relative ;
    width: fit-content;
    height: 31px;
    border-radius: 22px;
    border: 1px solid #747474;
    background-color: transparent;
    padding: 3px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition:all 200ms;
    margin-top: 20px;
`;

export const plus = styled.div`
        width:25px;
        height: 25px;
        border-radius: 50%;
        box-shadow:unset;
        background-color:#76D6E8;
        ${dFlexCenter};
        cursor: pointer;
        &:hover{
            background-color:#FFF;
            border:1px solid #76D6E8;
        }
        &::after{
            content: "+";
            color: #000;
            font-size: 20px;
            font-weight: 600;
        }
`;
