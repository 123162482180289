import styled from 'styled-components';
import {staticActive, staticInActive} from '../../assets/symbols/symbols/Symbols.css';
import {BGImageCenter} from '../../globalVariables/GlobalVariables';

export const wrapper = styled.div`
    display: flex;
`;

export const icon = styled.div`
    height: 15px;
    width: 20px;
    ${BGImageCenter(staticInActive, 'contain')};
    &.active{
        ${BGImageCenter(staticActive, 'contain')};
    }
`;

export const text = styled.div`
      &.active{
       color: #76D6E8;
       font-weight: 700;
    }
`;

