import styled from 'styled-components';
import {success} from '../../assets/symbols/symbols/Symbols.css';

export const checkbox=styled.div`
    height: 25px;
    width: 25px;
    background-color: #76d6e8;
    border-radius: 25px;
    cursor: pointer;
    &.checked{
        background-image: url("${success}");
        background-repeat: no-repeat;
        background-position:center center;    
        background-size: 10px; 
    }
`;