/* eslint-disable spellcheck/spell-checker */
import styled from 'styled-components';
import {tabletMedia} from '../../../../globalVariables/GlobalVariables';
import {tooltip} from '../../../../component/tooltip/tooltipAbsolute/TooltipAbsolute.css'; 

export const EMSFrame=styled.div`
    height:${props=>props.theme.mini?'60px;':'40px;'}
    ${props=>!props.theme.mini&&'display: flex;' +
    '    display: -webkit-box;' +
    '    display: -webkit-flex;' +
    '    display: -ms-flexbox;'}
    div{
        color:#D0D0D0;
    }
`;
export const EMSFrameTitle=styled.div`
    line-height: 12px;
    width: ${props=>props.theme.mini? '0':'45px'};
    font-size: ${props=>props.theme.mini?'0':' 14px'};
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
    text-align: center;
    -webkit-letter-spacing: -0.5px;
    -moz-letter-spacing: -0.5px;
    -ms-letter-spacing: -0.5px;
    letter-spacing: -0.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 1000px){
        width:40px;
        padding-bottom:13px;
        font-size: 10px;
        letter-spacing: 0;
    }
     
`;
export const EMSFrameContent=styled.div`
    ${props=>!props.theme.mini&&'display: flex;' +
    '    display: -webkit-box;' +
    '    display: -webkit-flex;' +
    '    display: -ms-flexbox;' +
    '    align-items: center;'}
    font-weight:200;
    /* &.today{
       font-weight:600; 
    } */
    &.cash{
        ${props=>props.theme.mini&&'position:relative;top:10px'};
        width: ${props=>props.theme.mini? '100%':'calc(100% - 40px)'};
        font-size: 22px;
        font-weight:800; 
        @media screen and (max-width: 1000px){
            width:100%;
        }
    }
    &.future{
        ${tabletMedia}{
        width: 100%;
        padding-inline-start: 20px;
        }
    }
`;
const images= {
    'orders':"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='27.829' height='26.799' viewBox='0 0 27.829 26.799'%3E%3Cdefs%3E%3Cstyle%3E .cls-1 %7B fill: %23fff; %7D %3C/style%3E%3C/defs%3E%3Cpath id='Path_2226' data-name='Path 2226' class='cls-1' d='M1.078,3.644H7.623V2.7A2.706,2.706,0,0,1,8.411.8h.008A2.681,2.681,0,0,1,10.324,0h7.182a2.7,2.7,0,0,1,1.912.8h0A2.706,2.706,0,0,1,20.208,2.7v.941h6.543a1.07,1.07,0,0,1,1.078,1.063V15.783a1.087,1.087,0,0,1-.842,1.047v8.9a1.069,1.069,0,0,1-1.07,1.064h-24A1.061,1.061,0,0,1,.851,25.735v-8.9A1.087,1.087,0,0,1,0,15.783V4.707A1.073,1.073,0,0,1,1.078,3.644Zm7.834,0h10.02V2.7a1.44,1.44,0,0,0-.426-1.01h0a1.361,1.361,0,0,0-1-.4h-7.18a1.361,1.361,0,0,0-1,.4h0A1.457,1.457,0,0,0,9.229,2.7v.941Zm2.6,9.139h4.806a.631.631,0,0,1,.631.638v1.291h8.738V5.777H2.141v8.936h8.734V13.422a.638.638,0,0,1,.645-.638Zm5.437,4.076h0v1.359a.632.632,0,0,1-.631.645H11.516a.64.64,0,0,1-.642-.643V16.862h-7.9v7.8H24.848v-7.8Zm-3.045-2.785H12.161v3.5h3.508v-3.5Z'/%3E%3C/svg%3E%0A",
    'cash':"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='25.876' height='28.52' viewBox='0 0 25.876 28.52'%3E%3Cdefs%3E%3Cstyle%3E .cls-1 %7B fill: %23fff; %7D %3C/style%3E%3C/defs%3E%3Cpath id='Path_260' data-name='Path 260' class='cls-1' d='M25.6,26.81A9.51,9.51,0,0,1,24.212,23.9a10.1,10.1,0,0,1-.625-3.4v-5.2a10.42,10.42,0,0,0-3.122-7.5A10.067,10.067,0,0,0,17,5.508a6.41,6.41,0,0,0-1.873-.555l3.054-2.984a1.111,1.111,0,0,0,.347-.763A1.1,1.1,0,0,0,17.415.095H8.464A1.111,1.111,0,0,0,7.7.442a1.174,1.174,0,0,0,0,1.6l3.054,2.984a9.586,9.586,0,0,0-1.873.555,10.067,10.067,0,0,0-3.47,2.3,9.766,9.766,0,0,0-2.29,3.469A9.4,9.4,0,0,0,2.289,15.3v5.2a10.1,10.1,0,0,1-.623,3.4A7.767,7.767,0,0,1,.278,26.74,1.043,1.043,0,0,0,0,27.5,1.1,1.1,0,0,0,1.11,28.614H24.767a1.338,1.338,0,0,0,.694-.208,1.111,1.111,0,0,0,.139-1.6ZM14.708,2.25h0l-1.8,1.8-1.8-1.8ZM3.054,26.393h0a13.9,13.9,0,0,0,.694-1.8A15.108,15.108,0,0,0,4.511,20.5v-5.2A8.18,8.18,0,0,1,5.136,12.1,7.311,7.311,0,0,1,7.009,9.4,8.643,8.643,0,0,1,9.717,7.523a8.792,8.792,0,0,1,3.26-.625h0a8.435,8.435,0,0,1,3.191.625A8.643,8.643,0,0,1,18.874,9.4a8.384,8.384,0,0,1,2.5,5.966v5.2a12.514,12.514,0,0,0,.763,4.093,8.288,8.288,0,0,0,.763,1.8H3.054Zm11.1-6.8h-.9a.317.317,0,0,1-.278-.347V16.264H12.35a8.218,8.218,0,0,0-1.873.139,1.545,1.545,0,0,0-.625,1.179v4.648a.317.317,0,0,1-.278.347h-.9a.317.317,0,0,1-.278-.347v-4.3a4.065,4.065,0,0,1,.694-2.29,2.529,2.529,0,0,1,2.012-.833,25.47,25.47,0,0,1,3.191.208c.069,0,.069.069.069.139v4.093a.242.242,0,0,1-.208.348Zm1.873,2.29a2.529,2.529,0,0,1-2.012.833,25.47,25.47,0,0,1-3.191-.208c-.069,0-.069-.069-.069-.139V18.554a.317.317,0,0,1,.278-.347h.9a.317.317,0,0,1,.278.347V21.26h.9a5.717,5.717,0,0,0,1.666-.139,1.546,1.546,0,0,0,.625-1.179V15.363a.317.317,0,0,1,.278-.347h.9a.317.317,0,0,1,.278.347v4.3a4.135,4.135,0,0,1-.838,2.22Z' transform='translate(0.001 -0.095)'/%3E%3C/svg%3E%0A",
    'tickets':"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 41.36 41.33'%3E%3Cdefs%3E%3Csymbol id='a' viewBox='0 0 41.37 41.33'%3E%3Crect x='1.5' y='1.5' width='38.36' height='38.33' style='fill:none;stroke:%23fff;stroke-linejoin:round;stroke-width:3px'/%3E%3Crect x='6.73' y='7.11' width='11.13' height='11.13' style='fill:none;stroke:%23fff;stroke-miterlimit:10;stroke-width:2px'/%3E%3Crect x='10.04' y='10.51' width='4.31' height='4.31' style='fill:%23fff'/%3E%3Crect x='13.29' y='26.27' width='6.25' height='6.25' style='fill:%23fff'/%3E%3Crect x='25.79' y='26.27' width='6.25' height='6.25' style='fill:%23fff'/%3E%3Crect x='19.54' y='20.02' width='6.25' height='6.25' style='fill:%23fff'/%3E%3Crect x='25.79' y='13.77' width='6.34' height='6.25' style='fill:%23fff'/%3E%3Crect x='32.04' y='1.88' width='6.34' height='6.25' style='fill:%23fff'/%3E%3Cpolygon points='13.35 38.53 0.85 38.55 0.84 32.3 13.34 32.28 13.35 38.53' style='fill:%23fff'/%3E%3C/symbol%3E%3C/defs%3E%3Ctitle%3Ecard-icon%3C/title%3E%3Cuse width='41.36' height='41.33' xlink:href='%23a'/%3E%3C/svg%3E",

};
export const EMSFrameImage=styled.div`
    background-image: url("${props => (images[props.className])}");
    background-repeat:no-repeat;
    background-size: 12px 12px;
    background-position: center center;
    ${props=>(props.theme.mini?'background-size: 15px 15px;':'')}
    width:${props=>props.theme.mini? '100%':'20px'};
    height:20px;
    &.cash{
        margin-top:${p=>p.theme.mini&&'20px'};
    }
    
`;
export const EMSFrameText=styled.div`
position: relative;
${tooltip}{
    &::before {
        inset-inline-end:5px;
    }
}
&.hasChildren{
    width: 100%;
}
    width:65px;
    text-align: center;
    font-size:16px;
    @media screen and (max-width: 1000px){
        width: 47px;
        font-size: 15px;
        text-align: right;
    }
`;