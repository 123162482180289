import styled from 'styled-components';
import {DEFAULT_COLOR, dFlexCenter, mobileMedia} from '../../../globalVariables/GlobalVariables';
import {btn} from '../../button/btn/Btn.css';

export const container = styled.div`
    width: 100%;
    ${dFlexCenter}
    flex-direction: column;
    `;

export const title = styled.div`
    letter-spacing: 0.7px;
    font-size: 25px;
    font-weight: 700;  
    ${mobileMedia}{
        padding-top: 20px;
    }
`;

export const description = styled.div`
    font-size: 15px;
    padding-block: 20px;
`;
export const images = styled.div`
    height: 170px;
    display: flex;
    align-items: flex-end;
    width: 100%;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
`;
export const styles = styled.div`
    ${btn}{
        background-color: #FFFFFF;
        &.active{
            background-color: ${DEFAULT_COLOR};
        }
    }
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
`;