import styled from 'styled-components';
import {BLACK,  GRAY92,  LIGHT_GRAY2} from '../../../../globalVariables/GlobalVariables';
import realtimeFonts from '../../fonts';
import {getColor} from '../../eventRealtime/EventRealtime.css';

export const section = styled.div`
  flex: 1;
  padding-inline: 20px;
  border-left: 1px solid ${LIGHT_GRAY2};
  &:last-child {
    border-left: none;
  }
`;
export const title = styled.div`
  ${realtimeFonts.windowTitle}
  color: ${p=>getColor(p.theme.isDarkMode)};
  margin-bottom: 5px;
`;

export const text = styled.div`
  width: max-content;
  padding: 2px 14px;
  font-weight: bold;
  background: ${GRAY92};
  color: ${BLACK};
  border-radius: 11px;
  text-align: center;
`;

export const amount = styled.div`
  ${realtimeFonts.value};
  margin-bottom: 10px;
`;
