import styled from 'styled-components';
import {BGImageCenter, dFlexCenter, mobileMedia} from '../../globalVariables/GlobalVariables';
import {desctop, mobile, post} from '../../assets/symbols/symbols/Symbols.css';

export const container = styled.div`
    display :flex;
    padding: 40px 10px ;
    height: 380px ;
    ${mobileMedia}{
        height: 650px ;
        flex-direction: column;
        align-items: center;
    } 
`;

export const sceenTypes = styled.div`
    width: 15%;
    display :flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    ${mobileMedia}{ 
        flex-direction:row;
        width: 80%;
        padding: 0 0 20px 0;
    } 
`;
export const sceenTypeItem = styled.div`
    cursor: pointer;
    height: 80px;
    width:65px;
    ${dFlexCenter}
    flex-direction: column;
    &::before{
        content:"";
        ${BGImageCenter(null, '25px')}
        width:35px;
        height:35px;
    }
    &.checked{
        background-color:#FFFFFF36 ;
        border-radius: 5px;
        border: 1px solid #707070;

    }
`;

export const mobileIcon = styled(sceenTypeItem)`
    &::before{
        background-image: url("${mobile}");
        background-size: 18px;
    }
`;
export const descIcon = styled(sceenTypeItem)`
    &::before{
        background-image: url("${desctop}");
    }
`;
export const postIcon = styled(sceenTypeItem)`
    &::before{
        background-image: url("${post}");
    }
`;
export const description = styled.div`
    width: 50%;
    padding:${p => p.length > 1 ? '30px 10px':'80px 10px 30px 10px'};
    display :flex;
    flex-direction: column;
    justify-content: space-between;
    white-space: pre-line;
    ${mobileMedia}{
        width: 100%;
        padding: 60px 0 0 0;
    } 
`;

export const sizeDesciption = styled.div`
    letter-spacing: 2px;
    ${mobileMedia}{
        padding: 30px 0px;
    } 
`;
export const imageEditor = styled.div`
    width:50%;
    ${dFlexCenter}
    ${mobileMedia}{
        ${p=>p.length && 'margin-block-start:65px;'};
    }
`;
export const wraper = styled.div`
   display: flex;
   width: 85%;
   position: relative;
   ${mobileMedia}{ 
        flex-direction: column;
        align-items: center;
        flex-direction: column-reverse;
    } 
`;
export const langbtn = styled.div`
    ${dFlexCenter}
    width: 70px;
    color: #CACACA;
    cursor: pointer;
    box-shadow: inset -1px 0 0 #CACACA;
    font-size: 11px;
    font-weight: bold;
    &.selected{
        color: black;
        background-color: #CACACA;
    }

`;
export const wrapLangsBtn = styled.div`
    display:flex;
    border-color: #CACACA;
    border: 1px solid;
    border-radius: 20px;
    height: 25px ;
    width:max-content;
    overflow: hidden;
    ${mobileMedia}{ 
        position:absolute;
        top:10px;
        left:50%;
        transform: translate(-50%);
    } 
`;