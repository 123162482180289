import styled from 'styled-components';
import {eye, hideEye} from '../../../assets/symbols/symbols/brandingIcons';
import {BGImageCenter} from '../../../globalVariables/GlobalVariables';
export const wrapper = styled.div`
    position: relative;
    width: inherit;
`;
export const hide=styled.div`
    opacity: 0.5;
    div{
      pointer-events: none;
    }
`;
export const eyeSection = styled.div`
    width: 30px;
    height: 30px;
    ${p=>BGImageCenter(p.show? eye : hideEye,'20px')};
    cursor: pointer;
    position: absolute;
    inset-inline-end: 0;
    bottom: 0;
`;