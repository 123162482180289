/* eslint-disable spellcheck/spell-checker */
import styled from 'styled-components';
import {add, remove} from '../../../assets/symbols/symbols/Symbols.css';
import {getGridArea} from '../../../functions/cssFenctions';
import {BGImageCenter, dFlexCenter, mobileMedia, scrollbar} from '../../../globalVariables/GlobalVariables';
import {title} from './socialNetwork/SocialNetwork.css';

export const POPUP_PADDING_INLINE='40px';
export const POPUP_MOBILE_PADDING_INLINE='30px';

export const container=styled.div` 
    padding: 20px ${POPUP_PADDING_INLINE};
    display: grid;
    grid-template-areas:"about          about"
                        "address        education"
                        "quote          certificate"
                        "galleryTitle   galleryTitle"
                        "gallery        gallery"
                        "networkTitle   networkTitle"
                        "network        network";
    column-gap: 30px;
    grid-template-columns: 1fr 1fr;
    ${getGridArea(['about','address','education','quote','network'])}
    ${mobileMedia}{
            display: flex;
            flex-direction: column;
            padding-inline:${POPUP_MOBILE_PADDING_INLINE};
            >div{
                margin-top: 10px;
            }
    }
`;

export const galleryTitle=styled(title)` 
        grid-area:galleryTitle;
`;

export const gallery=styled.div` 
    grid-area: gallery;
    background-color: #FAFAFA;
    width: 100%;
    padding: 15px;
        display :flex ;
    position: relative;
    ${scrollbar}
    &::before{
        content:"${p=>p.title}";
        position: absolute;
        top:-20px;
        letter-spacing: 1px;
        font-size: 12px;
        font-weight: 500;
    }
    ${mobileMedia}{
        margin-top: 10px !important;
    }
`;
const imageBox=styled.div`    
    border-radius: 5px;
    box-shadow: 0px 3px 6px #00000029;
    margin: 3px;
    height: 60px;
    width: 60px;
    flex-shrink: 0;
    `;

export const image=styled(imageBox)`
    ${p=>BGImageCenter(p.image,'contain')}
    ${dFlexCenter}
`;

export const removeImage=styled.div`  

            width:30px;
            height: 30px;
            opacity: 0;
            background-color: transparent;
            transition: background-color 400ms;
            ${BGImageCenter(remove,'9px')}

    &:hover{
            opacity: 1;
            background-color: #ddd;
            border-radius:50%;
            cursor: pointer;

    }

`;
export const addImage=styled(imageBox)`  
    ${BGImageCenter(add,'10px')}
    border: 1px dashed #707070;
    box-shadow: 0px 3px 6px #00000029;  
    position: relative;
    .dropzone{
        height: 100%;
        width:100%;
        position: absolute;

    }
`;