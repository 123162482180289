import styled from 'styled-components';
import zIndex from '../../../globalVariables/zIndex';
import {wrap as wrapEditor} from '../TextEditor.css';
import {STRONG_BLUE} from '../../../globalVariables/GlobalVariables';
import {btn} from '../../button/btn/Btn.css';
export const wrap = styled.div`
    border: 2px solid ${STRONG_BLUE};
    border-radius: 10px;
    ${wrapEditor}{
        border: none;
    }
    position: relative;
    width: 95%;
    &::before{
      content:'${p=>p.text}';
      position: absolute;
      top: -9.5px;
      inset-inline-start: 30px;
      background: #FFFFFF;
      padding-inline:20px 7px;
      width: max-content;
      z-index: ${zIndex.baseRaised};
      font-size:12px;
      font-weight: 600;
      display: flex;
      align-items: center;
    }
    &::after {
      content: '';
      position: absolute;
      top: -6px; 
      inset-inline-start: 35px;
      width: 10px;
      height: 10px; 
      background-color: ${STRONG_BLUE};
      border-radius: 2px; 
      z-index: ${zIndex.baseRaised};
    }
     
`;
export const saveButton = styled.div `
    width: 100%;      
    display: flex;
    flex-direction: row-reverse;
    padding: 10px;
    ${btn} {
      width: 100px;
    }
`;
