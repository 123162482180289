import styled from 'styled-components';

export const questionContiner = styled.div`
  padding: 20px;
    
    >span{
        color: blue;
        cursor: pointer;
    }
`;

