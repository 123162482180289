import styled from 'styled-components';
import {popUpBody} from '../../../component/newPopup/mobilePopup/MobilePopup.css';
import {mobileMedia} from '../../../globalVariables/GlobalVariables';

export const container=styled.div`
    font-size: 16px;
    white-space: break-spaces;
    padding: 0 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 250px;
    ${mobileMedia}{
        height: 460px;
    }
`;

export const wrapper=styled.div`
   ${popUpBody}{
        min-height: 330px;
   }
`;