import * as css from './TicketTable.css';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import setGlobalState from '../../../../redux/setGlobalState';
import {getEventTickets, getSearchTickets, updateTicketWeight} from '../../../../redux/tickets/ticketsActions';
import updateArrayAfterDrag from '../../../../functions/updateArrAfterDrag';
import DragTable from '../../../../component/newTable/dragTable/DragTable';
import useMobile from '../../../../hooks/useMobile';
import CourseRow from '../ticketRow/CourseRow';
import ClassicTicketRow from '../ticketRow/ClassicTicketRow';
import {useContext} from 'react';
import {TableContextValue} from '../../../components/tableContext/TableContext';
import {mobileMedia} from '../../../../globalVariables/GlobalVariables';

function TicketTable() {
    const {data,searchArray}= useContext(TableContextValue);
    const tickets = data;
    const eventType = useSelector(state => state.event.type);

    const search={
        onSearch: val => getSearchTickets(val),
        onSearchEnd:() => setGlobalState('tickets.searchTickets', []),
        searchState:{
            setIsSearchState: val => setGlobalState('tickets.isSearchState', val), 
            isSearchState: tickets.isSearchState
        }
    };

    const typeProps={
        course:{
            RowComp:CourseRow,
            searchProps:search
        },
        ticket:{
            RowComp:ClassicTicketRow,
            searchProps:false
        },
    };

    const {title,RowComp,searchProps}=typeProps[eventType]||typeProps.ticket;

    const {t} = useTranslation('ticket');
    const isMobile = useMobile();

    const onScrollEnd = () => {
        if(!tickets.isAcceptedAll)
            getEventTickets().then();
    };

    const onDragEnd = async (drag) => {
        const newArr = updateArrayAfterDrag(drag, tickets);
        await updateTicketWeight(newArr);
    };
    const cssStyle = `
        top: 20px;
        inset-inline-end: -40px;
        ${mobileMedia}{
            inset-inline-end: -2px;
        }
    `;
    return (
        <css.table>
            <DragTable rowHeight={isMobile?115:70} scrollProps={{onScrollEnd, toShowLoader: (searchArray?tickets.getSearchTicketsXHR==='LOADING': !tickets.isAcceptedAll), XHR: tickets.getEventTicketsXHR}}
                onDragEnd={onDragEnd} title={t(title)}
                list={(searchArray?searchArray:tickets?.tickets)?.map(ticket => (
                    {
                        comp: RowComp, 
                        props: {ticket},
                        key: ticket.tid
                    })
                )}
                searchProps={searchProps} 
                customScrollbar={{cssStyle}}
            />
        </css.table>

    );
}
export default TicketTable;
