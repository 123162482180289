import styled from 'styled-components';
import {BGImageCenter, BLACK, LIGHT_GRAY2, LIGHT_GRAY3, mobileMedia} from '../../../globalVariables/GlobalVariables';
import {teamMemberPhoto} from '../../../assets/symbols/images/teamMemberPhoto';
import {arrowBtn} from '../../../assets/symbols/symbols/Symbols.css';
import {cssEscape} from '../../../library/charEscape';

export const wrapper=styled.div`
    width: 90%;
    display: flex;
    position: relative;
`;

export const profile=styled.div`
    height: 35px;
    width: 35px;
    ${BGImageCenter(teamMemberPhoto,'contain')}
    border-radius: 50%;
    border: 2px solid ${BLACK};
    margin-inline-end: 8px;
`;

export const css=`
    position: absolute;
    font-size: 11px;
    color: ${LIGHT_GRAY3};
`;

export const text=styled.div`
    font-size: 13px;
    height: max-content;
    border-radius: 30px;
    background: ${LIGHT_GRAY2} 0% 0% no-repeat padding-box;
    width: calc(100% - 43px);
    padding: 10px;
    &::before{
        content:"${p=>(cssEscape(p.date))}";
        top: -17px;
        ${css}
    }
    &::after{
        content:"${p=>(cssEscape(p.time))}";
        bottom: -17px;
        left: 4px;
        ${css}
    }
`;

export const contact=styled.div`
    min-height: max-content;
    padding: 10px;
    flex-direction: column;
`;

export const title=styled.div`
    width: calc(100% - 43px);
    height: 40px;
    background: ${LIGHT_GRAY2} 0% 0% no-repeat padding-box;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    font-weight: bold;
`;

export const arrow=styled.div`
    height: 25px;
    width: 25px;
    ${BGImageCenter(arrowBtn,'6px')}
    transform: rotate(180deg);
`;

export const wrapAll=styled.div`
   padding-inline: 10px;
   ${mobileMedia}{
        padding-inline: 30px;
   }
`;

