import styled from 'styled-components';
import {BGImageCenter, LIGHT_SHADOW_COLOR} from '../../../../globalVariables/GlobalVariables';

export const item = styled.div`
    position: relative;
    height: 44px;
    width: calc(100% - 80px);
    margin: 8px auto;
    border-radius: 33px;
    box-shadow: 2px 5px 10px 2px ${LIGHT_SHADOW_COLOR};
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
export const rightPart = styled.div<{ icon?: string; }>`
    position: relative;
    inset-inline-start: 20px;
    display: flex;
    &::before{
        content: '';
        display: block;
        height: 20px;
        width: 20px;
        position: relative;
        inset-inline-start: -10px;
        ${p=>BGImageCenter(p.icon, 'cover')}
    }
`;

export const leftPart = styled.div`
    display: flex;
    align-items: center;
    &::after{
        content: '';
        display: block;
        height: 12px;
        width: 12px;
        position: relative;
        inset-inline-end: -5px;
        border-radius: 50%;
        background-color: ${p=>p.color};
    }
`;