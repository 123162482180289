import styled from 'styled-components';
import {arrowDark} from '../../../../../assets/symbols/symbols/Symbols.css';
import {close, title} from '../../../../../component/newPopup/innerPop/InnerPop.css';
import {BGImageCenter, mobileMedia} from '../../../../../globalVariables/GlobalVariables';

export const wrapAll = styled.div`
  height:100% ;
`;

export const wrapInputs = styled.div`
   width:60% ;
   padding: 0 20px;
   ${mobileMedia}{
     width:100% ;
     padding:0 ;
    }
`;

export const wrapCourse = styled.div`
`;

export const wrapImage = styled.div`
    height: max-content;
    padding-top:15px;
    width:40% ;   
    display    :flex ;
    justify-content: flex-end;
    padding-inline-end: 43px;

   ${mobileMedia}{
    justify-content: center;
     width:100% ;
     padding: 45px;
    }
`;

export const container = styled.div`
  height:100% ;
`;

export const wrapDate = styled.div`
    width: 97%;
    padding: 10px 40px 0 40px;
    ${mobileMedia}{
      padding: 0 20px;
    }
`;

export const firstPart=styled.div`
    padding: 25px 20px 0;
    display:flex ;
    ${mobileMedia}{
          display:block ;
    }
`;

export const guideTop=styled.div`
    display: flex;
    justify-content: space-between;
`;
export const text=styled.div`
    font-size: 11px;
    letter-spacing: 1px;
    padding: 5px 0;
 
`;

export const wrapPrice=styled.div`
  display:flex ;
  flex-flow: wrap;
  width:100% ;
  align-items:center ;
  padding-bottom:20px ;
`;
export const wrapPlus=styled.div`
    margin: 4px;
`;
export const price=styled.div`
   padding: 15px 0;
`;
export const payments=styled.div`
    padding: 10px 0 0 0;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    ${mobileMedia}{
     display:block ;
    }
`;

export const wrapForm=styled.div`
 //height:100% ;
 //  form{
 //    height:100% ;
 //  }
 ${title}{
  color:#484848
 }
 ${close}{
  ${BGImageCenter(arrowDark,'16px')}
}
`;