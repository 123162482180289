import * as css from './LeadsTable.css';
import Table from '../../../component/newTable/Table';
import LeadRow from './leadRow/LeadRow';
import {useContext} from 'react';
import {TableContextValue} from '../../components/tableContext/TableContext';
import {mobileMedia} from '../../../globalVariables/GlobalVariables';

function LeadsTable({leadPopOpen}) {
    const {searchArray}= useContext(TableContextValue);
    const cssStyle = `
        top: 20px;
        inset-inline-end: -40px;
        ${mobileMedia}{
            inset-inline-end: 0px;
        }
    `;
    return (
        <css.wrapper data-cy="leadsTable">
            <Table
                list={searchArray?.map(lead => ({comp: LeadRow, props: {lead,leadPopOpen}, key: lead.ref}))} customScrollbar={{cssStyle}}/>
        </css.wrapper>
    );
}
  
export default LeadsTable;
