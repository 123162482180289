import styled from 'styled-components';
import {BGImageCenter, DEFAULT_COLOR, mobileMedia} from '../../../globalVariables/GlobalVariables';
import {arrowBtn} from '../../../assets/symbols/symbols/Symbols.css';
import {courseFrame} from '../../../assets/symbols/images/Images.css';
import {popUpTop} from '../../../component/newPopup/topPopup/TopPopup.css';
import {Close} from '../../../component/close/Close.css';
import {btn} from '../../../component/button/btn/Btn.css';
import {popUpBody} from '../../../component/newPopup/mobilePopup/MobilePopup.css';
import zIndex from '../../../globalVariables/zIndex';
export const createCourse=styled.div`
  ${popUpTop} {
    background-color: ${DEFAULT_COLOR};
    border-radius: 8px 8px 0 0 ;
  }

  ${Close} {
    background-color: rgba(199, 236, 250, 0.38);
    border-radius: 50%;
    width: 34px;
    height: 34px;
    min-width: 34px;
    margin: 10px;
  }
  ${popUpBody}{
    overflow: visible;
  }


`;
export const container=styled.div`
 height: calc(100% - 160px);
  width: 100%;
  position: relative;
  background-color: ${DEFAULT_COLOR};
  padding:  80px 50px;
  padding-inline-end: 50%;
  ${mobileMedia}{
    text-align: center;
    padding:  80px 50px;
  }
  &::before{
    content: '';
    width: 410px;
    height: 375px;
    position: absolute;
    inset-inline-end: 3px;
    top: -150px;
    z-index: ${zIndex.baseHighest};
    ${BGImageCenter(courseFrame,'contain')}
    ${mobileMedia}{
      width: 220px;
      height: 200px;
      left: calc(50% - 110px);
      top: -145px;
      
    }
  }
  
`;

export const title=styled.div`
  font-size: 38px;
  font-weight: 800;
  letter-spacing: 2px;
  ${mobileMedia}{
    font-size: 23px;
  }
`;
export const text=styled.div`
  font-size: 22px;
  font-weight: 500;
  ${mobileMedia}{
    font-size: 16px;
  }
`;

export const actions =styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100px;
  padding-inline: 37px;
  ${mobileMedia}{
    padding-inline: ${p=>p.theme.rtl?'22px':'5px'};
    font-size: 12px;
  }
  ${btn}{
    padding: 0px 10px;
    padding-inline-end: 22px;
    ${mobileMedia}{
      font-size: 12px;
    }
    &::before{
      content:'';
      ${BGImageCenter(arrowBtn,'auto')}
      background-position: ${p=>p.theme.rtl?'2px':'calc(100% - 2px)'} center;
      transform: rotate(${p=>p.theme.rtl?'0deg':'180deg'});
      height: 16px;
      width: 16px;
      position: absolute;
      inset-inline-end: 1px;
    }
    }
 
`;

