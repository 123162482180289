import styled from 'styled-components';
import {Template} from '../Template.css';

export const EventTemplate = styled.div`
  width: calc(100% - 40px);
  height: 108px;
  background-color: #FFFFFF;
  border-radius: 6px;
  margin: auto;
  margin-bottom: 10px;
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  &.expanded{
        @media screen and (min-width: 800px){
            width:325px;
            margin:0;
        }
    }
  ${Template('320px')};

`;

export const wrap = styled.div`
  width: calc(100% - 135px);
  height: 100%;
  border-radius: 6px;
  background-color: #FFFFFFAD;
  display: grid;
  justify-items: end;
  padding-inline-end: 10px;
  grid-template-areas:
    "b b b"
    "c c c"
    "d e e";
    > div {
      background: #EEEEEEAD;
  };

`;
export const leftSquare = styled.div`
  width: 135px;
  height: 100%;
  background: #EEEEEEAD;
  border-radius: 6px;
`;
export const topSquare = styled.div`
width: 90%;
  height: 27px;
  background: #c5c5c5;
  grid-area: b;
  margin-top: 10px;
`;
export const secondSquare = styled.div`
  width: 90%;
  height: 12px;
  background: #c5c5c5;
  grid-area: c;
  margin-bottom: 15px;

`;
export const thirdSquare = styled.div`
width: 70%;
  height: 23px;
  background: #c5c5c5;
  grid-area: d;
`;

export const forthSquare = styled.div`
 width: 90%;
  height: 23px;
  background: #c5c5c5;
  grid-area: e;
`;
