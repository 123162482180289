import * as yup from 'yup';
import TitleStep ,{onClickTitleStep} from './components/titleStep/TitleStep';
import DescriptionStep from './components/descriptionStep/DescriptionStep';
import ArtistsStep, {onClickArtist} from './components/artistsStep/ArtistsStep';
import DateStep, {onClickDate} from './components/dateStep/DateStep';
import LocationStep, {getTopLocation, onClickLocation} from './components/locationStep/LocationStep';
import CategoryStep, {getTopCategory} from './components/categoryStep/CategoryStep';
import {combineDateTime, getDuration, getTs} from '../../library/dates';
import DateTopStep from './components/dateStep/dateTopStep/DateTopStep';
import ArtistTopStep from './components/artistsStep/artistTopStep/ArtistTopStep';
import BudgetItemStep, {getTopBudgetItem, onClickBudgetIndex} from './components/budgetItemStep/BudgetItemStep';
import store from '../../store';
import DuplicateStep, {getTopDuplicate} from './components/duplicateStep/DuplicateStep';
import {yupStringMin} from '../../functions/validationsYup';
import {DEFAULT_LANG, DEFAULT_TIME_ZONE} from '../../globalVariables/locals';
import LocaleStep, {getTopLocale, onClickLocale} from './components/localeStep/LocaleStep';
import LogoStep, {onClickLogo} from './components/logoStep/LogoStep';
import LogoTopStep from './components/logoStep/LogoTopStep';

const title={
    name:'title',
    RightComp:TitleStep,
    onClick:onClickTitleStep,
    isStickContinue:true
};

const description={
    name:'description',
    FullComp:DescriptionStep,
};

const date={
    name:'date',
    RightComp:DateStep,
    onClick:onClickDate,
    TopComp:DateTopStep,
    isShowErrorMessages:true,
    isEmptyField:(value)=>!value.startDate&&!value.endDate
};

const location={
    name:'location',
    FullComp:LocationStep,
    onClick:onClickLocation,
    getTopValue:getTopLocation,
    isEmptyField:(value)=>!value.locationMap?.title
};

const artists={
    name:'artists',
    RightComp:ArtistsStep,
    onClick:onClickArtist,
    TopComp:ArtistTopStep,
    isEmptyField:(value)=>!value?.length
};
const locale={
    name:'locale',
    onClick:onClickLocale,
    RightComp:LocaleStep,
    getTopValue:getTopLocale,
};

const category=(t)=>({
    name:'category',
    RightComp:CategoryStep,
    childrenProps:{},
    getTopValue:(value)=>getTopCategory(value,t),
    isEmptyField:(value)=>value!==0&&!value
});

const budgetIndex={
    name:'budgetIndex',
    RightComp:BudgetItemStep,
    getTopValue:getTopBudgetItem,
    onClick:onClickBudgetIndex,
    isEmptyField:(value)=>value===0
};
const logo={
    name:'logo',
    onClick:onClickLogo,
    TopComp:LogoTopStep,
    RightComp:LogoStep,
};

const duplicate=(t)=>({
    name:'duplicate',
    getTopValue:(value)=>getTopDuplicate(value,t),
    FullComp:DuplicateStep,
    onClick:()=>new Promise(() => {})
});

export const duplicateInitialValues = (type) => [
    {
        key: 'tickets',
        checked: true,
    },{
        key: 'coupons',
        checked: true,
    },
    ...(type==='area' ? [{
        key: 'seats',
        checked: true,
    }]:[]),
    {
        key: 'pixels',
        checked: false,
    },{
        key: 'fields',
        checked: type==='course',
    },{
        key: 'groups',
        checked: false,
    },{
        key: 'eventTeam',
        checked: false,
    }
];
export const newEventSteps={...{logo,title,date,description,category,location,artists,budgetIndex,duplicate,locale}};

const getDateInitialValue=(event)=>({
    startDate:event.timeStart||'',
    startTime:event.timeStart||'',
    timeOpen:event.timeOpen||'',
    duration:event.timeStart?getDuration(event.timeStart,event.timeEnd):'',
    endDate:event.timeEnd||'',
    endTime:event.timeEnd||'',
});

export const newEventInitialValues=(event={})=>{
    return ({
        title:event?.title||'',
        date:getDateInitialValue(event),
        locale:{lang:event.lang||DEFAULT_LANG,
            timeZone:event.timeZone||DEFAULT_TIME_ZONE},
        location:{
            location:event?.location||'',
            locationMap :event?.locationMap ? {
                ...event?.locationMap,
                lat: Number(event?.locationMap?.lat),
                lng: Number(event?.locationMap?.lng)

            }:''},
        artists:event?.artists,
        category:event?.category,
        description:event?.description||'',
        budgetIndex:event?.budgetIndex||0
    });};

const dateSchema = (t)=>yup.object().shape({
    startDate: yup.number().test('startDate', t('errors:requiredError',{name:t('newEvent:startDate')}), function(value) {
        const startTime = this.resolve(yup.ref('startTime'));
        return !(startTime && !value);
    }),
    startTime: yup.number().test('startTime',function (value) {
        const startDate = this.resolve(yup.ref('startDate'));
        const isPast =store.getState().event.ready<5 && combineDateTime(startDate, value) < getTs();
        if(startDate && !value) {
            return this.createError({message: t('errors:requiredError',{name:t('newEvent:startTime')})});
        } else if(isPast) {
            return this.createError({message: t('errors:startTimePastError')});
        }
        return true;}), 
    duration: yup.number().when(['startDate', 'startTime'], {
        is: (startDate, startTime) => !!(startDate || startTime),
        then: yup.number().required(t('errors:requiredError',{name:t('newEvent:eventDuration')})),
    }),        
    timeOpen: yup.number().when(['startDate', 'startTime'], {
        is: (startDate, startTime) => !!(startDate || startTime),
        then: yup.number().test('timeOpen', t('errors:timeOpenError'), function(value) {
            if(!value)
                return true;
            const startTime = this.resolve(yup.ref('startTime'));
            const realStartDate = combineDateTime(getTs(), startTime);
            const realOpenDate = combineDateTime(getTs(), value);
            return realStartDate >= realOpenDate;
        }),
    }),
    endDate: yup.number().when(['startDate', 'startTime'], {
        is: (startDate, startTime) => !!(startDate || startTime),
        then: yup.number().required(t('errors:requiredError',{name:t('newEvent:endDate')}))
        // .min(yup.ref('startDate'), t('errors:startDateAfterEndError')),
    }),
    endTime: yup.number().when(['startDate', 'startTime'], {
        is: (startDate, startTime) => !!(startDate || startTime),
        then: yup.number().required(t('errors:requiredError',{name:t('newEvent:endTime')})).test('endTime', t('errors:startDateAfterEndError'), function(value) {
            if(!value)
                return true;
            const startDate = this.resolve(yup.ref('startDate'));
            const endDate = this.resolve(yup.ref('endDate'));
            const startTime = this.resolve(yup.ref('startTime'));
            const endTime = this.resolve(yup.ref('endTime'));
            const realStartDate = combineDateTime(startDate, startTime);
            const realEndDate = combineDateTime(endDate, endTime);
            return realStartDate < realEndDate;
        }),
    })
});

export const newEventValidationSchema=(t)=>{
    return ({
        title:yup.string().matches(/^(?!.*[<>])(?![!@#$%^&(),.?":{}|[\]`\\/~\s]*$)(.|[\r\n])+$/, t('global:errorTemplate')).min(2,t('global:errorMinLength',{min:2})).max(200,t('global:errorLength')).required(t('global:errorRequired')),
        location: yup.object().shape({
            location:yupStringMin(2,t('global:errorMinLength',{min:2})).max(200,t('global:errorLength')),
        }),
        locale: yup.object().shape({
            lang:yupStringMin(2).max(2).required(),
            timeZone:yupStringMin(2).required()
        }).required(),
        date:  dateSchema(t),
        category:yup.number().nullable(true).required(),
        description:yup.string().matches(/^(?!.*[<>])(?![!@#$%^&(),.?":{}|[\]`\\/~\s]*$)(.|[\r\n])+$/, t('global:errorTemplate')).min(2,t('errorMinLength',{min:2})).required(), 
        budgetIndex:yup.number().required()
    });
};

