import styled from 'styled-components';
import {DEFAULT_COLOR, dFlexCenter} from '../../../globalVariables/GlobalVariables';

export const input=styled.div`
  display:flex ;
  border-bottom:2px solid ${DEFAULT_COLOR} ;
  height:35px; 
  inset-inline-end:2px;
  input[type=text]{
    width:calc(100% - 62px);
    background-color:transparent ;
    border:none;
    height:35px ;
    padding-inline-start:10px;
    font-size:14px ;
    line-height:35px ;
  }
  input[type=text]::placeholder{
    font-size:14px ;
    color:${DEFAULT_COLOR}   ;
  }
  input[type=text]::-webkit-input-placeholder{
    font-size:14px ;
    color:${DEFAULT_COLOR};
  }
`;
export const btn=styled.div`
  width:30px;
  ${dFlexCenter}
`;

