/* eslint-disable @typescript-eslint/no-unused-vars */
import * as XLSX from 'xlsx';
import * as css from './TransactionsReport.css';
import Btn from '../../button/btn/Btn';
import {useTranslation} from 'react-i18next';
import {clock, crm, nisBell, rect} from '../../../assets/symbols/symbols/Symbols.css';
import {useSelector} from 'react-redux';
import {FAILED_RED, SUCCESS_COLOR} from '../../../globalVariables/GlobalVariables';
import {translatedData} from '../../../pages/components/exportExcel/ExcelExportButton';
import ReportLine from './reportLine/ReportLine';
import MobilePopup from '../../newPopup/mobilePopup/MobilePopup';

function TransactionsReport({show}) {
    const {t} = useTranslation('transactions');
    const transactionsReport = useSelector((s:any)=> s.event.transactionsReport);
    const {lang}=useSelector((s:any)=>s.user);
   
    const cssStyle = `
        position: relative;
        width: 680px;
        height: 600px;
    `;

    const exportToExcel = async () => {
        const fileName = 'transactions_report';
        const success = transactionsReport?.details.filter(item => item.status === 1).map(({status, errorMessage, ...rest}) => rest);
        const failed = transactionsReport?.details.filter(item => item.status === 2).map(({status, ...rest}) => rest);
        const translatedDataSuccess = translatedData(success, lang, t, ['title']);
        const translatedDataFailed = translatedData(failed, lang, t, ['title']);
        const book = XLSX.utils.book_new();
        if(lang === 'he') {
            if(!book.Workbook) book.Workbook = {Views:[{RTL: true}]};
            else if(!book.Workbook.Views) book.Workbook.Views = [{RTL: true}];
            else book.Workbook.Views[0].RTL = true;
        }
        const sheet1 = XLSX.utils.json_to_sheet(translatedDataFailed);
        XLSX.utils.book_append_sheet(book, sheet1, t('failedTransactions'));
        const sheet2 = XLSX.utils.json_to_sheet(translatedDataSuccess);
        XLSX.utils.book_append_sheet(book, sheet2, t('successTransactions'));
        XLSX.writeFile(book, `${fileName}.xlsx`);
    };
    
    return <>
        {!!show.isOpen && <MobilePopup close={show.close} showClass={show.showClass} title={t('transactionsReport')} list={undefined} top={undefined} dataCyName={'TransactionsReport'} cssStyle={cssStyle} forwardRef={undefined}>
            <>
                <css.header />
                <css.content>
                    <ReportLine title={t('successTransactions')} value={transactionsReport?.success} icon={crm} color={SUCCESS_COLOR}/>
                    <ReportLine title={t('failedTransactions')} value={transactionsReport?.failed} icon={crm} color={FAILED_RED}/>
                    <ReportLine title={t('tickets')} value={transactionsReport?.tickets} icon={rect} />
                    <ReportLine title={t('duration')} value={transactionsReport?.time} icon={clock} />
                    <ReportLine title={t('total')} value={`${transactionsReport?.sum}₪`} icon={nisBell} />
                    <ReportLine title={t('dateAndTime')} value={transactionsReport?.dateAndTime} icon={nisBell} />
                </css.content>
                <css.buttons>
                    <Btn className={'white'} onClick={show.close} disabled={false}>{t('close')}</Btn>
                    <Btn onClick={exportToExcel} className={undefined} disabled={undefined}>{t('download')}</Btn>
                </css.buttons>
            </>
        </MobilePopup>}
    </>;
}
export default TransactionsReport;