import styled from 'styled-components';
import {BGImageCenter} from '../../../globalVariables/GlobalVariables';

export const role=styled.div`
  background-color:${p=>p.color};
  width: max-content;
  border-radius: 25px;
  padding: 4px 10px;
  padding-inline-start: ${p=>p.role==='waiting'?'10px':'35px'};
  color: ${p=>p.role==='waiting'?'#FFFFFF':'#000000'};
  cursor: ${p => p.role === 'waiting' ? 'pointer' : 'unset'};
  ${p=>BGImageCenter(p.icon,'20px 20px')};
  background-position: ${p=>p.theme.rtl?'calc(100% - 10px)':'10px'} center;
`;
