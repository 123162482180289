import styled from 'styled-components';
import {btn} from '../../../component/button/btn/Btn.css';
import {ellipsisLines, mobileMedia} from '../../../globalVariables/GlobalVariables';
import zIndex from '../../../globalVariables/zIndex';

export const wrapper = styled.div`
    opacity:0 ;
    background-color:#${p=>p.color};
    height: 170px;
    width: 410px;
    position: absolute;
    top: 45px;
    inset-inline-end: 10px;
    border-radius: 5px;
    color: #FFFFFF;
    z-index: ${zIndex.basicModal};
    transition:all 500ms ;
    box-shadow: rgba(45,45,45,.05) 0 2px 2px, rgba(49,49,49,.05) 0 4px 4px, rgba(42,42,42,.05) 0 8px 8px, rgba(32,32,32,.05) 0 16px 16px, rgba(49,49,49,.05) 0 32px 32px, rgba(35,35,35,.05) 0 64px 64px;
    &.show{
        opacity: 1;
    }
    ${btn}{
        margin-inline-start: 10px;
    }
    &::after {
        content: '';
        background-color:#${p=>p.color};
        width: 10px;
        height: 10px;
        position: absolute;
        -webkit-transform: rotate( 45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        top: -5px;
        inset-inline-end: 30px;
        transition:all 300ms;
    }
    div{
        letter-spacing: normal !important;
    }
    ${mobileMedia}{
        width: 315px;
        inset-inline-end: -10px;
        ${ellipsisLines(3)}
    }
`;
