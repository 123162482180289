import {mobileMedia, tabletMedia} from '../../globalVariables/GlobalVariables';

const realtimeFonts={
    title:`
        line-height: 41px;
        font-weight: 500;
        font-size: 40px;
        ${tabletMedia}{
            font-size: 24px;
        }  
        ${mobileMedia}{
            font-size: 30px;
            line-height: 50px;
        }  
    `,
    windowTitle:`
        font-weight: 500;
        font-size: 22px;
        ${tabletMedia}{
            font-size: 18px;
        }
        ${mobileMedia}{
            font-size: 14px;
        }        
    `,
    mainDataBold:`
        font-weight: 500;
        font-size: 40px;
        @media screen and (max-width:1100px) and (min-width:1000px){
            font-size: 38px;
        }    
        ${mobileMedia}{
            font-size: 24px;
        }      
    `,
    mainData:`
        font-size: 40px;
        letter-spacing:-1.36px;
        ${mobileMedia}{
            font-size: 24px;
        }      
    `,
    textBold:`
        font-weight: 700;
        font-size: 14px;
    `,
    text:`
        font-size: 14px;
        ${mobileMedia}{
            font-size: 12px;
        }
    `,
    amount:`
        font-size: 24px;
        font-weight: 500;
        ${mobileMedia}{
            font-size: 18px;
        }
    `,
    numbers:`
        font-weight: 800;
        font-size: 14px;
    `,
    value:`
        font-size: 40px;
        ${tabletMedia}{
            font-size: 24px;
        }
    `,
    subText:`
        font-size: 12px;
    `
};

export default realtimeFonts;
