import styled from 'styled-components';
import {tabletMedia} from '../../../globalVariables/GlobalVariables';
import {tooltip} from '../../../component/tooltip/tooltipAbsolute/TooltipAbsolute.css'; 

export const EventMenuStats=styled.div`

    padding-top:${p=>p.theme.mini?0: 20}px;
    @media screen and (max-width: 1000px){
        display:flex;
    }
    
`;

export const futureWrap=styled.div`
    display: flex;
    justify-content: space-between;
   ${tabletMedia}{
        justify-content: start;
    }
    padding-inline-end: 25px;
    padding-inline-start: 5px;
`;
export const future=styled.div`
width: 70px;
text-align: start;
position: relative;
${tooltip}{
    &::before {
        inset-inline-start:-15px;
        inset-block-start:-20px;
    }
}
&.end{
    text-align: end;
    ${tabletMedia}{
        text-align: start;
        padding-inline-start: 50px;
    }
    ${tooltip}{
    &::before {
        inset-inline-start:35px;
        inset-block-start:-20px;
    }
    &::after{
        content:unset;
    }
}
}
`;
export const futureSum=styled.div`
font-weight: 700;
font-size: 16px;
line-height: 15px;
`;
export const futureText=styled.div`
white-space: nowrap;
font-size: 12px;
line-height: 10px;

`;

