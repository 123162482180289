import styled from 'styled-components';
import {packageImage, pencil} from '../../../assets/symbols/symbols/Symbols.css';
import {BGImageCenter, boxShadow, DEFAULT_COLOR, mobileMedia} from '../../../globalVariables/GlobalVariables';
import {optionsIcon} from '../memberOption/MemberOption.css';

export const container=styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;
export const option=styled.div` 
  width:20px;
  height:20px;
  cursor: pointer;
  position: relative;
  margin-top: 10px;
  ${optionsIcon}{
    transform: rotate(90deg);
  }

`;

export const image=styled.div`
transition: background-color 400ms;
  cursor: pointer;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  ${p=>BGImageCenter(p.image,'contain')};
  ${boxShadow};  
  background-color:#AAA;
  &:not(&.disabled):hover{
    background-color:${DEFAULT_COLOR};
    ${BGImageCenter(pencil,'20px')};
  }
  &.isPackageRole{
      position: relative;
      &::after{
        content:'';
        width:20px;
        height:20px;
        ${BGImageCenter(packageImage,'18px')};
        top: 65px;
        inset-inline-end: 2px;
        position: absolute; 
        ${mobileMedia}{
          top: 50px;
        }
      }
  }
  ${mobileMedia}{
    width: 70px;
    height: 70px;
  }
`;
export const name=styled.div`
 font-weight: 500;
  margin: 10px 0;
  max-width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  
`;
