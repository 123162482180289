import styled from 'styled-components';
import {btn} from '../../button/btn/Btn.css';
import {shareImageBase, sIBImage} from '../FullEditImage.css';

export const shareImage = styled(shareImageBase)`
      `;

export const image = styled(sIBImage)`
`;

export const wrapImage = styled.div`
  padding:10px 30px; 
  display: flex;
  justify-content: center;
`;

export const text = styled.div`
    padding-inline-end: 10px;
    color:white;
`;

export const wrapBtns = styled.div`
   display:flex ;
   ${btn}{
      padding:0 12px ;
      height:25px;
   }
`;
export const wrapAll = styled.div`
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
`;

export const wrapBtn = styled.div`
    padding-inline-end:5px;
`;