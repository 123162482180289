import styled from 'styled-components';
import {BGImageCenter, GRAY, dFlexCenter, mobileMedia} from '../../../globalVariables/GlobalVariables';
import {googleWallet} from '../../../assets/symbols/symbols/brandingIcons';
import {eyeSection,wrapper as eyeWrapper} from '../../components/eyeHideSection/EyeHideSection.css';
import {btn as googleBtn} from '../../../component/googleWalletBtn/GogleWalletBtn.css';
import {btn} from '../../../component/button/btn/Btn.css';
export const wrapper=styled.div`
    display: flex;
    justify-content: center;
    padding-bottom: 15px;
    gap: 10px;
    >${btn}{
       margin: 0 7px;
    }
    >div{
      ${googleBtn}{
      background-color: ${GRAY};
      border-color: ${GRAY};
      }
      ${eyeSection}{
            width: 20px;
            height: 100%;
            inset-inline-end: 4px;
            ${dFlexCenter}
            top: 0;
      } 
    } 
     ${mobileMedia}{
      flex-direction: column;
      align-items: center;
      ${btn}{
         width: 170px;
      }
      div{
      ${googleBtn}{
         width: 170px;
      }
      ${eyeWrapper}{
            width: 170px;
      }
    } 
 }
`;

export const icon=styled.div`
   width: 30px;
   height: 30px;
   ${BGImageCenter(googleWallet,'25px')}
`;
