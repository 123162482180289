import styled from 'styled-components';
import {btn} from '../btn/Btn.css';

export const Counter = styled.div`
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: ${props => props.typeCss.circleColor};
    position:absolute ;
    inset-inline-end:4px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
`;
export const space = styled.div`
    width:22px ;

`;
export const btnCount = styled.div`
        padding: 0 6px;
        line-height: 12px;
        font-size: 13px;
        background-color: ${props => props.typeCss.backgroundColor};
        color:white;
        margin-right: 0px;
        &:hover{
            background-color:white;
            color:${props => props.typeCss.backgroundColor};
            border-color:white ;
        }
`;
export const btnProgressBar = styled.div`
    background-image: ${props => 'linear-gradient(' + props.progressBarDeg + 'deg, transparent 50%,' + props.progressBarColor + ` 50%),
     linear-gradient(90deg,`+ props.typeCss.backgroundColor + ' 50%, transparent 50%)'};
     background-color:${props => props.typeCss.circleColor};
     padding: 3px;
     margin-right: 10px;
     border-radius: 28px;
     ${btn}{
        margin: auto;
        height: 30px;
        border: 1px solid #EEE;
     }
`;
export const seconds = styled.div`
 
`;
