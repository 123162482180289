import styled from 'styled-components';
import {scrollbar} from '../../../../globalVariables/GlobalVariables';

const BUTTON_HEIGHT='50px';
export const wrap=styled.div`
height: 100%;
/* border: solid; */
padding: 0 30px;

`;
export const regulation=styled.div` 
height: calc(100% - ${BUTTON_HEIGHT});
${scrollbar}
    white-space: pre-line;
    padding-top: 40px;
    padding-bottom: 30px;
`;

export const regulationTitle=styled.div` 
font-size:16px;
font-weight: 800;
`;

export const acceptBtn=styled.div` 
width: 100%;
display: flex;
justify-content: flex-end;
align-items: center;
height: ${BUTTON_HEIGHT};

`;
