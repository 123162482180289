import TabsPopup from '../../../../../component/newPopup/tabsPopup/TabsPopup';
import {createRealtimeMessage} from '../../../../../redux/realtime/realtimeActions';
import FormContext from '../../../../components/formContext/FormContext';
import MessageForm from './messageForm';
import PopBottom from './popBottom';
import * as Yup from 'yup';
import {useTranslation} from 'react-i18next';
import {combineDateTime, getTs} from '../../../../../library/dates';
import useErrorMessages from '../../../../../hooks/useErrorMessages';
import {useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import setGlobalState from '../../../../../redux/setGlobalState';
import FormikModeBtn from '../../../../../component/formikModeBtn/FormikModeBtn';
import * as css from './form.css';
import {uploadFile} from '../../../../../redux/user/userActions';
function SystemMessagePop({showClass, close}) {

    const {t} = useTranslation('realtime');
    const [type, setType] = useState('text');
    const systemMessageEdit = useSelector((s: any)=> s.realtime.systemMessageEdit);
    const defaultTab = systemMessageEdit?systemMessageEdit.title? 0:1:0;
    const tabsList =  [{type: 'text', stageText: t('text'), Comp: MessageForm},
        {type: 'image', stageText: t('image'), Comp: MessageForm}
    ];

    useEffect(()=>{
        if(defaultTab !== null) {
            setType(defaultTab? 'image' : 'text');
        }
    },[defaultTab]);

    const initialValues= useMemo(()=>({
        title:'',
        message:'',
        link:'',
        design:'',
        image:'',
        ...systemMessageEdit,
        distributionRole: systemMessageEdit?.distributionRole?.split(',') || [],
        publishDate: systemMessageEdit?.publishTime || getTs(),
        publishHour: systemMessageEdit?.publishTime || getTs(),
        expirationDate: systemMessageEdit?.expirationTime,
        expirationHour:  systemMessageEdit?.expirationTime,
        displayInWindow: systemMessageEdit? !!systemMessageEdit?.displayInWindow :true,
        displayInPop: !!systemMessageEdit?.displayInPop,
        watchRequired: !!systemMessageEdit?.watchRequired,    
        status:!!(systemMessageEdit?.status??true)
    }),[systemMessageEdit]);

    useErrorMessages();

    const validation={
        title:type === 'text'?Yup.string().required():Yup.string(),
        message:Yup.string(),
        link:Yup.string(),
        linkText:Yup.string().nullable(),
        publishDate: Yup.date(),
        publishHour: Yup.date(),
        expirationDate: Yup.date().nullable(),
        expirationHour: Yup.date().nullable(),
        viewCount: Yup.number().min(-1).max(10).test('not-zero', t('notZeroError'), value => value !== 0),
        distributionRole: Yup.array().min(1).required(),
        officeTitle: Yup.string().required(),
        watchRequired: Yup.boolean(),
        // distributionType: Yup.string().required(),
        status: Yup.boolean(),
        image:type === 'image'?Yup.string().required(): Yup.string().nullable(),
        displayInPop: Yup.boolean(),
        displayInWindow: Yup.boolean()
    };

    const onSubmitForm = async (_changedValues,values) => {
        const publishTime =combineDateTime(values.publishDate, values.publishHour);
        const expirationTime = combineDateTime(values.expirationDate, values.expirationHour);
        const watchRequired = values.watchRequired? 1:0;
        const displayInPop = values.displayInPop? 1:0;
        const displayInWindow = values.displayInWindow? 1:displayInPop? 0:1;
        const newMessage = {...systemMessageEdit, ...values, publishTime, expirationTime, watchRequired, displayInPop, displayInWindow};
        delete newMessage.publishDate;
        delete newMessage.publishHour;
        delete newMessage.expirationDate;
        delete newMessage.expirationHour;
        newMessage.image = await uploadFile(newMessage.image,  '',  'realtimeMessageImage');
        createRealtimeMessage(newMessage).then(()=> onclose());
    };

    const onclose= ()=>{
        close();
        setGlobalState('realtime.systemMessageEdit', null);
    };
    const bottomComp= {Comp: PopBottom, close: onclose};
    return (
        <FormContext
            enableReinitialize
            initialValues={initialValues}
            validationSchema={ Yup.object().shape(validation)}
            onSubmit={onSubmitForm}
        >
            <TabsPopup showClass={showClass} tabsList={tabsList} onClickStage={setType}
                bottomComp={bottomComp} currentIndex={defaultTab}
                header={{title: '', close: onclose}} top={undefined} image={undefined} color={undefined} stageChildren={undefined}  >
                <css.top>
                    <FormikModeBtn name='status' className="small" forwardRef={undefined} />
                </css.top>
            </TabsPopup>
        </FormContext>
    );
}

export default SystemMessagePop;