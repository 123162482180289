import styled from 'styled-components';
import * as images from '../../../assets/symbols/images/Images.css';
import {arrowDark} from '../../../assets/symbols/symbols/Symbols.css';
import {btn} from '../../../component/button/btn/Btn.css';
import {BGImageCenter, tabletMedia, tabletScreen} from '../../../globalVariables/GlobalVariables';
import {BIG_SCREEN, MIDDLE_SCREEN, SMALL_SCREEN} from '../pageWrapper/PageWrapper.css';
import zIndex from '../../../globalVariables/zIndex';
export const subMenu = styled.div`
    z-index: ${zIndex.baseHigh};
    background: rgb(232 232 232 / 0%);
    width: 100%;
    height: 65px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    border-top: solid 1px #d7d7d7;
    border-bottom: solid 1px #d7d7d7;
    @media screen and (max-width: 1000px){
        display:block;
        position: fixed;
        bottom: 0;
        height:50px;
        transition:all 400ms;    
        background:#76D6E8;     
        &.show{
            height:${props => props.height}px;
            background:#FFFFFF;
            align-items: start;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            .moreOp{
                visibility:hidden;
            }
        }
    }
    
`;
export const subMenuBackground = styled.div`
    backdrop-filter:unset;
    transition:all 400ms;
    transition-delay: 400ms;
    transition-property: height;
    ${tabletScreen}{
      pointer-events:none;
    div{
        pointer-events:all;
    }
    }
    &.show{
        transition:all 400ms; 
        pointer-events:all;
        -webkit-backdrop-filter:brightness(60%) blur(10px);
        backdrop-filter: brightness(60%) blur(10px);
        width: 100%;
        height: 100%;
        bottom: 0;
        position: fixed;
        z-index: ${zIndex.baseHighest};
    }
     
`;
export const subMContain = styled.div`
    display:flex;
    align-items: center;
`;

export const iconBtn = styled.div`
    font-size:12px;
    font-weight:400;
    position:relative;
    padding:25px 7px;
    display:flex ;
    justify-content:center ;

`;

export const moreOp = styled(iconBtn)`
    &::before{
        content: '+';
        position: absolute;
        top: -1px;
        width: 100%;
        text-align: center;
        font-size: 24px;
        right: calc(50% - 23px);
        font-weight: 700;
        color: #2a2929;
    }
`;

export const smOperations = styled.div`
   height:100% ;
   width:max-content ;
   display: flex;
   align-items: center;
   ${btn}{
        margin-inline-end:5px;
      ${tabletMedia}{
          width:100% ;
          margin-top: 6px;
        }
        &.gray{
          background-color:#E8E8E8 ;
        }
   }
   ${tabletMedia}{
      display: block;
      height: max-content;
   }
`;

export const smImage = styled.div`
    width:25%;
    height: 100%;
    position:relative;
  @media screen and (max-width:${BIG_SCREEN}) and (min-width:${MIDDLE_SCREEN}){
    width:110px;
    padding: 0;
  }
  @media screen and (max-width:${SMALL_SCREEN}){
    width:110px;
    padding: 0;
  }
  &.openLeft{
    width:430px;
    padding-inline:30px;
  }
    &.ticketsGroups::before{
       width: 127px;
       height: 133px;
       background-position: center;
       background-size: contain;
       top: -30px;
       left: 30%;
      pointer-events: none;
    }
    &::before{
        content:'';
        background:url("${props => images[props.image]}");
        background-repeat: no-repeat;
        position: absolute;
        width: 100%;
        height: 100px;
        background-position: center;
        background-size: 120px 120px;
        top: -10px;
        @media screen and (max-width: 1000px){
            content:unset;
        }
    }

`;
export const smOp = styled.div`
    width: max-content;
    border: 1px solid;
    padding: 5px 10px;
    border-radius: 30px;
    cursor: pointer;
    margin: 0 5px;
    background: #8FDEED;
    &:hover{
        background:#FFFFFF;
    }
    @media screen and (max-width: 1000px){
        margin: 5px auto;
        background: #FFFFFF;
        padding: 2px 15px;
        font-weight: 500;
        width:100%;
        text-align:center;
    } 
`;
export const close = styled.div`
    width: max-content;
    margin: 5px auto;
    text-align: center;
    padding-top:6px;
    font-size:12px;
`;

export const warpAll = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-inline: 60px;
    width:75%;
    min-width: 1080px;
    max-width:calc(100% - 70px);
    transition: all 300ms;
   &.changeCss{
    justify-content: flex-end;
   }
    @media screen and (max-width:${SMALL_SCREEN}){
    width:calc(100% - 190px);
    min-width:unset;
  }
  @media screen and (max-width:${BIG_SCREEN}) and (min-width:${MIDDLE_SCREEN}){
    width:calc(100% - 190px);
    min-width:unset;
  }
  &.openLeft{
    width:calc(100% - 430px);
  }
  ${tabletMedia}{
     display:block;
     margin: 0 auto;
     padding-top: 15px;
     width:max-content;
    &:not(.show){
        display:none;
    }
   
} 
`;

export const plus = styled.div`
    font-size: 17px;
    width: 16px;
`;

export const right = styled.div`
  display:flex ;
  align-items:center;
  margin-inline-end: auto;
  
`;
export const pageHeader = styled.div`
  font-weight: bolder;

`;

export const back = styled.div`
    width: 45px;
    height: 50px;
    ${BGImageCenter(arrowDark,'18px')}
    background-position: center right;
    ${props=>!props.theme.rtl&&'transform: rotate(180deg)'};
`;