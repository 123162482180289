import styled from 'styled-components';

export const bodyChildren = styled.div`

`;
export const topChildren = styled.div`
`;
export const groupForm = styled.div`
`;
export const bottomChildren = styled.div`
    display: flex;
    padding: 17px;
`;

export const topTopChildren = styled.div`
    float: ${p=>p.theme.rtl?'left':'right'};
    margin-top: 22px;
`;
export const formFields = styled.div`
`;
export const formImage = styled.div`
`;
export const snail = styled.div`
`;
export const btns = styled.div`
    display: flex;
    width: 130px;
    justify-content: space-between;
    position: absolute;
    inset-inline-end: 23px;
    top:26px;
    
`;
export const link = styled.div`
    background-color: #fff;
    border-radius: 50%;
    position: absolute;
    ${props => props.theme.rtl ? 'left' : 'right'}: 56px;
    top: 16px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
`;