import styled from 'styled-components';
import {search} from '../../assets/symbols/symbols/Symbols.css';
export const Search=styled.div`
    width:100%;
    height:50px;
    display:flex;
    cursor:pointer;
    &.focus{
      input{
        border-bottom:2px solid #76D6E8;
      }
    }   
`;
export const SearchClose=styled.div`
    background-image: url("${search}");
    background-repeat:no-repeat;
    background-position:center center;
    background-size: 12px 12px;
    width:12px;
    margin-inline-start: -30px;
    transition:all 400ms;
    height:100%;
    &:hover:not(.close){
         transform: scale(1.5);
    }
    &.close{
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32.88 32.88'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:none;stroke:%23484848;stroke-width: 4px;stroke-miterlimit:10;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3EArtboard 1%3C/title%3E%3Cline class='a' x1='2.96' y1='3.4' x2='29.19' y2='29.63'/%3E%3Cline class='a' x1='2.96' y1='29.63' x2='29.19' y2='3.4'/%3E%3C/svg%3E");

    }
`;
export const SearchText=styled.div`
    width:calc(100%);
    height: 100%;
    font-size: 16px;
    padding:10px 20px;
    display:flex;
    align-items: center;

`;
export const SearchInput=styled.input`
    width:100%;
    border:0;
    background:transparent;
    padding:0 10px;
    font-size: 18px;    
`;