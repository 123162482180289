import styled from 'styled-components';
import {btnPlus} from '../../../../component/button/btnPlus/BtnPlus.css';

export const wrapper = styled.div`
    margin: 20px;
    height:90px;
    width:90px;
    border-radius: 50%;
    border: 2px dashed #F3F3F3;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  ${btnPlus}{
    border:unset;
    &:hover{
      background-color: unset;
    }
  }
`;

export const title = styled.div`
  font-weight: 600;
  margin: 10px auto;
  text-align: center;
`;

export const wrapAll = styled.div`
  width: max-content;
`;