/* eslint-disable spellcheck/spell-checker */
import styled from 'styled-components';
import * as scroll  from '../../../component/scrollbar/Scrollbar.css';

export const icon=styled.div`
    background-repeat: no-repeat;
    height: 17px;
    width: 18px;
    background-size: contain;
    background-position: center;
    margin-${props => props.theme.rtl ? 'left' : 'right'}: 10px;
`; 

export const subjectIcon=styled(icon)`
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17.809' height='16.949' viewBox='0 0 17.809 16.949'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:%232c2c2c;%7D%3C/style%3E%3C/defs%3E%3Cpath class='a' d='M245.3,262.451c-1.381-2.33-.815-5.8.631-7.968a.691.691,0,0,1,.944-.184.71.71,0,0,1,.288.407h0c.03.124.06.238.084.348a7.376,7.376,0,0,1,.611-1.426,6.941,6.941,0,0,1,3.4-3.035.684.684,0,0,1,.879.4.7.7,0,0,1-.015.512c-.134.308-.248.616-.348.9a11.552,11.552,0,0,1,3.577-2.181c2.692-1.018,5.762-.72,6.93,2.345a.691.691,0,0,1-.4.879l-.065.025c-2.29.611-3.144,2.106-3.959,3.512-1.113,1.967-2.171,3.805-5.916,3.745a8.325,8.325,0,0,0,1.008.368.69.69,0,0,1,.452.854.667.667,0,0,1-.343.407c-1.719.969-4.739,1.664-6.676.9a3.129,3.129,0,0,0-.4,2.558h1.878a.406.406,0,0,1,.407.407.415.415,0,0,1-.407.417h-2.424l-.06-.01a.674.674,0,0,1-.735-.536,4.517,4.517,0,0,1,.661-3.651Zm5.007,4.2h7.1a.412.412,0,0,0,.412-.417.4.4,0,0,0-.412-.407h-7.1a.408.408,0,0,0-.417.407.417.417,0,0,0,.417.417Zm-3.855-4.928h0c.884.959,3.492.333,4.55.01a2.77,2.77,0,0,1-1.058-1.078c-.248-.477-.387-1.515.576-1.4,4.158.542,5.121-1.143,6.15-2.951a7.464,7.464,0,0,1,4.009-3.9c-2.161-3.547-8.4.452-9.473,2.9a.679.679,0,0,1-1.326-.214,12.571,12.571,0,0,1,.358-2.27,5.823,5.823,0,0,0-1.182,1.485,4.165,4.165,0,0,0-.546,3.145.678.678,0,0,1-1.222.551c-.3-.467-.646-.909-.9-1.4a6.288,6.288,0,0,0,.065,5.126Z' transform='translate(-244.513 -249.7)'/%3E%3C/svg%3E");
    height: 100%;
`;

export const contentMiddle=styled(scroll.ScrollBarArrow)`
    height: 100%;
    width: 100%;
    cursor:text;
    border: none;
    resize: none;
    padding: 1px;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    .contentEditable{
        height: 100%;
        width: 100%; 
    }
    .contentEditable:focus{
        outline: 0px solid transparent;
    }
    .menuPopUp{
        right: -200px;
        top: -35px;
    }
    .menuPopUp.show{
        right: 31px;
    }
    :focus {
        outline: 0px solid transparent;
        border:none
    }
    & span{
        color:#76d6e8;
        cursor:pointer;
        border-bottom: 1px solid;
        position: relative;
        display: inline-block;
        padding: 0px 4px;
        line-height: 1.1;
        transition: all 200ms ease 0s;
        -webkit-user-select: none; /* Chrome/Safari */        
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* IE10+ */
    
        /* Not implemented yet */
        -o-user-select: none;
        user-select: none;    
    }
    & span:hover{
        border:1px solid;
        border-radius: 28px;
        padding: 0 5px 0 20px;
    }
    & span:hover:before{
        content:'';
        width:10px;
        height:10px;        
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8.344' height='6.434' viewBox='0 0 8.344 6.434'%3E%3Cdefs%3E%3Cstyle%3E .cls-1 %7B fill: none; stroke: %2376d6e8; stroke-linecap: round; stroke-width: 2px; %7D %3C/style%3E%3C/defs%3E%3Cpath id='Path_2879' data-name='Path 2879' class='cls-1' d='M233.982,21l2.766,3.434L239.514,21' transform='translate(-232.576 -19.594)'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        left: 6px;
        top: 4px;
    }
`;

export const subjectContent=styled(contentMiddle)`
    width: 80%;
    border-${props => props.theme.rtl ? 'right' : 'left'}: 1px solid;
    padding-${props => props.theme.rtl ? 'right' : 'left'}: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &[data-placeholder]:empty:before{
        content: attr(data-placeholder);
        font-weight: 600;
    }
    :focus {
        border-${props => props.theme.rtl ? 'right' : 'left'}: 1px solid;
    }
`;

export const subject=styled.div`
    padding: 0 20px;
    width: 75%;
    display: flex;
    @media screen and (max-width: 750px){  
        width: 55%;
    } 
`;
