import styled from 'styled-components';
import {redVerification} from '../../assets/symbols/images/redVerification';
import {greenCertificate, vi} from '../../assets/symbols/symbols/Symbols.css';
import {BGImageCenter, DEFAULT_COLOR, dFlexCenter, mobileMedia} from '../../globalVariables/GlobalVariables';
import {POPUP_TOP} from '../newPopup/topPopup/TopPopup.css';
import zIndex from '../../globalVariables/zIndex';
const progressHeight='50px';

export const wrap=styled.div`
    padding: 60px;
    padding-top: 10px;
    width: 100%;
    height: calc(100% - ${POPUP_TOP});
  ${mobileMedia}{
    padding: 10px;
  }
    `;

export const progress=styled.div`
    width: 100%;
    height: ${progressHeight}; 
    position: relative;
    ${mobileMedia}{
    margin: 0 2.5%;
    width:95%;
}
    `;

export const circles=styled.div` 
    position: relative; 
    display: flex;
    justify-content: space-evenly;
    align-items: flex-end;
    height: 100%;
    width: 100%;
    &::before{
        content: '';
        position: absolute;
        bottom: 0;
        height: 4px;
        width: 100%;
        background-color: red;
        background: transparent linear-gradient(271deg, #F00000 0%, #C6D600 74%, #C6D600 100%) 0% 0% no-repeat padding-box;
    }
    ${mobileMedia}{
    &::before{
        height: 2px;
    }
}
`;

export const circle=styled.div`
    cursor: pointer;
    flex: 1;
    z-index: ${zIndex.baseRaised};
    line-height: 15px;
    padding-bottom: 13px;
    height : 50px;
    text-align: center;
    position: relative;
    ${dFlexCenter}
    &::before{
        content: "${p=>p.index}";
        ${dFlexCenter}
        position: absolute;
        font-weight: 800;
        background-color: white;
        height: 26px;
        width: 26px;
        line-height: 26px;
        border-radius: 50%;
        border:2px solid ${DEFAULT_COLOR};
        bottom: -13px;
    }
    &.complete{
        &::before{
        background-color: #C6D628;
        ${BGImageCenter(vi,'11px')}
        border-color: white;
        }
    }
    &.current{
        &::before{
        background-color:${DEFAULT_COLOR};
        border-color: white;
        }
    }
    `;

export const firstImage=styled.div`  
    position: absolute;
    ${BGImageCenter(redVerification,'90px')}
    width: 100px;
    height: 100px;
    inset-inline-start: -45px;
    z-index: ${zIndex.baseHigh};
    transition: all 300ms;
    transform: scale(0.5);
    opacity: 0;
    /* opacity: 0.8; */
    &.show{
        transform: scale(1);
        opacity: 1;
}
${mobileMedia}{
    display: none;
}
`;

export const lastImage=styled(firstImage)`  
    ${BGImageCenter(greenCertificate,'100px')}
    inset-inline-start: unset;
    inset-inline-end: -58px;
`;

export const body=styled.div`  
    height: calc(100% - ${progressHeight});
    background-color: #26B5EB2B;
    border: 1px solid #76D6E8;
    border-top:0px ;
    border-radius: 0 0 6px 6px;
  ${mobileMedia}{
    overflow: auto;
    border-radius: 6px;
    margin-top:-1px;
  }
`;