import styled from 'styled-components';
import {mobileMedia, scrollbar} from '../../globalVariables/GlobalVariables';
import {icons} from '../../component/rightHeader/RightHeader.css';

export const BRANDING_PAGE_WIDTH='630px';

export const container=styled.div`
	height: 100%;
	width: 100%;
  ${icons}{
    display: none;
  }
`;
export const languageBtn=styled.div`
	display: flex;
  justify-content: flex-end;
  width: 100%;
`;

export const designEmailArea = styled.div`
  display: flex;
  align-items: flex-start;
  height: 100%;
  ${mobileMedia}{
        flex-direction: column;
        align-items: center;
    }
`;

export const left = styled.div`
    display:flex;
    flex-wrap: wrap;
    gap: 20px;
    padding-bottom: 30px;  
    width: calc(100% - 85px);
    height: 100%;
    justify-content: center;
    ${scrollbar}
    ${mobileMedia}{
        align-items: center;
        width:100%;
        padding-bottom: 85px;
        max-width: ${BRANDING_PAGE_WIDTH};
    }
`;