import styled from 'styled-components';
import zIndex from '../../../../globalVariables/zIndex';
import {dFlexCenter, ellipsis, LIGHT_GRAY, mobileMedia} from '../../../../globalVariables/GlobalVariables';

export const chartContainer = styled.div`
    display: flex;
    align-items: center; 
    width: 100%;
    min-height: 55%;
    position: relative; 
    z-index: ${zIndex.baseRaised};
  
    *:focus {
        outline: none;
    }
    ${mobileMedia} {
        min-height: 65%;
    }
`;

export const container = styled.div`
    flex-direction: column;
    ${dFlexCenter};
    width: 30%;
    height: 100%;
    ${mobileMedia} {
        height: 260px;
        margin-top: 20px;
        width: 100%;
    }
    *:focus {
        outline: none;
    }
`;
export const ticketName = styled.div`
    font-weight: bold;
    font-size: 14px;
    ${ellipsis};
    text-align: center;
    width: 100px;
`;export const amounts = styled.div`
    font-size: 12px;

`;export const sold = styled.div`
    font-weight: bold;
    font-size: 24px;
`;

export const summaryTitle = styled.div`
margin-bottom: 5px;
    font-size: 16px;
    span{
        font-weight: bold;
    }
`;
export const summary = styled.div`
    background-color: ${LIGHT_GRAY};
    padding-inline: 7px;
    border-radius: 25px;
    margin-bottom: 4px;
    span{
        font-weight: bold;
    }
`;

export const summaryContainer = styled.div`
    text-align: center;
    margin-top: 15px;
   
`;

