import styled from 'styled-components';
import {BGImageCenter, dFlexCenter} from '../../globalVariables/GlobalVariables';

export const plus = styled.div`
        width:32px;
        height: 32px;
        border-radius: 50%;
        box-shadow:unset;
        background-color:#76D6E8;
        ${dFlexCenter};
        cursor:pointer;
        &:hover{
            background-color:#FFF;
            border:1px solid #76D6E8;
        }
        &::after{
            content: "+";
            color: #000;
            font-size: 20px;
            font-weight: 600;
        }
`;

export const wrapPlus=styled.div`
    margin: 4px;
`;
export const container = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
`;
export const icon = styled.div`
    width: 17px ;
    height: 17px;
    border-radius: 50%;
    ${props=>BGImageCenter(props.icon,'17px')}
    margin-inline-end:10px;
`;

export const text = styled.div`

    font-size: 12px;
`;

export const list = styled.div`
    width: 180px;
`;
export const values = styled.div`
    font-weight: 600;
    padding-inline-end: 10px;
`;
export const wrapAll = styled.div`
  position:relative ;
  display:flex ;
  flex-flow: wrap;
  align-items:center ;
  width:100%;
`;
export const wrapText = styled.div`
  font-weight:600 ;
`;
