import styled from 'styled-components';
import {btnPlus} from '../button/btnPlus/BtnPlus.css';

export const wrapper = styled.div`
    height:${p=>p.size?p.size:90}px;
    width:${p=>p.size?p.size:90}px;
    //margin:10px;
    border-radius: 50%;
    border: 2px dashed #76D6E8;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  ${btnPlus}{
    border:unset;
    &:hover{
      background-color: unset;
    }
  }
`;

export const title = styled.div`
    font-weight: 600;
     margin: 10px auto;
  text-align: center;
`;

export const wrapAll = styled.div`
  width: max-content;
`;