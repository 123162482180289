import styled from 'styled-components';
import zIndex from '../../globalVariables/zIndex';

export const boxofficeIframe = styled.div`
    iframe{
        display: block;
        position: fixed;
        border: none;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: background-color 500ms ease 0s;
        z-index: ${zIndex.upperModal};
    }
`;