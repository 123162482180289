import styled from 'styled-components';
import {mobileMedia} from '../../../globalVariables/GlobalVariables';

export const participantsGrid=styled.div`
    display: flex;
    flex-wrap: wrap;
    width: fit-content;
    margin: 0 auto;
    padding-bottom: 30px;
    ${mobileMedia}{
        width:320px ;
    }
    div{
        letter-spacing: normal;
    }
`;

export const wrapRouting=styled.div`
    display: flex;
    justify-content:space-between ;
    width:100% ;
`;

