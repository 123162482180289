import styled from 'styled-components';
import {whiteArrow, fullArrow, simpleArrow, ex} from '../../../assets/symbols/symbols/Symbols.css';
import {BGImageCenter, mobileMedia, DEFAULT_COLOR, dFlexCenter} from '../../../globalVariables/GlobalVariables';

export const wrapperWidth = styled.div`
  ${dFlexCenter}
`;
export const wrapper = styled.div`
  font-size: 13px;
  font-weight: 600;
  height: 26px;
  padding-inline:2px;
  border: 1px solid;
  border-radius: 25px;
  transition: all 300ms;
  ${dFlexCenter}
  cursor: pointer;
    ${p => p.theme.isCurrent && `
        border-color: ${DEFAULT_COLOR};  background-color:${DEFAULT_COLOR};
   `};
    &:hover{
    ${p => !p.theme.direction && `border-color: ${DEFAULT_COLOR}; color:${DEFAULT_COLOR}`};
    }
    ${mobileMedia}{
        width:max-content ;
  }

`;
export const cancel = styled.div`
  width:12px;
  height: 15px;
  margin-top: 0.5px;
  border-radius: 50%;
  transition: width 300ms;
  &.active{
    ${BGImageCenter(ex,'7px')}
  }
`;

export const text = styled.div`
  height:20px;
  line-height: 20px;
`;
export const arrow = styled.div`
  width: 14px;
  margin-inline-start: 3px;
  ${dFlexCenter}
  flex-direction: column;
  &::before, &::after {
      content: '';
      width: 8px;
      height: 7px;
      transition: all 200ms 150ms;
  }
  &::before {
    transform: rotate(${p => p.theme.action === 'filter' ? '90deg' : '180deg'});
    ${p => BGImageCenter(p.theme.action === 'filter' ? simpleArrow : fullArrow, 'contain')};
    ${p => p.theme.direction === 'des' && BGImageCenter(whiteArrow, 'contain')};
  }
  &::after {
    ${p => p.theme.action !== 'filter' ? BGImageCenter(fullArrow, 'contain'):'display:none;'};
    ${p => p.theme.direction === 'asc' && BGImageCenter(whiteArrow, 'contain')};
  }
`;