import React, {useEffect, useState} from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'quill-emoji/dist/quill-emoji.css';
import 'quill-emoji';
import * as css from './TextEditor.css.js';
import {SnowTheme} from 'quill-color-picker-enhance';
import 'quill-color-picker-enhance/dist/index.css';
import {addNoDelete, addVariables, convertVariablesToHtml, deltaToHtml, replaceVariablesInDelta} from './Variables.js';
import {useVariables} from '../../assets/objects/Variables.js';
import VariableDropdown from './VariablesDropdown.js';
import {useTranslation} from 'react-i18next';
import {removeTagsFromText} from '../../pages/branding/emailSubjectTextEditor/EmailSubjectTextEditor.js';
import DOMPurify from 'dompurify';
import {SMS_CHARS_ON_MESSAGE, SMS_FREE_CHARS} from '../../pages/branding/smsEditor/SmsEditor.js';
import {DEFAULT_LANG} from '../../globalVariables/locals.js';
import XRegExp from 'xregexp';

function TextEditor({defaultText, onSave, onChange, showToolbar = false,limit={}, quillRef, dependencies = [], lang=DEFAULT_LANG}) {
    const Quill = ReactQuill.Quill;
    Quill.register('themes/snow-quill-color-picker-enhance', SnowTheme);
    const {t} = useTranslation('variables');
    const [text, setText] = useState('');
    const {defaultVariables, disabledVariables} = useVariables();
    const [showWarning, setShowWarning] = useState(false);
    const variables = {...defaultVariables, ...disabledVariables};
    const {showCharCounter,maxChar,showMessagesAmount}=limit;

    const direction=lang!=='he'?'ltr':'rtl';
    const convertTextBeforeSave = ()=>{
        const quill = quillRef.current.getEditor();
        const delta = quill.getContents();
        const contentWithPlaceholders = replaceVariablesInDelta(delta);
        const htmlOutput = deltaToHtml(contentWithPlaceholders);
        return htmlOutput;

    };
    const saveText = () => {
        onSave(convertTextBeforeSave());
    };

    useEffect(() => {
        if(quillRef.current ) {
            addVariables();
            addNoDelete();
            const quill = quillRef.current.getEditor();
            const htmlWithValues = defaultText ? convertVariablesToHtml(defaultText, variables, t) : '';
            quill.setContents(quill.clipboard.convert(DOMPurify.sanitize(htmlWithValues)));
        }
    }, dependencies);

    const cleanText = (text) => {
        const regexPattern = XRegExp('[' +
            '\\x00-\\x09' +   // ASCII Control characters from 0x00 to 0x09
            '\\x0B-\\x1F' +   // ASCII Control characters from 0x0B to 0x1F
            '\\x7F-\\x9F' +   // More control characters
            '\\u00A0' +       // Non-breaking space
            '\\u200B-\\u200F' + // Zero-width space and bidirectional text controls
            '\\u00AD' +       // Soft hyphen
            '\\u2060' +       // Word joiner
            '\\uFEFF' +       // Zero width no-break space (Byte Order Mark)
            ']', 'g');
    
        const cleanedText = XRegExp.replace(text, regexPattern, '');
        return cleanedText;
    };

    const handlePaste = (e) => {
        const clipboardData = e.clipboardData || window.clipboardData;
        const pastedData = clipboardData.getData('text');
        const sanitizedData = DOMPurify.sanitize(pastedData);
        const cleanData = cleanText(sanitizedData);
        const editor = quillRef.current.getEditor();
        const currentText = editor.getText();
        const selection = editor.getSelection(true);
        const replacementLength = selection ? selection?.length : 0;
        const futureLength = currentText.length - replacementLength + cleanData.length;
    
        if(futureLength > maxChar) {
            e.preventDefault();
        } else {
            e.preventDefault();
            if(selection) {
                editor.deleteText(selection?.index, selection?.length);
                editor.insertText(selection?.index, cleanData, 'user');
            } else {
                const cursorPosition = editor.getSelection()?.index;
                editor.insertText(cursorPosition, cleanData, 'user');
            }
        }
    };

    useEffect(() => {
        if(quillRef?.current) {
            const quill = quillRef?.current?.getEditor();
            const checkForDisabledVariables = () => {
                const disabled = hasDisabledVariables();
                setShowWarning(disabled);
            };
            // fix bug in color-picker position
            const toolbarElement = quill.getModule('toolbar').container;
            const createAndAppendInput = (className) => {
                const input = document.createElement('input');
                input.type = 'color';
                input.hidden = true;
                input.className = className;
                toolbarElement.appendChild(input);
            };
            createAndAppendInput('custom-picker color');
            createAndAppendInput('custom-picker background');

            checkForDisabledVariables();
            quill?.on('text-change', checkForDisabledVariables);
            quill.root.addEventListener('paste', handlePaste);
            return () => {
                quill.off('text-change', checkForDisabledVariables);
                quill.root.removeEventListener('paste', handlePaste);
            };
        }
    }, []);

    const modules = {
        toolbar: [
            ['bold', 'italic', 'underline'],
            [{'color': 'custom-picker'}, {'background': 'custom-picker'}],
            ['emoji'],
            ['variableDropdown']
        ],
        'emoji-toolbar': true,
        'emoji-textarea': false,
        'emoji-shortname': true,
        history: {
            delay: 500,
            maxStack: 100,
            userOnly: true,
        }
    };
       
    const hasDisabledVariables = () => {
        const quill = quillRef?.current?.getEditor();
        if(quill) {
            const html = quill?.root?.innerHTML;
            return Object.keys(disabledVariables).some((key) => html.includes(`data-label="${key}"`));
        }
        return false;
    };

    const removeHiddenSpace=(string) =>string.split('').filter((_,index)=>string.charCodeAt(index)!==65279).join('');
    const clearText=(string)=>removeTagsFromText(removeHiddenSpace(string));

    const handleTextChange = (value) => {
        const clearValue=clearText(value);
        if(onChange && value){     
            onChange(convertTextBeforeSave());
        }
        setText(clearValue);
    };

    const isMaxLengthReached = maxChar ? text.length >= maxChar : false;

    const handleKeyDown = (event) => {
        if(isMaxLengthReached && event.key !== 'Backspace') {
            event.preventDefault();
        }
    };

    return (
        <css.wrap direction={direction} showToolbar={showToolbar} showCharCounter={showCharCounter}>
            <VariableDropdown direction={direction} quillRef={quillRef} remainingChars={maxChar-text.length}/>
            <ReactQuill
                onChange={handleTextChange}
                onKeyDown={handleKeyDown}
                ref={quillRef}
                onBlur={saveText}
                modules={modules}
                theme='snow-quill-color-picker-enhance'
            />
            {showCharCounter&&<css.maxChar>
                {`${text.length}/${maxChar} `}
                <b>{t('chars')}   </b>
                {(showMessagesAmount&& text.length>SMS_FREE_CHARS)&&
                <>{`${t('messagesCount')}:${(Math.ceil((text.length-SMS_FREE_CHARS) / SMS_CHARS_ON_MESSAGE))} `}
                    <span>{t('additionalCost')}</span>
                </>}
            </css.maxChar>}
            {showWarning && <css.warning>{t('warningDisabled')}</css.warning>}
        </css.wrap>
    );
}

export default TextEditor;
