import styled from 'styled-components';
import {BGImageCenter, mobileMedia} from '../../../../../globalVariables/GlobalVariables';
import {message} from '../../../../../assets/symbols/symbols/Symbols.css';

export const openMessagePopup = styled.div`
    height: 60px;
    width: 35px;
    cursor: pointer;
    ${BGImageCenter( message, '25px 25px')};
    transition:all 400ms;
    &:hover{
        transform: scale(1.1);
    }
    ${mobileMedia}{
        position: absolute;
        inset-block-end: -25px;
        inset-inline-start: 30px;
    }
 `;