import styled from 'styled-components';
import * as scroll  from '../../../component/scrollbar/Scrollbar.css';
const HEIGHT = p=>p.theme.realtime && p.theme.mini ? '80px' : '240px';
export const Menu=styled(scroll.Scrollbar)`
   height: calc(100% - ${HEIGHT});
    //height:calc(100% - 411px);
    @media screen and (max-width: 1000px){
       // height:calc(100% - 300px);
       height:calc(100% - 193px);
    }
`;