import styled from 'styled-components';
import {BGImageCenter, LIGHT_GRAY2, dFlexCenter} from '../../globalVariables/GlobalVariables';
import {copyLink} from '../../assets/symbols/symbols/Symbols.css';
import zIndex from '../../globalVariables/zIndex';

export const copy = styled.div`
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 12px;
    cursor: pointer;
`;

export const iconLink = styled.div`
position: relative;
  width: 13px;
  height: 13px;
  ${BGImageCenter(copyLink,'contain')};
  margin-inline-start: 5px;
`;

export const copied = styled.div`
      position: absolute;
      font-size: 12px;
      width: max-content;
      height: 30px;
      padding: 0 20px;
      border-radius: 30px;
      background: ${LIGHT_GRAY2};
      font-weight: 600;
      z-index: ${zIndex.baseRaised};
      ${dFlexCenter}
`;

