import styled from 'styled-components';
import realtimeFonts from '../../fonts';
import zIndex from '../../../../globalVariables/zIndex';

export const explainContainerIn = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 10px 20px;
    z-index: ${zIndex.baseHigh};
`;

export const explainExplain = styled.div`
    margin-bottom: 6px;
`;
export const explainLine = styled.div`
    ${realtimeFonts.textBold};
    margin-bottom: 4px;
`;

export const explainList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

