import styled from 'styled-components';
import {blur} from '../../../../component/list/List.css';

export const ticketsList = styled.div`
    height: 50px;
    width: 70px;
    position: absolute;
    left: 0;
    top: -30px;
    ${blur}{
        position: unset;
    }
`;
