import styled from 'styled-components';
import zIndex from '../../globalVariables/zIndex';
export const Scrollbar=styled.div`
    overflow: auto;
    &::-webkit-scrollbar {
        width: 0;
        height: 0;
    }
`;

export const ScrollBarArrow = styled.div`
    overflow: auto;

    &::-webkit-scrollbar {
        width: 0px;
        z-index:${zIndex.baseHighest};
        cursor:auto;
    }

    &:hover::-webkit-scrollbar {
        width: 17px;
    }
    
    /* Track */
    &::-webkit-scrollbar-track {
        // box-shadow: inset 0 0 5px grey; 
        border-radius: 10px;
    }
    
    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #a8a7a7; 
        border-radius: 10px;
    }
    
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        cursor: move;
    }
`;
