import styled from 'styled-components';
import {bottom, container} from '../../newPopup/innerPop/InnerPop.css';
import {BGImageCenter} from '../../../globalVariables/GlobalVariables';
import {creditImage} from '../../../assets/symbols/images/creditImage';
export const wrapAll = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    ${bottom}{
        gap: 10px;
    }
    ${container}{
        &.show{
            width:100%;
        }
    }
`;
export const content = styled.div`
    height: calc(100% - 60px);
    padding: 40px;
`;
export const image = styled.div`
    width: 150px;
    height: 150px;
    margin: 0 auto;
    ${BGImageCenter(creditImage, 'cover')}
`;
export const title = styled.div`
    font-size: 16px;
    font-weight: 600;
    line-height: 3;
`;
export const text = styled.div`
    line-height: 1.5;
    &.color{
        color: RED !important;
    }
    &.bold{
        font-weight: 600;
    }
`;
