import styled from 'styled-components';
import {Items, List} from '../../../../component/list/List.css';
import {mobileMedia} from '../../../../globalVariables/GlobalVariables';

export const wrapper=styled.div`
    height: 150px;
    width: 100%;
    ${List}{
        top:25px;
        ${mobileMedia}{ 
            top:100%;
            &.show{
                top:30%;
            }
        }
    }
    ${Items}{
        height:120px;
        ${mobileMedia}{ 
            height:70%;
        }
    }
`;