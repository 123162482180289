import styled from 'styled-components';
import {BGImageCenter, mobileMedia} from '../../../../globalVariables/GlobalVariables';
import * as input from '../../../../component/classes/input';
import {btn} from '../../../../component/button/btn/Btn.css';
import {textWrap ,title as inputTitle} from '../../../../component/input/basicInput/BasicInput.css';
import zIndex from '../../../../globalVariables/zIndex';

export const container = styled.div`
    max-width:calc(100vw - 50px) ;
    height: 130px;
    display:flex ;
    border-radius: 5px;
    position: relative;
    width:370px ;
    overflow: hidden;
    box-shadow: 0 3px 6px #00000029;
    opacity: 1;
    transition: all 300ms;
    &.deleted{
      opacity: 0;
    }
    ${mobileMedia}{
        width: 350px;
    }
`;

export const right = styled.div`
  width:70px;
  position:relative ;
  display:flex ;
  align-items:center ;
  box-shadow:-2px 2px 5px 1px #00000029;
  border-radius:${p => p.theme.rtl ? '0 5px 5px 0' : '5px 0 0 5px'};
  ${p=>BGImageCenter(p.color,'96px')}
  background-color: #43BAE7;
  &::before{
    content: '';
    width: 100%;
    height: 100%;
    z-index: ${zIndex.baseRaised};
    transition: all 300ms;
    background-color: transparent;
    border-radius:${p => p.theme.rtl ? '0 5px 5px 0' : '5px 0 0 5px'};
  }
  &.edit{
    &::before{
      background-color: #FFFFFF;
    }
  }
`;

export const left = styled.div`
    width: calc(100% - 70px);
    background-color: transparent;
    transition: all 200ms;
    padding-inline-start: 15px;
    ${btn}{
      position: absolute;
      inset-inline-end: 8px;
      bottom: 8px;
      height: 25px;
      line-height: 25px;
      opacity: 0;
      &.LOADING{
        width: 25px
      }
    }
    &.edit{
      padding-inline-start: 10px;
      background-color: #F9F9F9;
    ${btn}{
      opacity: 1;
    }
    }
`;

export const options = styled.div`
    display: flex;
    height: 30px;
    align-items: center;
    margin-inline-end: 10px;
    justify-content: flex-end;
`;

export const texts = styled.div`
    position: relative;
    padding: 0 20px;
    width: 100%;
    input{
      margin-inline-start:0;
      font-weight: normal;
      font-size: 13px;
    }
    ${textWrap}{
      inset-inline-start: 0;
    }
    ${inputTitle}{
      font-weight:normal ;
    }
  ${input.container}{
    padding: 0 0 20px 0;
  }
  ${input.wrap}{
    margin-inline-start: 0px;
  }
  ${input.icon}{
    display: none;
  }
  &:not(.edit){
    ${input.input}{
      border: none;
    }
    ${input.title}{
      display: none;
    }
  }
`;

export const text = styled.div`
 font-size: 14px;
`;

export const title = styled(text)`
    height: 17px;
    padding-bottom: 3px;
    font-size: 14px;
    font-weight: bold;
    transition: all 300ms;
    &.edit{
      font-weight: 600;
      font-size: 12px;
      height: 21px;
    }
`;
