import styled from 'styled-components';
import convertHexToRgbA from '../../../../../functions/ConvertHexToRgbA';
import {dFlexCenter,mobileScreen,mobileMedia} from '../../../../../globalVariables/GlobalVariables';

export const wrapInput = styled.div`
   width:50% ;
   padding: 10px;
   @media screen and (max-width:${mobileScreen}px) {
        width: 100%;
    }
`;

export const wrapAll = styled.div`
    display: flex;
    width: 100%;
    padding: 40px 80px 20px;
    @media screen and (max-width:${mobileScreen}px) {
        padding: 40px 30px 20px;
        display:block ;
    }
`;

export const container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top: 2px solid #76D6E8;
    padding-bottom:35px ;
`;

export const wrapCB = styled.div`
    width: ${props => props.theme.rtl ? 135:190}px;
    padding-top: 15px;
    padding-${props => props.theme.rtl ? 'right' : 'left'}:25px;
      .classicCheckbox{
        width: 12px;
        height: 12px;
        border: 1px solid #484848;
    }
`;

export const wraper = styled.div`
    padding-bottom:40px ;
    display: flex;
    align-items: center;
    justify-content: space-between;
    ${mobileMedia} {
      display: block;
    }
`;

export const wrapText = styled.div`
  width: ${props => props.theme.rtl ? 85:78}%;
  @media screen and (max-width:${mobileScreen}px) {
        width: 100%;
    }
`;

export const bottomTitle = styled.div`
    font-size: 14px;
    letter-spacing: 2px;
    padding-top: 20px;
`;

export const title = styled.div`
    padding: 5px 0 10px 0;
    font-size: 13px;
`;

export const wrapPopup = styled.div`
   .header{
       &::after{
        background-position: inherit;
        background-size: 177px;
       }
   }
   .Button{
         width:70px ;
    }
    .bottom{
        justify-content: flex-end;
        padding: 0 60px;
    }
`;

export const plus = styled.div`
        width:17px;
        height: 17px;
        border:1px solid #76D6E8;
        border-radius: 50%;
        box-shadow:unset;
        background-color:#76D6E8;
        ${dFlexCenter}
        &:hover{
            box-shadow:0px 0px 0px 5px rgba(${convertHexToRgbA('#76D6E8')}, 0.5);
        }
        &::after{
            content: "+";
            color: #fff;
            font-size: 19px;
            font-weight: 600;
            height: 100%;
            line-height: 15px;
        }
`;
export const textBtn=styled.div`
    margin-${props => props.theme.rtl ? 'right' : 'left'}:7px;

`;
export const wrapBtn=styled.div`
    display: flex;
    padding: 0 5px;
    align-items: center;
`;
