import styled from 'styled-components';

export const contentBottom=styled.div`
    height: 50px;
`;

export const charsCounter=styled.div`
    padding: ${props => props.theme.rtl ? '15px 32px 0 0' : '15px 0 0 32px'};
    font-size: 14px;
    float: ${props => props.theme.rtl ? 'right' : 'left'};
`;

export const textStyling=styled.div`
display:flex;
float: ${props => props.theme.rtl ? 'left' : 'right'};
padding: 15px 40px;
>div{
    background-repeat: no-repeat;
    height: 20px;
    width: 25px;
}
`;
export const textEmoji=styled.div`
background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='19' height='19' viewBox='0 0 19 19'%3E%3Cdefs%3E%3Cstyle%3E.a,.c,.e%7Bfill:none;%7D.a,.b,.c%7Bstroke:%232c2c2c;%7D.a%7Bstroke-width:2.5px;%7D.b%7Bfill:%232c2c2c;%7D.c%7Bstroke-linecap:round;stroke-linejoin:round;stroke-width:1.3px;%7D.d%7Bstroke:none;%7D%3C/style%3E%3C/defs%3E%3Cg class='a'%3E%3Ccircle class='d' cx='9.5' cy='9.5' r='9.5'/%3E%3Ccircle class='e' cx='9.5' cy='9.5' r='8.25'/%3E%3C/g%3E%3Cg class='b' transform='translate(6 7)'%3E%3Ccircle class='d' cx='1.5' cy='1.5' r='1.5'/%3E%3Ccircle class='e' cx='1.5' cy='1.5' r='1'/%3E%3C/g%3E%3Cg class='b' transform='translate(10 7)'%3E%3Ccircle class='d' cx='1.5' cy='1.5' r='1.5'/%3E%3Ccircle class='e' cx='1.5' cy='1.5' r='1'/%3E%3C/g%3E%3Cpath class='c' d='M-4708.041,1562.893l2.468,1.24,2.64-1.24' transform='translate(4715 -1551)'/%3E%3C/svg%3E");
`;
export const fontColor=styled.div`
background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13.975' height='15.32' viewBox='0 0 13.975 15.32'%3E%3Cdefs%3E%3Cstyle%3E.a,.b%7Bfill:none;stroke:%232c2c2c;%7D.a%7Bstroke-linecap:round;stroke-linejoin:round;stroke-width:2.5px;%7D.b%7Bstroke-width:1.6px;%7D%3C/style%3E%3C/defs%3E%3Cg transform='translate(-441.929 -437.011)'%3E%3Cpath class='a' d='M-4634.428,1566.231l5.366-12.427,5.325,12.427' transform='translate(5078 -1115.542)'/%3E%3Cline class='b' x2='8.973' transform='translate(444.613 447.502)'/%3E%3C/g%3E%3C/svg%3E");
`;
export const textUnderline=styled.div`
background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14.22' height='17.166' viewBox='0 0 14.22 17.166'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:%232c2c2c;%7D%3C/style%3E%3C/defs%3E%3Cg transform='translate(-510.416 -483.286)'%3E%3Cpath class='a' d='M520.453,484.673h0a1.373,1.373,0,1,1,2.747,0v8.718a3.173,3.173,0,0,1-.979,2.3l.01.04a3.358,3.358,0,0,1-2.337.959H515.17a3.338,3.338,0,0,1-2.336-.959l-.09-.12a3.221,3.221,0,0,1-.879-2.217v-8.718a1.378,1.378,0,1,1,2.756,0v8.718a.515.515,0,0,0,.11.34l.04.04a.556.556,0,0,0,.4.15h4.724a.526.526,0,0,0,.4-.15h0a.528.528,0,0,0,.159-.379v-8.718Z' transform='translate(0 0)'/%3E%3Cpath class='a' d='M512.884,486.375h0a.928.928,0,0,1-1.017-.809.95.95,0,0,1,1.017-.829h12.16a.951.951,0,0,1,1.043.829c0,.459-.452.809-1.043.809Z' transform='translate(-1.451 14.077)'/%3E%3C/g%3E%3C/svg%3E");
`;
export const textBold=styled.div`
background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10.555' height='15.537' viewBox='0 0 10.555 15.537'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:%232c2c2c;%7D%3C/style%3E%3C/defs%3E%3Cpath class='a' d='M507.723,479.169a3.169,3.169,0,0,0,.2-1.09V475.2a2.9,2.9,0,0,0-.236-1.189,2.93,2.93,0,0,0-.654-.989l-.1-.091a3.128,3.128,0,0,0-.907-.58l-.082-.036a3.13,3.13,0,0,0-1.089-.217h-6.2a1.3,1.3,0,0,0-1.289,1.289v12.968a1.287,1.287,0,0,0,1.289,1.28h6.2a3.134,3.134,0,0,0,1.089-.2l.082-.036a2.965,2.965,0,0,0,.907-.589l.1-.064a3.705,3.705,0,0,0,.654-1.007,2.846,2.846,0,0,0,.236-1.17v-2.9a3.207,3.207,0,0,0-.2-1.089l-.036-.082a2.846,2.846,0,0,0-.354-.617,3.073,3.073,0,0,0,.354-.617Zm-2.36,5.4a.532.532,0,0,1-.036.2.367.367,0,0,1-.118.154l-.036.044a.411.411,0,0,1-.118.074l-.036.008a.465.465,0,0,1-.163.036h-4.928v-3.929h4.946a.475.475,0,0,1,.182.036.453.453,0,0,1,.154.108v.019a.293.293,0,0,1,.118.145l.009.036a.519.519,0,0,1,.027.163Zm0-6.49a.681.681,0,0,1-.027.173l-.009.036a.531.531,0,0,1-.118.164.589.589,0,0,1-.154.1.352.352,0,0,1-.182.036h-4.946v-3.92h4.928a.461.461,0,0,1,.163.027h.036a.475.475,0,0,1,.118.09l.036.045a.294.294,0,0,1,.118.173.572.572,0,0,1,.036.2Z' transform='translate(-497.367 -472.1)'/%3E%3C/svg%3E");
`;