import styled from 'styled-components';
import {dFlexCenter} from '../../../../globalVariables/GlobalVariables';

export const container = styled.div`
    padding: 100px 35px 45px 35px ;  
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    input{
        width: 40px;
        height: 50px;
        margin:0 3px;
        font-size: 16px;
    }
`;

export const title=styled.div`  
    font-size: 20px;
    font-weight: 800;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-bottom: 20px;
`;

export const text=styled.div`  
    font-weight: 500;
    font-size: 16px;
`;

export const bottom=styled.div`  
    ${dFlexCenter}
    justify-content: flex-end;
    flex-direction: column;
    width: 100%;
    height: 130px;
    div:last-child{
        margin-top: 20px;
    }
`;

