import styled from 'styled-components';
import {BGImageCenter, BLACK, scrollbar} from '../../globalVariables/GlobalVariables';

export const container = styled.div<{ image?: string; }>`
    height: 100%;
    color: ${BLACK};
    padding: 20px;
    margin-block: 40px;
    &::before{
        content:'';
        width: 100%;
        height: 150px;
        position:absolute;  
        inset-inline-start: 0px;
        ${p=>BGImageCenter(p.image,'contain')} 
        top: -70px;
    }
    ${scrollbar}
`;
